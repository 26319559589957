import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import FormPopUp from '../../../form/FormPopUp';
import { InterviewRoom } from '../../../../types';
import CandidateInfo from './CandidateInfo';
import {
  InfoWrap,
  SectionTitle,
  Description,
  StageInfoWrap,
  Blander,
} from './styles';
import Timeline from './Timeline';
import ClientTasks from './Tasks/ClientTasks';
import DisabledClientTasks from './Tasks/DisabledClientTasks';
import ClientOfferTasks from './Tasks/ClientOfferTasks';
import DisabledClientOfferTasks from './Tasks/DisabledClientOfferTasks';
import RecruiterTasks from './Tasks/RecruiterTasks';
import DisabledRecruiterTasks from './Tasks/DisabledRecruiterTasks';
import RecruiterOfferTasks from './Tasks/RecruiterOfferTasks';
import DisabledRecruiterOfferTasks from './Tasks/DisabledRecruiterOfferTasks';
import Footer from './Footer';
import useInterviewStageModal, { useStages } from './hooks';
import { useAppSelector } from '../../../../store/hooks';
import { CLIENT_ROLE, RECRUITER_ROLE } from '../../../../constants/roles';
import {OFFERED, ON_HOLD, REJECTED} from '../../../../constants/statuses';

interface IhandleEndProcess {
  interviewId: string,
  candidateID: string,
  stage_name: string,
  reason_for_change: string,
  from: string,
  stage_id: string,
  is_rejection: boolean,
}

interface InterviewStageModalProps {
  interview: InterviewRoom | { [key:string]: any };
  setShowInterviewStageModal: (show: boolean) => void,
  visible: boolean,
  handleSwitchStage: (
    interviewId: string,
    candidateId: string,
    nextStageName: string,
    currentStageName: string,
    hasUncompletedTasks: boolean,
  ) => void,
  handleEndProcess: ({
    interviewId,
    candidateID,
    stage_name,
    reason_for_change,
    from,
    stage_id,
    is_rejection
  }: IhandleEndProcess) => void,
  currentStage: string,
}

const InterviewStageModal = ({
  interview,
  setShowInterviewStageModal,
  visible,
  handleSwitchStage,
  handleEndProcess,
  currentStage,
}: InterviewStageModalProps) => {
  const [t] = useTranslation();

  const { user } = useAppSelector((state) => state.user);

  const {
    actions: {
      handleCandidateNameClick,
      handleCloseModal,
      setSelectedStageid
    },
    data: {
      candidate,
      candidateInfo,
      client,
      selectedStageId,
      currentStageId,
    },
  } = useInterviewStageModal({ interview, setShowInterviewStageModal });

  const { data: { stages } } = useStages(interview?.job?.id);

  const disabledWidgets = !!selectedStageId && currentStageId !== selectedStageId;

  const isClient = user?.role === CLIENT_ROLE;
  const isRecruiter = user?.role === RECRUITER_ROLE;
  const showTasks = selectedStageId && currentStageId === selectedStageId;
  const isOfferedStage = currentStageId === OFFERED;
  const isOfferedStageSelected = selectedStageId === OFFERED;
  const isOnHoldStage = currentStageId === ON_HOLD;
  const isOnHoldStageSelected = selectedStageId === ON_HOLD;
  const showOfferedStage = isOfferedStage || isOfferedStageSelected;
  const blockOffSyncActions = false;

  const handleSwitchStageInTimeline = (nextStageName: string) => {
    const hasUncompletedTasks = interview.current_stage?.tasks?.filter(
      (task: any) => task.status !== 'completed',
    ).length;

    handleSwitchStage(
      interview.id,
      candidate.id,
      nextStageName,
      currentStage,
      hasUncompletedTasks,
    );
  };

  const handleEnd = useCallback(() => handleEndProcess({
    interviewId: interview.id,
    candidateID: candidate.id,
    stage_name: REJECTED,
    reason_for_change: '',
    from: currentStage,
    stage_id: '',
    is_rejection: true,
  }), [interview, candidate, currentStage, handleEndProcess]);

  const description = useMemo(() => {
    if (disabledWidgets) {
      const selectedStage = stages.find((stage) => stage.id === selectedStageId);
  
      return selectedStage?.description || '';
    }
    return interview?.current_stage?.description;
  }, [stages, selectedStageId, disabledWidgets, interview?.current_stage?.description]);

  return (
    <FormPopUp
      visible={visible}
      setVisible={handleCloseModal}
      title={isOfferedStage ? t('OFFER') : interview?.current_stage?.stage_name}
    >
      <InfoWrap>
        <CandidateInfo
          name={candidate.name}
          role={candidateInfo.current_role}
          company={candidateInfo.current_company}
          cv={candidate.cv}
          linkedin={candidate.linkedin_link}
          handleClick={handleCandidateNameClick}
        />
      </InfoWrap>

      <SectionTitle>{t('TIMELINE')}</SectionTitle>
      <Timeline
        selectedStageId={selectedStageId}
        setSelectedStageid={setSelectedStageid}
        currentStageId={currentStageId}
        handleSwitchStage={handleSwitchStageInTimeline}
        stages={stages}
        blockOffSyncActions={blockOffSyncActions}
      />

      <StageInfoWrap>
        {selectedStageId !== OFFERED && currentStageId !== OFFERED && !isOnHoldStage && !isOnHoldStageSelected && (
          <>
            <SectionTitle>{t('DESCRIPTION')}</SectionTitle>
            <Description>
              {description}
            </Description>
          </>
        )}

        {!isOnHoldStage && !isOnHoldStageSelected &&(
          <SectionTitle>{t('TASKS')}</SectionTitle>
        )}
        {isClient && !isOfferedStage && !isOfferedStageSelected && !isOnHoldStage && !isOnHoldStageSelected && (
          <>
            {showTasks && !blockOffSyncActions ? (
              <ClientTasks
                interview={interview}
              />
            ) : (
              <DisabledClientTasks />
            )}
          </>
        )}

        {isClient && showOfferedStage && (
          <>
            {showTasks && !blockOffSyncActions && !isOnHoldStage && !isOnHoldStageSelected ? (
              <ClientOfferTasks
                interview={interview}
              />
            ) : (
              <DisabledClientOfferTasks />
            )}
          </>
        )}

        {isRecruiter && !isOfferedStage && !isOfferedStageSelected && !isOnHoldStage && !isOnHoldStageSelected && (
          <>
            {showTasks ? (
              <RecruiterTasks
                interview={interview}
              />
            ) : (
              <DisabledRecruiterTasks />
            )}
          </>
        )}

        {isRecruiter && showOfferedStage && !isOnHoldStage && !isOnHoldStageSelected && (
          <>
            {showTasks ? (
              <RecruiterOfferTasks
                interview={interview}
              />
            ) : (
              <DisabledRecruiterOfferTasks />
            )}
          </>
        )}

        {disabledWidgets && (
          <Blander />
        )}
      </StageInfoWrap>

      <Footer
        companyId={client?.company?.id}
        recruiterId={interview?.recruiter?.id}
        interviewId={interview.id}
        handleEndProcess={handleEnd}
        blockOffSyncActions={blockOffSyncActions}
      />
    </FormPopUp>
  )
};

export default InterviewStageModal;
