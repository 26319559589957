import { useTranslation } from 'react-i18next';
import MessageRatings from "./MessageRatings";
import styled from 'styled-components';

const MessageRatingDisclaimer = styled.p`
  font-weight: normal;
  font-size: 0.8rem;
  margin-top: 2rem;
`;

type RatingRequestMessageProps = {
  onChange: (value: number) => void;
};

const RatingRequestMessage = ({ onChange }: RatingRequestMessageProps) => {
  const { t } = useTranslation();
  return (
    <>
      <MessageRatings onChange={onChange} />
      <MessageRatingDisclaimer>* {t("rating responses are anonymous")}</MessageRatingDisclaimer>
    </>
  )
};

export default RatingRequestMessage;
