import { EmploymentTypeValue } from '../../../../types';

export const mapEmploymentTypeFormToApi = (formData: any) => ({
  type: {
    ...formData.type,
  },
  values: Object.entries<object>(formData.values[formData.type.id].field).map(([key, value]) => ({
    field: {
      id: key,
    },
    ...value,
  })),
});

export const mapEmploymentTypeApiToForm = (apiData: any) => ({
  ...apiData,
  values: {
    [apiData.type.id]: {
      field:
        (apiData.values).reduce((acc: any, entry: EmploymentTypeValue) => {
          return {
            ...acc,
            [entry.field.id]: {
              numeric_value: entry.numeric_value,
              text_value: entry.text_value
            }
          }
        }, {}),
    }
  },
});
