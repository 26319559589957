import { History } from 'history';

export const navigate = (
  history: History,
  add?: { [key: string]: string },
  remove?: string[],
  pathname?: string,
) => {
  const params = new URLSearchParams(history.location.search);
  let url: { [key: string]: string } = { search: '?' };
  if (add) {
    Object.keys(add).forEach((el) => params.set(el, add[el]));
  }
  if (remove) {
    remove.forEach((el) => params.delete(el));
  }
  if (pathname) {
    url[pathname] = pathname;
  }
  url.search = '?' + params.toString();
  history.push(url)
};

export const openInNewTab = (url: string): void => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}
