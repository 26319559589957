import { ReactNode } from 'react';

import {
  TasksListItem,
  TasksListItemWrap,
  ListItemTitle,
  ListItemTitleButton,
} from './styles';
import { ReactComponent as CheckIcon } from '../../../../../icons/check-icon-brand.svg';

interface Iprops {
  title: string,
  isActive?: boolean,
  isActiveLine?: boolean,
  isDone?: boolean,
  children?: ReactNode,
  handleClick?: () => void,
  isButton?: boolean,
}

const ListItem = ({
  isActive,
  isActiveLine,
  isDone,
  title,
  children,
  handleClick,
  isButton
}: Iprops) => (
  <TasksListItem isActive={isActive} isActiveLine={isActiveLine}>
    <div className="list-item-bullet">
      {isActive && isDone && (
        <CheckIcon />
      )}
    </div>
    <TasksListItemWrap>
      {isButton ? (
        <ListItemTitleButton onClick={handleClick}>
          {title}
        </ListItemTitleButton>
      ) : (
        <ListItemTitle
          onClick={handleClick}
          isBold={isActive || isDone}
        >
          {title}
        </ListItemTitle>
      )}
      {children}
    </TasksListItemWrap>
  </TasksListItem>
);

export default ListItem;