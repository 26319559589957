import React from 'react';
import { Radio, RadioGroupProps } from 'antd';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import RadioInput from '../inputs/RadioInput';

interface TabRadioGroupProps extends RadioGroupProps {
  data: { title: string | number; value: string | number }[];
  customClass?: string;
}

const RadioTab = styled(Radio.Group)`
  width: 100%;
  display: flex;
  align-items: stretch;
  margin: 2.5rem 0;
  &.mb-0{
  margin-bottom: 0;
  }
  &.simple-form-radio {
    margin: 0;
  }
  & label {
    width: 100%;
    margin-right: 1rem;
    padding: 1.25rem 1rem;
    border-radius: 8px;
    & span {
      font-size: 14px;
      font-weight: bold;
      line-height: 1.43;
      color: #061c2e;
    }
    border: solid 1px #dae5ec;
    &.ant-radio-wrapper-checked {
      border-radius: 8px;
      background-color: #f2f6fa;
      border: none;
    }
    &:last-child {
      margin-right: 0;
    }
  }
`;

const TabRadioButton = ({
  onChange,
  value,
  data,
  customClass,
  ...rest
}: TabRadioGroupProps) => {
  const history = useHistory();
  const params = new URLSearchParams(history.location.search);
  const recruiter =
    params.get('recruiter') || history.location.pathname.includes('recruiters');
  const client =
    params.get('client') || history.location.pathname.includes('clients');
  return (
    <RadioTab className={customClass ? customClass : ''} onChange={onChange} value={value} {...rest}>
      {data.map((el) => (
        <RadioInput
          value={el.value}
          key={el.value}
          disabled={
            !!(
              (recruiter && el.value === 'client') ||
              (client && el.value === 'recruiter')
            )
          }
        >
          {el.title}
        </RadioInput>
      ))}
    </RadioTab>
  );
};

export default TabRadioButton;
