import React, { useRef, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Badge } from 'antd';
import {
  format,
  formatRelative,
  isPast,
  isYesterday,
  isToday,
  isTomorrow,
} from 'date-fns';
import Header from '../layout/Header';
import NameAvatar from '../../UI/NameAvatar';

import { useAppSelector } from '../../store/hooks';
import helloImage from '../../icons/spaceman.svg';
import chevronRightIcon from '../../icons/icon-chevron-right.svg';
import ListWidget from '../dashboard/ListWidget';
import { ClientDashboardCandidates } from '../dashboard/ClientDashboardCandidates';
import { DashboardAction } from '../../types';
import {
  getClientMetrics,
  getClientDashboardRequiredActions,
  getClientDashboardUpcomingActions,
} from '../../api/dashboard';
import { useHistory } from 'react-router';
import { CANDIDATES, JOBS } from '../../constants/routes';
import { getRandomColor } from '../../constants/palette';
import { CalendarWidget } from '../calendar/CalendarWidget';
import {
  INTERVIEW_ID_PARAM,
  STAGE_PARAM,
  SHOW_DATE_PROPOSE_PARAM,
  HISTORY_PARAM,
  SHOW_FEEDBACK_PARAM,
  SHOW_OFFER_PARAM,
} from '../../constants/queryParams';

interface StageInterface {
  stage_name: string;
  count: number;
  new: number;
}
const PageWrapper = styled.div`
  padding: 0 2.5rem 2rem;
   @media (max-height: 740px) {
    padding: 0 2.5rem 1rem;
  }
`;

const DashboardGrid = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 100%;
  //flex-direction: column;
  grid-template-rows: 144px minmax(50px, 550px) minmax(252px, 255px);
  height: calc(100vh - 115px);
  @media (max-height: 740px) {
    height: calc(100vh - 95px);
    grid-template-rows: 128px minmax(50px, 550px) minmax(240px, 240px);
  }
  @media (min-width: 2100px) {
    height: calc(100vh - 115px);
    grid-template-rows: 150px minmax(50px, 100%) minmax(240px, 252px);
  }
`;

const DashboardTopBlock = styled.div`
  display: grid;
  grid-template-columns: 368px repeat(4, auto);
  grid-template-rows: 4rem 4rem;
  row-gap: 1rem;
  column-gap: 1rem;
`;

const HelloBlock = styled.div`
  width: 368px;
  height: 144px;
  flex-grow: 0;
  padding: 24px 40px 48px 32px;
  border-radius: 8px;
  background-color: #34ce72;
  position: relative;
  @media (max-height: 740px) {
    height: 128px;
  }
`;

const HelloBlockTitle = styled.h2`
  margin: 0 0 8px;
  font-family: Lato;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  max-width: 75%;
`;

const HelloBlockDescription = styled.p`
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  max-width: 78%;
`;

const HelloBlockImage = styled.img`
  position: absolute;
  right: .5rem;
  bottom: .5rem;
`;

const StatisticsItem = styled.div<{ stages?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  border-radius: 8px;
  background-color: #ffffff;
  // height: ${(props) => (props.stages ? 'auto' : '4rem')};
  // max-height: ${(props) => (props.stages ? '9rem' : 'inherit')};
  cursor: pointer;
  height: 4rem;
   @media  (max-height:740px){
    height: 3.5rem;
  }
  // grid-row-start: ${(props) => (props.stages ? '1' : 'inherit')};
  // grid-row-end: ${(props) => (props.stages ? 'span2' : 'inherit')};
  // grid-column-start: ${(props) => (props.stages ? '4' : 'inherit')};
  // grid-column-end: ${(props) => (props.stages ? '4' : 'inherit')};
`;

const StatisticsItemTitle = styled.h4`
  font-family: Lato;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.8px;
  text-align: left;
  color: #627d95;
  max-width: 60%;
  text-transform: uppercase;
  position: relative;
  margin: 0;
`;

const StatisticsItemValueBadge = styled(Badge)`
  & .ant-badge-count {
    top: 0;
    right: -4px;
    min-width: 1rem;
    height: 1rem;
    padding: 0;
    line-height: 1rem;
    background: #34ce72;
  }
`;

const StatisticsItemValue = styled.h5`
  margin: 0;
  font-family: Lato;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: right;
  color: #061c2e;
`;

const DashboardMiddleBlock = styled.div`
  position: relative;
  z-index: 9;
  height: 100%;
  //height: -webkit-fill-available;
  height: -moz-available;
  //min-height: 252px;
  //margin: 1rem 0;
`;

const DashboardBottomBlock = styled.div`
  display: grid;
  grid-template-columns: 272px auto;
  grid-template-rows: auto;
  column-gap: 1rem;
  position: relative;
  z-index: 9;
  max-height: 252px;
  @media (min-width: 2100px) {
      max-height: initial;
  }
`;

const CandidatePosition = styled.p`
  font-family: Lato;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #627d95;
  margin: 0;
`;

const CandidateStageProgressBar = styled.span<{ progress: number }>`
  width: ${(props) => props.progress + '%'};
  background-color: #aebeca;
`;

const CandidatesListItem = styled.li`
  display: grid;
  grid-template-columns: 200px 200px auto 160px;
  column-gap: 2rem;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  border-bottom: 1px solid #dae5ec;
  position: relative;
  cursor: pointer;
`;

const CandidateNameContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CandidateName = styled.h6`
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #061c2e;
  margin-left: 0.5rem;
  margin-bottom: 0;
`;

const CandidateInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const CandidateStageProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const CandidateStage = styled.div`
  background: #f2f6fa;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 128px;
  padding: 4px 8px;
  font-family: Lato;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.8px;
  text-align: center;
  color: #061c2e;
  text-transform: uppercase;
  margin-bottom: 8px;
`;

const CandidateStageProgress = styled.span`
  display: flex;
  width: 128px;
  height: 4px;
  border-radius: 4px;
  background: #f2f6fa;
`;

const CandidateActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 40px;
`;

const CandidateActionName = styled.p`
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #061c2e;
  margin-bottom: 4px;
`;

const CandidateActionDate = styled.p<{ isExpired: boolean }>`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: ${(props) => (props.isExpired ? '#eb5757' : '#627d95')};
  margin: 0;
`;

const CandidateActionDateRelative = styled.span`
  text-transform: capitalize;
`;

const StatisticsWrapper = styled.div`
  display: grid;
  width: calc(100vw - 528px);
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  row-gap: 1rem;
  column-gap: 1rem;
`;

const StatisticsItemStages = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

const StatisticsItemStage = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;

const EmptyText = styled.p`
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #aebeca;
  padding: 5rem 0;
`;

const CandidateActionIcon = styled.img`
  position: absolute;
  right: 0;
`;

const fields = [
  'open_jobs',
  'introduced_candidates',
  'in_process_candidates',
  'hired_candidates',
];

const lastFields = ['offered_candidates', 'placements'];

const RecruiterDashboard = () => {
  const { user } = useAppSelector((state) => state.user);
  const loader = useRef(null);
  const [nextPage, setNextPage] = useState(null);
  const [stages, setStages] = useState<StageInterface[]>([]);
  const [metrics, setMetrics] = useState(null);
  const [actionsFilter, setActionsFilter] = useState(0);
  const [actions, setActions] = useState<DashboardAction[]>([]);
  const [t] = useTranslation();
  const history = useHistory();

  const loadActions = useCallback(
    async (query: string) => {
      let res = null as any;
      if (actionsFilter === 0) {
        res = await getClientDashboardRequiredActions(query);
      }
      if (actionsFilter === 1) {
        res = await getClientDashboardUpcomingActions(query);
      }
      if (res) {
        setActions((state: any) =>
          query ? [...state, ...res.data.results] : [...res.data.results],
        );
        setNextPage(res.data.pagination.next);
      }
    },
    [actionsFilter],
  );

  const handleObserver = useCallback(
    async (entities: any) => {
      const cursor = `${
        history.location.search
          ? history.location.search + '&cursor=' + nextPage
          : '?cursor=' + nextPage
      }`;
      const target = entities[0];
      if (target.isIntersecting && nextPage) {
        await loadActions(cursor);
      }
    },
    [nextPage, history.location.search, loadActions],
  );

  useEffect(() => {
    loadActions('');
  }, [loadActions]);

  useEffect(() => {
    const current = loader.current;
    let options = {
      root: document.querySelector('#list-widget ul'),
      rootMargin: '0px 0px 300px 0px',
      threshold: 1.0,
    };
    const observer = new IntersectionObserver(handleObserver, options);

    if (!nextPage) {
      observer.disconnect();
    } else {
      if (loader && current) {
        observer.observe((current as unknown) as Element);
      }
    }
    return () => observer.unobserve((current as unknown) as Element);
  }, [nextPage, handleObserver]);

  const handleChangeActionsFilter = (filter: string, index: number) => {
    setActionsFilter(index);
    setActions([]);
  };

  useEffect(() => {
    getClientMetrics().then((res) => {
      if (res?.data?.stages && res?.data?.stages?.length > 0) {
        const stageList = res.data.stages;
        const firstEl = stageList.shift();
        const secondEl = stageList.length
          ? stageList.reduce(
              (acc: StageInterface, curr: StageInterface) => {
                acc.count += curr.count;
                acc.new += curr.new;
                return acc;
              },
              {
                stage_name: '2nd+ interview',
                count: 0,
                new: 0,
              },
            )
          : { stage_name: '2nd+ interview', count: 0, new: 0 };
        setStages([firstEl, secondEl]);
      } else {
        setStages([
          { stage_name: '1st interview', count: 0, new: 0 },
          { stage_name: '2nd+ interview', count: 0, new: 0 },
        ]);
      }
      setMetrics(res?.data);
    });
  }, [user]);

  const handleCandidateClick = (action: DashboardAction) => {
    if (action.stage && action.interview.status !== 'offered') {
      if (actionsFilter === 0 && action.action_type === 'feedback') {
        // show feedback modal
        const query = new URLSearchParams();
        query.set(INTERVIEW_ID_PARAM, action.interview.id);
        query.set(STAGE_PARAM, action.stage.stage_name);
        query.set(SHOW_FEEDBACK_PARAM, 'true');

        history.push(CANDIDATES + '?filter=in-process&' + query.toString());

      } else if (actionsFilter === 0 && action.action_type === 'planning') {
        // show proposed date modal
        const query = new URLSearchParams();
        query.set(INTERVIEW_ID_PARAM, action.interview.id);
        query.set(STAGE_PARAM, action.stage.stage_name);
        query.set(SHOW_DATE_PROPOSE_PARAM, 'true');

        history.push(CANDIDATES + '?filter=in-process&' + query.toString());
      } else if (action.action_type === 'move_next_stage') {
        // show tasks overview
        const query = new URLSearchParams();
        query.set(INTERVIEW_ID_PARAM, action.interview.id);
        query.set(STAGE_PARAM, action.stage.stage_name);

        history.push(CANDIDATES + '?filter=in-process&' + query.toString());

      } else if (action.action_type === 'decision' || action.action_type === 'upload_document') {
        // show tasks overview
        const query = new URLSearchParams();
        query.set(INTERVIEW_ID_PARAM, action.interview.id);
        query.set(STAGE_PARAM, action.stage.stage_name);

        history.push(CANDIDATES + '?filter=in-process&' + query.toString());
      } else {
        // show candidate info
        history.push(
          CANDIDATES +
            `?filter=in-process&${HISTORY_PARAM}=` +
            action.interview.candidate.id +
            `&${INTERVIEW_ID_PARAM}=` +
            action.interview.id,
        );
      }
    } else if (action.interview.status === 'offered') {
      if (action.action_type !== 'move_next_stage') {
        // show offer
        const query = new URLSearchParams();
        query.set(INTERVIEW_ID_PARAM, action.interview.id);
        query.set(STAGE_PARAM, 'offered');
        query.set(SHOW_OFFER_PARAM, action?.interview?.candidate?.id);

        history.push(CANDIDATES + '?filter=in-process&' + query.toString());
      } else {
        // show tasks overview
        const query = new URLSearchParams();
        query.set(INTERVIEW_ID_PARAM, action.interview.id);
        query.set(STAGE_PARAM, action.stage.stage_name);

        history.push(CANDIDATES + '?filter=in-process&' + query.toString());
      }
    } else if (action.interview.status === '') {
      // show tasks overview
      const query = new URLSearchParams();
      query.set(INTERVIEW_ID_PARAM, action.interview.id);
      query.set(STAGE_PARAM, action.stage.stage_name);

      history.push(CANDIDATES + '?filter=in-process&' + query.toString());
    }
  };

  const handleStatisticsClick = (field: string) => {
    switch (field) {
      case 'open_jobs':
        history.push(JOBS);
        break;
      case 'total_clients':
        history.push(JOBS);
        break;
      case 'introduced_candidates':
        history.push(CANDIDATES);
        break;
      case 'in_process_candidates':
        history.push(CANDIDATES + '?filter=in-process');
        break;
        case 'placements':
        history.push(CANDIDATES + '?filter=in-process');
        break;
      default:
        history.push(CANDIDATES);
        break;
    }
  };

  return (
    <PageWrapper>
      <Header title={t('DASHBOARD_TITLE')}>{/* <SearchInput/> */}</Header>
      <DashboardGrid>
        <DashboardTopBlock>
          <HelloBlock>
            <HelloBlockTitle>
              {t('HELLO')} {user?.name}!
            </HelloBlockTitle>
            <HelloBlockDescription>
              {t('HELLO_DESCRIPTION')}
            </HelloBlockDescription>
            <HelloBlockImage src={helloImage} />
          </HelloBlock>
          <StatisticsWrapper>
            {fields.map((field, fieldIndex) => {
              const value = (metrics as any)?.[field];
              const fieldTitle = 'DASHBOARD_' + field.toUpperCase();
              if (value) {
                return (
                  <StatisticsItem
                    onClick={() => handleStatisticsClick(field)}
                    key={fieldIndex}
                  >
                    <StatisticsItemTitle>
                      {t(fieldTitle)}{' '}
                      {field === 'hired_candidates' ? t('TOTAL') : ''}
                    </StatisticsItemTitle>
                    <StatisticsItemValueBadge count={value.new ? value.new : 0}>
                      <StatisticsItemValue>{value?.count}</StatisticsItemValue>
                    </StatisticsItemValueBadge>
                  </StatisticsItem>
                );
              }
              return null;
            })}
            {stages.length > 0 && (
              <>
                <StatisticsItem
                  stages
                  onClick={() =>
                    history.push(CANDIDATES + '?filter=in-process')
                  }
                >
                  <StatisticsItemStages>
                    <StatisticsItemStage>
                      <StatisticsItemTitle>
                        {stages[0].stage_name}
                      </StatisticsItemTitle>
                      <StatisticsItemValueBadge
                        count={stages[0].new ? stages[0].new : 0}
                      >
                        <StatisticsItemValue>
                          {stages[0].count}
                        </StatisticsItemValue>
                      </StatisticsItemValueBadge>
                    </StatisticsItemStage>
                  </StatisticsItemStages>
                </StatisticsItem>
                <StatisticsItem
                  stages
                  onClick={() =>
                    history.push(CANDIDATES + '?filter=in-process')
                  }
                >
                  <StatisticsItemStages>
                    <StatisticsItemStage>
                      <StatisticsItemTitle>
                        {stages[1].stage_name}
                      </StatisticsItemTitle>
                      <StatisticsItemValueBadge
                        count={stages[1].new ? stages[1].new : 0}
                      >
                        <StatisticsItemValue>
                          {stages[1].count}
                        </StatisticsItemValue>
                      </StatisticsItemValueBadge>
                    </StatisticsItemStage>
                  </StatisticsItemStages>
                </StatisticsItem>
              </>
            )}

            {lastFields.map((field, fieldIndex) => {
              const value = (metrics as any)?.[field];
              const fieldTitle = 'DASHBOARD_' + field.toUpperCase();
              if (value) {
                return (
                  <StatisticsItem
                    onClick={() => handleStatisticsClick(field)}
                    key={fieldIndex}
                  >
                    <StatisticsItemTitle>{t(fieldTitle)}</StatisticsItemTitle>
                    <StatisticsItemValueBadge count={value.new ? value.new : 0}>
                      <StatisticsItemValue>{value?.count}</StatisticsItemValue>
                    </StatisticsItemValueBadge>
                  </StatisticsItem>
                );
              }
              return null;
            })}
          </StatisticsWrapper>
        </DashboardTopBlock>

        <DashboardMiddleBlock>
          <ClientDashboardCandidates />
        </DashboardMiddleBlock>

        <DashboardBottomBlock>
          <CalendarWidget />
          <ListWidget
            id={'list-widget'}
            title={t('INTERVIEWS_TITLE')}
            tabs={['ACTION_REQUIRED', 'UPCOMING']}
            onChangeTab={(filter, index) =>
              handleChangeActionsFilter(filter, index)
            }
          >
            {actions && actions.length === 0 && (
              <EmptyText>{t('NO_INTERVIEWS_YET')}</EmptyText>
            )}
            {actions?.map((action) => {
              const color = getRandomColor();
              return (
                <CandidatesListItem
                  key={action.id}
                  onClick={() => handleCandidateClick(action)}
                >
                  <CandidateNameContainer>
                    <NameAvatar
                      name={action.interview.candidate.name}
                      color={color}
                    />
                    <CandidateName>
                      {action.interview.candidate.name}
                    </CandidateName>
                  </CandidateNameContainer>
                  <CandidateInfoContainer>
                    {/* <CandidateCompanyName>
                    {action.interview.job.company.name}
                  </CandidateCompanyName> */}
                    <CandidatePosition>
                      {action.interview.job.title}
                    </CandidatePosition>
                  </CandidateInfoContainer>
                  <CandidateStageProgressContainer>
                    {action.stage && action.interview.status !== 'offered' && (
                      <>
                        <CandidateStage>
                          {action.stage.stage_name}
                        </CandidateStage>
                        <CandidateStageProgress>
                          {action.stage_progress && (
                            <CandidateStageProgressBar
                              progress={action.stage_progress}
                            />
                          )}
                        </CandidateStageProgress>
                      </>
                    )}
                    {action.interview.status === 'offered' && (
                      <>
                        <CandidateStage>{t('OFFER_STAGE')}</CandidateStage>
                        <CandidateStageProgress>
                          {action.stage_progress && (
                            <CandidateStageProgressBar
                              progress={action.stage_progress}
                            />
                          )}
                        </CandidateStageProgress>
                      </>
                    )}
                  </CandidateStageProgressContainer>

                  <CandidateActionContainer>
                    <CandidateActionName>
                      {action.interview.status !== 'offered'
                        ? action.task_description
                        : action.action_type === 'move_next_stage'
                        ? action.task_description
                        : t('SEND_OFFER_TASK')}
                    </CandidateActionName>
                    {actionsFilter === 0 && (
                      <CandidateActionDate
                        isExpired={isPast(new Date(action.expired_at))}
                      >
                        {isToday(new Date(action.expired_at)) ||
                        isTomorrow(new Date(action.expired_at)) ||
                        isYesterday(new Date(action.expired_at)) ? (
                          <CandidateActionDateRelative>
                            {formatRelative(
                              new Date(action.expired_at),
                              new Date(),
                            )}
                          </CandidateActionDateRelative>
                        ) : (
                          format(new Date(action.expired_at), 'MMMM d, yyyy')
                        )}
                      </CandidateActionDate>
                    )}
                  </CandidateActionContainer>
                  <CandidateActionIcon src={chevronRightIcon} />
                </CandidatesListItem>
              );
            })}
            <div className="loader" ref={loader} />
          </ListWidget>
        </DashboardBottomBlock>
      </DashboardGrid>
    </PageWrapper>
  );
};

export default RecruiterDashboard;
