import React from 'react';
import { ReactComponent as CalendarIcon } from '../../icons/calendar.svg';
import { DatePicker, DatePickerProps, TimePickerProps } from 'antd';
import styled from 'styled-components';
import moment, { Moment } from 'moment';

const StyledPicker = styled(DatePicker)<DatePickerProps | TimePickerProps>`
  height: 3rem;
  border-radius: 0.5rem;
  border: 1px solid #aebeca;
  width: 100%;
  &.ant-picker-focused {
    box-shadow: 0 0 0 2px rgba(52, 206, 114, 0.2);
  }
  & svg {
    transition: all ease 0.2s;
    color: #627d95;
  }
  &:hover {
    border-color: #34ce72;
    & svg {
      width: 14px;
      height: 14px;
      transition: all ease 0.2s;
    }
  }
  & .ant-picker-input > input {
    font-size: 0.875rem;
    line-height: 1.43;
    color: #061c2e;
  }
`;
const format = (value: any) => {
  return value.format('MMM D, H:mm');
};
const DateTimePicker = ({ ...rest }: DatePickerProps) => {
  const disabledDate = (current: Moment) => {
    return current && current < moment().add(-1, 'days').endOf('day');
  };
  function range(start: any, end: any) {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }
  const disabledTime = (current: any) => {
    const now = new Date();
    if (current && current.isSame(now, 'day')) {
      return {
        disabledHours: () => range(0, 24).splice(0, now.getHours()),
        disabledMinutes: () =>
          current.isSame(now, 'hour') && current.isSame(now, 'day')
            ? range(0, now.getMinutes())
            : [],
      };
    } else {
      return {};
    }
  };
  return (
    <StyledPicker
      showTime={{
        format: 'HH:mm',
        minuteStep: 15
      }}
      showNow={false}
      placeholder={''}
      size="large"
      suffixIcon={<CalendarIcon />}
      disabledDate={disabledDate}
      disabledTime={disabledTime}
      format={format}
      {...rest}
    />
  );
};

export default DateTimePicker;
