import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { InterviewRoom, InterviewTask } from '../types';
import InterviewStage from '../components/guest/InterviewStage';
import styled from 'styled-components';
import { getInterview } from '../api/interviews';
import GuestFeedbackForm from '../components/guest/FeedbackForm';
import Loader from '../UI/Loader';
import ProposeDateForm from '../components/guest/ProposeDateForm';
import { COMPLETED } from '../constants/statuses';
const Wrapper = styled.div`
  max-width: 650px;
  width: 100%;
  margin: 0 auto;
  padding: 2rem;
`;
const Guest = () => {
  const [interview, setInterview] = useState<InterviewRoom | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [showFeedback, setShowFeedback] = useState<boolean>(false);
  const [showTasks, setShowTasks] = useState<boolean>(false);
  const [showDates, setShowDates] = useState<boolean>(false);
  const history = useHistory();
  const params = new URLSearchParams(history.location.search);
  const feedback = params.get('feedback');
  const review = params.get('review');
  const tasks = params.get('tasks');
  const proposed_date = params.get('proposed_date');
  //const stage_name = params.get('stage_name');
  const onComplete = () => {};
  useEffect(() => {
    let interview_id;

    if (feedback) {
      interview_id = feedback;
      setShowFeedback(true);
    }
    if (proposed_date) {
      interview_id = proposed_date;
    }
    if (tasks) {
      interview_id = tasks;
    }
    interview_id &&
      getInterview(interview_id).then((res) => {
        const mainInterview = res.data.current_stage.tasks?.find(
          (task: InterviewTask) => task.is_planning_task,
        );
        if (tasks || proposed_date) {
          if (mainInterview?.status === COMPLETED) {
            setShowTasks(true);
          } else {
            setShowDates(res.data);
          }
        }

        setInterview(res.data);
        setLoading(false);
      });
  }, [feedback, tasks, proposed_date]);
  return (
    <Loader spinning={loading}>
      <Wrapper>
        {interview && showTasks && (
          <InterviewStage interview={interview} onComplete={onComplete} />
        )}
        {interview && showDates && <ProposeDateForm interview={interview} setInterview={setInterview}/>}
        {interview && showFeedback && (
          <GuestFeedbackForm interview={interview} review={review} />
        )}
      </Wrapper>
    </Loader>
  );
};

export default Guest;
