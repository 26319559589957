import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import isThisWeek from 'date-fns/isThisWeek';
import isYesterday from 'date-fns/isYesterday';
import isToday from 'date-fns/isToday';
import nl from 'date-fns/locale/nl';
import en from 'date-fns/locale/en-US';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { ReactComponent as CloseIcon } from '../../icons/icon-close.svg';
import { ReactComponent as SendIcon } from '../../icons/icon-send.svg';
import { ReactComponent as BackBtnIcon } from '../../icons/back-btn.svg';
import {
  getUserRoom,
  getSupportRoom,
  getInterviewRoom,
  getRecruiterInterviews,
  getClientInterviews,
  sendUserMessage,
  sendSupportMessage,
  sendInterviewMessage,
} from '../../api/messages';
import { ADMIN_ROLE, CLIENT_ROLE, RECRUITER_ROLE } from '../../constants/roles';
import { Message, InterviewRoom, CandidatesStagesStatuses } from '../../types';
import { CANDIDATES, CLIENT, HOME, MESSAGES, RECRUITER } from '../../constants/routes';
import {
  getGlobalUnread,
  getInterviewsUnread,
  getSupportUnread,
} from '../../store/messagesSlice';
import { MESSAGE_TYPES } from '../../constants/messageTypes';
import Loader from '../../UI/Loader';
import { ReactComponent as ArrowRight } from '../../icons/arrow-right.svg';
import { ReactComponent as MobileLogo } from '../../icons/nav-logo.svg';
import {
  RoomContainer,
  MessagesContainer,
  InterviewContainer,
  InterviewListHeader,
  MessagesListHeaderWrapper,
  MessagesListHeader,
  MessageHeaderItem,
  InterviewsList,
  MessagesList,
  MessageItem,
  MessageText,
  MessageButton,
  MessageHeader,
  MessageSenderInfo,
  MessageSenderAvatar,
  MessageSenderName,
  StyledAvatar,
  MessageDatetime,
  NewMessage,
  NewMessageHeader,
  RoundButton,
  InterviewItem,
  InterviewItemTitle,
  InterviewItemDescription,
  UnreadCounter,
  EmptyContainer,
  EmptyText,
  MessagesListHeaderSmall,
  NewMessageField,
  RoomProps,
  Title,
  DateText,
  HeaderMobile,
  HeaderMobileLeft,
  CompanyLogo,
  CompanyName,
  LogoLink
} from './RoomStyles';
import {
  SHOW_OFFER_PARAM,
  INTERVIEW_ID_PARAM,
  SHOW_DATE_PROPOSE_PARAM,
  STAGE_PARAM,
} from '../../constants/queryParams';
import CandidateStatusDropdown from "../candidates/CandidateStatusDropdown";
import { getCandidatesStagesStatuses } from "../../api/candidates";
import TooltipPrompt from "../../UI/tootlip";
import useRatingRequest from './hooks/useRatingRequest';

const Room = (props: RoomProps) => {
  const { searchQuery, contact, setContact, ...rest } = props;
  const [sending, setSending] = useState(false);
  const [newMessageText, setNewMessageText] = useState('');
  const [newMessageExpanded, setNewMessageExpanded] = useState(false);
  const [interviewsLoading, setInterviewsLoading] = useState(false);
  const [interviews, setInterviews] = useState<InterviewRoom[]>([]);
  const [selectedInterview, setSelectedInterview] = useState<InterviewRoom>();
  const [candidateStagesStatuses, setCandidateStagesStatuses] = useState<CandidatesStagesStatuses[]>([]);
  const [nextPage, setNextPage] = useState(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const params = new URLSearchParams(history.location.search);
  const isQuestion = params.get('question');
  const job = params.get('job');
  const company = params.get('company');
  const { t, i18n } = useTranslation();
  const loader = useRef(null);
  const { interviewId } = useParams<{ interviewId: string }>();
  const dispatch = useAppDispatch();
  const { interviewsUnread } = useAppSelector((state) => state.messages);
  const { user } = useAppSelector((state) => state.user);
  const isAdmin = user?.role === ADMIN_ROLE;
  const isClient = user?.role === CLIENT_ROLE;
  const isRecruiter = user?.role === RECRUITER_ROLE;
  const getUserMessages = useCallback(
    async (contactId: string) => {
      setLoading(true);

      const result = await getUserRoom(contactId);
      if (result && result.data) {
        setMessages(result.data.results);
        setLoading(false);
        setNextPage(result.data.pagination.next);
        if (isAdmin) {
          dispatch(getGlobalUnread());
        }
      }
    },
    [isAdmin, dispatch],
  );
  useEffect(() => {
    if (isQuestion && isRecruiter) {
      setNewMessageExpanded(true);
      setNewMessageText(t('QUESTION_PREFILLED_TEXT', { company, job }));
    }
  }, [isQuestion, isRecruiter, t, job, company]);
  useEffect(() => {
    if (interviewId && interviews.length > 0) {
      let interview = interviews.find(
        (interview) => interview.id === interviewId,
      );

      if (interview) {
        setSelectedInterview(interview);
      }
    }
  }, [interviewId, interviews]);
  useEffect(() => {
    if (isClient || isRecruiter) getCandidatesStagesStatuses().then((res) => setCandidateStagesStatuses(res.data.results));
  }, [isClient, isRecruiter]);

  const getSupportMessages = useCallback(async () => {
    setLoading(true);
    setMessages([]);
    setSelectedInterview(undefined);

    const result = await getSupportRoom();
    if (result && result.data) {
      setMessages(result.data.results);
      setLoading(false);
      setNextPage(result.data.pagination.next);
      dispatch(getSupportUnread());
      dispatch(getGlobalUnread());
    }
  }, [dispatch]);

  const getInterviews = useCallback(
    async (contactId: string) => {
      setInterviewsLoading(true);
      setMessages([]);
      setSelectedInterview(undefined);
      setNewMessageExpanded(false);
      setNewMessageText('');
      let query = '';
      let result = null;
      if (searchQuery) {
        query = '?candidate_name=' + searchQuery;
      }
      if (isClient) {
        result = await getRecruiterInterviews(contactId, query);
      }
      if (isRecruiter) {
        result = await getClientInterviews(contactId, query);
      }
      if (result && result.data) {
        if (result.data.error_code) {
          setInterviews([]);
          setInterviewsLoading(false);
          return;
        }
        setInterviews(result.data);
        setInterviewsLoading(false);
      }
    },
    [isClient, isRecruiter, searchQuery],
  );

  const getInterviewMessages = useCallback(
    async (interviewId: string) => {
      setLoading(true);
      setNewMessageExpanded(false);
      setNewMessageText('');
      const result = await getInterviewRoom(interviewId);
      if (result && result.data) {
        setMessages(result.data.results);
        setLoading(false);
        setNextPage(result.data.pagination.next);
        dispatch(getInterviewsUnread(interviewId));
      }
    },
    [dispatch],
  );

  useEffect(() => {
    if (selectedInterview) {
      if (isClient || isRecruiter) {
        getInterviewMessages(selectedInterview.id);
      }
    }
  }, [getInterviewMessages, selectedInterview, isClient, isRecruiter]);

  const reloadMessages = useCallback(() => {
    if (contact && contact.id) {
      if (contact.id !== 'support') {
        if (isAdmin) {
          getUserMessages(contact.id);
        }
        if (isClient || isRecruiter) {
          getInterviews(contact.id);
        }
      } else {
        getSupportMessages();
      }
    }
  }, [
    getUserMessages,
    getSupportMessages,
    getInterviews,
    contact,
    isAdmin,
    isClient,
    isRecruiter,
  ]);

  useEffect(reloadMessages, [reloadMessages]);

  const handleObserver = useCallback(
    async (entities: any) => {
      const cursor = `${history.location.search
        ? history.location.search + '&cursor=' + nextPage
        : '?cursor=' + nextPage
        }`;
      const target = entities[0];
      if (target.isIntersecting && nextPage) {
        let contactId = contact?.id;
        if (contactId) {
          // @ts-ignore
          let result = null;
          if (isAdmin) {
            result = await getUserRoom(contactId, cursor);
          }
          if (isClient || isRecruiter) {
            if (selectedInterview && selectedInterview.id) {
              result = await getInterviewRoom(selectedInterview.id, cursor);
            }
          }
          if (result && result.data) {
            const messagesPortion = result.data.results;
            setMessages((state) => [...state, ...messagesPortion]);
            setNextPage(result.data.pagination.next);
          }
        }
      }
    },
    [
      nextPage,
      history.location.search,
      selectedInterview,
      isAdmin,
      isClient,
      isRecruiter,
      contact,
    ],
  );

  useEffect(() => {
    const current = loader.current;
    let options = {
      root: null,
      rootMargin: '0px 0px 300px 0px',
      threshold: 1.0,
    };
    const observer = new IntersectionObserver(handleObserver, options);

    if (!nextPage) {
      observer.disconnect();
    } else {
      if (loader && current) {
        observer.observe(current as unknown as Element);
      }
    }
    return () => observer.unobserve(current as unknown as Element);
  }, [nextPage, handleObserver]);

  const toggleNewMessageForm = () => {
    setNewMessageExpanded(!newMessageExpanded);
  };

  const handleSendMessage = useCallback(async () => {
    if (newMessageText) {
      setSending(true);
      let result = null as any;
      if (isAdmin && contact && contact.id) {
        result = await sendUserMessage(contact?.id, newMessageText);
      }
      if ((isClient || isRecruiter) && contact) {
        if (selectedInterview && contact.id !== 'support') {
          result = await sendInterviewMessage(
            selectedInterview.id,
            newMessageText,
          );
        } else if (!selectedInterview && contact.id === 'support') {
          result = await sendSupportMessage(newMessageText);
        }
      }
      if (result && result.data) {
        setSending(false);
        setNewMessageExpanded(false);
        setNewMessageText('');
        setMessages((state) => [result.data, ...state]);
      }
    }
  }, [
    newMessageText,
    selectedInterview,
    contact,
    isAdmin,
    isClient,
    isRecruiter,
  ]);

  const checkIntroducedUsers = (interview: InterviewRoom) => {
    const msInHour = 1000 * 60 * 60;
    const createdDate = (new Date(interview.created_at)).getTime();

    const current_time = (new Date()).getTime();
    const diff_hours = Math.floor(
      Math.abs(current_time - createdDate) / msInHour,
    );

    return diff_hours >= interview.open_chatbox_after;
  };

  const handleSystemMessageClick = useCallback(
    (type, feedback?: string) => {
      if (selectedInterview) {
        if (type === MESSAGE_TYPES.DATES_ARE_PROPOSED) {
          history.push(
            CANDIDATES +
            `?filter=in-process&${INTERVIEW_ID_PARAM}=` +
            selectedInterview.id +
            `&${STAGE_PARAM}=` +
            selectedInterview.current_stage.stage_name +
            `&${SHOW_DATE_PROPOSE_PARAM}=true`,
          );
        } else if (type === MESSAGE_TYPES.FEEDBACK_REQUIRED) {
          history.push(
            CANDIDATES +
            '?filter=in-process&feedback=' +
            selectedInterview.id +
            '&stage=' +
            selectedInterview.current_stage.stage_name,
          );
        } else if (type === MESSAGE_TYPES.FEEDBACK_RECEIVED) {
          history.push(
            CANDIDATES +
            ('?filter=in-process&' +
              'history=' +
              selectedInterview.candidate.id +
              '&interview_id=' +
              selectedInterview.id +
              '&feedback_id=' +
              feedback +
              '&feedback_interview_id=' +
              selectedInterview.id +
              '&stage_name=' +
              ''),
          );
        } else if (type === MESSAGE_TYPES.OFFER_MAKE) {
          history.push(
            CANDIDATES +
            ('?filter=in-process&' +
              `${SHOW_OFFER_PARAM}=` +
              selectedInterview.candidate.id +
              `&${INTERVIEW_ID_PARAM}=` +
              selectedInterview.id),
          );
        }
      } else {
        history.push(CANDIDATES + '?filter=in-process');
      }
    },
    [history, selectedInterview],
  );

  const handleSelectInterview = async (interview: InterviewRoom) => {
    setSelectedInterview(interview);
    if (contact) {
      history.push(MESSAGES + '/' + contact.id + '/' + interview.id);
    }
  };


  const [RatingRequestMessage, getMessageText] = useRatingRequest(reloadMessages, selectedInterview, user);
  return (
    <RoomContainer {...rest}>
      {!contact && (
        <EmptyContainer>
          <EmptyText>{t('CHAT_EMPTY_MESSAGE')}</EmptyText>
        </EmptyContainer>
      )}
      {(isClient || isRecruiter) && contact && contact.id !== 'support' && (
        <InterviewContainer className={selectedInterview ? 'hide-mobile' : ''}>
          {contact && (
            <HeaderMobile title={t('')}>
              <HeaderMobileLeft>
                <BackBtnIcon
                  onClick={() => {
                    history.push(MESSAGES);
                    setContact(null);
                  }}
                />
                <CompanyLogo
                  style={{ backgroundImage: `url(${contact?.image})` }}
                />
                <CompanyName>{contact?.name}</CompanyName>
              </HeaderMobileLeft>
              <LogoLink to={HOME}><MobileLogo style={{ width: 40, marginLeft: 12 }} /></LogoLink>
            </HeaderMobile>
          )}
          <Loader spinning={interviewsLoading}>
            <InterviewListHeader>{t('CANDIDATES')}</InterviewListHeader>
            <InterviewsList>
              {interviews.map((interview) => {
                let unreadFromStore = (interviewsUnread as any)[interview.id];
                let unreadCount = interview.unread_message_cnt;
                if (unreadFromStore !== undefined) {
                  unreadCount = unreadFromStore;
                }
                // const diffInDays =
                //   (interview.confirmed_date &&
                //     differenceInCalendarDays(
                //       new Date(interview.confirmed_date),
                //       new Date(),
                //     )) ||
                //   0;
                return (
                  <InterviewItem
                    key={interview.id}
                    onClick={() => handleSelectInterview(interview)}
                    active={
                      selectedInterview
                        ? selectedInterview.id === interview.id
                        : false
                    }
                  >
                    <InterviewItemTitle>
                      {interview.candidate.name}
                    </InterviewItemTitle>
                    <InterviewItemDescription>
                      {interview.job.title}
                    </InterviewItemDescription>
                    {interview.confirmed_date &&
                      interview.status !== 'offered' && (
                        <InterviewItemDescription>
                          <Title>
                            <ArrowRight />
                            {t('INTERVIEW')}:<span>&nbsp;</span>
                            <DateText>
                              {format(
                                new Date(interview.confirmed_date),
                                'MMM d, kk:mm',
                              )}
                            </DateText>
                          </Title>
                        </InterviewItemDescription>
                      )}
                    {unreadCount > 0 && (
                      <UnreadCounter>{unreadCount}</UnreadCounter>
                    )}
                    <CandidateStatusDropdown customWrapperClass={isClient && (interview.id !== selectedInterview?.id) ? 'hidden' : ''} interview={interview} statuses={candidateStagesStatuses} onClick={(e: any) => e.stopPropagation()} isClient={isClient} />
                  </InterviewItem>
                );
              })}
            </InterviewsList>
          </Loader>
        </InterviewContainer>
      )}
      <MessagesContainer
        className={
          selectedInterview || (isAdmin && contact) || contact?.id === 'support'
            ? ''
            : 'hide-mobile'
        }
      >
        <div className="loader" ref={loader} />
        <Loader spinning={loading}>
          {contact?.id === 'support' && (
            <MessagesListHeaderWrapper>
              <BackBtnIcon
                className="back"
                onClick={() => {
                  history.push(`${MESSAGES}`);
                  setContact(null);
                }}
              />
              <MessagesListHeader>
                <MessageHeaderItem>
                  {t('HIREPORT_SUPPORT_TITLE')}
                </MessageHeaderItem>
              </MessagesListHeader>
              <LogoLink to={HOME}><MobileLogo style={{ width: 40, marginLeft: 12 }} /></LogoLink>
            </MessagesListHeaderWrapper>
          )}
          {!!(
            (isClient || isRecruiter) &&
            messages.length &&
            contact?.id !== 'support'
          ) && (
              <MessagesListHeaderWrapper>
                <BackBtnIcon
                  className="back"
                  onClick={() => {
                    history.push(`${MESSAGES}/${contact?.id}`);
                    setSelectedInterview(undefined);
                  }}
                />
                <MessagesListHeader>
                  <MessageHeaderItem>{
                    isClient ?
                      <TooltipPrompt title={t('CLICK_TO_SEE_PROFILE')}><Link to={RECRUITER + '/' + contact?.id}> {messages[0].info?.name}</Link></TooltipPrompt>
                      :
                      isRecruiter ?
                        <TooltipPrompt title={t('CLICK_TO_SEE_PROFILE')}><Link to={CLIENT + '/' + contact?.id}> {messages[0].info?.name}</Link></TooltipPrompt>
                        :
                        messages[0].info?.name
                  }</MessageHeaderItem>
                  <MessagesListHeaderSmall>
                    {messages[0].info?.company_name}
                  </MessagesListHeaderSmall>
                  <MessagesListHeaderSmall>
                    {messages[0].info?.phone}
                  </MessagesListHeaderSmall>
                  <MessagesListHeaderSmall>
                    {messages[0].info?.email}
                  </MessagesListHeaderSmall>
                </MessagesListHeader>
                <LogoLink to={HOME}><MobileLogo style={{ width: 40, marginLeft: 12 }} /></LogoLink>
              </MessagesListHeaderWrapper>
            )}
          {isAdmin && contact && (
            <MessagesListHeaderWrapper>
              <BackBtnIcon
                className="back"
                onClick={() => {
                  history.push(`${MESSAGES}`);
                  setContact(null);
                }}
              />
              <MessagesListHeader>
                <MessageHeaderItem>{
                  contact.role === 'recruiter' ?
                    <TooltipPrompt title={t('CLICK_TO_SEE_PROFILE')}><Link to={RECRUITER + '/' + contact?.id}> {contact?.name}</Link></TooltipPrompt>
                    :
                    contact?.name
                }</MessageHeaderItem>
                {/*<MessageHeaderItem>{contact?.name}</MessageHeaderItem>*/}
                <MessagesListHeaderSmall>
                  {contact.role === 'client' ?
                    <TooltipPrompt title={t('CLICK_TO_SEE_PROFILE')}><Link to={CLIENT + '/' + contact.company_id}> {contact?.company_name}</Link></TooltipPrompt>
                    :
                    contact?.company_name
                  }
                </MessagesListHeaderSmall>
                <MessagesListHeaderSmall>
                  {contact?.phone}
                </MessagesListHeaderSmall>
                <MessagesListHeaderSmall>
                  {contact?.email}
                </MessagesListHeaderSmall>
              </MessagesListHeader>
              <LogoLink to={HOME}><MobileLogo style={{ width: 40 }} /></LogoLink>
            </MessagesListHeaderWrapper>
          )}
          {contact && (
            <MessagesList
              empty={messages.length === 0}
              withHeader={
                ((isClient || isRecruiter) &&
                  selectedInterview !== undefined) ||
                (isAdmin && contact !== null)
              }
              expanded={newMessageExpanded}
            >
              {!loading && messages.length === 0 && (
                <p>{t('NO_MESSAGES_YET')}</p>
              )}
              {!loading &&
                messages.map((message, index) => (
                  <MessageItem key={message.id}>
                    <MessageHeader>
                      <MessageSenderInfo>
                        {message.sender.image ? (
                          <MessageSenderAvatar
                            style={{
                              backgroundImage:
                                'url(' +
                                (message.message_type === 0
                                  ? message.sender.image
                                  : message.info.image) +
                                ')',
                            }}
                          />
                        ) : (
                          <StyledAvatar
                            name={
                              message.message_type === 0
                                ? message.sender.name
                                : message.info.name
                            }
                            image={
                              message.message_type === 0
                                ? message.sender.image
                                : message.info.image
                            }
                          />
                        )}
                        <MessageSenderName>
                          {message.message_type === 0
                            ? message.sender.name
                            : message.info.name}
                        </MessageSenderName>
                      </MessageSenderInfo>
                      <MessageDatetime>
                        {isToday(new Date(message.created_at))
                          ? t('TODAY') +
                          format(new Date(message.created_at), ', HH:mm', {
                            locale: i18n.language === 'en' ? en : nl,
                          })
                          : isYesterday(new Date(message.created_at))
                            ? t('YESTERDAY') +
                            format(new Date(message.created_at), ', HH:mm', {
                              locale: i18n.language === 'en' ? en : nl,
                            })
                            : isThisWeek(new Date(message.created_at))
                              ? format(
                                new Date(message.created_at),
                                'EEEE, HH:mm',
                                {
                                  locale: i18n.language === 'en' ? en : nl,
                                },
                              )
                              : format(
                                new Date(message.created_at),
                                'MMM d, HH:mm',
                                {
                                  locale: i18n.language === 'en' ? en : nl,
                                },
                              )}
                      </MessageDatetime>
                    </MessageHeader>
                    <MessageText
                      system={message.message_type !== MESSAGE_TYPES.USER_TYPE}
                      disabled={
                        message.is_old &&
                        message.message_type !== MESSAGE_TYPES.FEEDBACK_RECEIVED
                      }
                    >
                      {(
                        message.message_type === MESSAGE_TYPES.USER_TYPE
                          ? message.text
                          : getMessageText(message)
                      )}
                      {message.message_type ===
                        MESSAGE_TYPES.DATES_ARE_PROPOSED && (
                          <MessageButton
                            type="primary"
                            onClick={() =>
                              handleSystemMessageClick(
                                MESSAGE_TYPES.DATES_ARE_PROPOSED,
                              )
                            }
                          >
                            {t('CHOOSE_DATES')}
                          </MessageButton>
                        )}
                      {(message.message_type ===
                        MESSAGE_TYPES.FEEDBACK_RECEIVED ||
                        message.message_type === MESSAGE_TYPES.OFFER_MAKE) && (
                          <MessageButton
                            type="primary"
                            onClick={() =>
                              handleSystemMessageClick(
                                message.message_type,
                                message.feedback_id,
                              )
                            }
                          >
                            {t('REVIEW')}
                          </MessageButton>
                        )}
                      {message.message_type ===
                        MESSAGE_TYPES.FEEDBACK_REQUIRED && (
                          <MessageButton
                            type="primary"
                            onClick={() =>
                              handleSystemMessageClick(
                                MESSAGE_TYPES.FEEDBACK_REQUIRED,
                              )
                            }
                          >
                            {t('SEND_FEEDBACK')}
                          </MessageButton>
                        )}
                      <RatingRequestMessage message={message} />
                    </MessageText>
                  </MessageItem>
                ))}
            </MessagesList>
          )}
        </Loader>
        {((isAdmin && contact) ||
          (!isAdmin && contact?.name === 'support') ||
          (!isAdmin && contact && selectedInterview && !isRecruiter) ||
          !(
            isRecruiter &&
            selectedInterview?.status === 'introduced' &&
            !messages.length
          ) ||
          checkIntroducedUsers(selectedInterview)
        ) &&
          contact?.name !== 'support'
          && (
            <NewMessage expanded={newMessageExpanded}>
              <Loader spinning={sending}>
                <NewMessageHeader
                  expanded={newMessageExpanded}
                  onClick={() => toggleNewMessageForm()}
                >
                  {t('WRITE_A_MESSAGE')}
                  {newMessageExpanded && <CloseIcon />}
                </NewMessageHeader>
                {newMessageExpanded && (
                  <NewMessageField
                    autoSize={{ minRows: 10, maxRows: 10 }}
                    maxLength={2000}
                    value={newMessageText}
                    onChange={(e: any) => setNewMessageText(e.target.value)}
                  />
                )}
              </Loader>
              {newMessageExpanded && (
                <RoundButton
                  disabled={newMessageText === '' || sending}
                  onClick={() => handleSendMessage()}
                >
                  <SendIcon />
                </RoundButton>
              )}
            </NewMessage>
          )}
      </MessagesContainer>
    </RoomContainer>
  );
};

export { Room };
