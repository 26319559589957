import React from "react";
import { Col, Row, message } from 'antd';
import Button from '../../UI/buttons/Button';
import { inviteAllRecruitersToJob, sendInviteToRecruiters } from "../../api/recruiter";
import { useHistory } from "react-router-dom";
import { JOBS } from "../../constants/routes";
import { Progress } from 'antd';
import { ReactComponent as AlertIcon } from '../../icons/alert-icon.svg';
import styled from "styled-components";

export const StyledAlertIcon = styled(AlertIcon)`
  & path {
    stroke: ${props => props.color};
  }
`;

export const calcuclateAlertType = (numOfSelectedRecrutiers: number, highThreshold: number): "#eb5757" | "#F58316" | "#34CE72" => {
    let lowThreshold = Math.floor(highThreshold * 0.5)
    if (numOfSelectedRecrutiers <= lowThreshold) return "#eb5757";
    if (numOfSelectedRecrutiers > lowThreshold && numOfSelectedRecrutiers < highThreshold) return "#F58316";
    return "#34CE72";
}


interface RecruiterManagementBoardFooterProps {
  numOfSelectedRecrutiers: number;
  recomendedNumberOfRecrutiers: number;
  highThreshold: number;
  companyName: string | undefined;
  selectedRows: string[];
  selectedJob: string | null;
  fromJobCreation: boolean;
  setIsModalVisible: React.Dispatch<React.SetStateAction<any>>;
  allSelected: boolean;
}

export default function RecruiterManagementBoardFooter({
  numOfSelectedRecrutiers,
  recomendedNumberOfRecrutiers,
  highThreshold,
  companyName,
  selectedRows,
  selectedJob,
  fromJobCreation,
  setIsModalVisible,
  allSelected
}: RecruiterManagementBoardFooterProps) {

  const history = useHistory();

  const calculateAlertMsg = (numOfSelectedRecrutiers: number, highThreshold: number): string => {
    let messagePrefix = (numOfSelectedRecrutiers < highThreshold) ? "Only " : "";
    return `${messagePrefix}${numOfSelectedRecrutiers} recruiters selected`;
  }


  const handleAddRecruitersToJob = () => { // @ts-ignore
    if ( allSelected ) {
      handleAssignAllRecruiters();
      return;
    }
    // @ts-ignore
    sendInviteToRecruiters(companyName, selectedRows, selectedJob)
      .then(_ => {
        message.success("Talent suppliers assigned!");
        if (fromJobCreation) {
          history.push(JOBS + '/');
        }
        setIsModalVisible(false);
      })
      .catch(_ => message.error("Ooops something went wrong, please try again later"))
  }

  const handleAssignAllRecruiters = () => {
    // @ts-ignore
    inviteAllRecruitersToJob(companyName, selectedJob)
      .then(_ => {
        message.success("Talent suppliers assigned!");
        if (fromJobCreation) {
          history.push(JOBS + '/');
        }
        setIsModalVisible(false);
      })
      .catch(_ => message.error("Ooops something went wrong, please try again later"))
  }


  let alertType = calcuclateAlertType(numOfSelectedRecrutiers, highThreshold);
  let alertMsg = calculateAlertMsg(numOfSelectedRecrutiers, highThreshold);
  let alertDescription = `Recomended number of recruiters is ${recomendedNumberOfRecrutiers}`;
  let loaderPercentage = (numOfSelectedRecrutiers / recomendedNumberOfRecrutiers) * 100;

  return (
    <Row gutter={10} justify="space-between" align="middle" style={{ marginTop: "0.5em", backgroundColor: "#F2F6FA", borderRadius: "8px", padding: "0.5em" }}>
      <Col span={1}>
        <div style={{ backgroundColor: "#fff", height: "50px", width: "50px", borderRadius: "8px", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <StyledAlertIcon color={alertType} />
        </div>
      </Col>
      <Col span={11}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ fontWeight: 600, fontSize: "14px" }}>{alertMsg}</div>
          <div style={{ fontSize: "12px" }}>{alertDescription}</div>
          <Progress trailColor="#fff" strokeLinecap="square" size="small" strokeColor={alertType} percent={loaderPercentage} showInfo={false} />
        </div>
      </Col>
      <Col span={11}>
        <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
          <Button size="middle" style={{ height: "40px", color: "#627d95", borderColor: "#627d95" }} onClick={handleAssignAllRecruiters}>Continue with all</Button>
          <Button size="middle" type="primary" style={{height: "40px"}} onClick={handleAddRecruitersToJob}>Confirm my selection</Button>
        </div>
      </Col>
    </Row>
  );
}
