import axios from 'axios';

const api_url = process.env.REACT_APP_API_URL;

export const getSkillsList = async () => {
  return await axios.get(`${api_url}skills/`);
};

export const createNewSkill = async (newSkill: string) => {
  return await axios.post(`${api_url}skills/`, {
    'name': newSkill
  });
};
