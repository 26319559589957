import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  TasksList,
} from './styles';
import ListItem from './ListItem';

const RecruiterOfferTasks = () => {
  const [t] = useTranslation();

  return (
    <TasksList>
      <ListItem
        isActive={false}
        isActiveLine={false}
        isDone={false}
        title={t('RECRUITER_OFFER_STAGE_TITLE_1', { numberOfHours: 'XX' })}
      />

      <ListItem
        isActive={false}
        isActiveLine={false}
        isDone={false}
        title={t('RECRUITER_OFFER_STAGE_TITLE_2')}
      />
    </TasksList>
  )
}

export default React.memo(RecruiterOfferTasks);