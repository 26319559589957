import { TFunction } from 'react-i18next';
import { EmploymentType, EmploymentTypeField, EmploymentTypeInstance } from '../types';

const HOLIDAY_ALLOWANCE = 1.08; // 8%
export const CURRENCY_SYMBOL = '€'; // can change later

type EmploymentTypeSummary = {
  id: string;
  label: string;
  secondaryLabel: string;
  value?: string;
  min?: number;
  max?: number;
  /** Attempts to suggest how the UI might display the field based on ID, etc. */
  displayHint?: string | null;
}

const getRangeString = (min: number | undefined | null, max: number | undefined | null, isCurrency: boolean) => {
  const currencyToken = isCurrency ? CURRENCY_SYMBOL : '';
  if (min && max)
    return `${currencyToken}${Math.round(min).toLocaleString()} - ${currencyToken}${Math.round(max).toLocaleString()}`;
  if (max)
    return `Up to ${currencyToken}${Math.round(max).toLocaleString()}`;
  if (min)
    return `${currencyToken}${Math.round(min).toLocaleString()}`;
  return '';
}

const getBasicFieldValueSummary = (t: TFunction<"translation">, employmentTypeInstance: EmploymentTypeInstance) => (field: EmploymentTypeField) => {
  const minValue = employmentTypeInstance.values.find((value) => value.field.id === field.id)
  const min = employmentTypeInstance.values.find((value) => value.field.id === field.id)?.numeric_value
  const max = employmentTypeInstance.values.find((value) => value.field.id === `${field.id}_max`)?.numeric_value
  const numericValue = getRangeString(min, max, field.is_currency);
  const value = numericValue || (minValue?.text_value || '');
  const displayHint = ['pay', 'salary', 'rate'].some((term) => field.id.includes(term))
    ? 'wallet' : field.id.includes('hour') ? 'clock' : null;
  const employmentTypeSummary: EmploymentTypeSummary = {
    id: field.id,
    label: t(field.label),
    value,
    min,
    max,
    displayHint,
    secondaryLabel: t(field.secondary_label)
  };
  return employmentTypeSummary;
}

// !WB: this is a bit complicated, but I've tried to isolate the custom behaviour needed on the vacancy page to just this place
const getCustomFieldValueSummary: (t: TFunction<"translation">, usage: string, employmentTypeInstance: EmploymentTypeInstance, field: EmploymentTypeField) => EmploymentTypeSummary[] = (t, usage, employmentTypeInstance, field) => {
  const basicFieldSummary = getBasicFieldValueSummary(t, employmentTypeInstance)(field);
  const salaryMin = employmentTypeInstance.values.find((value) => value.field.id.endsWith('salary'))?.numeric_value ?? 0;
  const salaryMax = employmentTypeInstance.values.find((value) => value.field.id.endsWith('salary_max'))?.numeric_value ?? 0;
  const bonusAnnual = employmentTypeInstance.values.find((value) => value.field.id.endsWith('bonus_annual'))?.numeric_value ?? 0;
  const bonusMonthly = employmentTypeInstance.values.find((value) => value.field.id.endsWith('bonus_monthly'))?.numeric_value ?? 0;
  const maxAnnual = 12 * (HOLIDAY_ALLOWANCE * salaryMax + bonusMonthly) + bonusAnnual;

  switch (usage) {
    case 'banner':
    case 'compensation':
      // on the vacancy page. replace the single salary field with a range
      if (field.id.endsWith('salary_max')) return [];
      if (field.id.endsWith('salary')) {
        const minAnnual = 12 * (HOLIDAY_ALLOWANCE * salaryMin + bonusMonthly) + bonusAnnual;
        const annualSalaryField = {
          id: field.id,
          label: 'Annual salary',
          secondaryLabel: '',
          min: minAnnual,
          max: maxAnnual,
          value: getRangeString(minAnnual, maxAnnual, field.is_currency),
        };
        if (usage === 'banner')
          return [annualSalaryField];

        // compensation section only:
        return [
          annualSalaryField,
          {
            ...basicFieldSummary,
            label: `Monthly ${basicFieldSummary.label}`,
          },
        ];
      }
      break;
    case 'recruiter_list_headline':
      // on the recruiter list headline section, show "Up to <annual>" and monthly including bonus
      if (field.id.endsWith('salary_max')) return [
        {
          ...basicFieldSummary,
          value: getRangeString(null, maxAnnual, field.is_currency),
          secondaryLabel: '',
        },
        {
          ...basicFieldSummary,
          value: getRangeString(salaryMax + bonusMonthly, null, field.is_currency),
        },
      ];
      break;
  }

  return [basicFieldSummary];
}

const getEmploymentTypeSummary = (t: TFunction<"translation">, employmentTypeInstance: EmploymentTypeInstance | undefined, employmentTypeDefinitions: EmploymentType[]) => {
  if (!employmentTypeInstance) return null;
  const employmentType = employmentTypeDefinitions.find((employmentType) => employmentType.id === employmentTypeInstance.type.id);
  if (!employmentType) return null;

  const fields: Record<string, EmploymentTypeSummary[]> = {};
  employmentType.fields.forEach((field) => {
    const usages = (field.usage_list || '').split(',')
    usages.forEach((usage) => {
      fields[usage] = [
        ...(fields[usage] || []),
        ...getCustomFieldValueSummary(t, usage, employmentTypeInstance, field),
      ];
    })
  });

  return {
    employmentType: t(employmentType.name),
    hireportFeeDetails: (employmentType.hireport_fee_details.split('\\n') || []).map(t),
    fields,
  }
};

export default getEmploymentTypeSummary;