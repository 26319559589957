import React, { useMemo } from 'react';
import { getNameFirstLetters } from '../utils/candidate';
import styled from 'styled-components';
import { Avatar, AvatarProps } from 'antd';
import { getRandomColor } from '../constants/palette';

interface NameAvatarProps extends AvatarProps{
  name: string;
  image?: string | null;
  color?: { main: string; light: string };
}
const CircleAvatar = styled(Avatar)`
  min-width: 2.5rem;
  min-height: 2.5rem;
  display: flex;
  align-items: center;
  background: #fff;
  & img {
    height: auto;
    object-fit: contain;
    object-position: center;
  }
  &.bordered {
    border: 1px solid #dae5ec;
  }
`;
const NameAvatar = ({ name, color, image, ...rest }: NameAvatarProps) => {
  let randomColor = useMemo(() => {
    return getRandomColor();
  },[])
  return image ? (
    <CircleAvatar size={'large'} src={image} className="bordered" {...rest} />
  ) : (
    <CircleAvatar
      style={{ backgroundColor:color ?  color?.light : randomColor.light, color: color ? color?.main : randomColor.main}}
      size="large"
      {...rest}
    >
      {getNameFirstLetters(name)}
    </CircleAvatar>
  );
};

export default NameAvatar;
