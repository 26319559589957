import styled from "styled-components";
import JobSimpleCreationForm from "../components/form/JobSimpleCreationForm";
import React from "react";
import Banner from "../components/auth/Banner";
import { ReactComponent as MobileLogo } from '../icons/nav-logo.svg';
import { useTranslation } from 'react-i18next';

const PageWrapper = styled.section`
  background: #0c1e2d;
  width: 100%;
  display: flex;
  min-height: 100vh;
  overflow: hidden;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const FormWrapper = styled.div`
  background: #fff;
  width: 61%;
  padding: 4.5rem 4rem;
  display: flex;
  flex-direction: column;
  position: relative;
  
  & .simple-job-info-card {
    left: -300px;
    width: 300px;
    border-radius: 8px;
    position: absolute;
    svg {
      position: relative;
      float: right;
    }
    .ant-card-meta-title {
      font-weight: bold;
      font-size: 18px;
    }
  }
  
  & .stage-delete-icon {
    position: relative;
    top: 40px;
    color: #ff4d4f;
    cursor: pointer;
  }

  @media (max-width: 1200px) {
    width: 70%;
  }
  @media (max-width: 768px) {
    width: 90%;
    padding: 3rem;
  }
`;

const PageHeaderMobile = styled.div`
display: none;
  @media (max-width: 768px) {
display: flex;
padding: 1.25rem;
background: #ffffff;
align-items: center;
width: 100%;
justify-content: space-between;
box-shadow: 0 10px 10px -5px #00000013;
z-index: 999;
}
`;
const PageTitleMobile = styled.h1`
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.2;
  color: #061c2e;
  margin: 0;
`;
const StyledLogo = styled(MobileLogo)`
width: 2.5rem;
`;

const PublicJobCreate = () => {
    const { t } = useTranslation();

    return (
        <PageWrapper>
            <PageHeaderMobile>
                <PageTitleMobile>{t('LOGIN_TITLE')}</PageTitleMobile>
                <StyledLogo></StyledLogo>
            </PageHeaderMobile>
            <Banner title={t('LOGIN_BANNER_TEXT')}/>
            <FormWrapper>
                <JobSimpleCreationForm showBanner={false} jobId={''} />
            </FormWrapper>
        </PageWrapper>
    );
}

export default PublicJobCreate;
