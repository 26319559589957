import React from 'react';
import { useTranslation } from 'react-i18next';

import { InterviewRoom } from '../../../../../types';
import {
  TasksList,
  ListItemText,
  ButtonWrap,
  InterviewButton,
} from './styles';
import ListItem from './ListItem';
import Button from '../../../../../UI/buttons/Button';
import { ReactComponent as RescheduleIcon } from '../../../../../icons/reschedule.svg';
import { ReactComponent as FeedbackIcon } from '../../../../../icons/feedback-icon.svg';
import useTasks from './hooks';
import EditDate from '../EditDate';
import ExportDate from '../ExportDate';

interface IProps {
  interview: InterviewRoom | { [key:string]: any };
}

const Tasks = ({
  interview,
}: IProps) => {
  const [t] = useTranslation();

  const {
    actions: {
      //handleShowDateProposeModal,
      handleShowFeedbackModal,
      handleSetInterview,
      checkFeedBackTime,
    },
    data: {
      currentStageName,
      waitingRecruiter,
      planningInterviewIsCompleted,
      interviewComment,
      interviewLocation,
      interviewDate,
      feedbackTime,
      clientFeedbackIsCompleted,
      recruiterFeedbackIsCompleted,
      isBothSidePositive,
      waitingClient,
      afterInterviewFeedbackTime,
      interviewIsDone,
    }
  } = useTasks({ interview });

  const task1isDone = waitingRecruiter || planningInterviewIsCompleted;

  let taskText1 = ''; 
  if (waitingClient) { // show this if recruiter propose new interview date
    if (feedbackTime > 0) {
      taskText1 = t('RECRUITER_STAGE_TASK_TITLE-1_2', { numberOfHours: checkFeedBackTime(feedbackTime) })
    } else {
      taskText1 = t('RECRUITER_STAGE_TASK_TITLE-1_2_OUTDATED')
    }
  } else { // default texts
    taskText1 = t('INTERVIEW_TEXT_RECRUITER');
    /*if (feedbackTime > 0) {
      taskText1 = t('RECRUITER_STAGE_TASK_TITLE-1', { numberOfHours: checkFeedBackTime(feedbackTime) })
    } else {
      taskText1 = t('RECRUITER_STAGE_TASK_TITLE-1_OUTDATED')
    }*/
  }

  /*let taskText2 = '';
  if (planningInterviewIsCompleted) {
    taskText2 = t('STAGE_TASK_TITLE-2_DONE');
  } else {
    if (feedbackTime > 0 && task1isDone) {
      taskText2 = t('RECRUITER_STAGE_TASK_TITLE-2_TIME', { numberOfHours: checkFeedBackTime(feedbackTime) });
    } else {
      taskText2 = t('RECRUITER_STAGE_TASK_TITLE-2')
    }
  }*/

  let planningInterviewText = '';
  if (!planningInterviewIsCompleted) { 
    planningInterviewText = t('RECRUITER_STAGE_TASK_TITLE-3_DISABLED');
  } else if (clientFeedbackIsCompleted || recruiterFeedbackIsCompleted || interviewIsDone) {
    planningInterviewText = t('STAGE_TASK_INTERVIEW_DONE', { dateTime: interviewDate });
  } else {
    planningInterviewText = t('RECRUITER_STAGE_TASK_TITLE-3', { currentStageName, dateTime: interviewDate });
  }

  // same logic in the ClientTasks
  let clientFeedbackText = '';
  if (clientFeedbackIsCompleted) {
    clientFeedbackText = t('STAGE_COMPANY_FEEDBACK_IS_DONE'); // The company has given feedback
  } else {
    if (afterInterviewFeedbackTime > 48) {
      if (interviewIsDone) {
        clientFeedbackText = t(
          'RECRUITER_STAGE_TASK_TITLE-4-SIMPLE',
        ) // The company will provide you with feedback
      } else {
        clientFeedbackText = t('RECRUITER_STAGE_TASK_TITLE-4-SIMPLE_AFTER');
        // ...after the interview
      }
    } else if (afterInterviewFeedbackTime > 0 && afterInterviewFeedbackTime <= 48) {
      if (interviewIsDone) {
        clientFeedbackText = t(
          'RECRUITER_STAGE_TASK_TITLE-4',
          { numberOfHours: afterInterviewFeedbackTime },
        ); // The company will provide you with feedback within {{ numberOfHours }} hours
      } else {
        clientFeedbackText = t(
          'RECRUITER_STAGE_TASK_TITLE-4_AFTER',
          { numberOfHours: afterInterviewFeedbackTime },
        ); // ...after the interview
      } 
    } else {
      if (planningInterviewIsCompleted) {
        clientFeedbackText = t('RECRUITER_STAGE_TASK_TITLE-4_OUTDATED') // The company should have provided feedback
      } else {
        clientFeedbackText = t('CLIENT_TASK_TITLE-5_PRE'); // The company will have to provide feedback after the interview
      }
    }
  }

  let recruiterFeedbackText = '';
  if (recruiterFeedbackIsCompleted) {
    recruiterFeedbackText = t('STAGE_RECRUITER_FEEDBACK_IS_DONE'); // The recruiter has given feedback
  } else {
    if (afterInterviewFeedbackTime > 48) {
      if (interviewIsDone) {
        recruiterFeedbackText = t(
          'RECRUITER_STAGE_TASK_TITLE-5-SIMPLE',
        ); // You have to provide feedback
      } else {
        recruiterFeedbackText = t('RECRUITER_STAGE_TASK_TITLE-5-SIMPLE_AFTER'); // ...after the interview
      }
    } else if (afterInterviewFeedbackTime > 0 && afterInterviewFeedbackTime <= 48) {
      if (interviewIsDone) {
        recruiterFeedbackText = t(
          'RECRUITER_STAGE_TASK_TITLE-5',
          { numberOfHours: afterInterviewFeedbackTime }
        ); // You have to provide feedback within {{ numberOfHours }} hours
      } else {
        recruiterFeedbackText = t(
          'RECRUITER_STAGE_TASK_TITLE-5_AFTER',
          { numberOfHours: afterInterviewFeedbackTime }
        ); // ...after the interview
      }
    } else {
      if (planningInterviewIsCompleted) {
        recruiterFeedbackText = t('RECRUITER_STAGE_TASK_TITLE-5_OUTDATED') // You should have provided feedback
      } else {
        recruiterFeedbackText = t('STAGE_TASK_TITLE-5_PRE');
        // You will have to provide feedback after the interview
      }
    }
  }

  return (
    <TasksList>
      <ListItem
        isActive
        isActiveLine={task1isDone}
        isDone={task1isDone}
        title={task1isDone ? t('STAGE_TASK_TITLE-2_DONE') : taskText1}
        isButton={!task1isDone}
        handleClick={!task1isDone ? handleSetInterview : undefined}
      >
        {!task1isDone && (
          <>
            <ButtonWrap>
              <InterviewButton
                size="small"
                icon={<RescheduleIcon />}
                className={'ant-btn-interview'}
                onClick={handleSetInterview}
              >
                {t('SET_INTERVIEW_DATE')}
              </InterviewButton>
            </ButtonWrap>
          </>
        )}
      </ListItem>

      {/*<ListItem
        isActive={waitingRecruiter || planningInterviewIsCompleted}
        isActiveLine={planningInterviewIsCompleted}
        isDone={planningInterviewIsCompleted}
        title={taskText2}
        isButton={waitingRecruiter && !planningInterviewIsCompleted}
        handleClick={waitingRecruiter && !planningInterviewIsCompleted ? handleShowDateProposeModal : undefined}
      >
        {waitingRecruiter && (
        <>
          <ListItemText>{t('INTERVIEW_TEXT_RECRUITER')}</ListItemText>
          <ButtonWrap>
            <InterviewButton
              size="small"
              className={'ant-btn-interview'}
              icon={<RescheduleIcon />}
              onClick={handleSetInterview}
            >
              {t('SET_INTERVIEW_DATE')}
            </InterviewButton>
          </ButtonWrap>
        </>
        )}
      </ListItem>*/}

      <ListItem
        isActive={planningInterviewIsCompleted}
        isActiveLine={planningInterviewIsCompleted}
        isDone={planningInterviewIsCompleted}
        title={planningInterviewText}
        isButton={planningInterviewIsCompleted}
        handleClick={planningInterviewIsCompleted ? handleSetInterview : undefined}
      >
        <>
          {interviewLocation && (
            <ListItemText>{`${t('LOCATION_LABEL')}: ${interviewLocation}`}</ListItemText>
          )}
          {interviewComment && (
            <ListItemText>{`${t('COMMENT')}: ${interviewComment}`}</ListItemText>
          )}
          {planningInterviewIsCompleted && interview && (
            <ButtonWrap>
              <EditDate interview={interview} />

              <ExportDate interviewId={interview.id} />
            </ButtonWrap>
          )}
        </>
      </ListItem>
      
      {clientFeedbackIsCompleted ? (
        <>
          <ListItem
            isActive={planningInterviewIsCompleted}
            isActiveLine={planningInterviewIsCompleted}
            isDone={clientFeedbackIsCompleted}
            title={clientFeedbackText}
          />

          <ListItem
            isActive={planningInterviewIsCompleted}
            isActiveLine={isBothSidePositive}
            isDone={recruiterFeedbackIsCompleted}
            title={recruiterFeedbackText}
            isButton={planningInterviewIsCompleted}
            handleClick={planningInterviewIsCompleted ? handleShowFeedbackModal : undefined}
          >
            {planningInterviewIsCompleted && (
              <ButtonWrap>
                <Button
                  size="small"
                  icon={<FeedbackIcon />}
                  onClick={handleShowFeedbackModal}
                >{recruiterFeedbackIsCompleted
                  ? t('VIEW_FEEDBACK')
                  : t('GIVE_FEEDBACK')
                }</Button>
              </ButtonWrap>
            )}
          </ListItem>
        </>
      ) : (
        <>
          <ListItem
            isActive={planningInterviewIsCompleted}
            isActiveLine={planningInterviewIsCompleted}
            isDone={recruiterFeedbackIsCompleted}
            title={recruiterFeedbackText}
            isButton={planningInterviewIsCompleted}
            handleClick={planningInterviewIsCompleted ? handleShowFeedbackModal : undefined}
          >
            {planningInterviewIsCompleted && (
              <ButtonWrap>
                <Button
                  size="small"
                  icon={<FeedbackIcon />}
                  onClick={handleShowFeedbackModal}
                >{recruiterFeedbackIsCompleted
                  ? t('VIEW_FEEDBACK')
                  : t('GIVE_FEEDBACK')
                }</Button>
              </ButtonWrap>
            )}
          </ListItem>

          <ListItem
            isActive={planningInterviewIsCompleted}
            isActiveLine={isBothSidePositive}
            isDone={clientFeedbackIsCompleted}
            title={clientFeedbackText}
          />
        </>
      )}
  
      <ListItem
        isActive={isBothSidePositive}
        isDone={isBothSidePositive}
        title={t('RECRUITER_STAGE_TASK_TITLE-6')}
      />
    </TasksList>
  )
}

export default React.memo(Tasks);
