import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { getJob } from '../api/jobs';
import { STEPS } from '../components/jobs/JobProgress';
import { JOBS } from '../constants/routes';
import ReactGa from 'react-ga4';

const PageWrapper = styled.section`
  background: #f2f6fa;
  width: 100%;
  display: flex;
  min-height: 100vh;
  flex-flow: row-reverse;
`;
const FormWrapper = styled.div`
  background: #fff;
  width: 61%;
  padding: 4.5rem 4rem;
  display: flex;
  flex-direction: column;
  position: relative;

  @media (max-width: 1200px) {
    width: 70%;
  }
  @media (max-width: 768px) {
    width: 90%;
    padding: 3rem;
  }
`;

export interface UrlParams {
  id: string;
  step: string;
}

const JobPage = () => {
  const history = useHistory();

  const { id }: UrlParams = useParams();

  useEffect(() => {
    if (id && id !== 'new') {
      getJob(id, history)
        .then((res) => {
          if (res.data.draft_status !== '') {
            if (res.data.draft_status === 'completed') {
              history.push(JOBS + '/edit/' + id + '/company_info');
            } else {
              const step = STEPS.find((s) => s.draft === res.data.draft_status);
              if (step) {
                history.push(JOBS + '/edit/' + id + '/' + step.value);
              }
            }
          }
        })
        .catch((err) => {
          console.error('Err', err);
        });
    }
  }, [id, history]);

  useEffect(() => {
    ReactGa.pageview('/job');
  }, []);
  return (
    <PageWrapper>
      <FormWrapper></FormWrapper>
    </PageWrapper>
  );
};

export default JobPage;
