import React from 'react';
import styled from 'styled-components';
import { Button as BaseButton, ButtonProps as BaseButtonProps } from 'antd';

const StyledButton = styled(BaseButton)`
  border-color: #34ce72;
  color: #34ce72;
  border-radius: ${(props) => (props.size === 'small' ? '.25rem' : '.5rem')};
  font-weight: 800;
  box-shadow: none;
  display: flex;
  align-items: center;
  background: transparent;

  &:hover{
    background: transparent;
  }

  &.ant-btn-default {
    border: solid 1px #34ce72;
    color: #34ce72;
    background: transparent;

    &:hover {
      border: solid 1px #22bf61;
      color: #22bf61;
    }
  }

  &:hover {
    border-color: #22bf61;
    color: #22bf61;
  }

  &:focus:not(.disabled) {
    box-shadow: 0 0 8px 0 #34ce72;
  }

  &:disabled,
  &.disabled {
    cursor: not-allowed;
    background: #dde7ee;
    color: #ffffff;
    border-color: #dde7ee;
    &:hover {
      background: #dde7ee;
      color: #ffffff;
      border-color: #dde7ee;
    }
  }

  &.ant-btn-primary {
    color: #ffffff;
    background: #34ce72;
    border: 1px solid #34ce72;

    &:hover {
      color: #ffffff;
      background: #22bf61;
      border: 1px solid #22bf61;
    }

    &:active,
    &:focus {
      color: #ffffff;
      background: #29ae5e;
      border: 1px solid #29ae5e;
    }

    &:after {
      box-shadow: 0 0 0 0 #34ce72;
    }

    &:disabled,
    &.disabled {
      cursor: not-allowed;
      background: #dde7ee;
      border-color: #dde7ee;
      color: #ffffff;
      &:hover {
        background: #dde7ee;
        color: #ffffff;
      }
    }
  }

  &.ant-btn-lg {
    padding: 0.813rem 2rem;
    height: auto;
    line-height: 1.25;
  }

  &.ant-btn-dangerous {
    color: #ff4d4f;
    border-color: #ff4d4f;
    &:focus,
    &:hover {
      color: #ff7875;
      border-color: #ff7875;
    }

    &:disabled,
    &.disabled {
      cursor: not-allowed;
      background: #dde7ee;
      border-color: #dde7ee;
      color: #ffffff;
      &:hover {
        background: #dde7ee;
        color: #ffffff;
      }
    }

    &.ant-btn-primary {
      background: #ff4d4f;
      color: #fff;
    }
  }

  &.ant-btn-ghost {
    background: transparent;
    &:focus,
    &:active {
        color: #22bf61;
        box-shadow: 0 0 8px 0 #34ce72;
        border-color: #22bf61;
      }
  }
  &.ant-btn.ant-btn-dangerous.ant-btn-ghost {
    background-color: transparent;
  }
  & svg {
    margin-right: 0.75rem;
  }

  &.ant-btn-sm {
    padding: 3px 0.5rem;
    height: auto;
    font-size: 0.75rem;
    line-height: 1.33;
  }

  &.ant-btn-background-ghost {
    &.ant-btn-primary {
      border: 1px solid #34ce72;
      color: #34ce72;
      &:hover {
        border: 1px solid #22bf61;
        color: #22bf61;
      }
      &:active,
      &:focus {
        border: 1px solid #29ae5e;
        color: #29ae5e;
      }
    }
  }
`;

interface ButtonProps extends BaseButtonProps {}
const Button = ({ children, size = 'large', ...rest }: ButtonProps) => {
  return (
    <StyledButton size={size} {...rest}>
      {children}
    </StyledButton>
  );
};

export default Button;
