import { SearchOutlined, CloseOutlined } from '@ant-design/icons';
import Input from '../../UI/inputs/Input';
import React from 'react';
import styled from "styled-components";

const InputWrapper = styled.div`
  position: relative;
  width: auto;
  min-width: 76%;
  left: 11.5rem;
  margin-top: 1rem;
  & .padded-left {
    padding-left: 2.5rem;
  }
  
  & .search-input-wrapper {
    width: 60%;
    left: 11rem;
    margin-bottom: 1rem;
  }
`;

const IconSearch = styled.span`
  position: absolute;
  font-size: 1.2rem;
  top: 40%;
  left: 0.5rem;
  cursor: pointer;
  transform: translateY(-50%);
  color: #627d95;
`;

const IconClose = styled.span`
  position: absolute;
  font-size: 1.2rem;
  top: 40%;
  right: 0.5rem;
  cursor: pointer;
  transform: translateY(-50%);
  color: #627d95;
`;

const SearchInput = (props: any) => {
    const [searchTerm, setSearchTerm] = React.useState('');

    const handleChange = () => {
        props.onSearch(searchTerm);
    };

    const clearState = () => {
        setSearchTerm((prev: string) => '');
        props.onSearch('');
    }

    return (
        <InputWrapper className={"padded-left search-input-wrapper"}>
        <Input value={searchTerm} onChange={(e: any) => setSearchTerm((prev: string) => e.target.value)} onPressEnter={handleChange} className={"search-input"} size="large" />
        <IconSearch onClick={handleChange}>
            <SearchOutlined />
        </IconSearch>
        <IconClose onClick={clearState}>
            <CloseOutlined />
        </IconClose>
    </InputWrapper>
    );
}

export default SearchInput;
