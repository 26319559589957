import { Job } from "../types";
import moment from 'moment';

const calculateDaysBefore = (job: Job): number => {
  return job?.published_at
  ? moment().diff(moment(job?.published_at), 'days')
  : 0;
}

export function calculateDaysSinceJobOpened(job: Job, t: (key: string) => string): string {
  let daysBefore = calculateDaysBefore(job);

  return job.status === 'published'
    ? job?.published_at
      ? `${t('OPEN_TITLE')} ${daysBefore + 1} ${daysBefore === 0 ? t('DAYS_TITLE') : t('DAYS_TITLE')
      }`
      : job.status
    : t(`${job.status.toUpperCase()}`)
}
