import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface JobInfoProps {
  icon: ReactNode;
  title: ReactNode;
}

const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 0.5rem;
  & svg {
    min-width: 1rem;
    min-height: 1rem;
    font-size: 1rem;
    color: #aebeca;
  }
`;
const InfoTitle = styled.p`
  font-size: 0.75rem;
  line-height: 1.33;
  color: #061c2e;
  margin: 0 0 0 1rem;
  position: relative;
  position: relative;
`;
const JobListInfoItem = ({ icon, title }: JobInfoProps) => {
  return (
    <InfoWrapper>
      {icon}
      <InfoTitle>{title}</InfoTitle>
    </InfoWrapper>
  );
};

export default JobListInfoItem;
