import { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, message } from 'antd';
import { RcFile } from 'antd/lib/upload';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

import { useAppSelector } from '../../../store/hooks';
import { ADMIN_ROLE } from '../../../constants/roles';
import { getCityList } from '../../../api/regions';
import { getRegions } from '../../../api/regions';
import { getIndustries } from '../../../api/industries';
import { createClientAddressList, editClientCompany } from '../../../api/client';
import { JOBS } from '../../../constants/routes';
import { getToken } from '../../../utils/localStorage';
import { useAppDispatch } from '../../../store/hooks';
import { getUserByToken } from '../../../store/userSlice';
import { getJob } from '../../../api/jobs';
import { Company } from '../../../types';
import {
  getCompanyByName,
  getClientCompanyById,
} from '../../../api/company';

interface Iprops {
  jobId: string,
}

const useCompanyInfoForm = ({ jobId }: Iprops) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const { user } = useAppSelector((state) => state.user);
  const [company, setCompany] = useState< Company| null>(null);
  const image = company?.image || '';
  const isAdmin = user?.role === ADMIN_ROLE;

  const [blockTransition, setBlockTransition] = useState(false);
  const [logo, setLogo] = useState<string>(image);
  const [fileError, setFileError] = useState<string>('');
  const [loading, setLoading] = useState(false);

  /* office and address logic start*/
  const [validating, setValidating] = useState(false);
  const [officeLocations, setOfficeLocations] = useState<{ id: string; address: string }[]>([]);
  const [address, setAddress] = useState('');

  /* get base data start */
  const [cities, setCities] = useState<{ id: string; name: string }[]>([]);
  const [regions, setRegions] = useState<{ id: string; name: string }[]>([]);
  const [industries, setIndustries] = useState<{ id: string; name: string }[]>([]);
  const [companies, setCompanies] = useState<{ id: string; name: string }[]>([]);

  useEffect(() => {
    const query = [getCityList(), getIndustries(), getRegions()];

    axios.all(query).then((res) => {
      setCities(res[0].data);
      setIndustries(res[1].data);
      setRegions(res[2].data);
    })
  }, []);
  /* get base data end*/

  const validateCustomList = useCallback(() => {
    let validated = false;
    const hasError = officeLocations.map((el: any) => {
      if (!el.address) {
        setValidating(true);
      }
      return !!el.address;
    });
    //hasError.push(!!address);
    if (hasError.includes(false)) {
      validated = false;
    } else {
      validated = true;
    }
    setValidating(!validated);
    return validated;
  }, [officeLocations]);

  /* setup company for Client */
  useEffect(() => {
    if (user && !isAdmin) {
      setCompany(user.company);
    }
  }, [user, setCompany, isAdmin]);

  /* setup company for Admin */
  useEffect(() => {
    if (isAdmin && jobId && jobId !== 'new') {
      // can show company only for jobs what already exists
      getJob(jobId).then((res) => {
        setCompany(res.data.company);
      });
    }

    getCompanyByName('').then((res) => setCompanies(res.data));
  }, [isAdmin, jobId, setCompany]);

  useEffect(() => {
    if (company) {
      const fieldsData = {
        ...company,
        city: company?.city?.id,
        industry: company?.industry?.id,
        region: company?.region?.id,
      }
      form.setFieldsValue(fieldsData);
      setLogo(company?.image || '');
      setOfficeLocations(company.office_locations);
      setAddress(company.address);
    }
  }, [company, form, setOfficeLocations])

  const handleFieldsChange = useCallback(() => {
    setBlockTransition(true);
  }, [setBlockTransition]);

  const handleUploadFile = useCallback(
    (file: RcFile): boolean => {
      if (file.type === 'image/png' || file.type === 'image/jpeg') {
        setFileError('');
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            const base64 = reader.result;
            if (base64) {
              setLogo(base64?.toString());

              form?.setFieldsValue({
                ...form.getFieldsValue(),
                image: base64.toString(),
              });
            }
          };
          reader.onerror = (error) => reject(error);
        });
      } else {
        setFileError(t('FILE_FORMAT_VALIDATION'));
      }
      return false;
    },
    [form, t],
  );

  const onFinish = useCallback((values: any) => {
    if (company) {
      if (validateCustomList()) {
        setLoading(true);

        if (logo.startsWith('http')) {
          delete values.image;
        } else if (!logo) {
          delete values.image;
        }

        createClientAddressList(company.id, officeLocations).then((res) => {
          editClientCompany({ ...values, address }, company.id)
            .then((res) => {
              const token = getToken('access_token');
              dispatch(getUserByToken(token));

              setLoading(false);

              message.success(t('INFORMATION_SAVE_SUCCESS'), 5);

              history.push(`${JOBS}/edit/${jobId}/info`);
            })
            .catch(() => setLoading(false));
        });
      }
    }
  }, [
    company,
    address,
    history,
    logo,
    validateCustomList,
    t,
    officeLocations,
    dispatch,
    jobId,
  ]);

  const handleSelectCompany = useCallback((companyId) => {
    getClientCompanyById(companyId).then((res) => setCompany(res.data));
  }, [setCompany]);

  return {
    actions: {
      handleFieldsChange,
      handleUploadFile,
      setOfficeLocations,
      setAddress,
      onFinish,
      handleSelectCompany,
    },
    data: {
      blockTransition,
      form,
      logo,
      company,
      isAdmin,
      cities,
      officeLocations,
      validating,
      address,
      regions,
      industries,
      loading,
      fileError,
      companies,
    },
  };
};

export default useCompanyInfoForm;
