import React from 'react';
import { Job, Language } from '../../types';
import { JOBS } from '../../constants/routes';
import RoundAddButton from '../../UI/buttons/RoundAddButton';
import { useHistory } from 'react-router-dom';
import { ADMIN_ROLE } from '../../constants/roles';
import RecruiterJobListItem from '../jobs/jobList/RecruiterJobListItem';

interface JobProps {
  jobs: Job[]
  languages: Language[]
  setJobs: React.Dispatch<React.SetStateAction<any>>
}

const AdminJobs = ({jobs,setJobs,languages}:JobProps) => {
  const history = useHistory();
  return (
    <>
      <RoundAddButton onClick={() => history.push(JOBS + '/edit/new/company_info')}/>
      {jobs.map(job => <RecruiterJobListItem job={job} setJobs={setJobs} key={job.id} role={ADMIN_ROLE} languages={languages}/>)}
    </>
  );
};

export default AdminJobs;
