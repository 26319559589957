import React, { ReactNode } from 'react';
import { Spin, SpinProps } from 'antd';
import styled from 'styled-components';
interface LoaderProps extends SpinProps{
  children?:ReactNode,
}
const StylesSpinner = styled(Spin)`
& .ant-spin-dot-item{
background-color: #34ce72;
}
&.mobile-spinner{
@media (max-width: 768px){
width: 100%;
}
}
`
const Loader = ({children,...rest}:LoaderProps) => {
  return (
    <StylesSpinner  {...rest} >
      {children}
    </StylesSpinner>
  );
};

export default Loader;
