import React, { ReactNode, useEffect, useState } from 'react';
import NameAvatar from '../../UI/NameAvatar';
import format from 'date-fns/format';
import Subtitle from '../../UI/typography/Subtitle';
import FormPopUp from '../form/FormPopUp';
import { InterviewRoom } from '../../types';
import { useAppSelector } from '../../store/hooks';
import { CLIENT_ROLE } from '../../constants/roles';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { getFeedback } from '../../api/interviews';
import FeedbackResult from './FeedbackResult';
import { getRandomColor } from '../../constants/palette';
import { useHistory } from 'react-router-dom';
import { navigate } from '../../utils/routes';

interface FeedbackModalProps {
  info: InterviewRoom;
}

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3rem;
`;
const HeaderItem = styled.div`
  display: flex;
  align-items: center;
  max-width: max-content;
  &:last-child {
    max-width: 50%;
  }
`;
const NameWrapper = styled.div<ReactNode & { align?: string }>`
  margin-left: 0.5rem;
  &.row {
    display: flex;
    align-items: center;
  }
`;
const Feature = styled.span`
  font-size: 0.875rem;
  line-height: 1.43;
  color: #627d95;
  margin: 0 1rem;
`;
const Name = styled.p`
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.43;
  color: #061c2e;
  margin-bottom: 0;
`;
const Role = styled.p`
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.43;
  color: #627d95;
  margin: 0;
`;

const ShowFeedback = ({ info }: FeedbackModalProps) => {
  const history = useHistory();
  const params = new URLSearchParams(history.location.search);
  const feedback_id = params.get('feedback_id');
  const history_modal = params.get('history');
  const feedback_interview_id = params.get('feedback_interview_id');
  const stage = params.get('stage_name');
  const [visible, setVisible] = useState(!!feedback_id);
  const [result, setResult] = useState({});
  const [t] = useTranslation();
  useEffect(() => {
    setVisible(!!feedback_id);
  }, [feedback_id]);
  useEffect(() => {
    if (visible) {
      feedback_interview_id &&
        feedback_id &&
        getFeedback(feedback_interview_id, feedback_id).then((res) =>
          setResult(res.data),
        );
    } else {
      navigate(history, undefined, ['feedback_id','feedback_interview_id',history_modal ? '' : 'interview_id','stage_name']);
      setResult({});
    }
  }, [visible, feedback_id, feedback_interview_id, history,history_modal]);
  const mainInterview = info.current_stage?.tasks?.find(
    (task) => task.is_planning_task,
  );
  const { user } = useAppSelector((state) => state.user);
  const isClient = user?.role === CLIENT_ROLE;

  return (
    <FormPopUp
      visible={visible}
      setVisible={setVisible}
      title={`${stage} ${t('FEEDBACK')}`}
    >
      <Header>
        <HeaderItem>
          {!isClient && (
            <>
              <NameAvatar
                name={info.job.company.name}
                color={getRandomColor()}
                image={undefined}
              />
              <NameWrapper className="row">
                <Name>{info.job.company.name}</Name>
              </NameWrapper>
              <Feature>&</Feature>
            </>
          )}
          <NameAvatar
            name={info.candidate.name}
            image={undefined}
            color={getRandomColor()}
          />
          <NameWrapper>
            <Name>{info.candidate.name}</Name>
            {isClient && <Role>{info.candidate.current_role}</Role>}
          </NameWrapper>
        </HeaderItem>
        <HeaderItem>
          <NameWrapper>
            <Name>
              {mainInterview && mainInterview.time_from && mainInterview.time_to ? (
                <>
                  {format(new Date(mainInterview.time_from), 'MMMM d, yyyy')},{' '}
                  {format(new Date(mainInterview.time_from), 'H:mm')} -{' '}
                  {format(new Date(mainInterview.time_to), 'H:mm')}
                </>
              )  : '-'}
            </Name>
            <Role>{mainInterview && mainInterview?.place}</Role>
          </NameWrapper>
        </HeaderItem>
      </Header>
      <Subtitle title={t('RESPONDENT_DECISION')} />
      <FeedbackResult data={result} />
    </FormPopUp>
  );
};

export default ShowFeedback;
