import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as NotificationIcon } from '../../icons/notification.svg';
import { Badge } from 'antd';
import NotificationPopup from '../Popups/NotificationPopup';
import { useAppSelector } from '../../store/hooks';
import useClickAwayListener from '../../hooks/useClickAwayListener';

const StyledNotification = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #627d95;
  position: relative;
  cursor: pointer;
  padding: 0;
  border: none;
  &.ant-btn svg {
    margin: 0;
  }
  &:focus {
    & .notification-right {
      display: block;
    }
  }
`;
const StyledBadge = styled(Badge)`
  & .ant-badge-count {
    top: 0.25rem;
    right: 2px;
    min-width: 1rem;
    height: 1rem;
    padding: 3px;
    line-height: 1rem;
    background: #34ce72;
    display: flex;
    align-items: center;
    justify-content: center;
    & .ant-scroll-number-only {
      height: auto;
      & > p.ant-scroll-number-only-unit {
        height: auto;
      }
    }
  }
`;
const NotificationMenuItem = () => {
  const [showNotifications, setShowNotifications] = useState(false);
  const notificationRef = useRef<HTMLDivElement>(null);
  const { unreadCount } = useAppSelector((state) => state.notifications);
  const handleClick = () => {
    setShowNotifications(!showNotifications);
  };
  useClickAwayListener(notificationRef, () => {
    if (showNotifications) {
      setShowNotifications(false);
    }
  });
  return (
    <StyledNotification ref={notificationRef}>
      <div onClick={handleClick}>
        <StyledBadge count={unreadCount}>
          <NotificationIcon />
        </StyledBadge>
      </div>
      <NotificationPopup
        show={showNotifications}
        handleShow={setShowNotifications}
      />
    </StyledNotification>
  );
};

export default NotificationMenuItem;
