import styled from 'styled-components';

export const SingleJobBody = styled.div`
  margin: -4rem auto 0;
  padding-bottom: 5rem;
  max-width: 1140px;
  width: 100%;
  
  .application-process {
    & .coming-soon {
      p {
        text-align: left;
      }
    }
  }
`;

export const InterviewDescription = styled.p`
  margin-bottom: 32px;
`;

export const ApplicationProcessWrap = styled.div`
  max-width: 752px;
`;
