import { Col, Row } from 'antd';
//import { ReactComponent as NavLogo } from '../../../icons/HirePort-Public.svg';
import {
  BannerWrapper,
  BannerOverlay,
  BannerContent,
  BannerHeader,
  BannerInfo,
  BannerLogo,
  BannerInfoText,
  JobTitle,
  SkillItemList,
  JobInfoItem,
} from './styles';
import hireportLogoIcon from "../../../icons/logo-ufo.svg";
import React from "react";
import { CompanyProfile} from "../index";
import SvgIcon from "../../../components/helper/SvgIcon";
import { ReactComponent as StarIcon } from '../../../icons/start-rating.svg';
import CompanyActivityFeed from "../../../components/client/CompanyActivityFeed";
import {CompanyActivity} from "../../../types";
import BackButton from '../../../UI/buttons/BackButton';
import {useHistory} from "react-router-dom";
import {HeaderTitle} from "../../../components/jobs/singleJob/Banner/styles";
import {useTranslation} from "react-i18next";
interface BannerProps {
  loading: boolean;
  company?: CompanyProfile|null;
  activityFeed?: CompanyActivity[] | null
}

const Banner = ({
  loading,
  company,
  activityFeed
}: BannerProps) => {
  //const [t] = useTranslation();
  //const isRecruiter = user?.role === RECRUITER_ROLE;

  const defaultBackgroundImage = 'https://hireport-acceptance-storage-eu-central-1.s3.eu-central-1.amazonaws.com/media/assets/img-022.min.jpg';
  const bg_image = defaultBackgroundImage;
  const logo = company?.image ?? hireportLogoIcon;
  const history = useHistory();
  const [t] = useTranslation();

  return (
    <BannerWrapper
      style={{
        backgroundImage: `url(${bg_image ? bg_image : defaultBackgroundImage}`,
        backgroundColor: bg_image ? '#fff' : 'inherit',
      }}
    >
      <BannerOverlay>
        <BannerContent>
          <BannerHeader>
            <BackButton onClick={() => history.goBack()} />
            <HeaderTitle>{t('BACK')}</HeaderTitle>
            {/*<NavLogo />*/}
          </BannerHeader>
          {!loading && (
            <BannerInfo>
              {logo && (
                <BannerLogo>
                  <img src={logo} alt="Logo" />
                </BannerLogo>
              )}
              <BannerInfoText>
                <JobTitle>
                  <h2>
                    {company?.name}
                  </h2>
                </JobTitle>

                <Row
                  gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}
                  justify="space-between"
                >
                  <Col xs={{ span: 24 }} md={{ span: 12 }} xl={{ span: 12 }}>
                    <Row
                        gutter={[8, { xs: 8, sm: 8, md: 8, lg: 8 }]}
                        justify="start"
                    >
                      {company?.total_ratings && (
                      <JobInfoItem>
                        <StarIcon />
                        {company?.total_ratings?.toFixed(1)}
                      </JobInfoItem>
                      )}
                      {company?.badges && company?.badges?.map((badge) => (
                          <JobInfoItem>
                            {badge.icon && (
                                <SvgIcon url={badge.icon} text={badge.name} />
                            )}
                          </JobInfoItem>
                      ))
                      }
                    </Row>
                    <Row
                        gutter={[8, { xs: 8, sm: 8, md: 8, lg: 8 }]}
                        justify="start"
                    >
                      <CompanyActivityFeed activityFeed={activityFeed} />
                    </Row>
                  </Col>

                  <Col xs={{ span: 24 }} md={{ span: 12 }} xl={{ span: 13 }}>

                    <SkillItemList>
                     {/* {is_visa_available && <SkillItemAlt style={{ backgroundColor: '#f0ecf7' }}>Visa</SkillItemAlt>}
                      {relocationObject && <SkillItemAlt style={{ backgroundColor: '#f0ecf7' }}>{t(relocationObject.label)}</SkillItemAlt>}*/}
                    </SkillItemList>
                  </Col>
                  {/*<Col xs={{ span: 24 }} md={{ span: 24 }} xl={{ span: 6 }}>*/}
                  {/*  <ActionButtons>*/}
                  {/*    {!user && (*/}
                  {/*        <StyledButton*/}
                  {/*            icon={<CheckIcon />}*/}
                  {/*            size="large"*/}
                  {/*            type="primary"*/}
                  {/*            onClick={() => {*/}
                  {/*                storeJobId(id);*/}
                  {/*                return history.push(*/}
                  {/*                    REGISTER*/}
                  {/*                )*/}
                  {/*              }*/}
                  {/*            }*/}
                  {/*        >*/}
                  {/*              {t('RECEIVING_CANDIDATES')}*/}
                  {/*        </StyledButton>*/}
                  {/*    )}*/}
                  {/*  </ActionButtons>*/}
                  {/*</Col>*/}
                </Row>
              </BannerInfoText>
            </BannerInfo>
          )}
        </BannerContent>
      </BannerOverlay>
    </BannerWrapper>
  );
};

export default Banner;
