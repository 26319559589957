import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface TextProps {
  children: ReactNode
}
const SalaryItem = styled.p`
  font-size: .75rem;
  line-height: 1.33;
  color: #061c2e;
  margin-bottom: .5rem;
`
const SalaryText = ({children}:TextProps) => {
  return (
    <SalaryItem>
      {children}
    </SalaryItem>
  );
};

export default SalaryText;
