import React, { useEffect, useState } from 'react';
import ConfirmProposeDate from '../form/ConfirmProposeDate';
import AddProposeDate from '../form/AddProposeDate';
import Tab from '../../UI/Tab';
import { message, Tabs } from 'antd';
import { COMPLETED, PLANNING, WAITING_RECRUITER } from '../../constants/statuses';
import { InterviewRoom } from '../../types';
import { useTranslation } from 'react-i18next';
import {
  confirmProposedDate,
  getInterview,
  getProposedDates,
  proposeInterviewDates,
} from '../../api/interviews';
import NameAvatar from '../../UI/NameAvatar';
import { getRandomColor } from '../../constants/palette';
import styled from 'styled-components';
import FormTitle from '../auth/FormTitle';
import { ErrorMessage } from '../auth/LoginForm';

interface ProposedDateProps {
  interview: InterviewRoom;
  setInterview: React.Dispatch<React.SetStateAction<InterviewRoom | null>>;
}
const { TabPane } = Tabs;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0.75rem 0;
  margin-bottom: 1.5rem;
`;
const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-right: 2rem;
`;
const HeaderRight = styled.div`
  width: 50%;
  & p {
    text-align: right;
  }
`;
const Name = styled.p`
  font-size: 0.875rem;
  margin-left: 0.5rem;
  font-weight: 700;
  line-height: 1.43;
  color: #061c2e;
  margin-bottom: 0;
`;
const NameWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const Feature = styled.span`
  font-size: 0.875rem;
  line-height: 1.43;
  color: #627d95;
  margin: 0 1rem;
`;
const ProposeDateForm = ({ interview, setInterview }: ProposedDateProps) => {
  const [t] = useTranslation();
  const [dates, setDates] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const isPlanning = interview?.current_stage?.status === PLANNING;
  const isUncompleted = interview?.current_stage?.tasks.filter(el => el.status !== COMPLETED).length;
  const [activeKey, setActiveKey] = useState(isPlanning ? '2' : '1');
  useEffect(() => {
    setActiveKey(interview?.current_stage?.status === PLANNING ? '2' : '1');
  }, [interview]);
  const waitingForRecruiter =
    interview?.current_stage?.status === WAITING_RECRUITER;
  useEffect(() => {
    getProposedDates(interview.id).then((res) => {
      setDates(res.data.proposed_dates);
      setLoading(false);
    });
  }, [interview.id]);
  const handleComplete = (date_id: string) => {
    confirmProposedDate(interview.id, date_id).then((res) => {
      message.success(t('DATE_CONFIRMED'), 3);
      getInterview(interview.id).then((res) => setInterview(res.data));
    });
  };
  const addDates = async (dates: {}[]) => {
   return await proposeInterviewDates(interview.id, dates).then((res) => {
      getInterview(interview.id).then((res) => {
        setDates(dates);
        setInterview(res.data);
      });
    });
  };
  return (
    <>
      <FormTitle text={t('PLAN_INTERVIEW')} />
      <Header>
        <HeaderLeft>
          <NameWrapper>
            <NameAvatar
              name={interview.job.company.name}
              image={
                interview.job.company.image
                  ? interview.job.company.image
                  : process.env.PUBLIC_URL + 'images/company-logo.png'
              }
              color={getRandomColor()}
            />
            <Name>{interview.job.company.name}</Name>
          </NameWrapper>
          <Feature>&</Feature>
          <NameWrapper>
            <NameAvatar
              name={interview.candidate.name}
              color={getRandomColor()}
            />
            <Name>{interview.candidate.name}</Name>
          </NameWrapper>
        </HeaderLeft>
        <HeaderRight>
          <Name>{interview.job.title}</Name>
        </HeaderRight>
      </Header>
      {isUncompleted ? <Tab activeKey={activeKey} onChange={(key) => setActiveKey(key)}>
        <TabPane tab={t('PROPOSED_DATES')} key="1" disabled={isPlanning}>
          <ConfirmProposeDate
            dates={dates}
            onConfirm={handleComplete}
            interview={interview}
            loading={loading}
          />
        </TabPane>

        <TabPane
          tab={isPlanning ? t('DATES') : t('OTHER_DATES')}
          key="2"
          disabled={waitingForRecruiter}
        >
          <AddProposeDate addDates={addDates}/>
        </TabPane>
      </Tab>
        : <ErrorMessage message={t('NOT_ACTUAL')} type="error" />
      }
    </>
  );
};

export default ProposeDateForm;
