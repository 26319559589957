import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import ReactDOM from 'react-dom';
import { isPast, parseISO, format } from 'date-fns';
import styled from 'styled-components';
import FullCalendar, { EventInput } from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import enLocale from '@fullcalendar/core/locales/es-us';
import nlLocale from '@fullcalendar/core/locales/nl';

import '../components/calendar/calendar.css';
import { getEvents } from '../api/events';
import NameAvatar from '../UI/NameAvatar';
import UnconfirmedEvents from '../components/calendar/UnconfirmedEvents';
import { getInterview } from '../api/interviews';
import { ReactComponent as IconPlace } from '../icons/icon-place.svg';
import { CANDIDATES } from '../constants/routes';
import en from 'date-fns/locale/en-US';
import nl from 'date-fns/locale/nl';
import ReactGa from 'react-ga4';
import RescheduleDate from '../components/candidates/kanban/RescheduleDate';
import ExportEvent from '../components/calendar/ExportEvent';
import { SHOW_DATE_PROPOSE_PARAM, INTERVIEW_ID_PARAM } from '../constants/queryParams';

const CalendarContainer = styled.div`
  padding: 2rem 2.5rem;
  position: relative;
`;

const EventPlaceIcon = styled(IconPlace)`
  margin-right: 8px;
`;

const CalendarEvent = styled.div<{ temp: boolean; past: boolean }>`
  background-color: ${(props) => (props.temp ? '#ffeeee' : '#f2f6fa')};
  opacity: ${(props) => (props.past ? '0.5' : '1')};
  pointer-events: ${(props) => (props.past ? 'none' : 'inherit')};
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1rem;
  margin: 0 0.5rem 0.5rem;
  max-width: calc(100% - 1rem);
  border-radius: 8px;
`;

const CalendarEventTitle = styled.p`
  font-family: Lato;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #061c2e;
  margin: 0;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const CalendarEventDescription = styled.p`
  font-family: Lato;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #627d95;
  margin: 0;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const UnconfirmedPanel = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 2rem;
  width: 368px;
  padding: 16px 32px 32px;
  border-radius: 8px;
  box-shadow: 0 32px 80px 0 rgba(6, 28, 46, 0.2),
    0 16px 20px 0 rgba(6, 28, 46, 0.16);
  background-color: #ffffff;
  z-index: 1000;
  &.left {
    left: 0;
  }
  &.right {
    right: 0;
  }
`;

const PanelHeader = styled.div`
  border-bottom: 1px solid #dae5ec;
  padding: 1rem 0;
  display: flex;
  font-family: Lato;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.96px;
  text-align: left;
  color: #061c2e;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const UnconfirmedList = styled.ul`
  list-style: none;
  max-height: calc(100% - 3rem);
  overflow-y: auto;
  margin: 0;
`;

const UnconfirmedEvent = styled.li`
  padding: 12px 16px;
  display: flex;
  align-items: center;
  background: #f2f6fa;
  border-radius: 8px;
  margin-bottom: 8px;

  &:hover {
    opacity: 0.8;
  }
`;

const UnconfirmedEventInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
`;

const UnconfirmedEventInfoTitle = styled.h5`
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #061c2e;
  margin-bottom: 4px;
`;

const UnconfirmedEventInfoDescription = styled.h5`
  font-family: Lato;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #627d95;
  margin: 0;
`;

const StageName = styled.h3`
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #061c2e;
  margin: 24px 0;
`;

const EventPlace = styled.div`
  margin-top: 24px;
  display: flex;
`;
const EventActions = styled.div`
  margin-top: 24px;
  display: flex;
`;

const Calendar = () => {
  const [, i18n] = useTranslation();
  const history = useHistory();
  const [locale, setLocale] = useState(i18n.language);

  useEffect(() => {
    setLocale(i18n.language);
  }, [i18n.language]);
  useEffect(() => {
    ReactGa.pageview('/calendar');
  }, []);

  const renderEventContent = (eventInfo: any) => {
    const type = eventInfo.event.extendedProps.event_type;
    const date = eventInfo.event.extendedProps.event_date;

    return (
      <CalendarEvent
        temp={type === 2}
        past={isPast(parseISO(date))}
        title={
          eventInfo.event.extendedProps.company_name +
          ' (' +
          eventInfo.event.title +
          ')'
        }
      >
        <CalendarEventTitle>
          {eventInfo.timeText}{' '}
          {eventInfo.event.extendedProps.data.interview_stage_name}
        </CalendarEventTitle>
        <CalendarEventDescription>
          {eventInfo.event.title}
        </CalendarEventDescription>
      </CalendarEvent>
    );
  };

  const handleEventClick = async (info: any) => {
    const { el, event } = info;
    const pos = el.closest('.fc-daygrid-event-harness').getBoundingClientRect();
    const viewport = window.innerWidth;
    const right = pos.right;
    const width = pos.width;
    let elementClass = 'left';
    if (viewport - right - width < 368) {
      elementClass = 'right';
    }
    const interviewId = event.extendedProps.data.interview_id;
    const eventId = event.id;

    if (event.extendedProps.event_type !== 2) {
      const result = await getInterview(interviewId);
      const interview = result.data;
      let child = el
        .closest('.fc-daygrid-event-harness')
        .appendChild(document.createElement('div'));

      const interviewPlace = interview.current_stage.tasks.find(
        (t: any) => t.is_planning_task === true,
      ).place;

      ReactDOM.render(
        <UnconfirmedPanel id="eventPanel" className={elementClass}>
          <PanelHeader>
            <span>
              {format(new Date(event.start), 'MMMM d, EEEE', {
                locale: i18n.language === 'en' ? en : nl,
              })}
            </span>
            <span>
              {format(new Date(event.start), 'HH:mm', {
                locale: i18n.language === 'en' ? en : nl,
              })}
            </span>
          </PanelHeader>
          <StageName>{interview.current_stage.stage_name}</StageName>
          <UnconfirmedList>
            <UnconfirmedEvent
              style={{ cursor: 'pointer' }}
              onClick={() =>
                history.push(
                  `${CANDIDATES}?filter=in-process&history=${interview.candidate.id}&interview_id=${interview.id}`,
                )
              }
            >
              <NameAvatar
                name={interview.candidate.name}
                color={{
                  main: '#7949cd',
                  light: '#f2edfa',
                }}
              />
              <UnconfirmedEventInfo>
                <UnconfirmedEventInfoTitle>
                  {interview.candidate.name}
                </UnconfirmedEventInfoTitle>
                <UnconfirmedEventInfoDescription>
                  {interview.candidate.current_role.name}
                </UnconfirmedEventInfoDescription>
              </UnconfirmedEventInfo>
            </UnconfirmedEvent>
            <UnconfirmedEvent>
              <NameAvatar
                style={{backgroundColor:'#fff'}}
                name={''}
                image={
                  interview.client.image
                    ? interview.client.image
                    : interview.job.company.image
                    ? interview.job.company.image
                    : process.env.PUBLIC_URL + '/images/company-logo.png'
                }
              />
              <UnconfirmedEventInfo>
                <UnconfirmedEventInfoTitle>
                  {interview.client.company.name}
                </UnconfirmedEventInfoTitle>
              </UnconfirmedEventInfo>
            </UnconfirmedEvent>
            {interviewPlace && (
              <EventPlace>
                <EventPlaceIcon />
                {interviewPlace}
              </EventPlace>
            )}
             <EventActions>
               <RescheduleDate interview={interview} history={history}/>
               <ExportEvent event={eventId}/>
            </EventActions>
          </UnconfirmedList>
        </UnconfirmedPanel>,
        child,
      );
      document.addEventListener('click', () => {
        document.getElementById('eventPanel')?.remove();
      });
    } else {
      const result = await getInterview(interviewId);
      const interview = result.data;
      history.push(
        CANDIDATES +
          `?filter=in-process&${INTERVIEW_ID_PARAM}=` +
          interview.id +
          '&stage=' +
          interview.current_stage.stage_name +
          `&${SHOW_DATE_PROPOSE_PARAM}=true`,
      );
    }
  };

  const handleLoadEvents = (
    info: {
      start: Date;
      end: Date;
      startStr: string;
      endStr: string;
      timeZone: string;
    },
    successCallback: (events: EventInput[]) => void,
    failureCallback: (error: {
      message: string;
      response?: any;
      [otherProp: string]: any;
    }) => void,
  ) => {
    const start = format(new Date(info.start), 'yyyy-MM-dd');
    const end = format(new Date(info.end), 'yyyy-MM-dd');
    getEvents('', start, end).then((result) => {
      successCallback(
        result.data.map((e: any) => ({
          ...e,
          date: e.event_date,
          title: e.data.title,
        })),
      );
    });
  };

  return (
    <CalendarContainer id="calendar-page">
      <UnconfirmedEvents />
      <FullCalendar
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
        events={handleLoadEvents}
        headerToolbar={{
          start: 'title prev,next',
          end: '',
        }}
        locales={[enLocale, nlLocale]}
        locale={locale}
        weekNumberCalculation="ISO"
        eventContent={renderEventContent}
        eventTimeFormat={{
          hour: '2-digit',
          minute: '2-digit',
          meridiem: false,
          hour12: false,
        }}
        eventClick={handleEventClick}
      />
    </CalendarContainer>
  );
};

export default Calendar;
