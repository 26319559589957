import React from 'react';
import { Tooltip, TooltipProps } from 'antd';



const TooltipPrompt = ({title,children,placement = 'top'}:TooltipProps) => {
  return (
    <Tooltip title={title} color={"#000000bf"} placement={placement}>
      {children}
    </Tooltip>
  );
};

export default TooltipPrompt;
