import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  TasksList,
} from './styles';
import ListItem from './ListItem';

const DisabledClientTasks = () => {
  const [t] = useTranslation();

  return (
    <TasksList>
      {/*<ListItem
        title={t('CLIENT_STAGE_TASK_TITLE-1', { numberOfHours: 'XX' })}
      />*/}

      <ListItem
        title={t('INTERVIEW_TEXT_CLIENT')}
      />

      <ListItem
        title={t('CLIENT_STAGE_TASK_TITLE-3_DISABLED')}
      />

      <ListItem
        title={t('RECRUITER_STAGE_TASK_TITLE-5_AFTER', { numberOfHours: 'XX' })}
      />

      <ListItem
        title={t('CLIENT_STAGE_TASK_TITLE-4_AFTER', { numberOfHours: 'XX' })}
      />
  
      <ListItem
        title={t('RECRUITER_STAGE_TASK_TITLE-6')}
      />

    </TasksList>
  )
}

export default React.memo(DisabledClientTasks);
