import { useEffect, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { CandidateInfo as ICandidateInfo, InterviewRoom } from '../../../../types';
import { getCandidate } from '../../../../api/candidates';
import { CANDIDATES } from '../../../../constants/routes';
import {
  INTERVIEW_ID_PARAM,
  STAGE_PARAM,
  HISTORY_PARAM,
} from '../../../../constants/queryParams';
import { INTRODUCED, OFFERED, ON_HOLD } from '../../../../constants/statuses';
import { InterviewStage } from '../../../../types';
import { getJob } from '../../../../api/jobs';

interface IuseInterviewStageModal {
  interview: InterviewRoom | { [key:string]: any };
  setShowInterviewStageModal: (show: boolean) => void,
}

const useInterviewStageModal = ({ interview, setShowInterviewStageModal }: IuseInterviewStageModal) => {
  const history = useHistory();

  const params = new URLSearchParams(history.location.search);
  const interview_id = params.get(INTERVIEW_ID_PARAM);

  const [candidateInfo, setCandidateInfo] = useState<ICandidateInfo | {[key: string]: string}>({
    current_role: '',
    current_company: '',
  });

  const [currentStageId, setCurrentstageId] = useState('');

  const [selectedStageId, setSelectedStageid] = useState('');

  const handleCloseModal = useCallback(() => {
    setCandidateInfo({
      current_role: '',
      current_company: '',
    });
    setShowInterviewStageModal(false);

    const params = new URLSearchParams(history.location.search);
    params.delete(INTERVIEW_ID_PARAM);
    params.delete(STAGE_PARAM);
  
    const query = params.toString();
    history.push(CANDIDATES + '?' + query);
  }, [history, setShowInterviewStageModal, setCandidateInfo]);

  /* get addition candidate info */
  const { candidate, client, id } = interview;

  useEffect(() => {
    setSelectedStageid(currentStageId);

    if (interview?.status === INTRODUCED || interview?.status === OFFERED || interview?.status === ON_HOLD) {
      setCurrentstageId(interview.status);
    } else {
      setCurrentstageId(interview?.current_stage?.stage_id);
    }
  }, [currentStageId, interview])

  useEffect(() => {
    if (candidate?.id) {
      getCandidate(candidate.id).then((res) => setCandidateInfo(res.data));
    }
  }, [candidate?.id]);

  useEffect(() => {
    if (!interview_id) {
      handleCloseModal();
    }
  }, [interview_id, handleCloseModal]);

  useEffect(() => {
    return () => {
      setCurrentstageId('');
      setSelectedStageid('');
      setCandidateInfo({
        current_role: '',
        current_company: '',
      });
    }
  }, []);

  const handleCandidateNameClick = useCallback(() => {
    handleCloseModal();
    const query = new URLSearchParams(history.location.search);
    query.set(HISTORY_PARAM, candidate.id);
    query.set(INTERVIEW_ID_PARAM, id);
    history.push({ search: query.toString() });
  }, [history, candidate?.id, id, handleCloseModal]);

  return {
    actions: {
      handleCandidateNameClick,
      handleCloseModal,
      setSelectedStageid,
    },
    data: {
      candidate: candidate || {},
      candidateInfo,
      client,
      selectedStageId,
      currentStageId,
    }
  }
};

interface Istage extends InterviewStage {
  description?: string,
}

export const useStages = (jobId?: string) => {
  const [stages, setStages] = useState<Istage[]>([]);

  useEffect(() => {
    // each job has an individual set of stages, we can't use common set from the Kanban
    if (jobId) {
      getJob(jobId).then((res) => {
        // result [{id: 'c1ea573c-89cf-4d1c-b73b-37b1b5e41618', name: 'Screening', order: 1, description: 'abc'}, {...}]
        const stages = res.data.stages.map((stage: any) => ({ ...stage.stage, description: stage.description }));
        setStages(stages);
      })
    }

    return () => setStages([]);
  }, [jobId]);

  return {
    data: {
      stages,
    },
  };
};

export default useInterviewStageModal;
