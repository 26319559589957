import { useTranslation } from "react-i18next";

const RatingRequestConfirmation = () => {
  const { t } = useTranslation();
  return (
    <p>{t("Thank you for your rating")}</p>
  )
};

export default RatingRequestConfirmation;
