import React, {useEffect, useState} from 'react';
import styled from "styled-components";

interface IconProps {
    url: string;
    text?: string|null;
}

const SVGWrapper = styled.div`{
    font-size: 0.875rem;
    line-height: 1.71;
    color: #ffffff;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-transform: capitalize;
    margin-left: 0.5rem;
}`;
const SvgIcon = (props: IconProps) => {
    const [svg, setSvg] = useState<string>('');

    useEffect(() => {
        fetch(props.url)
            .then(response => response.text())
            .then(svg => setSvg(svg));
    }, [props.url]);

    return <SVGWrapper dangerouslySetInnerHTML={{ __html: svg + props.text }} />;
}

export default SvgIcon;
