import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import Input from './Input';

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
  & .padded-right {
    padding-right: 2.5rem;
  }
`;
const IconEye = styled.span`
  position: absolute;
  font-size: 1.5rem;
  top: 50%;
  right: 0.5rem;
  cursor: pointer;
  transform: translateY(-50%);
  color: #627d95;
`;
const InputPassword = ({ ...rest }) => {
  const [type, setType] = useState('password');
  const [isSafari, setIsSafari] = useState(false);
  const toggleType = () => {
    setType(type === 'password' ? 'text' : 'password');
  };
  useEffect(() => {
    let ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf('safari') !== -1) {
      if (ua.indexOf('chrome') < 0) {
        setIsSafari(true);
      }
    }
  }, []);

  return (
    <InputWrapper>
      <Input
        size="large"
        type={type}
        className={`${isSafari && 'padded-right'} password-input`}
        {...rest}
      />
      {type === 'password' ? (
        <IconEye>
          <EyeInvisibleOutlined onClick={toggleType} />
        </IconEye>
      ) : (
        <IconEye>
          <EyeOutlined onClick={toggleType} />
        </IconEye>
      )}
    </InputWrapper>
  );
};

export default InputPassword;
