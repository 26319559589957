import axios from 'axios';

export const loginUserRequest = async (data: object) => {
  return await axios
    .post(process.env.REACT_APP_API_URL + 'rest-auth/login/', data,{
      headers: {
        "X-Timezone-Offset": (new Date().getTimezoneOffset()/60).toString(),
      }
    })
    .then((res) => res.data)
    .catch((e) => {
      return e.response;
    });
};

export const loginAsUser = async (userId: string) => {
  return await axios
    .post(process.env.REACT_APP_API_URL +  'rest-auth/login-as-user/', { user: userId })
    .then((res) => res.data)
    .catch((e) => {
      return e.response;
    });
}

export const logoutUserRequest = async () => {
  return await axios
    .post(process.env.REACT_APP_API_URL + 'rest-auth/logout/')
    .then((res) => res.data)
    .catch((e) => {
      return e.response;
    });
};

export const loginUserWithGoogleRequest = async (data: object) => {
  return await axios
    .post(
      process.env.REACT_APP_API_URL + 'rest-auth/social/google/login/',
      data,
    )
    .then((res) => res.data)
    .catch((e) => {
      return e.response;
    });
};

export const confirmLoginWithGoogleRequest = async (query: string) => {
  return await axios
    .get(
      process.env.REACT_APP_API_URL +
        'rest-auth/social/google/server/login/callback/' +
        query,
    )
    .then((res) => res.data)
    .catch((e) => {
      return e.response;
    });
};

export const loginUserWithLinkedinRequest = async (data: object) => {
  return await axios
    .post(
      process.env.REACT_APP_API_URL + 'rest-auth/social/linkedin/login/',
      data,
    )
    .then((res) => res.data)
    .catch((e) => {
      return e.response;
    });
};

export const getUserInfo = async (data: string) => {
  return axios
    .get(process.env.REACT_APP_API_URL + 'rest-auth/user/')
    .then((res) => res.data)
    .catch((e) => {
      return e.response;
    });
};

export const getInvitedUserDetails = async (uid: string, token: string) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}users/invite/details/${uid}/${token}/`,
  );
};

export const sendResetPassword = async (email: string) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}rest-auth/password/reset/`,
    { email },
  );
};

export const confirmResetPassword = async (data: {
  uid: string;
  token: string;
  new_password: string;
}) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}rest-auth/password/reset/confirm/`,
    data,
  );
};

export const changePassword = async (data: {
  old_password: string;
  new_password: string;
}) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}rest-auth/password/change/`,
    data,
  );
};
export const getUserByMagicLink = async (magic_link: string) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}rest-auth/magic-link/login/`,
    { magic_link },
  );
};
