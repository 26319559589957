import { ReactNode } from 'react';
import styled from 'styled-components';

export const Header = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
margin-bottom: 3rem;
`;

export const HeaderItem = styled.div`
display: flex;
align-items: center;
max-width: max-content;
&:last-child {
  max-width: 50%;
}
`;

export const NameWrapper = styled.div<ReactNode & { align?: string }>`
margin-left: 0.5rem;
&.row {
  display: flex;
  align-items: center;
}
`;

export const Feature = styled.span`
font-size: 0.875rem;
line-height: 1.43;
color: #627d95;
margin: 0 1rem;
`;

export const Name = styled.p`
font-size: 0.875rem;
font-weight: 700;
line-height: 1.43;
color: #061c2e;
margin-bottom: 0;
`;

export const Role = styled.p`
font-size: 0.875rem;
font-weight: 700;
line-height: 1.43;
color: #627d95;
margin: 0;
`;
