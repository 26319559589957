import styled from 'styled-components';
import { Upload } from 'antd';

import { ReactComponent as JobIcon } from '../../../icons/jobs.svg';

export const FormSubtitleHint = styled.p`
  margin-bottom: 40px;
`;

// logo styles forked from ProfileSettings component

export const StyledUpload = styled(Upload)`
  & .ant-upload {
    display: flex;
    align-items: center;
  }
`;

export const CompanyLogo = styled.div`
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background: #627d95 no-repeat center;
  
  &.bg-white {
    background: #ffffff no-repeat center;
  }

  & img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    border-radius: 50%;
  }
`;

export const FormatHint = styled.div`
  font-size: 0.75rem;
  line-height: 1.33;
  text-align: left;
  color: #627d95;
  margin-left: 1rem;
`;

export const ImageUploadButton = styled.div`
  border-radius: 0.25rem;
  background-color: #f2f6fa;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #34ce72;
  position: absolute;
  bottom: 2px;
  right: 0;
  z-index: 9;
`;

export const StyledIcon = styled(JobIcon)`
  color: #aebeca;
  width: 3rem;
  height: 3rem;
`;

export const ButtonWrap = styled.div`
  padding-top: 16px;
`;