import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  padding: 20px 32px 24px;
  border-radius: 8px;
  background-color: #f2f6fa;
`;

export const Col1 = styled.div`
  width: 40px;
`;

export const Col2 = styled.div`
  flex-grow: 1;
  padding: 0 16px;
`;

export const Title = styled.h4`
  display: inline-block;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.33;
  color: #061c2e;

  &:hover,
  &:focus {
    color: #22bf61;
    cursor: pointer;
  }
`;

export const SubTitle = styled.p`
  font-size: 12px;
  line-height: 1.33;
  color: #627d95;
  margin: 0;
`;

export const ActionLink = styled.a`
  font-size: 0.75rem;
  font-weight: 800;
  line-height: 1.33;
  color: #627d95;
  display: flex;
  align-items: center;
  padding: 7px 8px;
  border: 1px solid #627d95;
  border-radius: 0.25rem;
  margin-left: 1rem;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }

  & svg {
    min-width: 1rem;
    min-height: 1rem;
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
  }

  &:hover {
    color: #627d95;
  }
`;