import { Rate } from "antd";
import styled from "styled-components";

const MessageRatings = styled(Rate)`
  display: block;
  color: #34ce72;
  margin: 1rem 0;
  & .ant-rate-star-first, & .ant-rate-star-second {
    color: #eafaf1;
  }
  & .ant-rate-star-full .ant-rate-star-first, & .ant-rate-star-full .ant-rate-star-second {
    color: inherit;
  }
  & svg {
    width: 2em;
    height: 2em;
    overflow: visible;
  }
  & svg path {
    stroke: #34ce72;
    stroke-width: 2em;
    stroke-linejoin: round;
  }
`;

export default MessageRatings;
