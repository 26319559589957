import { Trans, useTranslation } from 'react-i18next';
import Button from '../../../UI/buttons/Button';
import FormItem from '../../../components/form/FormItem';
import { FormSubtitle } from '../../form/JobInfoForm/styles';
import { useEffect, useState } from 'react';
import { AtsStatus, forceSync } from '../../../api/integrations';

type RefreshDataSectionProps = {
  atsStatus: AtsStatus;
  onCheckAtsStatus: () => void;
}

const RefreshDataSection = ({ atsStatus, onCheckAtsStatus }: RefreshDataSectionProps) => {
  const { t } = useTranslation();
  const [syncing, setSyncing] = useState<Date | null>(null);
  if (atsStatus.last_sync_date && syncing && (atsStatus.last_sync_date > syncing))
    setSyncing(null);
  useEffect(() => {
    const handle = syncing ? setInterval(() => { onCheckAtsStatus() }, 5000) : null;
    return () => { handle && clearInterval(handle) };
  }, [syncing, onCheckAtsStatus])
  return (
    <FormItem>
      <FormSubtitle>{t('ats.ATS_CONNECTION')}</FormSubtitle>
      <p>
        <Trans
          i18nKey="ats.REFRESH_DATA_TEXT_FMT.0"
          values={{ tool: atsStatus.tool_name, time: atsStatus.last_sync_date?.toLocaleTimeString(), date: atsStatus.last_sync_date?.toLocaleDateString() }}
          components={{ b: <strong /> }}
        />
      </p>
      <p>
        <Trans
          i18nKey="ats.REFRESH_DATA_TEXT_FMT.1"
          values={{ tool: atsStatus.tool_name, time: atsStatus.last_sync_date?.toLocaleTimeString(), date: atsStatus.last_sync_date?.toLocaleDateString() }}
          components={{ b: <strong /> }}
        />
      </p>
      <Button loading={!!syncing} onClick={() => { setSyncing(atsStatus.last_sync_date ?? null); forceSync(); }}>
        {syncing ? t('ats.REFRESHING_DATA_BTN') : t('ats.REFRESH_DATA_BTN')}
      </Button>
    </FormItem>
  )
}

export default RefreshDataSection;
