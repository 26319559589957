import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as LogoImage } from '../../icons/logo.svg';
import { HOME } from '../../constants/routes';
import LanguageSwitcher from './LanguageSwitcher';


interface BannerProps {
  clickable?:boolean
}
const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 2rem;
  position: relative;
  z-index: 99;
  @media (max-width: 768px) {
    padding-left: 0;
  }
`;

const NavLink = styled(Link)`
  height: auto;
  display: flex;
`;

const BannerNav = ({clickable = true}:BannerProps) => {
  return (
    <Nav>
      {clickable
        ? <NavLink to={HOME}>
          <LogoImage/>
        </NavLink>
        : <LogoImage/>
      }
      <LanguageSwitcher />
    </Nav>
  );
};

export default BannerNav;
