import React from 'react';
import styled from 'styled-components';

interface PageWrapperProps extends React.HTMLAttributes<HTMLDivElement> {}

const StyledPageWrapper = styled.div`
  padding: 0 2.5rem 2rem;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  &.full-width {
    padding-right: 0;
  }
  @media (max-width: 576px) {
    padding: 0 1.5rem;
  }
`;
const PageWrapper = ({ children, ...rest }: PageWrapperProps) => {
  return <StyledPageWrapper {...rest}>{children}</StyledPageWrapper>;
};

export default PageWrapper;
