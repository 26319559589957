import { useEffect } from 'react';
import { Col, Form, Radio, Row, Select } from 'antd';
import FormItem from '../form/FormItem';
import { ReactComponent as ArrowDownIcon } from '../../icons/arrow-down.svg';
import { ReactComponent as InfoIcon } from '../../icons/info-icon-rounded.svg';
import { ReactComponent as SearchIcon } from '../../icons/search.svg';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { City, Seniority, Skill, Specialization } from '../../types';
import NumberInput from '../../UI/buttons/NumberInput';
import SelectInput from '../../UI/inputs/Select';
import RadioInput from '../../UI/inputs/RadioInput';
import CheckboxInput from '../../UI/inputs/CheckboxInput';
import Input from '../../UI/inputs/Input';

interface FilterProps {
  domains: Specialization[];
  seniority: Seniority[];
  cities: City[];
  skills: Skill[];
  getData: (query?: string) => void;
}

const FilterWrapper = styled.div`
  border-bottom: 1px solid #dae5ec;
  position: relative;
`;

const StyledButton = styled.button`
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  background: #34ce72;
  color: #ffffff;
  border: none;
  outline: none;
  cursor: pointer;
  position: absolute;
  top: 1.5rem;
  left: calc(100% + 0.5rem);
  & svg {
    width: 1.2rem;
    height: 1.2rem;
  }
  &:hover {
    background: #22bf61;
  }
`;

const LogicalFilter = styled.div`
  border-top: 1px solid rgba(174, 190, 202, 0.47);
  padding: 1rem;
`;

const { Option } = Select;

const CandidateAppointFilter = ({
  cities,
  seniority,
  domains,
  skills,
  getData,
}: FilterProps) => {
  const [t] = useTranslation();
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      search: '',
      salary_from: null,
      salary_to: null,
      logical: 'AND',
      not: false,
    })
  }, [form]);

  const onFinish = (values: any) => {
    let str = [];
    for (let key in values)
      if (values.hasOwnProperty(key) && values[key]) {
        if (key === 'skill') {
          if (
            !values[key].length ||
            (values[key].length && values[key][0] === '')
          ) {
          } else {
            str.push(
              encodeURIComponent(key) +
                '=' +
                encodeURIComponent(
                  values.not
                    ? 'NOT ' +
                        values[key].join(
                          ` ${values.logical} ${values.not ? 'NOT' : ''} `,
                        )
                    : values[key].join(` ${values.logical} `),
                ),
            );
          }
        } else {
          str.push(
            encodeURIComponent(key) + '=' + encodeURIComponent(values[key]),
          );
        }
      }
    let query = str.join('&');
    getData(query);
  };

  return (
    <FilterWrapper>
      <Form
        form={form}
        layout="vertical"
        name="candidate-filter"
        onFinish={onFinish}
        scrollToFirstError={true}
      >
        <Row gutter={16}>
          <Col xs={{ span: 12 }} md={{ span: 8 }} xl={{ span: 8 }}>
            <FormItem label={t('NAME_LABEL')} name="search">
              <Input placeholder={t('CANDIDATE_NAME')} size={'large'} />
            </FormItem>
          </Col>

          <Col xs={{ span: 12 }} md={{ span: 8 }} xl={{ span: 8 }}>
            <FormItem label={t('DOMAIN_TITLE')} name="domain">
              <SelectInput
                size="large"
                showSearch
                placeholder={t('DOMAIN_PLACEHOLDER')}
                optionFilterProp="children"
                filterOption={true}
                className="custom-select"
                suffixIcon={<ArrowDownIcon />}
              >
                <Option value="">{t('NONE')}</Option>
                {domains.map((dom) => (
                  <Option value={dom.id} key={dom.id}>
                    {dom.name}
                  </Option>
                ))}
              </SelectInput>
            </FormItem>
          </Col>

          <Col xs={{ span: 12 }} md={{ span: 8 }} xl={{ span: 8 }}>
            <FormItem label={t('SENIORITY_LABEL')} name="seniority">
              <SelectInput
                size="large"
                showSearch
                placeholder={t('SENIORITY_PLACEHOLDER')}
                optionFilterProp="children"
                filterOption={true}
                className="custom-select"
                suffixIcon={<ArrowDownIcon />}
              >
                <Option value="">{t('NONE')}</Option>
                {seniority.map((sen) => (
                  <Option value={sen.id} key={sen.id}>
                    {sen.name}
                  </Option>
                ))}
              </SelectInput>
            </FormItem>
          </Col>

          <Col xs={{ span: 12 }} md={{ span: 16 }} xl={{ span: 12 }}>
            <FormItem
              label={t('SKILL_LABEL')}
              name="skill"
              tooltip={{ title: t('SKILL_INFO_TEXT'), icon: <InfoIcon /> }}
            >
              <SelectInput
                size="large"
                allowClear={true}
                showSearch
                placeholder={t('SKILL_PLACEHOLDER')}
                optionFilterProp="children"
                filterOption={true}
                mode="multiple"
                className="custom-select"
                dropdownRender={(menu) => (
                  <div>
                    {menu}
                    <LogicalFilter>
                      <Row justify="space-between">
                        <Col>
                          <FormItem name="logical" className="no-margin">
                            <Radio.Group>
                              <RadioInput value="AND">{t('AND')}</RadioInput>
                              <RadioInput value="OR">{t('OR')}</RadioInput>
                            </Radio.Group>
                          </FormItem>
                        </Col>
                        <Col>
                          <FormItem
                            name="not"
                            className="no-margin"
                            valuePropName="checked"
                          >
                            <CheckboxInput>{t('NOT')}</CheckboxInput>
                          </FormItem>
                        </Col>
                      </Row>
                    </LogicalFilter>
                  </div>
                )}
              >
                {skills.map((skill) => (
                  <Option value={skill.id} key={skill.id}>
                    {skill.name}
                  </Option>
                ))}
              </SelectInput>
            </FormItem>
          </Col>

          <Col xs={{ span: 12 }} md={{ span: 8 }} xl={{ span: 6 }}>
            <FormItem label={t('LOCATION_LABEL')} name="location">
              <SelectInput
                size="large"
                showSearch
                placeholder={t('LOCATION_PLACEHOLDER')}
                optionFilterProp="children"
                filterOption={true}
                className="custom-select"
                suffixIcon={<ArrowDownIcon />}
              >
                <Option value="">{t('NONE')}</Option>
                {cities.map((city) => (
                  <Option value={city.id} key={city.id}>
                    {city.name}
                  </Option>
                ))}
              </SelectInput>
            </FormItem>
          </Col>

          <Col xs={{ span: 12 }} md={{ span: 24 }} xl={{ span: 6 }}>
            <FormItem label={t('SALARY_RANGE_LABEL')}>
              <Row gutter={8}>
                <Col xs={{ span: 12 }} md={{ span: 12 }} xl={{ span: 12 }}>
                  <FormItem
                    label=""
                    name="salary_from"
                    className="no-margin"
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          const to = getFieldValue('salary_to');
                          if (to && value > to) {
                            return Promise.reject(t('SALARY_RANGE_ERROR'));
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <NumberInput
                      size="large"
                      type={'number'}
                      placeholder={t('SALARY_FROM_PLACEHOLDER')}
                    />
                  </FormItem>
                </Col>
                <Col xs={{ span: 12 }} md={{ span: 12 }} xl={{ span: 12 }}>
                  <FormItem label="" name="salary_to" className="no-margin">
                    <NumberInput
                      size="large"
                      type={'number'}
                      placeholder={t('SALARY_TO_PLACEHOLDER')}
                    />
                  </FormItem>
                </Col>
              </Row>
            </FormItem>
          </Col>
        </Row>

        <StyledButton type="submit">
          <SearchIcon />
        </StyledButton>
      </Form>
    </FilterWrapper>
  );
};

export default CandidateAppointFilter;
