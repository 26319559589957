import React from "react";
import { Recruiter } from '../../types'
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { RECRUITER } from "../../constants/routes";
import TooltipPrompt from "../../UI/tootlip";
import NameImage from "../client/NameImage";
import { Text } from "./RecruiterList"
import { ReactComponent as Logo } from '../../icons/logo-ufo.svg';
import styled from "styled-components";
import { ReactComponent as StarIcon } from '../../icons/star-green-filled.svg';
import { structuredDomains } from "../../utils/general";
import CheckboxInput from "../../UI/inputs/CheckboxInput";

const calcRecruiterType = (rec: Recruiter) => {
    const StyledLogo = styled(Logo)`
    width: 1em;
    height: 1em;
    stroke-width: 1.5;
    & path {
      fill: #34ce72;
    }
  `;

    let CommunityRecruiter = () => {
        return (
            <div style={{ display: "flex", alignItems: "center", justifyContent: "start" }} >
                <StyledLogo /><Text style={{ marginTop: 0, marginLeft: "5px", marginRight: "10px", color: "#34ce72" }}>Community</Text>
            </div>
        )
    }
    return rec.own_recruiter ? <Text>Own supplier</Text> : <CommunityRecruiter />
}

const trimSpecialisations = (specs: any): string => {
    if (specs.length < 1) return '-';
    let structured_specs = structuredDomains(specs);
    let sufix = "";
    if (specs.length > 10) {
        structured_specs = structured_specs.slice(0, 10);
        sufix = "...";
    }
    return structured_specs
        .map((spec: any) => spec.title)
        .map((specName: any) => specName.replace("(main)", ""))
        .join(', ') + sufix;
}

export const getRating = (recs: Recruiter) => {
    if (recs.ratings.length <= 0 || !recs.ratings) return '-';
    for (let i = 0; i < recs.ratings.length; i++) {
        if (recs.ratings[i].subject === "Overall") return recs.ratings[i].rating;
    }
    return "-";
};


interface RecruiterSelectTableRowProps {
    recruiter: Recruiter;
    setRecruiterIsSelectedField: (rec: Recruiter, is_selected: boolean) => void;
}
export default function RecruiterSelectTableRow({ recruiter, setRecruiterIsSelectedField }: RecruiterSelectTableRowProps) {
    const [t] = useTranslation();
    return (
        <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-around",
            borderBottom: "1px solid #DAE5EC",
            padding: "6px 0"
        }}>
            <CheckboxInput
                style={{ flex: "5%" }}
                checked={recruiter.is_selected}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setRecruiterIsSelectedField(recruiter, e.target.checked);
                }}

            />
            <div style={{ flex: "20%" }}>
                <TooltipPrompt title={t('CLICK_TO_SEE_PROFILE')}>
                    <Link to={RECRUITER + '/' + recruiter.id}>
                        <NameImage
                            title={recruiter.name}
                            image={recruiter.image}
                            color={recruiter.own_recruiter ? { main: "#7949cd", light: "#f2edfa" } : { main: "#29ae5e", light: "#eaf7ef" }}
                            description={
                                recruiter.recruiter_type !== 'freelance'
                                    ? recruiter.company.name
                                    : recruiter.title
                            }
                        />
                    </Link>
                </TooltipPrompt>
            </div>
            <div style={{ flex: "10%" }}>
                {calcRecruiterType(recruiter)}
            </div>
            <Text style={{ flex: "35%" }}>
                {trimSpecialisations(recruiter.specializations)}
            </Text>
            <Text style={{ flex: "20%" }}>
                {recruiter.fee_description === "" ? "-" : recruiter.fee_description}
            </Text>
            <Text style={{ flex: "5%", display: "flex", justifyContent: "start", alignItems: "center" }}>
                {getRating(recruiter)} <StarIcon style={{ height: "1em", width: "1em", color: "#34CE72", marginLeft: "0.3em" }} />
            </Text>
        </div>
    )
}

