import React from 'react';
import styled from 'styled-components';
import BannerNav from '../auth/BannerNav';
import BannerText from '../auth/BannerText';
import BannerImage from '../auth/BannerImage';
import BannerFooter from './BannerFooter';

const StyledBanner = styled.div`
  width: 67.5%;
  padding: 4.5rem 2.5rem 2.5rem;
  position: relative;
  transition: width ease 0.3s;

  &.small {
    width: 50%;
  }
  @media (max-width: 1200px) {
    width: 50%;
  }
  @media (max-width: 768px) {
    width: 100%;
    padding: 3rem 2rem;
    &.small {
      width: 100%;
    }
    display: none;
  }
`;

interface BannerProps {
  size?: string;
  title: string;
  clickable?: boolean;
}
const Banner = ({ size, title, clickable }: BannerProps) => {
  return (
    <StyledBanner className={size === 'small' ? 'small' : ''}>
      <BannerNav clickable={clickable} />
      <BannerText title={title} />
      <BannerImage />
      <BannerFooter/>
    </StyledBanner>
  );
};

export default Banner;
