import { Page } from '../MagicLink/styled';
import Loader from '../../UI/Loader';
import useMagicLinksInit from './hooks';

const MagicLinkInit = () => {
  useMagicLinksInit();

  return (
    <Page>
      <Loader spinning={true}></Loader>
    </Page>
  );
}

export default MagicLinkInit;
