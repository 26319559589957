import { ReactComponent as TeamIcon } from '../../../icons/team.svg';
import TextWithIcon from './TextWithIcon';
import { Role, TeamRole } from '../../../types';
import styled from 'styled-components';
import BubbleChart from './BubbleChart';
import { palette } from '../../../constants/palette';

interface TeamSectionProps {
  roles: Role[] | [];
  team: TeamRole[];
  languages: string[];
  languageList: { [key: string]: string };
}
const TeamWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 350px;
  display: flex;
`;
const TeamSection = ({
  roles,
  team,
  languages,
  languageList,
}: TeamSectionProps) => {

  const roleCollection = team.map((item, i) => {
    const color =
      palette[
        i + 1 > palette.length ? Math.floor(Math.random() * palette.length) : i
      ];
    return {
      count: item.number_of_people,
      label: `${item.number_of_people} ${
        roles.find((el) => el.id === item.role)?.name
      }`,
      textColor: color.main,
      bgColor: color.light,
    };
  });
  return (
    <>
      <TextWithIcon
        icon={<TeamIcon />}
        title={
          languages.map((language) => languageList[language]).join(', ')
        }
      />
      <TeamWrapper>
        <BubbleChart info={roleCollection} />
      </TeamWrapper>
    </>
  );
};

export default TeamSection;
