import React, { useEffect, useState } from 'react';
import { Col, Form, message, Row } from 'antd';
import PersonalInfo from './shared/PersonalInfo';
import styled from 'styled-components';
import { passwordRule } from '../../utils/validationRules';
import FormItem from './FormItem';
import Button from '../../UI/buttons/Button';
import InputPassword from '../../UI/inputs/InputPassword';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { UrlParams } from '../../pages/InvitationConfirm';
import { confirmClientInvitation, updateClient } from '../../api/client';
import { ErrorMessage } from '../auth/LoginForm';
import { loginUser } from '../../store/userSlice';
import { useAppDispatch } from '../../store/hooks';
import {DASHBOARD} from '../../constants/routes';
import { loginUserRequest } from '../../api/user';
import axios from 'axios';
import FormTitle from '../auth/FormTitle';
import { useTranslation } from 'react-i18next';
import Input from '../../UI/inputs/Input';
import { storeJobId } from '../../utils/localStorage';

const StyledForm = styled(Form)`
  margin-top: 2.5rem;
  width: 100%;
`;
const StyledRow = styled(Row)`
  margin-bottom: 1rem;
`;
const PasswordHint = styled.p`
  font-size: 0.625rem;
  color: #627d95;
  margin: 0.5rem 0 1.5rem;
`;
const ClientInvitationForm = ({ user }: any) => {
  const [error, setError] = useState('');
  const [phone, setPhone] = useState('');
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const { uid, token }: UrlParams = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  const search = useLocation().search;

  const checkJob = () => {
      const searchParams = new URLSearchParams(search);
      const jobId = searchParams.get('job_id');

      if (jobId) {
          storeJobId(jobId);
      }
  }

  const onFinish = (values: any) => {
    setLoading(true)
    const info = { ...values };
    const confirmData = { uid, token, new_password: info.password };
    confirmClientInvitation(confirmData)
      .then(async ({ data }) => {
        message.success(data.detail, 3);
        delete info.password;
        const credentials = { email: values.email, password: values.password };
        const user = await loginUserRequest(credentials);
        if (user) {
          axios.defaults.headers.common[
            'Authorization'
          ] = `Token ${user.access_token}`;
          updateClient({...values,phone})
            .then(async (res) => {
              axios.defaults.headers.common['Authorization'] = '';
              const login = await dispatch(loginUser(credentials));
              setLoading(false)
              if (login.payload?.user) {
                  let loginedUser = login.payload?.user;
                  // @ts-ignore
                  localStorage.setItem("intercomID", process.env.REACT_APP_INTERCOM_ID);
                  // @ts-ignore
                  window.Intercom('boot', {
                      app_id: process.env.REACT_APP_INTERCOM_ID,
                      email: loginedUser.email,
                      name: loginedUser.name,
                      user_id: loginedUser.id,
                      user_role: loginedUser.role,
                  });
                checkJob();
                history.push(DASHBOARD);
              }
            })
            .catch((e) => setLoading(false));
        }
      })
      .catch((e) =>
        {e.response?.data.message
          ? setError(e.response?.data.message)
          : setError('Something went wrong')
          setLoading(false)},
      );
  };
  useEffect(() => {
    if(user){
      form.setFieldsValue({
        name: user.name === 'Username' ? '' : user.name,
        title: user.title,
        company: user.company.name,
        department: user.department,
        email: user.email,
        username: user.email,
      });
      setPhone(user.phone)
    }
  }, [user, form]);
  return (
    <>
      <FormTitle text={t('PERSONAL_INFO_TITLE')} />
      <StyledForm
        scrollToFirstError={true}
        layout="vertical"
        name="invitation"
        onFinish={onFinish}
        form={form}
      >
        {error && <ErrorMessage message={error} type="error" />}
        <PersonalInfo form={form} disableDepartment={!!user.department} disableFields={true} setPhone={setPhone} phone={phone}/>
        <StyledRow>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 11 }}
            md={{ span: 24 }}
            lg={{ span: 11 }}
          >
            <FormItem
              label={t('')}
              name="email"
              initialValue={user.email}
              className="hidden-input"
            >
              <Input type={'email'} />
            </FormItem>
            <FormItem
              label={t('PASSWORD_LABEL')}
              name="password"
              rules={passwordRule(t)}
              className="no-margin"
            >
              <InputPassword />
            </FormItem>
            <PasswordHint>{t('PASSWORD_HINT')}</PasswordHint>
          </Col>
        </StyledRow>

        <FormItem>
          <Button type="primary" htmlType="submit" loading={loading}>
            {t('SIGN_UP_TITLE')}
          </Button>
        </FormItem>
      </StyledForm>
    </>
  );
};

export default ClientInvitationForm;
