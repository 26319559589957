import React, { ReactElement } from 'react';
import styled from 'styled-components';

interface SubTitleProps {
  title: string | ReactElement;
  marginBottom?: string,
}

interface StyledProps {
  marginBottom?: string,
}

const SubTitle = styled.h5<StyledProps>`
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.33;
  color: #061d31;
  margin-bottom: ${props => props.marginBottom || '1.5rem'};
`;

const Subtitle = ({ title, marginBottom }: SubTitleProps) => {
  return <SubTitle marginBottom={marginBottom}>{title}</SubTitle>;
};

export default Subtitle;
