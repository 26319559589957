import React from 'react';
import { useTranslation } from 'react-i18next';

import { Prompt } from '../styles';
import { CLIENT_ROLE, RECRUITER_ROLE, ADMIN_ROLE } from '../../../../../constants/roles';

interface Iprops {
  waitingFor: string,
  role: string,
  diffInHours: number,
}

const ItemFooter = ({
  role,
  waitingFor,
  diffInHours,
}: Iprops) => {
  const [t] = useTranslation();

  const isCurrentUser = waitingFor === role;
  const isAdmin = role === ADMIN_ROLE;
  const time = Math.abs(diffInHours) || 1;

  let waitingForRole;
  if (waitingFor === CLIENT_ROLE) waitingForRole = t('CLIENT_TITLE');
  if (waitingFor === RECRUITER_ROLE) waitingForRole = t('RECRUITER_TITLE');

  return (
    <Prompt
      color={
        isCurrentUser
        ? 'warn'
        : 'success'
      }
    >
      {isCurrentUser && isAdmin ? (
        t('BOTH_PARTIES_CALL_TO_ACTION')
      ) : (
        <>
          {isCurrentUser ? ( // you need to take action...
            t('NEED_ACTION_TEXT', {
              time,
            })
          ) : (
            t('RESPOND_TEXT', { // The {{ role }} will respond... 
              time,
              role: waitingForRole,
            })
          )}
        </>
      )}
    </Prompt>
  );
};

export default React.memo(ItemFooter);