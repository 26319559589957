import React, { useEffect, useState } from 'react';
import { Form, message } from 'antd';
import FormItem from './FormItem';
import Button from '../../UI/buttons/Button';
import Subtitle from '../../UI/typography/Subtitle';

import PersonalInfo from './shared/PersonalInfo';
import CompanyInfo from './shared/CompanyInfo';
import { editClient, sendInvitationWithCreate } from '../../api/client';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from '../auth/LoginForm';
import { Client } from '../../types';
import { useHistory } from 'react-router-dom';

interface UserAddProps {
  onSuccess?: any;
  regions: { id: string; name: string }[];
  industries: { id: string; name: string }[];
  open?: (open: boolean) => void;
  visible?: boolean;
  edit?: boolean;
  user?: Client;
  update?: React.Dispatch<React.SetStateAction<any>>;
}

const ClientAddForm = ({
  onSuccess,
  regions,
  open,
  industries,
  edit,
  user,
                         visible,
  update
}: UserAddProps) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [loading,setLoading] = useState(false);
  // eslint-disable-next-line
  const [validating, setValidating] = useState(false);
  const [phone, setPhone] = useState<string>('');
  const [officeLocations, setOfficeLocations] = useState<
    { id: string; address: string }[]
  >([{ id: '', address: '' }]);
  const [address, setAddress] = useState<string>('');
  const [error, setError] = useState('');
  const { t } = useTranslation();
  const params = new URLSearchParams(history.location.search);
  const onFinish = async (values: any) => {
    // Commented because of limited fields validation
    //if (validateCustomList()) {
      setLoading(true)
      if (edit) {
        editClient({
          ...values,
          phone,
          office_locations: officeLocations[0].id ? officeLocations : null,
          address,
        }).then((res) => {
          setError('')
          params.delete('client');
          params.delete('recruiter');
          history.push(`${history.location.pathname}?${params.toString()}`);
          message.success(t('INFORMATION_SAVE_SUCCESS'), 5);
          onSuccess();
          setOfficeLocations([{ id: '', address: '' }]);
          setAddress('');
          setPhone('');
          setLoading(false)
        }).catch(() => setLoading(false));
      } else {
        const data = await sendInvitationWithCreate({
          ...values,
          phone,
          office_locations: officeLocations[0].id ? officeLocations : null,
          address,
        });
        await setLoading(false)
        if (data.status === 200 || data.details) {
          setError('')
          message.success(t('INVITE_SEND_NOTIFICATION'), 3);
          open && open(false);
          form.resetFields();
        } else if (data.status === 400) {
          setError(data.data.message);
        }
      }
    //}
  };
  useEffect(() => {
    if (!visible){
      setError('')
    }
  },[visible])
  useEffect(() => {
    if (!user) {
      form.resetFields();
    }
  }, [user, form]);
  // Disabled because of limited mandatory fields list
  // const validateCustomList = () => {
  //   let validated = false;
  //   const hasError = officeLocations.map((el: any) => {
  //     if (!el.address) {
  //       setValidating(true);
  //     }
  //     return !!el.address;
  //   });
  //   hasError.push(!!address);
  //   if (hasError.includes(false)) {
  //     validated = false;
  //   } else {
  //     validated = true;
  //   }
  //   setValidating(!validated);
  //   return validated;
  // };
  return (
    <Form
      scrollToFirstError={true}
      layout="vertical"
      name="add-client"
      onFinish={onFinish}
      form={form}
      onValuesChange={(e) => {
        if (e.male_percentage && e.male_percentage > 100) {
          form.setFieldsValue({ male_percentage: 100 });
        }
      }}
    >
      {error && <ErrorMessage message={error} type="error" />}
      <Subtitle title={t('PERSONAL_INFO_TITLE')} />
      <PersonalInfo
        setPhone={setPhone}
        phone={phone}
        form={form}
        setLocations={setOfficeLocations}
        setAddress={setAddress}
        disableFields={edit}
        user={user}
      />
      <Subtitle title={t('COMPANY_INFO_TITLE')} />
      <CompanyInfo
        form={form}
        regions={regions}
        industries={industries}
        locations={officeLocations}
        setLocations={setOfficeLocations}
        setAddress={setAddress}
        address={address}
        validating={validating}
        disabled={edit}
        companyId={user?.company.id}
      />
      <FormItem>
        <Button
          type="primary"
          htmlType="submit"
          onClick={(e) => {
            e.preventDefault();
            // Disabled as we have only limited set of required fields
            //validateCustomList();
            form.submit();
          }}
          loading={loading}
        >
          {t(edit ? 'SAVE_TITLE' : 'MAGIC_LINK_TITLE')}
        </Button>
      </FormItem>
    </Form>
  );
};

export default ClientAddForm;
