import styled from 'styled-components';
import NameAvatar from '../../UI/NameAvatar';

interface NameProps{
  name: string;
  color: {main:string, light:string}
  onClick?:() => void;
  role?: string;
  company?: string;
}

const NameWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Wrap = styled.div`
  padding-left: 0.5rem;
`;

const FullName = styled.h5`
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.43;
  color: #061c2e;
  margin-bottom: 4px;
`;

const RoleItem = styled.p`
  font-size: 12px;
  line-height: 1.33;
  color: #627d95;
  margin: 0;
`;

const CandidateNameAvatar = ({
  name,
  color,
  role,
  company,
  onClick
}: NameProps) => {
  return (
    <NameWrapper >
      <NameAvatar  name={name} color={color}/>
      <Wrap>
        <FullName onClick={onClick} style={{cursor: onClick ? 'pointer':'unset'}}>{name}</FullName>
        {role && company && (
          <RoleItem>
            {role} at {company}
          </RoleItem>
        )}
      </Wrap>
    </NameWrapper>
  );
};

export default CandidateNameAvatar;
