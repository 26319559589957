import {  RefObject, useEffect } from 'react';



const useClickAwayListener = (ref:RefObject<any>, callback: () => void) => {
  const handleClick = (e:any) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  });
};

export default useClickAwayListener;
