import React from 'react';
import { CandidateInfo, Certificate, Skill, Education } from '../../types';
import { CaretDownOutlined } from '@ant-design/icons';
import InfoItem from './InfoItem';
import { useTranslation } from 'react-i18next';
import TagButton from '../../UI/buttons/TagButton';
import styled from 'styled-components';
import FormItem from '../form/FormItem';
import { requiredRule } from '../../utils/validationRules';
import SelectInput from '../../UI/inputs/Select';
import { Select } from 'antd';

interface GeneralProps {
  candidate: CandidateInfo;
  isEditable?: boolean
  skills?: Skill[]
  certificates?: Certificate[],
  education?: Education[],
}

const Wrapper = styled.div`
margin-top: 2.5rem;
`;

const Title = styled.h3`
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.2;
  // color: #627d95;
  margin-bottom: 1rem;
`;

const Skills = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const { Option } = Select;

const GeneralInfo = ({
  candidate,
  isEditable,
  skills,
  certificates,
  education
}: GeneralProps) => {
  const [t] = useTranslation();

  return (
    <Wrapper>
      <Title>{t('Candidate Information')}</Title>
      <InfoItem
        title={t('LOCATION_CITY_LABEL')}
        result={candidate.city_of_residence}
      />
      <InfoItem title={t('EMAIL_LABEL')} result={candidate.email} />
      <InfoItem title={t('PHONE_TEXT')} result={candidate.phone} />
      <InfoItem
        title={t('DOMAIN_TITLE')}
        result={candidate.specializations.join(',\n')}
      />

      {education && (
        isEditable ?
          <FormItem
            label={t('CANDIDATE_EDUCATION')}
            name="education"
          >
            <SelectInput
              size="large"
              mode="multiple"
              allowClear
              optionFilterProp="children"
              filterOption={(input, option) =>
                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              suffixIcon={<CaretDownOutlined style={{ color: '#000' }} />}
            >
              {education?.map(
                (ed: Education) => (
                  <Option key={ed.id} value={ed.id}>
                    {ed.name}
                  </Option>
                ),
              )}
            </SelectInput>
          </FormItem>
          :
          <InfoItem
            title={t('CANDIDATE_EDUCATION')}
            result={candidate.education.join(',\n')}
          />
      )}

      {certificates && (
        isEditable ?
          <FormItem
            label={t('CANDIDATE_CERTIFICATES')}
            name="certificates"
          >
            <SelectInput
              size="large"
              mode="multiple"
              allowClear
              optionFilterProp="children"
              filterOption={(input, option) =>
                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              suffixIcon={<CaretDownOutlined style={{ color: '#000' }} />}
            >
              {certificates?.map(
                (certificate: Certificate) => (
                  <Option key={certificate.id} value={certificate.id}>
                    {certificate.name}
                  </Option>
                ),
              )}
            </SelectInput>
          </FormItem>
          :
          <InfoItem
            title={t('CANDIDATE_CERTIFICATES')}
            result={candidate.certificates.join(',\n')}
          />
      )}

      {skills && (
        isEditable ?
          <FormItem>
            <FormItem
              label={`${t('SKILL_TITLE')} (${t('MAX')} 10)`}
              name="skills"
              className="no-margin text-grey"
              rules={[
                ...requiredRule(t),
                {
                  type: 'array',
                  max: 10,
                  message: t('MAX_10_ERROR'),
                },
              ]}
            >
              <SelectInput
                size="large"
                allowClear={true}
                showSearch
                placeholder={t('SKILL_PLACEHOLDER')}
                optionFilterProp="children"
                mode="multiple"
                className="custom-select"
                onChange={(value) => {
                  if ((value as []).length > 10) {
                    (value as []).pop();
                  }
                }}
              >
                {skills?.map((skill) => (
                  <Option value={skill.id} key={skill.id}>
                    {skill.name}
                  </Option>
                ))}
              </SelectInput>
            </FormItem>
          </FormItem>
          :
          <InfoItem
            title={t('SKILL_TITLE')}
            result={<Skills>{candidate.skills.map((skill) => (
              <TagButton key={skill}>{skill}</TagButton>
            ))}</Skills>}
            direction="vertical"
          />
      )}
    </Wrapper>
  );
};

export default GeneralInfo;
