import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as MobileLogo } from '../../icons/nav-logo.svg';
import { HOME } from '../../constants/routes';

interface HeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  title?: string;
}

const PageHeader = styled.header`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 0;
  &.mobile-header {
    @media (max-width: 768px) {
      background: #ffffff;
      width: 100%;
      padding: 1.25rem;
      box-shadow: 0 10px 10px -5px #00000013;
      z-index: 999;
      & svg {
        display: block;
        width: 2.5rem;
      }
    }
  }
  &.mobile-hide {
    @media (max-width: 768px) {
      display: none;
    }
  }
`;
const PageTitle = styled.h1`
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.33;
  color: #061c2e;
  margin-bottom: 0;
  @media (max-width: 768px) {
    font-size: 1.25rem;
    font-weight: bold;
    line-height: 1.2;
    color: #061c2e;
    margin-right: 1rem;
  }
`;
const StyledMobileLogo = styled(Link)`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;
const Header = ({ children, title, ...rest }: HeaderProps) => {
  const history = useHistory();
  const isMessagePage = history.location.pathname.includes('/messages');
  return (
    <PageHeader  className={isMessagePage ? 'mobile-header' : ''} {...rest}>
      {title && <PageTitle>{title}</PageTitle>}
      {children}
      <StyledMobileLogo to={HOME}><MobileLogo /></StyledMobileLogo>
    </PageHeader>
  );
};

export default Header;
