import React, { useCallback, useEffect, useState } from 'react';
import { Prompt, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';

interface RouterBlockerProps {
  isBlocked: boolean;
}

const { confirm } = Modal;

const RouterBlocker = ({ isBlocked }: RouterBlockerProps) => {
  const [t] = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const [lastLocation, setLastLocation] = useState(location);
  const [shouldUnload, setShouldUnload] = useState(false);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
    setShouldUnload(false);
  },[]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const showModal = (nextLocation: any) => {
    openModal();
    setLastLocation(nextLocation);
  };

  const handleBlockedRoute = (nextLocation: any) => {
    if (!confirmedNavigation && isBlocked) {
      showModal(nextLocation);
      return false;
    }

    return true;
  };

  const handleConfirmNavigationClick = useCallback(() => {
    closeModal();
    setConfirmedNavigation(true);
  },[closeModal]);

  // Block react routes
  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      setShouldUnload(true);
      history.push(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation, history]);

  // Block non-react routes
  useEffect(() => {
    const unload = (event: any) => {
      if (isBlocked && !shouldUnload) {
        // eslint-disable-next-line no-param-reassign
        event.returnValue = t('FORM_CLOSE_CONFIRMATION');
      }
      if (shouldUnload) {
        // eslint-disable-next-line no-param-reassign
        event.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', unload);

    return () => window.removeEventListener('beforeunload', unload);
  }, [isBlocked, t, shouldUnload]);
  useEffect(() => {
    isModalOpen &&
      confirm({
        title: `${t('FORM_CLOSE_CONFIRMATION')}`,
        okText: t('CONFIRM_TITLE'),
        okType: 'danger',
        cancelText: t('REJECT_TITLE'),
        cancelButtonProps: {
          type: 'default',
        },
        onOk() {
          handleConfirmNavigationClick();
        },
        onCancel() {
          closeModal();
        },
      });
  }, [isModalOpen, t, handleConfirmNavigationClick,closeModal]);
  return (
    <div>
      <Prompt when message={handleBlockedRoute} />
    </div>
  );
};

export default RouterBlocker;
