import React from 'react';
import styled from "styled-components";

interface EmojiProps {
    symbol: string;
    label?: string;
    onClick?: any
    customClass?: string;
}

export const StyledEmoji = styled.span`
  margin-left: 0.25rem;
  color: #aebeca;
  cursor: pointer;
  min-width: 1.5rem;
  min-height: 1.5rem;
  margin-top: .3rem;
  font-size: 1.25rem;
  
  &.job-list {
    font-size: 1rem;
  }
  
  &.dashboard-list {
    font-size: 1rem;
    margin-top: 0;
  }
`;

const Emoji = (props: EmojiProps) => (
    <StyledEmoji
        onClick={props.onClick}
        className={props.customClass ? `emoji ${props.customClass}` : "emoji"}
        role="img"
        aria-label={props.label ? props.label : ""}
        aria-hidden={props.label ? "false" : "true"}
    >
        {props.symbol}
    </StyledEmoji>
);
export default Emoji;
