import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import SettingsMenu from '../../components/settings/SettingsMenu';
import { AtsJobStatus, AtsStatus, createStageMappings, getAtsJobStatus, getAtsStatus } from '../../api/integrations';
import AtsJobSettingsForm from '../../components/settings/atsSettings/AtsJobSettingsForm';
import { useHistory, useParams } from 'react-router-dom';
import { ATS_SETTINGS } from '../../constants/routes';

const PageWrapper = styled.section`
  background: #f2f6fa;
  width: 100%;
  display: flex;
  min-height: 100vh;
  flex-flow: row-reverse;
`;
const FormWrapper = styled.div`
  background: #fff;
  width: 61%;
  padding: 4.5rem 4rem;
  display: flex;
  flex-direction: column;
  position: relative;

  @media (max-width: 1200px) {
    width: 70%;
  }
  @media (max-width: 768px) {
    width: 90%;
    padding: 3rem;
  }
`;

const AtsJobSettings = () => {
  const [jobStatus, setJobStatus] = useState<AtsJobStatus | null>(null);
  const [atsStatus, setAtsStatus] = useState<AtsStatus | null>(null);
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const checkAtsJobStatus = useCallback(async () => {
    const status = await getAtsJobStatus(id);
    setJobStatus(status);
  }, [id]);

  const checkAtsStatus = () => {
    getAtsStatus().then(setAtsStatus)
  }

  useEffect(checkAtsStatus, []);

  const saveMappings = async (mappings: { localId: string, atsId: string }[]) => {
    await createStageMappings(id, mappings);
    checkAtsJobStatus();
    history.push(ATS_SETTINGS)
  }

  useEffect(() => { checkAtsJobStatus() }, [checkAtsJobStatus]);
  return (
    <PageWrapper>
      <FormWrapper>
        <SettingsMenu menu="ats" />
        {jobStatus && atsStatus && <AtsJobSettingsForm atsStatus={atsStatus} jobStatus={jobStatus} onSave={saveMappings} onCheckAtsStatus={() => { checkAtsStatus() }} />}
      </FormWrapper>
    </PageWrapper >
  );
};

export default AtsJobSettings;
