import React, { ReactNode, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getRejectedFeedback } from '../../api/interviews';
import { navigate } from '../../utils/routes';
import { useAppSelector } from '../../store/hooks';
import { CLIENT_ROLE } from '../../constants/roles';
import FormPopUp from '../form/FormPopUp';
import NameAvatar from '../../UI/NameAvatar';
import { getRandomColor } from '../../constants/palette';
import Subtitle from '../../UI/typography/Subtitle';
import { InterviewRoom } from '../../types';
import styled from 'styled-components';

interface FeedbackModalProps {
  info: InterviewRoom;
}

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3rem;
`;
const HeaderItem = styled.div`
  display: flex;
  align-items: center;
  max-width: max-content;
  
`;
const NameWrapper = styled.div<ReactNode & { align?: string }>`
  margin-left: 0.5rem;
  &.row {
    display: flex;
    align-items: center;
  }
`;
const Name = styled.p`
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.43;
  color: #061c2e;
  margin-bottom: 0;
`;
const Feature = styled.span`
  font-size: 0.875rem;
  line-height: 1.43;
  color: #627d95;
  margin: 0 1rem;
`;
const Role = styled.p`
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.43;
  color: #627d95;
  margin: 0;
`;
const FeedbackText = styled.p`
  font-size: 0.875rem;
  line-height: 1.71;
  color: #061c2e;
  margin: 0;
`;
const RejectionFeedback = ({ info }: FeedbackModalProps) => {
  const history = useHistory();
  const params = new URLSearchParams(history.location.search);
  const rejected_feedback = params.get('rejected_feedback');
  const feedback_interview_id = params.get('feedback_interview_id');
  const [visible, setVisible] = useState(!!rejected_feedback);
  const [result, setResult] = useState({ rejection_reason: '' });
  const [t] = useTranslation();
  useEffect(() => {
    setVisible(!!rejected_feedback);
  }, [rejected_feedback]);
  useEffect(() => {
    if (visible) {
      feedback_interview_id &&
        getRejectedFeedback(feedback_interview_id).then((res) =>
          setResult(res.data),
        );
    } else {
      navigate(history, undefined, [
        'rejected_feedback',
        'feedback_interview_id',
      ]);
      setResult({ rejection_reason: '' });
    }
  }, [visible, feedback_interview_id, history]);
  const { user } = useAppSelector((state) => state.user);
  const isClient = user?.role === CLIENT_ROLE;

  return (
    <FormPopUp
      visible={visible}
      setVisible={setVisible}
      title={t('REJECTION_FEEDBACK')}
    >
      <Header>
        <HeaderItem>
          {!isClient && (
            <>
              <NameAvatar
                name={info.job.company.name}
                color={getRandomColor()}
                image={undefined}
              />
              <NameWrapper className="row">
                <Name>{info.job.company.name}</Name>
              </NameWrapper>
              <Feature>&</Feature>
            </>
          )}
          <NameAvatar
            name={info.candidate.name}
            image={undefined}
            color={getRandomColor()}
          />

          <NameWrapper>
            <Name>{info.candidate.name}</Name>
            {isClient && <Role>{info.candidate.current_role}</Role>}
          </NameWrapper>
        </HeaderItem>
      </Header>
      <Subtitle title={t('RESPONDENT_DECISION')} />
      <FeedbackText>{result.rejection_reason}</FeedbackText>
    </FormPopUp>
  );
};

export default RejectionFeedback;
