import React from 'react';
import styled from 'styled-components';
import NameAvatar from '../../UI/NameAvatar';
import { getRandomColor } from '../../constants/palette';
interface AvatarProps {
  title: string;
  description: string;
  image: string;
  color?: {
    main: string;
    light: string;
  };
}
const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;
const UserAvatar = styled(NameAvatar)`
  min-width: 2.5rem;
  min-height: 2.5rem;
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 0.5rem;
  border-radius: 50%;
  border: ${(props) => (props.image ? '1px solid #dae5ec' : 'none')};
  display: flex;
  & img {
    height: auto;
    object-position: center;
    object-fit: contain;
  }
`;

const Text = styled.div``;
const Title = styled.h4`
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1.43;
  color: #061c2e;
  margin: 0;
`;
const Description = styled.p`
  font-size: 0.75rem;
  line-height: 1.33;
  color: #627d95;
  margin: 0.25rem 0 0;
`;
const NameImage = ({ title, description, image, color }: AvatarProps) => {
  return (
    <Wrapper>
      <UserAvatar
        size={'large'}
        image={image}
        name={title}
        color={color ? color : getRandomColor()}
      />
      <Text>
        <Title>{title}</Title>
        {description && <Description>{description}</Description>}
      </Text>
    </Wrapper>
  );
};

export default NameImage;
