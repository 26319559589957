import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

import {
    LOGIN,
    CANDIDATES,
    MESSAGES
} from '../../constants/routes';
import { getToken, removeToken, storeToken } from '../../utils/localStorage';
import { getUserByMagicLink } from '../../api/user';
import { useAppDispatch } from '../../store/hooks';
import { login, logout } from '../../store/userSlice';

const paramsToObject = (entries:any) => {
  const result:any = {}
  for(const [key, value] of entries) {
    result[key] = value;
  }
  return result;
};

const useMagicLink = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const pathname = getToken('pathname');
  const search = getToken('search');

  useEffect(() => {
    if (pathname && search) {
      const urlParams = new URLSearchParams(search);
      const params = paramsToObject(urlParams.entries());

      if (params.magic_link) {
        // clear up old login data
        dispatch(logout());

        // login wia magic link and go to page
        getUserByMagicLink(params.magic_link)
          .then((res) => {
            storeToken(res.data.access_token);

            axios.defaults.headers.common['Authorization'] = res.data.access_token
              ? `Token ${res.data.access_token}`
              : '';
            dispatch(login({ user: res.data.user }));

          if (res.data.user) {
              let user = res.data.user;
              // @ts-ignore
              localStorage.setItem("intercomID", process.env.REACT_APP_INTERCOM_ID);
              // @ts-ignore
              window.Intercom('boot', {
                  app_id: process.env.REACT_APP_INTERCOM_ID,
                  email: user.email,
                  name: user.name,
                  user_id: user.id,
                  user_role: user.role,
                  user_has_default_tier: user.role === 'recruiter' ? user.has_default_tier : false,
              });
          }

            let link = `${pathname}`;

            delete params.magic_link;
            if (Object.keys(params).length) {
               link = link + '?' + Object.keys(params).map(key => key + '=' + params[key]).join('&');
            }

            if (pathname === CANDIDATES) { // open Kanban with selected in the GH candidate
                link = `${pathname}?filter=in-process`;
              if (params.stage) {
                  link += `&stage=${params.stage}`;
              }
              if (params.interview_id) {
                  link += `&interview_id=${params.interview_id}`;
              }
            }

            if (pathname === MESSAGES) { // open Messages with the recruiter and applied candidate
                link = `${pathname}`;
                if (params.user_id && params.interview_id) {
                    link = `${pathname}/${params.user_id}/${params.interview_id}`;
                }
            }

            history.push(link);

            removeToken('pathname');
            removeToken('search');
          })
          .catch(() => {
            history.push(LOGIN);
            removeToken('pathname');
            removeToken('search');
          });
      } else { // go to login page if something wrong
        history.push(LOGIN);
        removeToken('pathname');
        removeToken('search');
      }
    } else {
      history.push(LOGIN);
    }
  }, [
    pathname,
    search,
    dispatch,
    history,
  ]);

  return {};
}

export default useMagicLink;
