import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Form } from 'antd';
import SettingsMenu from '../components/settings/SettingsMenu';
import RecruiterOnboard from '../components/recruiter/RecruiterOnboard';
import { useAppSelector } from '../store/hooks';
import ReactGa from 'react-ga4';

const PageWrapper = styled.section`
  background: #f2f6fa;
  width: 100%;
  display: flex;
  min-height: 100vh;
  flex-flow: row-reverse;
`;
const FormWrapper = styled.div`
  background: #fff;
  width: 61%;
  padding: 4.5rem 4rem;
  display: flex;
  flex-direction: column;
  position: relative;

  @media (max-width: 1200px) {
    width: 70%;
  }
  @media (max-width: 768px) {
    width: 90%;
    padding: 3rem;
  }
`;

const CandidatesSpecificationSettings = () => {
  const [form] = Form.useForm();
  const user = useAppSelector((state) => state.user.user);
  useEffect(() => {
    ReactGa.pageview('/candidates-specification');
  }, []);
  useEffect(() => {
    user &&
      form.setFieldsValue({
        name: user.name,
        title: user.title,
        city: user.city?.id,
        recruiter_type: user.recruiter_type,
        company: user.company?.name,
        department: user.department,
        email: user.email,
        phone: user.phone,
      });
  }, [user, form]);

  return (
    <PageWrapper>
      <FormWrapper>
        <SettingsMenu menu="candidate" />
        <RecruiterOnboard user={user} standalone />
      </FormWrapper>
    </PageWrapper>
  );
};

export default CandidatesSpecificationSettings;
