import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { ReactComponent as CloseIcon } from '../../../../../icons/icon-close-danger.svg';
import Button from '../../../../../UI/buttons/Button';
import { Wrapper, MiddleCol } from './styles';
import { useAppSelector } from '../../../../../store/hooks';
import { CLIENT_ROLE, RECRUITER_ROLE } from '../../../../../constants/roles';
import {SHOW_COMPANY_CHAT} from "../../../../../constants/queryParams";

interface IFooter {
  companyId: string,
  recruiterId: string,
  interviewId: string,
  handleEndProcess: () => void,
  blockOffSyncActions: boolean,
}

const Footer = ({
  companyId,
  recruiterId,
  interviewId,
  handleEndProcess,
  blockOffSyncActions,
}: IFooter) => {
  const [t] = useTranslation();
  const { user } = useAppSelector((state) => state.user);

  const history = useHistory();
  const isClient = user?.role === CLIENT_ROLE;
  const isRecruiter = user?.role === RECRUITER_ROLE;

  const openIntercom = () => {
    // @ts-ignore
    if (window.Intercom !== undefined) {
      // @ts-ignore
      window.Intercom('showNewMessage', `Hi team! I have a question about: `);
    }
  }

  const openCompanyChat = () => {
    const search = new URLSearchParams(history.location.search);
    search.set(SHOW_COMPANY_CHAT, 'true');
    const query = search.toString();
    history.push(window.location.pathname + '?' + query);
  }

  return (
    <Wrapper>
      <div>
        <Button
          onClick={openIntercom}
          size="small"
        >{t('CONTACT_SUPPORT')}</Button>
      </div>

      {isRecruiter && (
        <MiddleCol>
            <Button
              onClick={openCompanyChat}
              size="small"
            >{t('CONTACT_CLIENT')}</Button>
        </MiddleCol>
      )}

      {isClient && (
        <MiddleCol>
          <Button
            onClick={openCompanyChat}
            size="small"
          >{t('CONTACT_RECRUITER')}</Button>
        </MiddleCol>
      )}

      <div>
        <Button
          size="small"
          danger
          icon={<CloseIcon />}
          onClick={handleEndProcess}
          disabled={blockOffSyncActions}
        >{t('END_PROCESS')}</Button>
      </div>
    </Wrapper>
  );
}

export default React.memo(Footer);
