import React from 'react';
import { ColumnsType } from 'antd/es/table';

import { generateKey } from '../../utils/candidate';
import Button from '../../UI/buttons/Button';
import { ReactComponent as ArrowRightIcon } from '../../icons/arrow-right.svg';
import { Client } from '../../types';
import { Table } from 'antd';
import styled from 'styled-components';
import NameImage from './NameImage';
import TitleText from './TitleText';
import { useTranslation } from 'react-i18next';
import format from 'date-fns/format';
import { Link, useHistory } from 'react-router-dom';
import { CLIENT, COMPANIES, JOBS } from '../../constants/routes';
import TooltipPrompt from "../../UI/tootlip";

interface ClientListProps {
  clients: Client[];
}
const StyledTable = styled(Table)`
  & .ant-table-tbody {
    & > tr > td {
      padding: 1.5rem 0.5rem;
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
        & button {
          margin-left: auto;
        }
      }
    }
  }
`;
const CandidateList = styled.div`
  padding: 0 2rem;
  background: #ffffff;
  border-radius: 0.5rem;
  & .ant-table-tbody {
    & > tr {
      &.ant-table-row:hover > td {
        background: #fff;
      }
      &:last-child > td{
      border-bottom: none;
      }
    }
  }
  @media (max-width: 576px) {
    padding: 0 1.5rem;
  }
`;
const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
const ClientList = ({ clients }: ClientListProps) => {
  const [t] = useTranslation();
  const history = useHistory();
  const redirectTo = (id: string, department: string) => {
    history.push(`${JOBS}?company=${id}&department=${department}`);
  };
  const editClient = (id: string) => {
    const params = new URLSearchParams(history.location.search);
    params.set('client', id);
    const query = params.toString();
    history.push(`${COMPANIES}?${query}`);
  };
  const columns: ColumnsType<Client> = [
    {
      title: '',
      width: 170,
      key: 'company',
      responsive: ['sm', 'xxl', 'xl', 'lg', 'md'],
      dataIndex: 'name',
      render: (_, record) => (
        <TooltipPrompt title={t('CLICK_TO_SEE_PROFILE')}><Link to={CLIENT + '/' + record.company.id}>
          <NameImage
            title={record?.company?.name}
            image={
              record?.company?.image
                ? record.company.image
                : process.env.PUBLIC_URL + '/images/company-logo.png'
            }
            description={record?.company?.city?.name}
          />
        </Link></TooltipPrompt>
      ),
      fixed: 'left',
    },
    {
      title: '',
      width: 170,
      dataIndex: 'name',
      key: 'name-sm',
      responsive: ['xs'],
      render: (_, record) => (
        <NameImage
          title={record?.company?.name}
          image={record?.company?.image ? record.company.image : ''}
          description={record?.company?.city?.name}
        />
      ),
    },
    {
      title: '',
      width: 150,
      key: 'department',
      dataIndex: 'department',
      render: (department) => (
        <TitleText title={t('DEPARTMENT_TITLE')} text={[department]} />
      ),
    },
    {
      title: '',
      width: 180,
      key: 'name',
      dataIndex: 'travel_max',
      render: (name, record) => (
        <NameImage
          title={record.name}
          image={record?.company?.image}
          description={record?.company?.city?.name}
        />
      ),
    },
    {
      title: '',
      width: 150,
      key: 'registered_at',
      dataIndex: 'skills',
      render: (registered_at, record) => (
        <TitleText
          title={t('REGISTRATION_DATE')}
          text={[format(new Date(record.registered_at), 'MMM d, yyyy')]}
        />
      ),
    },
    {
      title: '',
      width: 90,
      key: 'jobs',
      dataIndex: 'salary',
      render: (_, record) => (
        <TitleText
          title={t('JOBS')}
          text={[
            `${t('OPEN_TITLE')}: ${record.statistics.open_jobs}`,
            `${t('UNPUBLISHED')}: ${record.statistics.unpublished_jobs}`,
          ]}
        />
      ),
    },
    {
      title: '',
      width: 90,
      key: 'jobs',
      dataIndex: 'salary',
      render: (_, record) => (
        <TitleText
          title={`${t('HIRED')}: ${record.statistics.hired}`}
          text={[
            `${t('REJECTED_TITLE')}: ${record.statistics.rejected}`,
            `${t('IN_PROCESS_TITLE')}: ${record.statistics.in_process}`,
          ]}
        />
      ),
    },
    {
      title: '',
      width: 200,
      key: 'action',
      responsive: ['sm', 'xxl', 'xl', 'lg', 'md'],
      dataIndex: 'id',
      fixed: 'right',
      render: (id, record) => (
        <ActionWrapper>
          <Button
            size="small"
            onClick={() => redirectTo(record.company?.id, record.department)}
            style={{ marginRight: '1rem' }}
          >
            {t('ASSOCIATED_JOBS')}
          </Button>
          <Button size="small" type="primary" onClick={() => editClient(id)}>
            {t('EDIT_TITLE')}
            <ArrowRightIcon style={{ marginRight: 0, marginLeft: '.5rem' }} />
          </Button>
        </ActionWrapper>
      ),
    },
    {
      title: '',
      width: 200,
      dataIndex: 'id',
      key: 'action-xs',
      responsive: ['xs'],
      render: (id, record) => (
        <ActionWrapper>
          <Button
            size="small"
            onClick={() => redirectTo(record.company?.id, record.department)}
            style={{ marginRight: '1rem' }}
          >
            {t('ASSOCIATED_JOBS')}
          </Button>
          <Button size="small" type="primary" onClick={() => editClient(id)}>
            {t('EDIT_TITLE')}
            <ArrowRightIcon style={{ marginRight: 0, marginLeft: '.5rem' }} />
          </Button>
        </ActionWrapper>
      ),
    },
  ];
  return (
    <CandidateList>
      <StyledTable
        locale={{ emptyText: ' ' }}
        // @ts-ignore
        columns={columns}
        dataSource={clients}
        showHeader={false}
        scroll={{ x: 950 }}
        pagination={false}
        rowKey={() => generateKey(5)}
      />
    </CandidateList>
  );
};

export default ClientList;
