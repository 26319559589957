import React from "react";
import { useTranslation } from 'react-i18next';

import {
  StageItem,
  Content,
  Title,
  TextLine,
  TextTitle,
  TextInfo
} from './styles';

interface Iprops {
  interviewer: string,
  role: string,
  description: string,
  goal: string,
  duration: string,
  location: string,
  preparation: string,
  name: string,
}

const Stage = ({
  interviewer,
  role,
  description,
  goal,
  duration,
  location,
  preparation,
  name
}: Iprops) => {
  const [t] = useTranslation();

  let interviewerText = '';
  if (interviewer && role) {
    interviewerText = (`${interviewer}, ${role}`);
  } else if (interviewer) {
    interviewerText = interviewer;
  } else if (role) {
    interviewerText = role;
  }

  return (
    <StageItem>
      <Content>
        <Title>{name}</Title>
        {interviewerText &&
          <TextLine>
            <TextTitle>{`${t('INTERVIEWER')}:`}</TextTitle>
            <TextInfo>{interviewerText}</TextInfo>
          </TextLine>
        }

        {description &&
          <TextLine>
            <TextTitle>{`${t('DESCRIPTION')}:`}</TextTitle>
            <TextInfo>{description}</TextInfo>
          </TextLine>
        }

        {goal &&
          <TextLine>
            <TextTitle>{`${t('GOAL')}:`}</TextTitle>
            <TextInfo>{goal}</TextInfo>
          </TextLine>
        }

        {duration &&
          <TextLine>
            <TextTitle>{`${t('DURATION')}:`}</TextTitle>
            <TextInfo>{duration}</TextInfo>
          </TextLine>
        }

        {location &&
          <TextLine>
            <TextTitle>{`${t('LOCATION_LABEL')}:`}</TextTitle>
            <TextInfo>{location}</TextInfo>
          </TextLine>
        }

        {preparation &&
          <TextLine>
            <TextTitle>{`${t('CANDIDATE_PREPARATION_LABEL')}:`}</TextTitle>
            <TextInfo>{preparation}</TextInfo>
          </TextLine>
        }
      </Content>
    </StageItem>
  );
};

export default React.memo(Stage);