import React, { useState } from 'react';
import styled from 'styled-components';
import { Drawer, Form } from 'antd';
import FormItem from '../form/FormItem';
import Button from '../../UI/buttons/Button';
import { ErrorMessage } from './LoginForm';
import FormTitle from './FormTitle';
import { sendResetPassword } from '../../api/user';
import { useTranslation } from 'react-i18next';
import Input from '../../UI/inputs/Input';

interface DrawerProps {
  visible: boolean;
  setVisible: (st: boolean) => void;
}

const StyledDrawer = styled(Drawer)`
  &.ant-drawer-open {
    width: 32.5% !important;
    @media (max-width: 1200px) {
      width: 50% !important;
    }
    @media (max-width: 768px) {
      width: 100% !important;
    }
  }
  & .ant-drawer-body {
    padding: 4.5rem 4rem;
    @media (max-width: 768px) {
      padding: 2rem;
    }
  }
`;
const StyledForm = styled(Form)`
  margin-top: 2.5rem;
`;
const FormText = styled.p`
  margin: 1rem 0 0;
  font-size: 0.875rem;
  line-height: 1.71;
  color: #061c2e;
`;
const SubTitle = styled.h5`
  font-size: 1.25rem;
  font-weight: 700;
  margin: 1rem 0;
  color: #061c2e;
`;
const SuccessText = styled.p`
  font-size: 0.875rem;
  line-height: 1.71;
  margin: 0 0 2.5rem;
  color: #061c2e;
  & span {
    color: #34ce72;
  }
`;
const ResetPasswordPopUp = ({ visible, setVisible }: DrawerProps) => {
  const [error, setError] = useState('');
  const [email, setEmail] = useState('');
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const onFinish = async (values: any) => {
    setError('');
    sendResetPassword(values.email)
      .then((res) => {
        setEmail(values.email);
      })
      .catch(
        (e) => e.response?.data?.message && setError(e.response?.data?.message),
      );
  };
  return (
    <StyledDrawer
      title=""
      placement="right"
      closable={true}
      onClose={() => {
        setVisible(!visible);
        setError('');
        setEmail('');
        form.resetFields();
      }}
      visible={visible}
      getContainer={false}
      mask={false}
    >
      <FormTitle text={t('RESET_PASSWORD_TITLE')} />
      {!email ? (
        <>
          <FormText>{t('RESET_PASSWORD_TEXT')}</FormText>
          <StyledForm
            layout="vertical"
            name="reset-request"
            onFinish={onFinish}
            form={form}
            scrollToFirstError={true}
          >
            {error && <ErrorMessage message={error} type="error" />}
            <FormItem label={t('EMAIL_LABEL')} name="email" rules={[]}>
              <Input size="large" />
            </FormItem>
            <FormItem className="text-right">
              <Button type="primary" htmlType="submit">
                {t('RESET_PASSWORD_TITLE')}
              </Button>
            </FormItem>
          </StyledForm>
        </>
      ) : (
        <>
          <SubTitle>{t('EMAIL_SENT_TEXT')}</SubTitle>
          <SuccessText>
            {t('RESET_PASSWORD_SUCCESS_TEXT_1')} <span>{email}</span>,{' '}
            {t('RESET_PASSWORD_SUCCESS_TEXT_2')}
          </SuccessText>
          <Button type="primary" onClick={() => setVisible(false)}>
            {t('LOG_IN_TEXT')}
          </Button>
        </>
      )}
    </StyledDrawer>
  );
};

export default ResetPasswordPopUp;
