import { Form, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Button from '../../../UI/buttons/Button';
import FormItem from '../../form/FormItem';
import { requiredRule } from '../../../utils/validationRules';
import Textarea from '../../../UI/inputs/Textarea';
import { CLIENT_ROLE, RECRUITER_ROLE } from '../../../constants/roles';
import { useAppSelector } from '../../../store/hooks';

interface ConfirmModalProps {
  stage: {
    interviewId: string;
    stage_name?: string;
    from?: string;
    stage_id?: string;
    reason_for_change?: string;
    candidateID: string;
    is_rejection?: boolean;
  } | null;
  setStage: React.Dispatch<
    React.SetStateAction<{
      interviewId: string;
      stage_name?: string;
      from: string;
      stage_id?: string;
      reason_for_change?: string;
      candidateID: string;
      is_rejection?: boolean;
    } | null>
  >;
  move: (
    interviewId: string,
    candidateID: string,
    nextStage: string,
    currentStage: string,
  ) => void;
  currentStage?: string,
}

const StyledForm = styled(Form)`
  padding: 3px;
`;
const StyledModal = styled(Modal)`
  & .ant-modal-content {
    padding: 32px;
  }
  & .ant-modal-header {
    border-bottom: none;
    padding: 0;
    margin-bottom: 16px;
    & div {
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: left;
      color: #061c2e;
    }
  }
  & .ant-modal-body {
    padding: 0;
    & p {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
      text-align: left;
      color: #061c2e;
    }
  }
  & .ant-modal-footer {
    padding: 0;
    display: flex;
    border-top: none;
  }
`;
const ConfirmModal = ({ stage, setStage, move, currentStage }: ConfirmModalProps) => {
  const [t] = useTranslation();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const { user } = useAppSelector((state) => state.user);
  const isClient = user?.role === CLIENT_ROLE;
  const isRecruiter = user?.role === RECRUITER_ROLE;

  useEffect(() => {
    form.resetFields();
    stage?.interviewId ? setOpen(true) : setOpen(false);
    if (!stage?.interviewId){
      setLoading(false)
    }
  }, [stage, form]);

  const handleOk = () => {
    setLoading(true);
    /*
    use currentStage if InterviewStageModal is opened before
    */
    if (stage){
      if (stage?.is_rejection){
        form.validateFields().then(res => {
          const str = form.getFieldValue('rejection_reason');
          // @ts-ignore
          move(stage.interviewId, stage.candidateID, stage?.stage_name || '', currentStage || stage?.from || '', str);
        }).catch(e => setLoading(false))
      }
      else{
        move(stage.interviewId, stage.candidateID, stage?.stage_name || '', currentStage || stage?.from || '');
      }

    }
  };

  return (
    <StyledModal
      closable={false}
      visible={open}
      title={t('MOVE_QUESTION_TITLE')}
      onOk={handleOk}
      onCancel={() => setStage(null)}
      footer={[
        <Button danger type="primary" onClick={handleOk} loading={loading}>
          {stage?.is_rejection ?t('REJECT_CANDIDATE') : t('MOVE_TITLE')}
        </Button>,
        <Button type="ghost" onClick={() => setStage(null)}>
          {t('CANCEL_TEXT')}
        </Button>,
      ]}
    >
      <p>
        {isClient && t('MOVE_TEXT')}
        {isRecruiter && t('MOVE_TEXT_RECRUITER')}
      </p>
      {stage?.is_rejection && <StyledForm layout="vertical" name="add-client" form={form} scrollToFirstError={true}>
        <FormItem
          label={t('REASON')}
          name="rejection_reason"
          rules={[...requiredRule(t)]}
        >
          <Textarea autoSize={{ minRows: 2 }} />
        </FormItem>
      </StyledForm>  }
    </StyledModal>
  );
};

export default ConfirmModal;
