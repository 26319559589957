import React from 'react';
import Button from '../../../../../UI/buttons/Button';
import {ReactComponent as RescheduleIcon} from '../../../../../icons/reschedule.svg';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Modal } from 'antd';
import { InterviewRoom } from '../../../../../types';
import { CANDIDATES } from '../../../../../constants/routes';
import {
  INTERVIEW_ID_PARAM,
  STAGE_PARAM,
  SHOW_DATE_PROPOSE_PARAM,
  RESCHEDULE_PARAM,
} from '../../../../../constants/queryParams';

const { confirm } = Modal;

interface IEditDate {
  interview: InterviewRoom | { [key:string]: any }
};

const EditDate = ({interview}: IEditDate) => {
  const [t] = useTranslation();
  const history = useHistory();

  const handleEdit = () => {
    confirm({
      title: `${t('RESCHEDULE_POPUP_TEXT')}`,
      okText: t('OK_TEXT'),
      okType: 'default',
      cancelText: t('CANCEL_TEXT'),
      cancelButtonProps: {
        danger:true
      },
      onOk() {
        const search = new URLSearchParams(history.location.search);

        search.set(INTERVIEW_ID_PARAM, interview?.id);
        search.set(STAGE_PARAM, interview?.current_stage?.stage_name);
        search.set(SHOW_DATE_PROPOSE_PARAM, 'true');
        search.set(RESCHEDULE_PARAM, '1');

        const query = search.toString();

        history.push(CANDIDATES + '?filter=in-process&' + query);
      },
    });
  };

  return (
    <Button size="small" icon={<RescheduleIcon/>} onClick={handleEdit} style={{marginRight:16}}>
      {t('EDIT_TITLE')}
    </Button>
  );
};

export default EditDate;
