import axios from 'axios';

const api_url = process.env.REACT_APP_API_URL;

export const getEducationList = async () => {
  return await axios.get(`${api_url}education/`);
};

export const addEducation = async (name:string) => {
  return await axios.post(`${api_url}education/`,{name});
};