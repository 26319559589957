import React from 'react';
import NameAvatar from '../../UI/NameAvatar';
import { orderTasks } from '../../utils/candidate';
import { InterviewRoom, InterviewTask } from '../../types';
import TaskItem from '../candidates/kanban/Task';
import styled from 'styled-components';
import { getRandomColor } from '../../constants/palette';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../store/hooks';
import {  GUEST_ROLE } from '../../constants/roles';
import { COMPLETED } from '../../constants/statuses';
import { ErrorMessage } from '../auth/LoginForm';

interface StageFormProps {
  interview: InterviewRoom;
  move?: boolean;
  handleComplete: (id: string) => void;
}
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0.75rem 0;
  margin-bottom: 2.5rem;
`;
const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-right: 2rem;
`;
const HeaderRight = styled.div`
  width: 50%;
  & p {
    text-align: right;
  }
`;
const Name = styled.p`
  font-size: 0.875rem;
  margin-left: 0.5rem;
  font-weight: 700;
  line-height: 1.43;
  color: #061c2e;
  margin-bottom: 0;
`;
const NameWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const Feature = styled.span`
  font-size: 0.875rem;
  line-height: 1.43;
  color: #627d95;
  margin: 0 1rem;
`;
const Title = styled.p`
  font-size: 0.625rem;
  font-weight: 700;
  line-height: 1.6;
  letter-spacing: 0.8px;
  color: #627d95;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
`;
const Description = styled.p`
  font-size: 0.875rem;
  line-height: 1.71;
  color: #061c2e;
  margin-bottom: 2.5rem;
`;
const InterviewStageModalForm = ({
  interview,
  handleComplete,
  move,
}: StageFormProps) => {
  const [t] = useTranslation();
  const { user } = useAppSelector((state) => state.user);
  const sortedTasks = interview.current_stage?.tasks.sort(
    (a: InterviewTask, b: InterviewTask) => (a.id < b.id ? -1 : 1),
  );
  const isDecisionMaker = user?.role === GUEST_ROLE;
  const isUncompleted = interview?.current_stage?.tasks.filter(
    (el) => el.status !== COMPLETED,
  ).length;

  return (
    <>
      <Header>
        <HeaderLeft>
          <NameWrapper>
            <NameAvatar
              name={interview.job.company.name}
              image={
                interview.job.company.image
                  ? interview.job.company.image
                  : process.env.PUBLIC_URL + 'images/company-logo.png'
              }
              color={getRandomColor()}
            />
            <Name>{interview.job.company.name}</Name>
          </NameWrapper>
          <Feature>&</Feature>
          <NameWrapper>
            <NameAvatar
              name={interview.candidate?.name}
              color={getRandomColor()}
            />
            <Name>{interview.candidate?.name}</Name>
          </NameWrapper>
        </HeaderLeft>
        <HeaderRight>
          <Name>{interview.job.title}</Name>
        </HeaderRight>
      </Header>
      <Title>{t('DESCRIPTION')}</Title>

      <Description>
        {move ? t('MOVE_TO_HIRED') : interview.current_stage.description}
      </Description>

      {isDecisionMaker && !isUncompleted ? (
        <ErrorMessage message={t('NOT_ACTUAL')} type="error" />
      ) : (
        <>
          {!move && <Title>{t('TASKS')}</Title>}
          {!move &&
            orderTasks(sortedTasks).map((task) => (
              <TaskItem
                interview={interview}
                data={task}
                key={task.id}
                name={interview.client.name}
                handleComplete={handleComplete}
              />
            )
          )}
        </>
      )}
    </>
  );
};

export default InterviewStageModalForm;
