import React, { useEffect, useState } from "react";
import { Form, Select, Col, Row, message } from 'antd';
import FormTitle from '../../components/auth/FormTitle';
import Button from '../../UI/buttons/Button';
import styled from 'styled-components';
import FormItem from './FormItem';
import { CaretDownOutlined } from '@ant-design/icons';
import SelectInput from '../../UI/inputs/Select';
import Input from '../../UI/inputs/Input';
import { FEE_TYPES } from "../recruiter/AddCompanyRecruiterForm";
import { updateJobFlexibleFee } from "../../api/jobs";
import { Job } from "../../types";
import { JOBS } from '../../constants/routes';
import { useHistory } from 'react-router';
import RecruiterManagementBoard from "../recruiter/RecruiterManagementBoard";
import { HIREPORT_DEFAULT_COMPENSATION_AMOUNT, HIREPORT_DEFAULT_COMPENSATION_TYPE } from '../../constants/recruiters';

const FormActions = styled.div`
  padding: 1.5rem 2rem;
  border-radius: 8px;
  background-color: #f2f6fa;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FormSubtitle = styled.h2`
  font-family: Lato;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #061c2e;
  margin: 0.5rem 0;
`;

interface JobPSLFormProps {
  job: Job;
  hasFlex: boolean;
  hasSelectRecruiter: boolean;
}
export default function JobPSLForm({ job, hasFlex, hasSelectRecruiter }: JobPSLFormProps) {
  const [form] = Form.useForm();
  let [isLoading, setIsLoading] = useState(false);

  const history = useHistory();

  const initialFormValues = {
    recruiter_compensation_type: HIREPORT_DEFAULT_COMPENSATION_TYPE,
    recruiter_compensation_type_tooltip: HIREPORT_DEFAULT_COMPENSATION_AMOUNT,
  };

  useEffect(() => {
    if (!job) return;

    const currentValues = form.getFieldsValue();
    form.setFieldsValue({
      ...form.getFieldsValue(),
      recruiter_compensation_type: job.recruiter_compensation_type || currentValues.recruiter_compensation_type,
      recruiter_compensation_type_tooltip: job.recruiter_compensation_type_tooltip || currentValues.recruiter_compensation_type_tooltip,
    })
  }, [job, form]);

  return (
    <Form
      scrollToFirstError={true}
      layout="vertical"
      name="job-form"
      form={form}
      initialValues={initialFormValues}
    >
      <FormTitle text={"Recruiter specification"} />
      {hasFlex &&
        <>
          <FormSubtitle>{"Fees"}</FormSubtitle>
          <p>Set your own custom fee for this specific job. This will be visible for all recruiters.</p>
          <FormItem >
            <Row gutter={16}>
              <Col
              >
                <FormItem
                  label="FEE STRUCTURE"
                  name="recruiter_compensation_type"
                  className="no-margin"
                >
                  <SelectInput
                    size="large"
                    suffixIcon={<CaretDownOutlined style={{ color: '#000' }} />}
                  >
                    {FEE_TYPES.map((fee, index) => (
                      <Select.Option value={fee.fee_value} key={index}>
                        {fee.fee_name}
                      </Select.Option>
                    ))}
                  </SelectInput>
                </FormItem>
              </Col>

              <Col span={12}>
                <FormItem
                  label="FEE DETAILS"
                  name="recruiter_compensation_type_tooltip"
                  className="no-margin"
                >
                  <Input maxLength={70} size="large" />
                </FormItem>
              </Col>
            </Row>
          </FormItem></>}

      {hasSelectRecruiter &&
        <div style={{ marginBottom: "20px" }}>
          <RecruiterManagementBoard fromJobCreation={false} selectedJob={job.id} setIsModalVisible={setIsLoading} />
        </div>}

      <FormActions>
        <Button
          type="primary"
          loading={isLoading}
          onClick={() => {
            setIsLoading(true);
            const draft = form.getFieldsValue();
            updateJobFlexibleFee(
              job.id,
              draft.recruiter_compensation_type,
              draft.recruiter_compensation_type_tooltip,
            )
              .then(() => {
                setIsLoading(false);
                message.success("Job successfully updated")
                history.push(JOBS + '/');
              })
              .catch(() => {
                setIsLoading(false);
                message.error("Oops something went wrong, please try again")
              });
          }}
        >
          {"Save"}
        </Button>
      </FormActions>
    </Form>
  );
}
