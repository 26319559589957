import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Form, Select } from 'antd';
import Button from '../../../UI/buttons/Button';
import FormTitle from '../../../components/auth/FormTitle';
import FormItem from '../../../components/form/FormItem';
import { FormSubtitle } from '../../form/JobInfoForm/styles';
import ListWidget from '../../dashboard/ListWidget';
import { AtsStatus, JobRef } from '../../../api/integrations';
import { JobActions, EmptyText, JobListItem, LocalJobTitle, ListContainer, RemoteJobTitle, JobActionButton } from './styles';
import SelectInput from '../../../UI/inputs/Select';
import { CaretDownOutlined } from "@ant-design/icons";
import { ATS_SETTINGS } from '../../../constants/routes';
import ConfirmDisconnectAtsModal from './ConfirmDisconnectAtsModal';
import RefreshDataSection from './RefreshDataSection';
import { getJobs } from '../../../api/jobs';
import { Job } from '../../../types';

const StyledForm = styled(Form)`
  margin-top: 2.5rem;
  width: 100%;
`;

const WarningText = styled.p`
  width: 66%;
`

const { Option } = Select;

type JobSelectProps = {
  selected: JobRef | null;
  jobs: JobRef[];
  onChange?: (key: string, value?: JobRef) => void;
  emptyLabelText: string;
}

const JobSelect = ({ selected, jobs, onChange, emptyLabelText }: JobSelectProps) => (
  <SelectInput
    suffixIcon={<CaretDownOutlined style={{ color: '#000' }} />}
    value={selected?.id ?? ''}
    onChange={(key) => onChange && onChange(key, jobs.find((job) => job.id === key))}
  >
    <Option value={''}>{emptyLabelText}</Option>
    {jobs.map((job) => (
      <Option key={job.id} value={job.id}>{job.name}</Option>
    ))}
    {selected && !jobs.some((job) => job.id === selected.id) && (
      <Option value={selected.id}>{selected.name}</Option>
    )}
  </SelectInput>
)

type AtsSettingsFormProps = {
  atsStatus: AtsStatus;
  onConnectJob: (localJobId: string, remoteJobId: string) => void;
  onDisconnectAts: () => void;
  onCheckAtsStatus: () => void
}

const AtsSettingsForm = ({ atsStatus, onConnectJob, onDisconnectAts, onCheckAtsStatus }: AtsSettingsFormProps) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [loading] = useState(false);
  const [localJobs, setLocalJobs] = useState<JobRef[]>([]);
  const [filterJobs, setFilterJobs] = useState(false);
  const [disconnectWarningVisible, setDisconnectWarningVisible] = useState(false);
  const jobConnections = (
    filterJobs
      ? atsStatus.job_connections.filter((job) => job.ats_job)
      : atsStatus.job_connections
  );
  jobConnections.sort((a, b) => `${a.local_job ? '0' : '1'}_${a.ats_job.name}`.localeCompare(`${b.local_job ? '0' : '1'}_${b.ats_job.name}`))
  const [selections, setSelections] = useState<Record<string, JobRef>>({})
  const history = useHistory()

  const refreshLocalJobs = useCallback(async () => {
    const localJobs: { data: Job[] } = await getJobs('?no_page=true');
    setLocalJobs(
      localJobs.data
        .filter((job) => !jobConnections.some((connection) => connection.local_job?.id === job.id))
        .map<JobRef>((job) => ({ id: job.id, name: job.title }))
    );
  }, [jobConnections])

  useEffect(() => { refreshLocalJobs() }, [refreshLocalJobs])

  const handleSelectionChanged = (remoteId: string, value?: JobRef) => {
    if (!value) {
      const { [remoteId]: __, ...filteredSelections } = selections;
      setSelections(filteredSelections);
      return;
    }

    // only allow this local job to be mapped to a single remote job by deselecting it if it is used elsewhere
    const conflicting = Object.entries(selections).find(([_, localJob]) => localJob.id === value.id);
    if (conflicting) {
      const { [conflicting[0]]: __, ...filteredSelections } = selections;
      setSelections({ ...filteredSelections, [remoteId]: value });
      return;
    }

    setSelections({ ...selections, [remoteId]: value });
    return;
  }

  return (
    <StyledForm
      scrollToFirstError={true}
      layout="vertical"
      name="invitation"
      form={form}
    >
      <FormTitle text={t('ats.ATS_SETTINGS_TITLE')} />
      <Trans
        i18nKey="ats.YOUR_ACCOUNT_IS_CONNECTED_TO_FMT"
        values={{ tool: atsStatus.tool_name }}
        components={{ b: <strong /> }}
      />
      <FormItem>
        <FormSubtitle>{t('ats.JOB_CONNECTIONS')}</FormSubtitle>
        <ListContainer>
          <ListWidget
            id={'connected-job-widget'}
            title={t('ats.ATS_JOBS')}
            tabs={['ALL_JOBS_FILTER_TITLE', 'ats.CONNECTED_JOBS_FILTER_TITLE']}
            onChangeTab={(filter) => { setFilterJobs(filter === 'ats.CONNECTED_JOBS_FILTER_TITLE') }}
          >
            {jobConnections && jobConnections.length === 0 && (
              <EmptyText>{t('NO_JOBS_YET')}</EmptyText>
            )}
            {jobConnections?.map((connection) => (
              <JobListItem
                key={connection.ats_job.id}
              >
                <RemoteJobTitle>{connection.ats_job.name}</RemoteJobTitle>
                {connection.local_job
                  ? <LocalJobTitle>{connection.local_job.name}</LocalJobTitle>
                  : <JobSelect
                    selected={selections[connection.ats_job.id] ?? connection.local_job ?? null}
                    jobs={localJobs}
                    emptyLabelText={t('ats.SELECT_LOCAL_JOB')}
                    onChange={(_, value) => { handleSelectionChanged(connection.ats_job.id, value) }} />
                }
                <JobActions>
                  {connection.local_job ?
                    <JobActionButton size="small" type="primary" onClick={() => { connection.local_job && history.push(`${ATS_SETTINGS}/jobs/${connection.local_job.id}`) }}>
                      {t('ats.CONFIGURE')}
                    </JobActionButton> :
                    <JobActionButton
                      size="small"
                      type="primary"
                      disabled={!selections[connection.ats_job.id]}
                      onClick={() => {
                        const localJobId = selections[connection.ats_job.id]?.id
                        localJobId && onConnectJob(localJobId, connection.ats_job.id)
                      }}>
                      {t('ats.CONNECT')}
                    </JobActionButton>
                  }
                </JobActions>
              </JobListItem>
            ))}
          </ListWidget>
        </ListContainer>
      </FormItem>
      <RefreshDataSection atsStatus={atsStatus} onCheckAtsStatus={onCheckAtsStatus} />
      <FormItem>
        <FormSubtitle>{t('DANGER_ZONE')}</FormSubtitle>
        <Button className={'ant-btn-dangerous'} loading={loading} onClick={() => { setDisconnectWarningVisible(true) }}>
          {t('ats.DISCONNECT_ATS')}
        </Button>
      </FormItem>
      <WarningText>{t('ats.DISCONNECT_ATS_CONFIRM_TEXT_SHORT')}</WarningText>
      <ConfirmDisconnectAtsModal
        isOpen={disconnectWarningVisible}
        onOk={() => { onDisconnectAts() }}
        onCancel={() => { setDisconnectWarningVisible(false) }} />
    </StyledForm >
  )
}

export default AtsSettingsForm
