import React from 'react';
import { Company } from '../../types';
import { Avatar } from 'antd';
import { getNameFirstLetters } from '../../utils/candidate';
import styled from 'styled-components';
import {RECRUITER} from "../../constants/routes";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import TooltipPrompt from "../../UI/tootlip";

interface RecruiterProps {
  recruiter: { company: Company; image: null | string; name: string, id: string };
  color?: { main: string; light: string };
}
const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;
const RecruiterAvatar = styled(Avatar)`
  min-width: 2.5rem;
  min-height: 2.5rem;
  margin-right: 0.5rem;
  background: #ffffff;
  & > img {
  object-fit: contain;
  }
`;
const Name = styled.h5`
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.43;
  color: #061c2e;
  margin-bottom: 0.25rem;
`;
const CompanyName = styled.p`
  font-size: 0.75rem;
  line-height: 1.33;
  color: #627d95;
  margin-bottom: 0.25rem;
`;
const CandidateRecruiterInfo = ({ recruiter, color }: RecruiterProps) => {
  const { image, company, name } = recruiter;
  const [t] = useTranslation();

  return (
    <Wrapper>
      {image ? (
        <RecruiterAvatar size={'large'} src={image} />
      ) : (
        <RecruiterAvatar
          style={{ backgroundColor: color?.light, color: color?.main }}
          size="large"
        >
          {getNameFirstLetters(name)}
        </RecruiterAvatar>
      )}
      <div>
          <TooltipPrompt title={t('CLICK_TO_SEE_PROFILE')}><Link to={RECRUITER + '/' + recruiter.id}>
            <Name>{name}</Name>
          </Link></TooltipPrompt>
        <CompanyName>{company?.name}</CompanyName>
      </div>
    </Wrapper>
  );
};

export default CandidateRecruiterInfo;
