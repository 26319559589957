import BubbleChart from './BubbleChart';
import { palette } from '../../../constants/palette';
import styled from 'styled-components';

interface ToolsProps {
  data?: { id: string; name: string }[];
}

const ToolWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 350px;
  display: flex;
`;

const ToolSection = ({ data }: ToolsProps) => {

  const toolsCollection = data?.map((tool,i) => {
    const color = palette[i +1 > palette.length ? Math.floor(Math.random() * palette.length) : i];
    return {
      count: 50,
      label: tool?.name,
      textColor: color.main,
      bgColor: color.light,
    };
  });

  return (
    <ToolWrapper>
      <BubbleChart info={toolsCollection || []} />
    </ToolWrapper>
  );
};

export default ToolSection;
