import React, { useCallback, useEffect, useRef, useState } from 'react';
import FormPopUp from '../form/FormPopUp';
import { useTranslation } from 'react-i18next';
import {
  CandidateInfo,
  Certificate,
  City,
  Role,
  Seniority,
  Skill,
  Specialization,
  Education,
} from '../../types';
import { getCandidateListByMatch } from '../../api/candidates';
import CandidateAppointFilter from './CandidateAppointFilter';
import { getAllInfo } from '../../utils/candidate';
import AppointCandidateList from './AppointCandidateList';
import styled from 'styled-components';
import Loader from '../../UI/Loader';

interface CandidateChooseModalProps {
  show: boolean;
  jobId: string;
  recruiterPoints?: number;
  introducedList: string[];
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  setCandidate: React.Dispatch<React.SetStateAction<CandidateInfo | null>>;
  addedCandidate: CandidateInfo | null;
  domains: Specialization[];
  certificates: Certificate[];
  education: Education[],
  seniority: Seniority[];
  cities: City[];
  skills: Skill[];
  roles: Role[];
  candidate: CandidateInfo | null;
}

const Candidates = styled.div`
  margin-top: 1.5rem;
`;
const NoResultText = styled.p`
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.33;
  letter-spacing: 0.96px;
  text-align: center;
  color: #061c2e;
  padding: 1rem;
`;
const CandidateChooseModal = ({
  show,
  setShow,
  cities,
  certificates,
  education,
  seniority,
  domains,
  skills,
  roles,
  jobId,
  recruiterPoints,
  setCandidate,
  candidate,
  introducedList,
  addedCandidate,
}: CandidateChooseModalProps) => {
  const [visible, setVisible] = useState(show);
  const [candidates, setCandidates] = useState<CandidateInfo[]>([]);
  const [nextPage, setNextPage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [candidatesQuery, setCadidatesQuery] = useState('');

  const loader = useRef(null);
  const [t] = useTranslation();

  useEffect(() => {
    if (addedCandidate) {
      setCandidates((prev) => [addedCandidate, ...prev]);
    }
  }, [addedCandidate]);

  useEffect(() => {
    setVisible(show);
  }, [show]);

  useEffect(() => {
    if (!visible) {
      setShow(false);
    }
  }, [visible, setShow]);

  useEffect(() => {
    candidate &&
      setCandidates((prevState) =>
        prevState.map((prev) => {
          if (prev.id === candidate.id) {
            return candidate;
          } else {
            return prev;
          }
        }),
      );
  }, [candidate]);

  const getData = useCallback(
    (query: string = '') => {
      setCadidatesQuery(query); // update query for the loading data on scroll
      setLoading(true);

      getCandidateListByMatch(jobId, '?' + query).then((res) => {
        setCandidates(() => [
          ...getAllInfo({
            candidates: res.data.results,
            cities,
            skills,
            roles,
            domains,
            certificates,
            education,
        }),
        ]);
        setNextPage(res.data.pagination.next);
        setLoading(false);
      });
    },
    [cities, domains, roles, skills, certificates, jobId, education],
  );

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    setCandidates((prevState) =>
      prevState.filter((el) => !introducedList.includes(el.id)),
    );
  }, [introducedList]);

  const handleObserver = useCallback(
    (entities: any) => {
      let query = '?cursor=' + nextPage;

      if (candidatesQuery) {
        query = query.concat(`&${candidatesQuery}`);
      }

      const target = entities[0];
      if (target.isIntersecting && nextPage) {
        getCandidateListByMatch(jobId, query).then((res) => {
          // @ts-ignore
          setCandidates((j) => [
            ...j,
            ...getAllInfo({
              candidates: res.data.results,
              cities,
              skills,
              roles,
              domains,
              certificates: [],
              education,
            }),
          ]);
          setNextPage(res.data.pagination.next);
        });
      }
    },
    [nextPage, cities, skills, roles, domains, jobId, candidatesQuery, education],
  );

  useEffect(() => {
    const current = loader.current;
    let options = {
      root: null,
      rootMargin: '0px 0px 300px 0px',
      threshold: 1.0,
    };
    const observer = new IntersectionObserver(handleObserver, options);

    if (!nextPage) {
      observer.disconnect();
    } else {
      if (loader && current) {
        observer.observe(current as unknown as Element);
      }
    }
    return () => observer.unobserve(current as unknown as Element);
  }, [nextPage, handleObserver]);

  return (
    <FormPopUp
      visible={visible}
      setVisible={setVisible}
      title={t('CHOOSE_CANDIDATE')}
      large={true}
    >
      <CandidateAppointFilter
        domains={domains}
        seniority={seniority}
        cities={cities}
        skills={skills}
        getData={getData}
      />
      <Loader spinning={loading}>
        <Candidates>
          {candidates.map((candidate, index) => (
            <AppointCandidateList
              candidate={candidate}
              recruiterPoints={recruiterPoints}
              setCandidate={setCandidate}
              key={candidate.id + index}
            />
          ))}
          {!loading && !candidates.length && (
            <NoResultText>{t('NO_RESULT')}</NoResultText>
          )}
        </Candidates>
      </Loader>
      <div className="loader" ref={loader} />
    </FormPopUp>
  );
};

export default CandidateChooseModal;
