import React, {useEffect, useState} from 'react';
import PlacesAutocomplete from "react-places-autocomplete";
import styled from "styled-components";
import Input from '../../../UI/inputs/Input';


const AddressInputWrapper = styled.div`
  position: relative;
  width: 100%;
`;
const ResultOptions = styled.div`
  position: absolute;
  top: calc(100% + 0.5rem);
  box-shadow: 0 2px 12px 0 rgba(6, 28, 46, 0.12),
    0 2px 4px 0 rgba(6, 28, 46, 0.1);
  background-color: #ffffff;
  border-radius: 0.5rem;
  width: 100%;
  z-index: 9999;
`;
const OptionsItem = styled.div`
    padding: .625rem 1rem;
    font-size: .875rem;
    line-height: 1.43;
    color: #061c2e;
    width: 100%;
    cursor:pointer;
    &:hover{
    background-color: #f2f6fa;;
    }
`
const AddressAutocomplete = ({value, handleSave, index, handleAddress}: any) => {
    const [search, setSearch] = useState(value);
    useEffect(() => {
        setSearch(value)
    },[value])
    return (
        <>
            <PlacesAutocomplete
                value={search}
                onChange={e => {
                    setSearch(e)
                    index !== undefined && handleSave(index, e)
                    handleAddress !== undefined && handleAddress(e)
                }}
                onSelect={address => {
                    setSearch(address)
                    index !== undefined && handleSave(index, address)
                    handleAddress !== undefined && handleAddress(address)
                }}
            >
                {({getInputProps, suggestions, getSuggestionItemProps}) => (
                    <AddressInputWrapper>
                        <Input
                            size="large"
                            {...getInputProps({
                                className: 'search__input'
                            })}
                        />
                        <ResultOptions>
                            {suggestions.map(suggestion => {
                                return (
                                    <OptionsItem
                                        {...getSuggestionItemProps(suggestion)}
                                        key={suggestion.description}
                                    >
                                        {suggestion.description}
                                    </OptionsItem>
                                );
                            })}
                        </ResultOptions>
                    </AddressInputWrapper>
                )}
            </PlacesAutocomplete>
        </>
    );
};

export default AddressAutocomplete;
