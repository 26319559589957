import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';

import Button from '../../../UI/buttons/Button';
import {ReactComponent as RescheduleIcon} from '../../../icons/reschedule.svg';
import { InterviewRoom } from '../../../types';
import { CANDIDATES } from '../../../constants/routes';
import {
  INTERVIEW_ID_PARAM,
  STAGE_PARAM,
  SHOW_DATE_PROPOSE_PARAM,
  RESCHEDULE_PARAM,
} from '../../../constants/queryParams';

const { confirm } = Modal;

interface RescheduleButtonProps {
  interview:InterviewRoom
  history:any
}

const RescheduleDate = ({interview, history}:RescheduleButtonProps) => {
  const [t] = useTranslation();
  const handleReschedule = () => {
    confirm({
      title: `${t('RESCHEDULE_POPUP_TEXT')}`,
      okText: t('OK_TEXT'),
      okType: 'default',
      cancelText: t('CANCEL_TEXT'),
      cancelButtonProps: {
        danger:true
      },
      onOk() {
        const search = new URLSearchParams(history.location.search);

        const filter  = search.get('filter');

        search.set(INTERVIEW_ID_PARAM, interview?.id);
        search.set(STAGE_PARAM, interview?.current_stage?.stage_name);
        search.set(SHOW_DATE_PROPOSE_PARAM, 'true');
        search.set(RESCHEDULE_PARAM, '1');

        const query = search.toString();

        if (filter){
          history.push(CANDIDATES + '?' + query);
        }else{
          history.push(CANDIDATES + '?filter=in-process&' + query);
        }
      },
    });
  };
  return (
    <Button type="ghost" size="small" icon={<RescheduleIcon/>} onClick={handleReschedule} style={{marginRight:16}}>
      {t('RESCHEDULE_BUTTON_TEXT')}
    </Button>
  );
};

export default RescheduleDate;
