import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import format from 'date-fns/format';
import { message } from 'antd';
import { useHistory } from 'react-router-dom';

import { InterviewRoom } from '../../../../types';
import FormPopUp from '../../../form/FormPopUp';
import NameAvatar from '../../../../UI/NameAvatar';
import Subtitle from '../../../../UI/typography/Subtitle';
import FeedbackForm from '../../../form/FeedbackForm';
import { getFeedback, sendFeedback } from '../../../../api/interviews';
import { useAppSelector } from '../../../../store/hooks';
import { CLIENT_ROLE } from '../../../../constants/roles';
import { CANDIDATES } from '../../../../constants/routes';

import { getRandomColor } from '../../../../constants/palette';
import {
  Header,
  HeaderItem,
  NameWrapper,
  Feature,
  Name,
  Role,
} from './styles';
import { STAGE_PARAM, SHOW_FEEDBACK_PARAM, INTERVIEW_ID_PARAM } from '../../../../constants/queryParams';

interface FeedbackModalProps {
  info: InterviewRoom;
  removeItem: (
    candidateID: string,
    stageName: string,
    interviewId: string,
  ) => void;
  onComplete: (id: string, stageName: string, candidateID: string) => void;
  setShowInterviewStageModal: (show: boolean) => void;
}

const FeedbackModal = ({
  info,
  onComplete,
  removeItem,
  setShowInterviewStageModal,
}: FeedbackModalProps) => {
  const [visible, setVisible] = useState(!!info);
  const [completedFeedback, setCompletedFeedback] = useState(null);
  const [loading, setLoading] = useState<boolean>(false);
  const current = info;
  const mainInterview = current.current_stage?.tasks?.find(
    (task) => task.is_planning_task,
  );

  const { user } = useAppSelector((state) => state.user);
  const isClient = user?.role === CLIENT_ROLE;
  const recruiterFeedbackCompleted = current.current_stage.feedbacks.find(
    (feedback) =>
      feedback.feedback_type === 'recruiter_feedback' && feedback.is_completed,
  );
  const clientFeedbackCompleted = current.current_stage.feedbacks.find(
    (feedback) =>
      feedback.feedback_type === 'client_feedback' && feedback.is_completed,
  );

  const completed = !!(isClient
    ? clientFeedbackCompleted
    : recruiterFeedbackCompleted);
  const [t] = useTranslation();
  const history = useHistory();
  const search = new URLSearchParams(history.location.search);
  const stage = search.get(STAGE_PARAM);

  const hide = useCallback(() => {
    const params = new URLSearchParams(history.location.search);
    params.delete(SHOW_FEEDBACK_PARAM);

    const query = params.toString();
    history.push(CANDIDATES + '?' + query);
  }, [history]);

  useEffect(() => {
    setVisible(!!info);
  }, [info]);

  useEffect(() => {
    if (
      stage &&
      current.current_stage.stage_name.toLowerCase() !== stage.toLowerCase()
    ) {
      hide();
    }
  }, [stage, current, hide]);

  useEffect(() => {
    if (completed) {
      const feedback_id = isClient
        ? clientFeedbackCompleted?.id
        : recruiterFeedbackCompleted?.id;
      getFeedback(current.id, feedback_id as string).then((res) =>
        setCompletedFeedback(res.data),
      );
    }
  }, [
    completed,
    clientFeedbackCompleted,
    recruiterFeedbackCompleted,
    current,
    isClient,
  ]);

  useEffect(() => {
    if (!visible) {
      hide();
    }
  }, [visible, hide]);

  const onFinish = (data: any) => {
    setLoading(true);
    sendFeedback(current.id, data)
      .then(() => {
        message.success(t('FEEDBACK_SENT'));
        if (data.decision === 'positive') {
          onComplete(
            current.id,
            current.current_stage?.stage_name.toLowerCase(),
            info.id,
          );
          
          hide();
        } else {
          removeItem(
            info.id,
            current.current_stage?.stage_name.toLowerCase(),
            current.id,
          );
          
          // close all modals
          setShowInterviewStageModal(false);
          const params = new URLSearchParams(history.location.search);
          params.delete(INTERVIEW_ID_PARAM);
          params.delete(STAGE_PARAM);
          params.delete(SHOW_FEEDBACK_PARAM);
        
          const query = params.toString();
          history.push(CANDIDATES + '?' + query);
        }
        setLoading(false);
        hide();
      })
      .catch(() => setLoading(false));
  };

  return (
    <FormPopUp
      visible={visible}
      onClose={() => {
        hide();
      }}
      setVisible={setVisible}
      title={`${
        current.current_stage.stage_name ? current.current_stage.stage_name : ''
      } ${t('FEEDBACK')}`}
    >
      <Header>
        <HeaderItem>
          {!isClient && (
            <>
              <NameAvatar
                name={current.job.company.name}
                color={getRandomColor()}
                image={current.job.company.image && current.job.company.image}
              />
              <NameWrapper className="row">
                <Name>{current.job.company.name}</Name>
              </NameWrapper>
              <Feature>&</Feature>
            </>
          )}
          <NameAvatar
            name={info.candidate.name}
            image={undefined}
            color={getRandomColor()}
          />
          <NameWrapper>
            <Name>{info.candidate.name}</Name>
            {isClient && <Role>{info.candidate.current_role}</Role>}
          </NameWrapper>
        </HeaderItem>
        <HeaderItem>
          <NameWrapper>
            <Name>
              {(mainInterview && mainInterview.time_from && mainInterview.time_to) ? (
                <>
                  {format(new Date(mainInterview.time_from), 'MMMM d, yyyy')},{' '}
                  {format(new Date(mainInterview.time_from), 'H:mm')} -{' '}
                  {format(new Date(mainInterview.time_to), 'H:mm')}
                </>
              ) : '-'}
            </Name>
            <Role>{mainInterview && mainInterview?.place}</Role>
          </NameWrapper>
        </HeaderItem>
      </Header>
      <Subtitle title={t('FILL_IN')} />
      <FeedbackForm
        onComplete={onFinish}
        completed={completed}
        isClient={isClient}
        data={completedFeedback}
        loading={loading}
      />
    </FormPopUp>
  );
};

export default FeedbackModal;
