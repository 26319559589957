import React, { useEffect, useState } from 'react';
import FormItem from './FormItem';
import { maxLengthRule, requiredRule } from '../../utils/validationRules';
import { Col, Form, message, Row, Select } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import Subtitle from '../../UI/typography/Subtitle';
import { getRecruiterCompanyByName } from '../../api/company';
import RadioButtonTab from '../../UI/buttons/RadioButtonTab';
import { getCityList } from '../../api/regions';
import Button from '../../UI/buttons/Button';
import { editRecruiter, sendRecruiterInvitation } from '../../api/recruiter';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from '../auth/LoginForm';
import { Recruiter } from '../../types';
import { useHistory } from 'react-router-dom';
import { RECRUITERS } from '../../constants/routes';
import SelectInput from '../../UI/inputs/Select';
import Input from '../../UI/inputs/Input';

interface CompanyProps {
  id: string;
  name: string;
}
interface RecruiterAddProps {
  open?: (open: boolean) => void;
  visible?: boolean;
  edit?: boolean;
  user?: Recruiter;
}

const { Option } = Select;
const RecruiterAddForm = ({ open, edit, user, visible }: RecruiterAddProps) => {
  const [companies, setCompanies] = useState<{ id: string; name: string }[]>(
    [],
  );
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [newCompany, setNewCompany] = useState('');
  const [showCompany, setShowCompany] = useState<boolean>(true);
  const [cities, setCities] = useState<{ id: string; name: string }[]>([]);
  const [phone, setPhone] = useState<string>('');
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const history = useHistory();

  const handleSearch = async (value: string) => {
    await getRecruiterCompanyByName(value).then((res) =>
      value
        ? setCompanies([{ id: ' ', name: value }, ...res.data])
        : setCompanies([...res.data]),
    );
  };
  useEffect(() => {
    getCityList().then((res) => setCities(res.data));
  }, []);
  useEffect(() => {
    if (!visible) {
      form.resetFields();
      setError('');
    }
  }, [visible, form]);
  const onFinish = (values: any) => {
    setLoading(true)
    let newValue = { ...values,phone };
    if (
      values.company &&
      !values.company.trim() &&
      values.recruiter_type !== 'freelance'
    ) {
      newValue.newCompany = newCompany;
    }
    if (edit && user) {
      editRecruiter(user.id, {
        name: values.name,
        title: values.title,
        phone,
        preference_relevance_interval: values.preference_relevance_interval,
        city: values.city,
      }).then((res) => {
        const params = new URLSearchParams(history.location.search);
        params.delete('recruiter');
        const query = params.toString();
        history.push(`${RECRUITERS}?${query}`);
        message.success(t('INFORMATION_SAVE_SUCCESS'), 5);
        setLoading(false)
      }).catch(() => setLoading(false));
    } else {
      sendRecruiterInvitation(newValue)
        .then((res) => {
          message.success(res.data.detail, 5);
          setLoading(false)
          open && open(false);
          form.resetFields();
        })
        .catch((e) =>
          {
            setLoading(false)
            e.response?.data.message
            ? setError(e.response?.data.message)
            : setError('Something went wrong')
          },
        );
    }
  };
  const handleValueChange = (e: any) => {
    if (e.recruiter_type) {
      if (e.recruiter_type === 'freelance') {
        setShowCompany(false);
      } else {
        setShowCompany(true);
      }
    }
  };
  const handleFormChange = (e: any) => {
    if (e.target.id === 'add-recruiter_company') {
      setNewCompany(e.target.value);
    }
  };
  useEffect(() => {
    if(user) {
      form.setFieldsValue({
        name: user.name,
        city: user.city?.id,
        title: user.title,
        company: user.company?.name,
        recruiter_type: user.recruiter_type,
        email: user.email,
      });
      setPhone(user.phone)
    }

    user && user.recruiter_type === 'freelance'
      ? setShowCompany(false)
      : setShowCompany(true);
  }, [user, form]);
  return (
    <Form
      scrollToFirstError={true}
      layout="vertical"
      name="add-recruiter"
      onFinish={onFinish}
      onValuesChange={handleValueChange}
      onChange={handleFormChange}
      form={form}
    >
      {error && <ErrorMessage message={error} type="error" />}
      <Subtitle title={t('PERSONAL_INFO_TITLE')} />
      <FormItem
        label={t('NAME_LABEL')}
        name="name"
        rules={[...requiredRule(t), ...maxLengthRule(128, t)]}
      >
        <Input size="large" />
      </FormItem>
      <FormItem className="no-margin">
        <Row>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 11 }}
            md={{ span: 24 }}
            lg={{ span: 11 }}
          >
            <FormItem
              label={t('TITLE_TEXT')}
              name="title"
              rules={[...requiredRule(t), ...maxLengthRule(255, t)]}
            >
              <Input size="large" />
            </FormItem>
          </Col>
          {showCompany && (
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 11, offset: 2 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 11, offset: 2 }}
            >
              <FormItem
                label={t('COMPANY_TITLE')}
                name="company"
                rules={requiredRule(t)}
              >
                <SelectInput
                  showSearch
                  allowClear
                  size="large"
                  defaultActiveFirstOption={false}
                  showArrow={false}
                  filterOption={false}
                  onSearch={handleSearch}
                  notFoundContent={null}
                  disabled={edit}
                >
                  {companies.map((company: CompanyProps) => (
                    <Option key={company.id} value={company.id}>
                      {company.name}
                    </Option>
                  ))}
                </SelectInput>
              </FormItem>
            </Col>
          )}
        </Row>
      </FormItem>
      <FormItem name="recruiter_type" rules={requiredRule(t)}>
        <RadioButtonTab
          size={'large'}
          disabled={edit}
          items={[
            { name: t('FREELANCE_TITLE'), value: 'freelance' },
            { name: t('INTERIM_TITLE'), value: 'interim' },
            { name: t('AGENCY_TITLE'), value: 'agency' },
          ]}
        />
      </FormItem>
      <FormItem
        label={t('LOCATION_CITY_LABEL')}
        name="city"
        rules={requiredRule(t)}
      >
        <SelectInput
          size="large"
          filterOption={true}
          suffixIcon={<CaretDownOutlined style={{ color: '#000' }} />}
        >
          {cities.map((city) => (
            <Option key={city.id} value={city.id}>
              {city.name}
            </Option>
          ))}
        </SelectInput>
      </FormItem>
      <FormItem className="no-margin">
        <Row>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 11 }}
            md={{ span: 24 }}
            lg={{ span: 11 }}
          >
            <FormItem
              label={t('EMAIL_LABEL')}
              name="email"
              rules={[
                {
                  type: 'email',
                  message: t('INVALID_EMAIL_ERROR'),
                  required: true,
                },
              ]}
            >
              <Input size="large" disabled={edit} />
            </FormItem>
          </Col>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 11, offset: 2 }}
            md={{ span: 24, offset: 0 }}
            lg={{ span: 11, offset: 2 }}
          >
            <FormItem
              label={t('PHONE_TEXT')}
              rules={requiredRule(t)}
            >
              <Input
                size="large"
                onChange={(e) => {
                  // if (e.target.value && (e.target.value[0] === '+' || typeof e.target.value[0] == 'number')){
                  //   setPhone(e.target.value);
                  // }
                  if (e.target.value){
                    const regex = /^[+]?\d+$/;
                    if (regex.test(e.target.value)) {
                      setPhone(e.target.value);
                    }
                  }else{
                    setPhone(e.target.value);
                  }
                }}
                value={phone}
              />
            </FormItem>
          </Col>
        </Row>
      </FormItem>
      <FormItem>
        <Button type="primary" htmlType="submit" loading={loading}>
          {t(edit ? 'SAVE_TITLE' : 'MAGIC_LINK_TITLE')}
        </Button>
      </FormItem>
    </Form>
  );
};

export default RecruiterAddForm;
