import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';

import { ReactComponent as DownloadIcon } from '../../../../../icons/download.svg';
import { ReactComponent as LinkedinIcon } from '../../../../../icons/linkedin-filled.svg';
import NameAvatar from '../../../../../UI/NameAvatar';
import {
  Wrapper,
  Col1,
  Col2,
  Title,
  SubTitle,
  ActionLink,
} from './styles';

interface ICandidateInfo {
  name: string,
  role?: string,
  company?: string,
  cv?: string,
  linkedin?: string,
  handleClick: () => void,
}

const CandidateInfo = ({
  name,
  role,
  company,
  cv,
  linkedin,
  handleClick
}: ICandidateInfo) => {
  const [t] = useTranslation();

  return (
    <Wrapper>
      <Col1>
        <NameAvatar name={name} />
      </Col1>

      <Col2>
        <Tooltip title={t('CANDIDATE_NAME_TOOLTIP')}>
          <Title onClick={handleClick}>{name}</Title>
        </Tooltip>
        <SubTitle>{role}</SubTitle>
        <SubTitle>{company}</SubTitle>
      </Col2>

      <div>
        {cv && (
          <ActionLink href={cv} target="_blank" rel="noreferrer">
            <DownloadIcon />
            {t('DOWNLOAD_CV_TITLE')}
          </ActionLink>
        )}
        {linkedin && (
          <ActionLink href={linkedin} target="_blank" rel="noreferrer">
            <LinkedinIcon /> {t('LINKEDIN_PROFILE')}
          </ActionLink>
        )}
      </div>
    </Wrapper>
  )
};

export default React.memo(CandidateInfo);
