import { useRef, useState } from 'react';
import Button from '../../UI/buttons/Button';
import { ReactComponent as ExportIcon } from '../../icons/export-icon.svg';
import { useTranslation } from 'react-i18next';
import { exportCalendarEvent } from '../../api/events';
import styled from 'styled-components';

interface ExportProps {
  event: string;
}

const StyledLink = styled.a`
  display: none;
`;

const ExportEvent = ({ event }: ExportProps) => {
  const [url, setUrl] = useState('');
  const downloadRef = useRef<HTMLAnchorElement>(null);
  const [t] = useTranslation();

  const handleExport = () => {
    exportCalendarEvent(event).then((res) => {
      const blob = new Blob([res.data]); // Step 3
      const fileDownloadUrl = URL.createObjectURL(blob);
      setUrl(fileDownloadUrl);
      downloadRef && downloadRef?.current?.click();
      setUrl('');
    });
  };

  return (
    <>
      <Button
        type="ghost"
        size="small"
        icon={<ExportIcon />}
        onClick={handleExport}
      >
        {t('EXPORT_TEXT')}
      </Button>
      <StyledLink download="event.ics" href={url} ref={downloadRef}>
        Link
      </StyledLink>
    </>
  );
};

export default ExportEvent;
