import React from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import styled from 'styled-components';
import { ReactComponent as SettingsIcon } from '../../icons/settings.svg';
import { ReactComponent as CandidatesIcon } from '../../icons/candidates.svg';
import { ReactComponent as WalletIcon } from '../../icons/wallet.svg';
import { ReactComponent as NotificationIcon } from '../../icons/notification.svg';
import { ReactComponent as EuroIcon } from '../../icons/euro.svg';
import { ReactComponent as LogoutIcon } from '../../icons/logout.svg';
import LanguageSwitcher from '../../components/auth/LanguageSwitcher';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { logoutUser } from '../../store/userSlice';
import {
  ADMIN_ROLE,
  // CLIENT_ROLE,
  RECRUITER_ROLE,
} from '../../constants/roles';
import {
  CANDIDATES_SPECIFICATION_SETTINGS,
  NOTIFICATIONS_SETTINGS,
  PAYMENT_INFO_SETTINGS, PAYMENTS,
  //PAYMENT_INFO_SETTINGS,
  PROFILE_SETTINGS,
} from '../../constants/routes';

interface ProfileMenuPopupProps {
  show: boolean;
}

const ProfileMenuContainer = styled.div`
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 2rem 5rem 0 rgba(6, 28, 46, 0.2),
    0 1rem 1.25rem 0 rgba(6, 28, 46, 0.16);
  background-color: #ffffff;
  position: absolute;
  bottom: 0;
  left: calc(100% + 0.75rem);
  min-width: 17rem;
  z-index: 9999999;
`;

const ProfileMenu = styled.ul`
  list-style: none;
`;

const ProfileMenuItem = styled.li`
  display: flex;
  align-items: center;
  color: #061c2e;
  margin-bottom: 1rem;

  svg {
    margin-right: 1rem;
    min-width: 24px;
  }

  path {
    stroke: #627d95;
  }
`;

const LogoutButton = styled(Button)`
  padding-left: 0;
  font-weight: normal !important;
  margin-top: 1rem;
  display: flex;
  align-items: center;
`;

const Divider = styled.div`
  width: 100%;
  margin-top: 1rem;
  border-bottom: 1px solid #dae5ec;
`;

const ProfileMenuPopup = ({ show }: ProfileMenuPopupProps) => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { user } = useAppSelector((state) => state.user);
  const isAdmin = user?.role === ADMIN_ROLE;
  // const isClient = user?.role === CLIENT_ROLE;
  const isRecruiter = user?.role === RECRUITER_ROLE;

  const handleLogout = () => {
    dispatch(logoutUser());
    const confirmLinkedin = localStorage.getItem('hp_linkedin_login');
    if (confirmLinkedin && confirmLinkedin === 'true') {
      localStorage.removeItem('hp_linkedin_login');
      window.open('https://linkedin.com/m/logout');
    }
  };

  return (
    <ProfileMenuContainer style={{ display: show ? 'block' : 'none' }}>
      <ProfileMenu>
        {!isAdmin && (
          <ProfileMenuItem onClick={() => history.push(PROFILE_SETTINGS)}>
            <SettingsIcon /> {t('PROFILE_SETTINGS')}
          </ProfileMenuItem>
        )}
        {isRecruiter && (
          <ProfileMenuItem
            onClick={() => history.push(CANDIDATES_SPECIFICATION_SETTINGS)}
          >
            <CandidatesIcon />
            {t('CANDIDATE_TITLE')}
          </ProfileMenuItem>
        )}
        {!isAdmin && (
          <ProfileMenuItem onClick={() => history.push(PAYMENT_INFO_SETTINGS)}>
            <WalletIcon />
            {t('PAYMENT_INFO')}
          </ProfileMenuItem>
        )}
        {!isAdmin && (
          <ProfileMenuItem onClick={() => history.push(NOTIFICATIONS_SETTINGS)}>
            <NotificationIcon />
            {t('NOTIFICATION_SETTINGS')}
          </ProfileMenuItem>
        )}
         {!isAdmin && (
          <ProfileMenuItem onClick={() => history.push(PAYMENTS)}>
            <EuroIcon />
            {t('PAYMENTS')}
          </ProfileMenuItem>
        )}
      </ProfileMenu>

      <Divider />
      <LanguageSwitcher variant="dark" />
      <Divider />
      <LogoutButton
        type="link"
        icon={<LogoutIcon style={{marginRight: 16}}/>}
        onClick={() => handleLogout()}
        danger
      >
        {t('LOGOUT')}
      </LogoutButton>
    </ProfileMenuContainer>
  );
};

export default ProfileMenuPopup;
