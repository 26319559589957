import React, { useRef, useState } from 'react';
import { ReactComponent as MoreIcon } from '../../icons/more-icon-horizontal.svg';
import styled from 'styled-components';
import CandidateActionModal from './CandidateActionModal';
import { Modal, Popover } from 'antd';
import useClickAwayListener from '../../hooks/useClickAwayListener';
import { CandidateInfo } from '../../types';
import { useTranslation } from 'react-i18next';
import { deleteCandidate } from '../../api/candidates';

interface CandidateActionProps {
  candidate: CandidateInfo;
  onAction: React.Dispatch<React.SetStateAction<CandidateInfo[]>>;
}

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1rem;
`;
const MoreButton = styled(MoreIcon)`
  cursor: pointer;
  color: #aebeca;
  font-size: 1.5rem;
  &:hover {
    color: #34ce72;
  }
`;
const { confirm } = Modal;
const CandidateActionButton = ({
  candidate,
  onAction,
}: CandidateActionProps) => {
  const [show, setShow] = useState(false);
  const [t] = useTranslation()
  const wrapperRef = useRef(null);
  useClickAwayListener(wrapperRef, () => {
    if (show) setShow(false);
  });
  const onDelete = () => {
    confirm({
      title: `${t('CANDIDATE_DELETE_CONFIRM')}?`,
      okText: t('CONFIRM_TITLE'),
      okType: 'danger',
      cancelText: t('REJECT_TITLE'),
      cancelButtonProps: {
        type: 'default',
      },
      onOk() {
        deleteCandidate(candidate.id).then(() =>
          onAction((st: any) => st.filter((el: any) => el.id !== candidate.id)),
        );
      },
    });
  };
  return (
    <ButtonWrapper>
      <Popover
        visible={show}
        placement="bottomRight"
        content={<CandidateActionModal candidate={candidate} onDelete={onDelete}/>}
      >
        <MoreButton onClick={() => setShow(!show)} ref={wrapperRef}/>
      </Popover>
    </ButtonWrapper>
  );
};

export default CandidateActionButton;
