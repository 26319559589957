import React from 'react';
import styled from 'styled-components';
import { ReactComponent as NotFountIcon } from '../icons/404.svg';
import { useTranslation } from 'react-i18next';
import Button from '../UI/buttons/Button';
import { DASHBOARD } from '../constants/routes';
import { useHistory } from 'react-router-dom';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
`;
const Text = styled.h3`
  margin: 2rem 0 4rem;
  font-size: 1.5rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #aebeca;
`;
const NotFound = () => {
  const [t] = useTranslation();
  const history = useHistory();
  return (
    <Wrapper>
      <NotFountIcon />
      <Text>{t('NOT_FOUND')}</Text>
      <Button onClick={() => history.push(DASHBOARD)}>
        {t('BACK_DASHBOARD')}
      </Button>
    </Wrapper>
  );
};

export default NotFound;
