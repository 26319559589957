import { configureStore } from '@reduxjs/toolkit';
import userReducer from './userSlice';
import messagesReducer from './messagesSlice';
import notificationsReducer from './notificationsSlice';

const store = configureStore({
  reducer: {
    user: userReducer,
    messages: messagesReducer,
    notifications: notificationsReducer,
  },
  devTools: true,
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
