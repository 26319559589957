import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import format from 'date-fns/format';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import addHours from 'date-fns/addHours';
import isBefore from 'date-fns/isBefore'

import { InterviewRoom } from '../../../../../types';
import { CANDIDATES } from '../../../../../constants/routes';
import {
  SHOW_DATE_PROPOSE_PARAM,
  SHOW_FEEDBACK_PARAM,
  SHOW_OFFER_PARAM,
  INTERVIEW_ID_PARAM,
  STAGE_PARAM,
  //RESCHEDULE_PARAM,
} from '../../../../../constants/queryParams';
import {
  COMPLETED,
  PLANNING,
  WAITING_RECRUITER,
  WAITING_CLIENT,
  POSITIVE,
  WAITING_RECRUITER_OFFER,
} from '../../../../../constants/statuses';
import differenceInHours from 'date-fns/differenceInHours';


const { confirm } = Modal;

/* some logic:
  isPlanning - the candidate just have been set to the interview stage, client must propose some dates
  waitingRecruiter - the client already propose some dates, time for the recruiter to accept one

  current_stage.tasks by default
  0. task_type === 'Main feedback', status === 'in_progress'
  1. task_type === 'Planning Interview', status === 'in_progress'
*/

interface IuseTasks {
  interview: InterviewRoom | { [key:string]: any };
}

const useTasks = ({ interview }: IuseTasks) => {
  const history = useHistory();
  const [t] = useTranslation();

  const currentStageName = interview?.current_stage?.stage_name;
  const startDate = interview?.current_stage?.created_at;
  const updateDate = interview?.current_stage?.updated_at;
  const feedBackTime = interview?.job?.feedback_time;

  const checkFeedBackTime = useCallback((feedback) => {
    const isCurrentStageChanged = new Date(startDate?.slice(0, -1)).getHours() !== new Date(updateDate?.slice(0, -1)).getHours()
      let updateFeedback = differenceInHours(
        addHours(new Date(updateDate), feedBackTime),
        new Date(),
        {
          roundingMethod: 'ceil',
        },
      );
      return isCurrentStageChanged ? updateFeedback : feedback
  }, [startDate, updateDate, feedBackTime]);

  const isPlanning = interview?.current_stage?.status === PLANNING;
  const waitingRecruiter = interview?.current_stage?.status === WAITING_RECRUITER;
  const waitingClient = interview?.current_stage?.status === WAITING_CLIENT;

  const interviewTask = interview?.current_stage?.tasks?.find((task: any) => task.task_type === 'Plan Interview');
  const planningInterviewIsCompleted = interviewTask?.status === COMPLETED;

  const interviewComment = interviewTask?.comment;
  const interviewLocation = interviewTask?.place;
  const interviewTimeFrom = interviewTask?.time_from;
  const interviewTimeTo = interviewTask?.time_to;

  const clientFeedback = interview?.current_stage?.feedbacks?.find((fb: any) => fb.feedback_type === 'client_feedback');
  const recruiterFeedback = interview?.current_stage?.feedbacks?.find((fb: any) => fb.feedback_type === 'recruiter_feedback');

  const clientFeedbackIsCompleted = clientFeedback?.is_completed;
  const recruiterFeedbackIsCompleted = recruiterFeedback?.is_completed;

  const isClientFeedbackDecisionPositive = clientFeedback?.decision === POSITIVE;
  const isRecruiterFeedbackDecisionPositive = recruiterFeedback?.decision === POSITIVE;
  const isBothSidePositive = isClientFeedbackDecisionPositive && isRecruiterFeedbackDecisionPositive;

  const isOfferWaitingForRucruiter = interview?.current_offer?.status === WAITING_RECRUITER_OFFER;
  const isOfferCompleted = interview?.current_offer?.status === COMPLETED;

  const interviewDate = useMemo(() => {
    if (interviewTimeFrom) {
      const day = format(
        new Date(interviewTimeFrom),
        'do',
      );

      const dateAndTime = format(
        new Date(interviewTimeFrom),
        'MMMM yyyy kk:mm',
      );

      return `${day} of ${dateAndTime}`;
    }
    return '';
  }, [interviewTimeFrom]);

  const interviewIsDone = interviewTimeFrom ? isBefore(new Date(interviewTimeFrom), new Date()) : false;

  /* calculate the client feedback time start */
  const clientFeedbackTime = interview?.job?.feedback_time;
  const stageCreatedAt = interview?.current_stage?.created_at;
  const offerCreatedAt = interview?.current_offer?.created_at;

  const feedbackTime = useMemo(() => {
    // time for offer stage is calculated from the different source
    const startedAt = offerCreatedAt ? offerCreatedAt : stageCreatedAt;

    return differenceInHours(
      addHours(new Date(startedAt), clientFeedbackTime),
      new Date(),
      {
        roundingMethod: 'ceil',
      },
    );
  }, 
  [
    clientFeedbackTime,
    stageCreatedAt,
    offerCreatedAt,
  ]);

  const afterInterviewFeedbackTime = useMemo(() => {
    return differenceInHours(
      addHours(new Date(interviewTimeTo), clientFeedbackTime),
      new Date(),
      {
        roundingMethod: 'ceil',
      },
    )
  }, [interviewTimeTo, clientFeedbackTime]);
  /* calculate the client feedback time end */

  const handleShowDateProposeModal = useCallback(() => {
    const search = new URLSearchParams(history.location.search);

    search.set(SHOW_DATE_PROPOSE_PARAM, 'true');

    const query = search.toString();
    history.push(CANDIDATES + '?' + query);
  }, [history]);

  const handleShowFeedbackModal = useCallback(() => {
    const search = new URLSearchParams(history.location.search);
    search.set(SHOW_FEEDBACK_PARAM, 'true');

    const query = search.toString();
    history.push(CANDIDATES + '?' + query);
  }, [history]);

  const handleShowOfferModal = useCallback(() => {
    const search = new URLSearchParams(history.location.search);
    search.set(SHOW_OFFER_PARAM, interview?.candidate?.id);

    const query = search.toString();
    history.push(CANDIDATES + '?' + query);
  }, [history, interview]);

  const handleSetInterview = useCallback(() => {
    confirm({
      title: `${t('RESCHEDULE_POPUP_TEXT')}`,
      okText: t('OK_TEXT'),
      okType: 'default',
      cancelText: t('CANCEL_TEXT'),
      cancelButtonProps: {
        danger:true
      },
      onOk() {
        const search = new URLSearchParams(history.location.search);

        search.set(INTERVIEW_ID_PARAM, interview?.id);
        search.set(STAGE_PARAM, interview?.current_stage?.stage_name);
        search.set(SHOW_DATE_PROPOSE_PARAM, 'true');
        //search.set(RESCHEDULE_PARAM, '1');

        const query = search.toString();

        history.push(CANDIDATES + '?filter=in-process&' + query);
      },
    });
  }, [history, t, interview?.current_stage?.stage_name, interview?.id]);

  return {
    actions: {
      handleShowDateProposeModal,
      handleShowFeedbackModal,
      handleShowOfferModal,
      handleSetInterview,
      checkFeedBackTime,
    },
    data: {
      currentStageName,
      isPlanning,
      waitingRecruiter,
      waitingClient,
      planningInterviewIsCompleted,
      interviewComment,
      interviewLocation,
      interviewDate,
      feedbackTime,
      clientFeedbackIsCompleted,
      recruiterFeedbackIsCompleted,
      isBothSidePositive,
      clientFeedbackTime,
      isOfferWaitingForRucruiter,
      isOfferCompleted,
      afterInterviewFeedbackTime,
      interviewIsDone,
      startDate,
      updateDate,
      feedBackTime,
    }
  };
};

export default useTasks;
