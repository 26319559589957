import React, { useEffect } from 'react';
import { message } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { confirmLoginWithGoogle } from '../store/userSlice';
import { DASHBOARD, LOGIN } from '../constants/routes';
import { useAppDispatch } from '../store/hooks';

const PageWrapper = styled.section`
  background: #0c1e2d;
  width: 100%;
  display: flex;
  min-height: 100vh;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export interface UserInterface {
  city: string | null;
  company: object | null;
  department: string;
  email: string;
  id: string;
  image: string | null;
  locations: [] | null;
  name: string;
  phone: string;
  preference_relevance_interval: null;
  recruiter_type: null;
  role: string;
  seniority: string | null;
  specializations: null;
  title: string;
}

const InvitationConfirm = (props: any) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const location = useLocation();
  useEffect(() => {
    const confirmLogin = async () => {
      const search = location.search;
      if (search) {
        const user = await dispatch(confirmLoginWithGoogle(search));
        if (user.payload?.user) {
          history.push(DASHBOARD);
        }
        if (user.payload?.error) {
          message.error(user.payload.error, 5);
          history.push(LOGIN);
        }
      }
    };
    confirmLogin();
  }, [dispatch, location, history]);

  return <PageWrapper></PageWrapper>;
};

export default InvitationConfirm;
