import { useTranslation } from 'react-i18next';

import Button from '../../../../../../UI/buttons/Button';
import {
  Item,
  SelectableItem,
  ItemName,
  Info,
  ButtonWrap,
} from './styles';

interface Iprops {
  name: string,
  label: string,
  isCurrent?: boolean,
  isSelected?: boolean,
  disabled?: boolean,
  setSelectedStageid: (id: string) => void,
  id: string,
  handleSwitchStage: (
    nextStageName: string,
  ) => void,
  blockOffSyncActions?: boolean,
}

const TimelineItem = ({
  name,
  label,
  isCurrent,
  isSelected,
  disabled,
  setSelectedStageid,
  id,
  handleSwitchStage,
  blockOffSyncActions,
}: Iprops) => {
  const [t] = useTranslation();

  const handleClick = () => setSelectedStageid(id);

  const handleSwitch = () => handleSwitchStage(name);

  return (
    <Item>
      <SelectableItem isCurrent={isCurrent} disabled={disabled}>
        <input
          type="checkbox"
          onClick={handleClick}
          disabled={disabled}
        />
      </SelectableItem>

      <ItemName>{label}</ItemName>

      {isCurrent && (
        <Info>{t('CURRENT_STAGE')}</Info>
      )}

      {!isCurrent && isSelected && (
        <ButtonWrap>
          <Button
            onClick={handleSwitch}
            size="small"
            type="primary"
            disabled={blockOffSyncActions}
          >
            {t('SWITCH_STAGE')}
          </Button>
        </ButtonWrap>
      )}
    </Item>
  );
};

export default TimelineItem;