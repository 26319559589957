import { Form, Radio } from 'antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { InterviewRoom, ProposedDate } from '../../types';
import FormItem from './FormItem';
import RadioButtonDark from '../../UI/radioButton/RadioButtonDark';
import Button from '../../UI/buttons/Button';
import { requiredRule } from '../../utils/validationRules';
import { useAppSelector } from '../../store/hooks';
import { CLIENT_ROLE, RECRUITER_ROLE, ADMIN_ROLE } from '../../constants/roles';
import { WAITING_CLIENT, WAITING_RECRUITER } from '../../constants/statuses';
import { ErrorMessage } from '../auth/LoginForm';
import TextWithUrl from '../../UI/typography/TextWithUrl';

interface ConfirmProps {
  dates: ProposedDate[];
  interview: InterviewRoom;
  loading: boolean;
  onConfirm: (id: string) => void;
}
const NoResult = styled.p`
  font-size: 0.75rem;
  font-style: normal;
  line-height: 1.33;
  color: #061c2e;
`;

const Title = styled.h5`
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.43;
  color: #061c2e;
  margin-bottom: 0.25rem;
`;

const Description = styled.h5`
  font-size: 0.75rem;
  line-height: 1.33;
  color: #627d95;
  margin: 0;
`;

const Text = styled.div`
  margin-left: 0.5rem;
`;

const CommentsTitle = styled.h5`
  padding-top: 0.5rem;
  font-size: 10px;
  font-weight: bold;
  line-height: 1.6;
  letter-spacing: 0.8px;
  text-align: left;
  color: #061c2e;
  margin-bottom: 8px;
  text-transform: uppercase;
`;

const ConfirmProposeDate = ({
  dates,
  onConfirm,
  interview,
  loading,
}: ConfirmProps) => {
  const [t] = useTranslation();
  const { user } = useAppSelector((state) => state.user);

  const planningTask = interview.current_stage?.tasks.find(
    (task) => task.is_planning_task,
  );

  const waitingForRecruiter =
    interview.current_stage?.status === WAITING_RECRUITER;

  const waitingForClient = interview.current_stage?.status === WAITING_CLIENT;

  const isClient =
    user?.role === CLIENT_ROLE || planningTask?.email === user?.email;

  const isRecruiter =
    user?.role === RECRUITER_ROLE && planningTask?.email !== user?.email;

  const onFinish = (values: any) => {
    if (values.date) {
      onConfirm(values.date);
    }
  };

  return dates.length ? (
    <Form onFinish={onFinish} scrollToFirstError={true}>
      <FormItem name="date" rules={requiredRule(t)}>
        <Radio.Group name="propose_date" style={{ width: '100%' }}>
          {dates.map((date) => (
            <RadioButtonDark
              value={date.id}
              key={date.id}
              disabled={
                (isClient && waitingForRecruiter) ||
                (isRecruiter && waitingForClient)
              }
            >
              <Text>
                <Title>
                  {moment(date.time_from).format('MMMM D, YYYY')},{' '}
                  {moment(date.time_from).format('H:mm')} -{' '}
                  {moment(date.time_to).format('H:mm')}
                </Title>
                <Description>{date.place}</Description>
              </Text>
            </RadioButtonDark>
          ))}
        </Radio.Group>
      </FormItem>
      
      <FormItem>
        <CommentsTitle>{t('COMMENTS')}</CommentsTitle>
        <TextWithUrl tagName="p" text={dates[0]?.comment} />
      </FormItem>
      {isClient && waitingForRecruiter ? (
        <ErrorMessage message={t('WAITING_RECRUITER')} type="success" />
      ) : isRecruiter && waitingForClient ? (
        <ErrorMessage message={t('WAITING_CLIENT')} type="success" />
      ) : (
        ''
      )}
      {/* for now, hide this feature from admin */}
      {user?.role !== ADMIN_ROLE && (
        <FormItem>
          <Button
            style={{ marginTop: '1rem' }}
            type="primary"
            htmlType="submit"
            disabled={
              (isClient && waitingForRecruiter) ||
              (isRecruiter && waitingForClient)
            }
          >
            {t('CONFIRM_DATE')}
          </Button>
        </FormItem>
      )}
    </Form>
  ) : !loading ? (
    <NoResult>{t('NO_RESULT')}</NoResult>
  ) : (
    <></>
  );
};

export default ConfirmProposeDate;
