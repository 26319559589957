import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import  { openInNewTab } from '../../utils/routes';
import {PartnerProgram} from "../../types";
import {getPartnerPrograms} from '../../api/partner-programs';
import { useAppSelector } from '../../store/hooks';

interface LinksMenuPopupProps {
  show: boolean;
}

const LinksMenuContainer = styled.div`
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 2rem 5rem 0 rgba(6, 28, 46, 0.2),
    0 1rem 1.25rem 0 rgba(6, 28, 46, 0.16);
  background-color: #ffffff;
  position: absolute;
  bottom: 0;
  left: calc(100% + 0.75rem);
  min-width: 17rem;
  z-index: 9999999;
`;

const LinksMenu = styled.ul`
  list-style: none;
`;

const LinksMenuItem = styled.li`
  display: flex;
  align-items: center;
  color: #061c2e;
  margin-bottom: 1rem;

  svg {
    margin-right: 1rem;
    min-width: 24px;
  }

  path {
    stroke: #627d95;
  }
`;

const Image = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  min-width: 1.5rem;
  min-height: 1.5rem;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 0.5rem;
  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;


const ProfileMenuPopup = ({ show }: LinksMenuPopupProps) => {
  const [partnerPrograms, setPartnerPrograms] = useState<PartnerProgram[]>([]);

  const { user } = useAppSelector((state) => state.user);
  useEffect(() => {
    getData();
  }, []);
  const getData = (query: string = '') => {
    getPartnerPrograms(query).then((res) => {
      setPartnerPrograms(res.data);
    });
  };

  const replace_user_id_in_link = (link: string) => {
    if (user === null) return link;
    return link.replace("{{user-id}}", user.id);
  }

  return (
    <LinksMenuContainer style={{ display: show ? 'block' : 'none' }}>
      <LinksMenu>
           {partnerPrograms.map((partnerProgram) => (
                <LinksMenuItem onClick={() => openInNewTab(replace_user_id_in_link(partnerProgram.link))} >
                  <Image>
                    <img
                        src={
                          partnerProgram.icon ? partnerProgram.icon : ''
                        }
                        alt={''}
                    />
                  </Image>
                  {partnerProgram.name}
                </LinksMenuItem>
            ))}
      </LinksMenu>
    </LinksMenuContainer>
  );
};

export default ProfileMenuPopup;
