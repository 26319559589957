import React, { ReactNode, useRef, useState } from 'react';
import { Popover } from 'antd';
import styled from 'styled-components';
import { ReactComponent as MoreIcon } from '../../../icons/more-icon.svg';
import useClickAwayListener from '../../../hooks/useClickAwayListener';

interface ActionProps {
  status: string
  id: string
  content: ReactNode
}

const StyledIcon = styled(MoreIcon)`
  cursor:pointer;
  color: #aebeca;
  font-size: 1.5rem;
  &:hover{
    color: #34ce72;
  }
`;
const StyledAction = styled.div`
  @media (max-width: 768px){
  width: 5%;
}
`;
const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  
`;

/*
  "DELETE_TITLE",
  "UNPUBLISH_TITLE",
  "PUBLISH_TITLE",
  "EDIT_TITLE",
*/

const ListAction = ({ status, id,content }: ActionProps) => {
  const actionRef = useRef(null);

  const [visible,setVisible] = useState(false);
  useClickAwayListener(actionRef,() => {
    setVisible(false)
  })
  return (
    <StyledAction ref={actionRef}>
      <Popover
        visible={visible}
        placement="leftTop"
        content={<StyledContentWrapper>
          {content}
        </StyledContentWrapper>}
        trigger="click">
        <StyledIcon onClick={() => setVisible(!visible)}/>
      </Popover>
    </StyledAction>
  );
};

export default ListAction;
