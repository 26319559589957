import React from 'react';
import { Radio } from 'antd';
import styled from 'styled-components';
import RadioInput from '../inputs/RadioInput';

interface TabProps {
  items: { name: string; value: string | number }[];
  disabled?: boolean;
  size?: string;
}

const TabWrapper = styled.div`
  width: 100%;
  & .ant-radio-group {
    display: flex;
    width: 100%;
    & .ant-radio {
      visibility: hidden;
      width: 0;
      height: 0;
    }
  }
`;
const StyledRadioButton = styled(RadioInput)`
  width: 100%;
  max-width: max-content;
  display: flex;
  text-align: center;
  & span {
    font-size: 0.75rem;
    font-weight: 800;
    line-height: 1.33;
    color: #627d95;
  }
  & span.ant-radio + * {
    padding: 0;
  }
  border-right: 1px solid #dae5ec;
  &:last-child {
    border-right: none;
    border-bottom-right-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  &:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
  }
  overflow: hidden;
  margin: 0;
  text-transform: capitalize;
  padding: 0.25rem 0.5rem;
  background-color: #f2f6fa;
  &.ant-radio-wrapper-checked {
    background-color: #627d95;
    & span {
      color: #ffffff;
    }
  }
  &.ant-radio-wrapper-disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
  &.large{
  padding: 0.3rem 3.5rem;
  }
`;

const RadioButtonTab = ({ items, disabled = false, size,...rest }: TabProps) => {
  return (
    <TabWrapper>
      <Radio.Group defaultValue="a" size="large" {...rest} disabled={disabled}>
        {items.map((item) => (
          <StyledRadioButton value={item.value} key={item.value} className={size ? size : ''}>
            {item.name}
          </StyledRadioButton>
        ))}
      </Radio.Group>
    </TabWrapper>
  );
};

export default RadioButtonTab;
