import { Modal } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Button from '../../../UI/buttons/Button';

const StyledModal = styled(Modal)`
  & .ant-modal-content {
    padding: 32px;
  }
  & .ant-modal-header {
    border-bottom: none;
    padding: 0;
    margin-bottom: 16px;
    & div {
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: left;
      color: #061c2e;
    }
  }
  & .ant-modal-body {
    padding: 0;
    & p {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
      text-align: left;
      color: #061c2e;
    }
  }
  & .ant-modal-footer {
    padding: 0;
    display: flex;
    border-top: none;
  }
`;

const WarningText = styled.p`
  margin-bottom: 2rem;
`;
const WarningList = styled.ul`
  margin: 2rem 0;
`;
const WarningListItem = styled.li`
  margin-left: 1rem;
  margin-right: 2rem;
  margin-bottom: 0.5rem;
`;

type ConfirmDeleteJobModalProps = {
  isOpen: boolean;
  onOk: () => void;
  onCancel: () => void;
};

const ConfirmDisconnectAtsModal = ({ isOpen, onOk, onCancel }: ConfirmDeleteJobModalProps) => {
  const [t] = useTranslation();

  return (
    <StyledModal
      closable={false}
      visible={isOpen}
      title={t('ats.DISCONNECT_ATS_CONFIRM_TITLE')}
      onOk={() => { onOk() }}
      onCancel={() => { }}
      footer={[
        <Button danger type="primary" onClick={() => onOk()} loading={false}>
          {t('ats.DISCONNECT_ATS_CONFIRM_OK')}
        </Button>,
        <Button type="ghost" onClick={() => { onCancel() }}>
          {t('CANCEL_TEXT')}
        </Button>,
      ]}
    >
      <WarningText>{t('ats.DISCONNECT_ATS_CONFIRM_TEXT.0')}</WarningText>
      <WarningList>
        <WarningListItem>{t('ats.DISCONNECT_ATS_CONFIRM_TEXT.1')}</WarningListItem>
        <WarningListItem>{t('ats.DISCONNECT_ATS_CONFIRM_TEXT.2')}</WarningListItem>
        <WarningListItem>{t('ats.DISCONNECT_ATS_CONFIRM_TEXT.3')}</WarningListItem>
      </WarningList>
      <WarningText>{t('ats.DISCONNECT_ATS_CONFIRM_TEXT.4')}</WarningText>
    </StyledModal>
  );
};

export default ConfirmDisconnectAtsModal;
