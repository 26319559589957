import React from 'react';
import { useTranslation } from 'react-i18next';

import Stage from './Stage';
import { JobStage } from '../../../../types';
import {
  StageList,
  StageItem,
  Content,
  Title,
  Total,
  TotalIconWrap,
  TotalText,
} from './styles';
import { ReactComponent as ClockIcon } from '../../../../icons/clock2.svg';

interface Iprops {
  stages: JobStage[]
  duration: number,
}

const ApplicationProcess = ({
  stages,
  duration
}: Iprops) => {
  const [t] = useTranslation();

  return (
    <div>
      <StageList>
        <StageItem>
          <Content>
            <Title>{t('INTRODUCED')}</Title>
          </Content>
        </StageItem>

        {stages.map(({
          id,
          interviewer_name,
          description,
          interview_goal,
          interview_duration,
          interview_location,
          candidate_preparation,
          stage,
          interviewer_role
        }) => (
          <Stage
            key={id}
            name={stage.name}
            interviewer={interviewer_name}
            role={interviewer_role}
            description={description}
            goal={interview_goal}
            duration={interview_duration}
            location={interview_location}
            preparation={candidate_preparation}
          />
        ))}

        <StageItem>
          <Content>
            <Title>{t('OFFER')}</Title>
          </Content>
        </StageItem>
      </StageList>

      {duration > 0 && (
        <Total>
          <TotalIconWrap>
            <ClockIcon />
          </TotalIconWrap>
          <TotalText>{t('INTERVIEW_TOTAL',  { days: duration })}</TotalText>
        </Total>
      )}
    </div>
  );
};

export default React.memo(ApplicationProcess);
