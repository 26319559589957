import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getInterviewMessageCounters,
  getSupportMessageCounter,
  getUnreadMessagesCount,
} from '../api/messages';
import { logoutUser } from './userSlice';

interface MessagesState {
  globalUnread: number;
  supportUnread: number;
  contactsUnread: object;
  interviewsUnread: object;
}

const initialState = {
  globalUnread: 0,
  supportUnread: 0,
  contactsUnread: {},
  interviewsUnread: {},
} as MessagesState;

export const getGlobalUnread = createAsyncThunk(
  'messages/getGlobalUnread',
  async () => {
    const response = await getUnreadMessagesCount();
    return response.data.unread_message_cnt;
  },
);

export const getInterviewsUnread = createAsyncThunk(
  'messages/getInterviewsUnread',
  async (interviewId: string) => {
    const response = await getInterviewMessageCounters(interviewId);
    return response.data;
  },
);

export const getSupportUnread = createAsyncThunk(
  'messages/getSupportUnread',
  async () => {
    const response = await getSupportMessageCounter();
    return response.data.unread_message_cnt;
  },
);

export const messagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    resetCounters: (state, action) => {
      state.globalUnread = initialState.globalUnread;
      state.contactsUnread = initialState.contactsUnread;
      state.interviewsUnread = initialState.interviewsUnread;
    },
    resetInnerCounters: (state) => {
      state.contactsUnread = initialState.contactsUnread;
      state.interviewsUnread = initialState.interviewsUnread;
    },
    resetContactCounter: (state, action) => {
      (state.contactsUnread as any)[action.payload] = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutUser.fulfilled, (state, action) => {
      state.interviewsUnread = {};
      state.contactsUnread = {};
      state.globalUnread = 0;
    });
    builder.addCase(getGlobalUnread.fulfilled, (state, action) => {
      state.globalUnread = action.payload;
    });
    builder.addCase(getInterviewsUnread.fulfilled, (state, action) => {
      state.globalUnread = action.payload.unread_message_cnt;
      const contacts =
        action.payload.client_companies &&
        action.payload.client_companies.length > 0
          ? action.payload.client_companies
          : action.payload.recruiters;
      if (contacts.length > 0) {
        let tempState = {};
        let tempContacts = {};
        contacts.forEach((c: any) => {
          (tempContacts as any)[c.id] = c.unread_message_cnt;
          c.interviews.forEach((i: any) => {
            (tempState as any)[i.id] = i.unread_message_cnt;
          });
        });
        state.interviewsUnread = { ...state.interviewsUnread, ...tempState };
        state.contactsUnread = { ...state.contactsUnread, ...tempContacts };
      }
    });
    builder.addCase(getSupportUnread.fulfilled, (state, action) => {
      state.supportUnread = action.payload;
    });
  },
});

export const {
  resetContactCounter,
  resetInnerCounters,
} = messagesSlice.actions;

export default messagesSlice.reducer;
