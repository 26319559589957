import styled from 'styled-components';

import Button from '../../../UI/buttons/Button';
import { ReactComponent as DeleteBaseIcon } from '../../../icons/delete.svg';

export const ColAction = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100%;
`;

export const DeleteIcon = styled(DeleteBaseIcon)`
  color: #eb5757;
  cursor: pointer;
  margin-left: 1rem;
`;

export const DeleteImageIcon = styled(DeleteBaseIcon)`
  color: #eb5757;
  cursor: pointer;
  position: absolute;
  right: 0.5rem;
  top: 1.5rem;
`;

export const FormSubtitle = styled.h2`
  font-family: Lato;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #061c2e;
  margin: 1.5rem 0;
`;

export const JobBackgroundImage = styled.div`
  height: 11rem;
  width: 100%;
  border-radius: 8px;
  background-color: #324759;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  &.bg_white {
    background-color: #ffffff;
  }
`;

export const ImageUploadButton = styled(Button)`
  position: absolute;
  right: 2rem;
  top: 1.5rem;
`;

export const FormActions = styled.div`
  padding: 1.5rem 2rem;
  border-radius: 8px;
  background-color: #f2f6fa;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
