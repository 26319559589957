import React, { useCallback, useEffect, useRef, useState } from 'react';
import FormPopUp from '../form/FormPopUp';
import { useTranslation } from 'react-i18next';
import { getJobs } from '../../api/jobs';
import { Form, Row } from 'antd';
import JobFilter from '../form/JobFilter';
import {
  City,
  Job,
  Seniority,
  Skill,
  Specialization,
} from '../../types';
import styled from 'styled-components';
import FilterJobItem from './FilterJobItem';
import { ReactComponent as SearchIcon } from '../../icons/search.svg';
import Loader from '../../UI/Loader';

interface AppointModalProps {
  id: string | null;
  setId: React.Dispatch<React.SetStateAction<string | null>>;
  domain: Specialization[];
  seniority: Seniority[];
  cities: City[];
  company_name: [];
  visa: boolean[];
  language: [];
  relocation: string;
  skills: Skill[];
}
const FilterWrapper = styled.div`
  border-bottom: 1px solid #dae5ec;
  position: relative;
`;
const JobWrapper = styled.div`
  margin-top: 3rem;
`;
const StyledButton = styled.button`
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  background: #34ce72;
  color: #ffffff;
  border: none;
  outline: none;
  cursor: pointer;
  position: absolute;
  top: 1.5rem;
  left: calc(100% + 0.5rem);
  & svg {
    width: 1.2rem;
    height: 1.2rem;
  }
  &:hover {
    background: #22bf61;
  }
`;
const NoResultText = styled.p`
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.33;
  letter-spacing: 0.96px;
  text-align: center;
  color: #061c2e;
  padding: 1rem;
`;
const AppointJobModal = ({
  id,
  setId,
  skills,
  seniority,
  domain,
  cities,
  company_name,
  visa,
  language,
  relocation
}: AppointModalProps) => {
  const [visible, setVisible] = useState(!!id);
  const [data, setData] = useState<{
    salary_from: null | string;
    salary_to: null | string;
    not: boolean;
    logical: null | string;
  }>({
    salary_from: null,
    salary_to: null,
    not: false,
    logical: 'AND',
  });
  const [form] = Form.useForm();
  const [jobs, setJobs] = useState<Job[]>([]);
  const [loading, setLoading] = useState(false);
  const [nextPage, setNextPage] = useState<string | null>(null);
  const loader = useRef(null);
  const [t] = useTranslation();
  useEffect(() => {
    getData();
  }, []);
  const getData = (query: string = '') => {
    setLoading(true);
    getJobs('?favorite=true&' + query).then((res) => {
      setJobs(res.data.results.filter((job: { status: string; }) => job.status !== 'unpublished'));
      setNextPage(res.data.pagination.next);
      setLoading(false);
    });
  };
  useEffect(() => {
    setVisible(!!id);
  }, [id]);
  useEffect(() => {
    if (!visible) {
      setId(null);
    }
  }, [visible, setId]);
  const handleObserver = useCallback(
    (entities: any) => {
      const cursor = '?favorite=true&cursor=' + nextPage;
      const target = entities[0];
      if (target.isIntersecting && nextPage) {
        getJobs(cursor).then((res) => {
          // @ts-ignore
          setJobs((j) => [...j, ...res.data.results.filter((job: { status: string; }) => job.status !== 'unpublished')]);
          setNextPage(res.data.pagination.next);
        });
      }
    },
    [nextPage],
  );
  useEffect(() => {
    const current = loader.current;
    let options = {
      root: null,
      rootMargin: '0px 0px 300px 0px',
      threshold: 1.0,
    };
    const observer = new IntersectionObserver(handleObserver, options);

    if (!nextPage) {
      observer.disconnect();
    } else {
      if (loader && current) {
        observer.observe((current as unknown) as Element);
      }
    }
    return () => observer.unobserve((current as unknown) as Element);
  }, [nextPage, handleObserver]);
  const onFinish = (values: any) => {
    let str = [];
    for (let key in values)
      if (values.hasOwnProperty(key) && values[key]) {
        if (key === 'skill') {
          if (
            !values[key].length ||
            (values[key].length && values[key][0] === '')
          ) {
          } else {
            str.push(
              encodeURIComponent(key) +
                '=' +
                encodeURIComponent(
                  values.not
                    ? 'NOT ' +
                        values[key].join(
                          ` ${values.logical} ${values.not ? 'NOT' : ''} `,
                        )
                    : values[key].join(` ${values.logical} `),
                ),
            );
          }
        } else {
          str.push(
            encodeURIComponent(key) + '=' + encodeURIComponent(values[key]),
          );
        }
      }
    let query = str.join('&');
    getData(query);
  };
  return (
    <FormPopUp
      visible={visible}
      setVisible={setVisible}
      title={t('CHOOSE_JOB')}
      large={true}
    >
      <FilterWrapper>
        <Form
          layout="vertical"
          name="filter"
          onFinish={onFinish}
          form={form}
          scrollToFirstError={true}
          onValuesChange={(e) => {
            if (e.salary_to || e.salary_to === '') {
              setData((prev) => ({ ...prev, salary_to: e.salary_to }));
            }
            if (e.salary_from || e.salary_from === '') {
              setData((prev) => ({ ...prev, salary_from: e.salary_from }));
            }
          }}
        >
          <Row gutter={16}>
            <JobFilter
              show={false}
              data={{ ...data, skills, domain, cities, seniority, company_name, visa, language, relocation }}
            />
          </Row>
          <StyledButton type="submit">
            <SearchIcon />
          </StyledButton>
        </Form>
      </FilterWrapper>
      <Loader spinning={loading}>
        <JobWrapper>
          {jobs.map((job) => (
            <FilterJobItem job={job} candidateId={id} key={job.id} />
          ))}
          {!loading && !jobs.length && (
            <NoResultText>{t('NO_RESULT')}</NoResultText>
          )}
        </JobWrapper>
      </Loader>
      <div className="loader" ref={loader} />
    </FormPopUp>
  );
};

export default AppointJobModal;
