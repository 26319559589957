import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { RECRUITER, RECRUITERS } from '../../constants/routes';
import { ColumnsType } from 'antd/es/table';
import { Recruiter } from '../../types';
import NameImage from '../client/NameImage';
import format from 'date-fns/format';
import Button from '../../UI/buttons/Button';
import { ReactComponent as ArrowRightIcon } from '../../icons/arrow-right.svg';
import { generateKey } from '../../utils/candidate';
import styled from 'styled-components';
import { Table } from 'antd';
import TooltipPrompt from "../../UI/tootlip";

interface ClientListProps {
  recruiters: Recruiter[];
}
export const StyledTable = styled(Table)`
  & .ant-table-tbody > tr {
    & > td {
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
        & button {
          margin-left: auto;
        }
      }
    }

    &:last-child > td {
      border-bottom: none;
    }
  }
  & .ant-checkbox-inner {
    width: 1.125rem;
    height: 1.125rem;
    border: 1px solid #aebeca;
    border-radius: 0.2rem;
    &:after {
      left: 24%;
    }
  }

  & .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #627d95;
    border-color: #627d95;
    border-radius: 0.2rem;
  }

  &:hover::after,
  &:hover .ant-checkbox::after {
    visibility: hidden;
  }

  & .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #627d95;
    border-radius: 0.2rem;
    &::after {
      left: 24%;
    }
  }

  & .ant-checkbox-input:hover {
    & + .ant-checkbox-inner {
      border-color: #29ae5e;
      border-radius: 0.2rem;
    }
  }

  & .ant-checkbox-checked {
    & .ant-checkbox-input:hover {
      & + .ant-checkbox-inner {
        background-color: #29ae5e;
        border-radius: 0.2rem;
      }
    }
  }

  & .ant-checkbox-indeterminate {
    & .ant-checkbox-input {
      & + .ant-checkbox-inner {
        background-color: #29ae5e;
        border-radius: 0.2rem;
        border-color: #29ae5e;
      }
    }
  }

`;
export const CandidateList = styled.div`
  padding: 0 2rem;
  background: #ffffff;
  border-radius: 0.5rem;
  & .ant-table-tbody > tr.ant-table-row:hover > td {
    background: #fff;
  }
  @media (max-width: 576px) {
    padding: 0 1.5rem;
  }
`;
export const Text = styled.p`
  font-size: 0.75rem;
  line-height: 1.33;
  color: #061c2e;
  margin: 0.5rem 0 0;
  &:first-child {
    margin: 0;
  }
`;
const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const RecruiterList = ({ recruiters }: ClientListProps) => {
  const [t] = useTranslation();
  const history = useHistory();
  const params = new URLSearchParams(history.location.search);
  const editClient = (id: string) => {
    params.set('recruiter', id);
    const query = params.toString();
    history.push(`${RECRUITERS}?${query}`);
  };
  const sort = params.get('sort');
  const sortData = (data: Recruiter[]) => {
    let key: string = '';
    if (sort === 'name') {
      key = 'name';
    } else if (sort === 'date') {
      key = 'registered_at';
    }
    return key
      ? data.slice().sort((a, b) => {
        // @ts-ignore
        if (b[key] < a[key]) {
          return 1;
        } else {
          // @ts-ignore
          if (b[key] > a[key]) {
            return -1;
          } else {
            return 0;
          }
        }
      })
      : data;
  };
  const columns: ColumnsType<Recruiter> = [
    {
      title: '',
      width: 200,
      key: 'company',
      responsive: ['sm', 'xxl', 'xl', 'lg', 'md'],
      dataIndex: 'name',
      render: (_, record) => (
        <TooltipPrompt title={t('CLICK_TO_SEE_PROFILE')}><Link to={RECRUITER + '/' + record.id}>
          <NameImage
            title={record.name}
            image={record.image}
            description={
              record.recruiter_type !== 'freelance'
                ? record.company.name
                : record.title
            }
          />
        </Link></TooltipPrompt>
      ),
      fixed: 'left',
    },
    {
      title: '',
      width: 200,
      dataIndex: 'name',
      key: 'name-sm',
      responsive: ['xs'],
      render: (_, record) => (
        <NameImage
          title={record.name}
          image={record.image}
          description={
            record.recruiter_type !== 'freelance'
              ? record.company.name
              : record.title
          }
        />
      ),
    },
    {
      title: '',
      width: 200,
      key: 'city',
      dataIndex: 'department',
      render: (city, record) => (
        <>
          <Text>
            {t('LOCATION_LABEL')}:{' '}
            {record.locations.length
              ? record.locations.map((loc) => loc.name).join(', ')
              : '-'}
          </Text>
          <Text>
            {t('DOMAIN_TITLE')}:{' '}
            {record.specializations.length
              ? record.specializations.map((spec) => spec.name).join(', ')
              : '-'}
          </Text>
        </>
      ),
    },
    {
      title: '',
      width: 200,
      key: 'name',
      dataIndex: 'travel_max',
      render: (name, record) => (
        <>
          <Text>
            {t('LAST_LOGIN')}:{' '}
            {record.last_login
              ? format(new Date(record.last_login), 'MMM d, H:m')
              : ' -'}
          </Text>
          <Text>
            {t('ON_PLATFORM')}:{' '}
            {record.registered_at
              ? format(new Date(record.registered_at), 'MMM d, yyyy')
              : ' -'}
          </Text>
        </>
      ),
    },
    {
      title: '',
      width: 160,
      key: 'registered_at',
      dataIndex: 'skills',
      render: (registered_at, record) => (
        <>
          <Text>
            {t('IN_PROCESS_TITLE')}: {record.statistics.in_process}
          </Text>
          <Text>
            {t('REJECTED_TITLE')}: {record.statistics.rejected}
          </Text>
        </>
      ),
    },
    {
      title: '',
      width: 160,
      key: 'jobs',
      dataIndex: 'salary',
      render: (_, record) => (
        <>
          <Text>
            {t('UPLOADED')}: {record.statistics.uploaded}
          </Text>
          <Text>
            {t('TOTAL_PLACEMENTS')}: {record.statistics.hired}
          </Text>
        </>
      ),
    },
    {
      title: '',
      width: 80,
      key: 'action',
      responsive: ['sm', 'xxl', 'xl', 'lg', 'md'],
      dataIndex: 'id',
      fixed: 'right',
      render: (id, record) => (
        <ActionWrapper>
          <Button size="small" type="primary" onClick={() => editClient(id)}>
            {t('EDIT_TITLE')}
            <ArrowRightIcon style={{ marginRight: 0, marginLeft: '.5rem' }} />
          </Button>
        </ActionWrapper>
      ),
    },
    {
      title: '',
      width: 100,
      dataIndex: 'id',
      key: 'action-xs',
      responsive: ['xs'],
      render: (id, record) => (
        <ActionWrapper>
          <Button size="small" type="primary" onClick={() => editClient(id)}>
            {t('EDIT_TITLE')}
            <ArrowRightIcon style={{ marginRight: 0, marginLeft: '.5rem' }} />
          </Button>
        </ActionWrapper>
      ),
    },
  ];
  return (
    <CandidateList>
      <StyledTable
        locale={{ emptyText: ' ' }}
        // @ts-ignore
        columns={columns}
        dataSource={sortData(recruiters)}
        showHeader={false}
        scroll={{ x: 950 }}
        pagination={false}
        onExpandedRowsChange={() => { }}
        rowKey={() => generateKey(5)}
      />
    </CandidateList>
  );
};

export default RecruiterList;
