import React from "react";
import { Recruiter } from "../../types";
import RecruiterSelectTableRow from "./RecruiterSelectTableRow";
import RecruiterSelectTableHeader from "./RecruiterSelectTableHeader";
import { SortingType, SortOrder } from "./RecruiterManagementBoard";

interface RecruiterSelectTableProps {
  recruiters: Recruiter[];
  setRecruiterIsSelectedField: (rec: Recruiter, is_selected: boolean) => void;
  handleTableScroll: (e: any) => void;
  handleSelectAll: (isChecked: boolean) => void;
  setSortType: React.Dispatch<React.SetStateAction<SortingType>>;
  setSortOrder: React.Dispatch<React.SetStateAction<SortOrder>>;
  currentSortOrder: SortOrder;
  currentSortType: SortingType;
}
export default function RecruiterSelectTable({
  recruiters,
  setRecruiterIsSelectedField,
  handleTableScroll,
  handleSelectAll,
  setSortType,
  setSortOrder,
  currentSortType,
  currentSortOrder
}: RecruiterSelectTableProps) {
  return (
    <div>
      <RecruiterSelectTableHeader
        setSortOrder={setSortOrder}
        setSortType={setSortType}
        currentSortType={currentSortType}
        currentSortOrder={currentSortOrder}
        handleSelectAll={handleSelectAll}
      />
      <div
        style={{ maxHeight: "400px", overflow: "auto" }}
        onScroll={handleTableScroll}
      >
        {recruiters.map((rec) => (
          <RecruiterSelectTableRow
            recruiter={rec}
            setRecruiterIsSelectedField={setRecruiterIsSelectedField}
          />
        ))}
      </div>
    </div>
  );
}
