export const workingTypeOptions = [
  {
    id: 'full_office',
    label: 'WORKING_TYPE_FULL_OFFICE',
  },
  {
    id: 'hybrid',
    label: 'WORKING_TYPE_HYBRID',
  },
  {
    id: 'fully_remote_home_country',
    label: 'WORKING_TYPE_FULLY_REMOTE_HOMECOUNTRY',
  },
  {
    id: 'fully_remote_same_timezone',
    label: 'WORKING_TYPE_FULLY_REMOTE_TIMEZONE',
  },
  {
    id: 'fully_remote_worldwide',
    label: 'WORKING_TYPE_FULLY_REMOTE_WORLDWIDE',
  },
];
