import React from 'react';
import styled from 'styled-components';
import TooltipPrompt from '../tootlip';
import { useTranslation } from 'react-i18next';

type FilterList = {
  title: string;
  value: string;
  translation: string;
};
interface FilterProps {
  onClick: (filter: string) => void;
  list: FilterList[];
  active: string;
}
const FilterWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: max-content;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    order: 3;
    max-width: 100%;
    margin: 1rem auto 0;
  }
`;
const FilterItem = styled.div`
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.33;
  letter-spacing: 0.96px;
  padding: 1rem 0;
  color: #061c2e;
  max-width: max-content;
  width: 100%;
  margin: 0 0.75rem;
  position: relative;
  text-transform: uppercase;
  cursor: pointer;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -1.5rem;
    height: 4px;
    width: 0;
    background: #34ce72;
    transition: width ease 0.3s;
  }
  &.active {
    &:before {
      width: 100%;
    }
  }
`;
const Filter = ({ list, onClick, active }: FilterProps) => {
  const [t] = useTranslation()
  return (
    <FilterWrapper>
      {list.map((item) => (

          <FilterItem
            key={item.title}
            onClick={() => onClick(item.value)}
            className={active === item.value ? 'active' : ''}
          >
            <TooltipPrompt title={t(item.translation)}>
            {item.title}
            </TooltipPrompt>
          </FilterItem>
      ))}
    </FilterWrapper>
  );
};

export default Filter;
