import React from 'react';
import styled from 'styled-components';
import animation from '../../icons/hireport.json';
import Lottie from 'lottie-react-web';

const Image = styled.div`
  position: absolute;
  top: 12rem;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: auto;
  max-width: 75%;
  @media (max-width: 1650px) {
    top: 14rem;
    max-width: 75%;
  }
  @media (max-width: 1440px) {
    top: 17rem;
    max-width: 75%;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;
const BannerImage = () => {
  return (
    <Image>
      <Lottie
        direction={1}
        options={{
          animationData: animation,
          loop: true,
        }}
      />
    </Image>
  );
};

export default BannerImage;
