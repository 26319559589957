import styled from 'styled-components';

export const Item = styled.li`
  position: relative;
  flex-grow: 1;
  flex-basis: 0;
  min-width: 0;
  text-align: center;

  &:before {
    position: absolute;
    z-index: 1;
    top: 16px;
    content: '';
    display: block;
    height: 2px;
    width: 100%;
    background-image: linear-gradient(to right, #DAE5EC 33%, #fff 0%);
    background-position: bottom;
    background-size: 6px 2px;
    background-repeat: repeat-x;
  }

  &:first-child {
    &:before {
      left: 50%;
    }
  }

  &:last-child {
    &:before {
      width: 50%;
    }
  }
`;

interface ISelectableItem {
  isCurrent?: boolean,
  disabled?: boolean,
}

export const SelectableItem = styled.label<ISelectableItem>`
  position: relative;
  z-index: 2;
  display: inline-block;
  height: 32px;
  width: 32px;

  & input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
    background-color: ${({ isCurrent }) => isCurrent ? '#fff' : '#DAE5EC'};
    margin: 0;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    border: ${({ isCurrent }) => isCurrent ? '13px' : '2px'} solid ${({ isCurrent }) => isCurrent ? '#22bf61' : '#DAE5EC'};
    cursor: ${({ disabled, isCurrent }) => disabled || isCurrent ? 'normal' : 'pointer'};

    &:hover,
    &:focus {
      border: ${({ isCurrent }) => isCurrent ? '13px' : '2px'} solid ${({ disabled, isCurrent }) => disabled && !isCurrent ? '#DAE5EC' : '#22bf61'};
    }
  }
`;

export const ItemName = styled.p`
  font-family: Lato;
  font-size: 12px;
  line-height: 1.33;
  text-align: center;
  color: #061c2e;
  margin: 0;
`;

export const Info = styled.p`
  font-family: Lato;
  font-size: 12px;
  line-height: 1.33;
  text-align: center;
  color: #627d95;
  margin: 0;
`;

export const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 4px;
`