import React from 'react';
import { InputNumberProps, InputProps } from 'antd';
import styled from 'styled-components';
import Input from '../inputs/Input';
import InputNumber from '../inputs/InputNumber';

const StyledInput = styled(Input)`
  &:disabled {
    border: solid 1px #aebeca;
    background-color: #f2f6fa;
  }
`;

const StyledInputNumber = styled(InputNumber)`
  &:disabled {
    border: solid 1px #aebeca;
    background-color: #f2f6fa;
  }
`;

const NumberInput = (props: InputProps) => {
  return (
    <StyledInput
      {...props}
      type="number"
      onWheel={(event) => {
        event.currentTarget.blur();
      }}
    />
  );
};

/**
 * Uses Ant Design's native number input control rather than a regular input control with type of input
 */
export const InputNumberInput = (props: InputNumberProps) => {
  return (
    <StyledInputNumber
      {...props}
    />
  );
};

export default NumberInput;
