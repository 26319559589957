import { useCallback } from 'react';
import { InterviewRoom, Message, User } from '../../../types';
import { rateInterview } from '../../../api/interviews';
import { CLIENT_ROLE } from '../../../constants/roles';
import { MESSAGE_TYPES } from '../../../constants/messageTypes';
import RatingRequestMessage from '../RatingRequestMessage';
import RatingRequestConfirmation from '../RatingRequestConfirmation';
import { useTranslation } from 'react-i18next';

type RenderMessageProps = {
  message: Message;
};

/**
 * A convenience hook to package up shared functionality for handling rating request messages.
 * This wouldn't be necessary if chat message components had been properly abstracted in the first place.
 * @param onRatingProvided a callback function that is called when a rating has been provided
 * @param interview the `InterviewRoom` container for the messages
 * @param user the `User` who is currently viewing the component
 * @returns a constant array `[RenderMessage, getMessageText]` where `RenderMessage` is a React component
 * which renders the rating request (or thankyou message) and `getMessageText` is a function taking the message
 * and returning a modified message text value.
 */
const useRatingRequest = (onRatingProvided: () => void, interview?: InterviewRoom, user?: User | null) => {
  const { t } = useTranslation();
  const handleMessageRating = useCallback(async (message: Message, rating: number) => {
    if (!interview || !user) return;
    const subject = (() => {
      if (!message.aux_data) return "";
      try {
        const obj = JSON.parse(message.aux_data);
        return obj["subject"] || "";
      } catch (e: any) {
        return "";
      }
    })();
    await rateInterview(
      interview.id,
      {
        subject,
        comments: "",
        rating,
        ratedBy: user.id,
        ratedUser: user?.role === CLIENT_ROLE ? interview.recruiter.company.id : interview.client.company.id,
        message: message.id,
      },
    )
    onRatingProvided();
  }, [user, interview, onRatingProvided]);

  const getMessageText = (message: Message) => {
    if (message.message_type !== MESSAGE_TYPES.RATING_REQUIRED)
      return t(message.text);
    if (!message.is_old)
      return `${t(message.text)} *`;
    return '';
  }

  const RenderMessage = ({ message }: RenderMessageProps) => {
    return (
      <>
        {message.message_type ===
          MESSAGE_TYPES.RATING_REQUIRED && !message.is_old && (
            <RatingRequestMessage onChange={(value) => { handleMessageRating(message, value) }} />
          )}
        {message.message_type ===
          MESSAGE_TYPES.RATING_REQUIRED && message.is_old && (
            <RatingRequestConfirmation />
          )}
      </>
    )
  }

  return [RenderMessage, getMessageText] as const;
}

export default useRatingRequest;
