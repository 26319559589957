import React, { useEffect, useState } from 'react';
import TabRadioButton from '../../UI/radioButton/TabRadioButton';
import ClientAddForm from '../form/ClientAddForm';
import RecruiterAddForm from '../form/RecruiterAddForm';
import FormPopUp from '../form/FormPopUp';
import { useTranslation } from 'react-i18next';
import { RadioChangeEvent } from 'antd';
import { getRegions } from '../../api/regions';
import { getIndustries } from '../../api/industries';
import { useHistory } from 'react-router-dom';
import { getRecruiter } from '../../api/recruiter';
import { getClient } from '../../api/client';

interface UserAddProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  update?: React.Dispatch<React.SetStateAction<any>>;
}
const userTypes: { title: string | number; value: string | number }[] = [
  {
    title: 'Client',
    value: 'client',
  },
  {
    title: 'Recruiter',
    value: 'recruiter',
  },
];

const AdminUserAdd = ({ visible, setVisible, update }: UserAddProps) => {
  const history = useHistory();
  const [user, setUser] = useState(undefined);
  const params = new URLSearchParams(history.location.search);
  const recruiter = params.get('recruiter');
  const client = params.get('client');
  const [regions, setRegions] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [userType, setUserType] = useState('client');
  const [t] = useTranslation();
  const isEdit = recruiter || client;
  const addClient = history.location.pathname.includes('client');
  const addRecruiter = history.location.pathname.includes('recruiter');

  const onChange = (e: RadioChangeEvent) => {
    setUserType(e.target.value);
  };

  useEffect(() => {
    getRegions().then((res) => setRegions(res.data));
    getIndustries().then((res) => setIndustries(res.data));
  }, []);

  useEffect(() => {
    if (!visible) {
      setUser(undefined);
    }
  }, [visible]);

  useEffect(() => {
    if (recruiter || addRecruiter) {
      setUserType('recruiter');
    } else {
      setUserType('client');
      setUserType('client');
    }
    if (isEdit) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  }, [isEdit, setVisible, recruiter, history, addRecruiter]);

  useEffect(() => {
    if (recruiter) {
      getRecruiter(recruiter, history).then((res) => setUser(res.data));
    }
    if (client) {
      getClient(client, history).then((res) => setUser(res.data));
    }
  }, [recruiter, client, history]);

  return (
    <FormPopUp
      visible={visible}
      setVisible={setVisible}
      title={t(
        addClient && isEdit
          ? 'EDIT_CLIENT'
          : addRecruiter && isEdit
          ? 'EDIT_RECRUITER'
          : addClient
          ? 'ADD_CLIENT'
          : addRecruiter
          ? 'ADD_RECRUITER'
          : 'ADD_USER_TITLE',
      )}
      onClose={() => {
        setVisible(false);
        setTimeout(() => {
          params.delete('client');
          params.delete('recruiter');
          history.push(`${history.location.pathname}?${params.toString()}`);
        }, 300);
      }}
    >
      {!(isEdit || addRecruiter || addClient) && (
        <TabRadioButton onChange={onChange} value={userType} data={userTypes} />
      )}
      {userType === 'client' ? (
        <ClientAddForm
          onSuccess={() => setVisible(false)}
          regions={regions}
          industries={industries}
          visible={visible}
          open={setVisible}
          edit={!!isEdit}
          user={user}
          update={update}
        />
      ) : (
        <RecruiterAddForm
          open={setVisible}
          edit={!!isEdit}
          user={user}
          visible={visible}
        />
      )}
    </FormPopUp>
  );
};

export default AdminUserAdd;
