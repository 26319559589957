import { ReactComponent as SearchIcon } from '../../icons/search.svg';
import Input from "../../UI/inputs/Input";
import { InputProps } from 'antd';

export default function NewSearchInput({ ...rest }: InputProps) {
  return (
    <div style={{display: "flex", justifyContent: "center", alignItems: "center", position: "relative"}}>
      <SearchIcon style={{color: "#aebeca", position: "absolute", left: "10px", top: "50%", transform: "translateY(-50%)", zIndex: 99}}/>
      <Input {...rest} style={{padding: "7px 7px 7px 40px", fontSize: "14px", height: "40px"}}/>
    </div>
  )
}
