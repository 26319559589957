import styled from 'styled-components';

import Button from '../../../UI/buttons/Button';

export const RemoveStageButton = styled(Button)`
  position: absolute;
  right: 2rem;
  top: 2rem;
  z-index: 100;
`;

export const FormSubtitle = styled.h2`
  font-family: Lato;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #061c2e;
  margin: 1.5rem 0;
`;

export const FormActions = styled.div`
  padding: 1.5rem 2rem;
  border-radius: 8px;
  background-color: #f2f6fa;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const JobStageContainer = styled.div`
  background-color: #f2f6fa;
  border-radius: 8px;
  padding: 2rem;
  margin-bottom: 1rem;
  position: relative;
`;