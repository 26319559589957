import React from 'react';
import { useTranslation } from 'react-i18next';

import { InterviewRoom } from '../../../../../types';
import {
  TasksList,
  ListItemText,
  ButtonWrap, InterviewButton
} from './styles';
import ListItem from './ListItem';
import Button from '../../../../../UI/buttons/Button';
import { ReactComponent as FeedbackIcon } from '../../../../../icons/feedback-icon.svg';
import useTasks from './hooks';
import EditDate from '../EditDate';
import ExportDate from '../ExportDate';
import { ReactComponent as RescheduleIcon } from '../../../../../icons/reschedule.svg';

interface IProps {
  interview: InterviewRoom | { [key:string]: any };
}

const ClientTasks = ({
  interview,
}: IProps) => {
  const [t] = useTranslation();

  const {
    actions: {
      handleShowFeedbackModal,
      handleSetInterview,
      //checkFeedBackTime
    },
    data: {
      currentStageName,
      isPlanning,
      waitingRecruiter,
      waitingClient,
      planningInterviewIsCompleted,
      interviewComment,
      interviewLocation,
      interviewDate,
      //feedbackTime,
      clientFeedbackIsCompleted,
      recruiterFeedbackIsCompleted,
      isBothSidePositive,
      afterInterviewFeedbackTime,
      interviewIsDone,
    }
  } = useTasks({ interview });

  const showProposeDate = isPlanning || waitingClient;

  const task1isDone = waitingRecruiter || planningInterviewIsCompleted;

  // /let taskText1 = '';
  //if (waitingClient) { // show this if recruiter propose new interview date
    /*if (feedbackTime > 0) {
      taskText1 = t('CLIENT_STAGE_TASK_TITLE-1_2', { numberOfHours: checkFeedBackTime(feedbackTime) });
    } else {
      taskText1 = t('CLIENT_STAGE_TASK_TITLE-1_2_OUTDATED');
    }
  } else { // default texts
    if (feedbackTime > 0) {
      taskText1 = t('CLIENT_STAGE_TASK_TITLE-1', { numberOfHours: checkFeedBackTime(feedbackTime) });
    } else {
      taskText1 = t('CLIENT_STAGE_TASK_TITLE-1_OUTDATED');
    }*/
  //}

  /*let taskText2 = '';
  if (planningInterviewIsCompleted) {
    taskText2 = t('STAGE_TASK_TITLE-2_DONE');
  } else {
    if (feedbackTime > 0 && task1isDone) {
      taskText2 = t('CLIENT_STAGE_TASK_TITLE-2_TIME', { numberOfHours: checkFeedBackTime(feedbackTime) });
    } else {
      taskText2 = t('CLIENT_STAGE_TASK_TITLE-2')
    }
  }*/

  let planningInterviewText = '';
  if (!planningInterviewIsCompleted) { 
    planningInterviewText = t('CLIENT_STAGE_TASK_TITLE-3_DISABLED');
  } else if (clientFeedbackIsCompleted || recruiterFeedbackIsCompleted || interviewIsDone) {
    planningInterviewText = t('STAGE_TASK_INTERVIEW_DONE_CLIENT', { dateTime: interviewDate });
  } else {
    planningInterviewText = t('CLIENT_STAGE_TASK_TITLE-3', { currentStageName, dateTime: interviewDate });
  }

  /*
    client feedback is ready - The company has given feedback
    feedbackbTime > 48 and interview is done - You have to provide feedback
    feedbackbTime > 48 and interview will be in the future - You have to provide feedback after the interview
    feedbackbTime > 0 и < 48 and interview is done - You have to provide feedback within {{ numberOfHours }} hours
    feedbackbTime > 0 и < 48 and interview will be in the future - You have to provide feedback within {{ numberOfHours }} hours after the interview
    feedbackbTime < 0 - You should have provided feedback
    no interview date yet (default status) - You will have to provide feedback within 24h after the interview
  */

  let clientFeedbackText = '';
  if (clientFeedbackIsCompleted) {
    clientFeedbackText = t('STAGE_COMPANY_FEEDBACK_IS_DONE'); // The company has given feedback
  } else {
    if (afterInterviewFeedbackTime > 48) {
      if (interviewIsDone) {
        clientFeedbackText = t(
          'RECRUITER_STAGE_TASK_TITLE-5-SIMPLE',
        ) // You have to provide feedback
      } else {
        clientFeedbackText = t('RECRUITER_STAGE_TASK_TITLE-5-SIMPLE_AFTER');
        // ...after the interview
      }
    } else if (afterInterviewFeedbackTime > 0 && afterInterviewFeedbackTime <= 48) {
      if (interviewIsDone) {
        clientFeedbackText = t(
          'RECRUITER_STAGE_TASK_TITLE-5',
          { numberOfHours: afterInterviewFeedbackTime },
        ) // You have to provide feedback within {{ numberOfHours }} hours
      } else {
        clientFeedbackText = t(
          'RECRUITER_STAGE_TASK_TITLE-5_AFTER',
          { numberOfHours: afterInterviewFeedbackTime },
        ) // ...after the interview
      }
    } else {
      if (planningInterviewIsCompleted) {
        clientFeedbackText = t('RECRUITER_STAGE_TASK_TITLE-5_OUTDATED') // You should have provided feedback
      } else {
        clientFeedbackText = t('STAGE_TASK_TITLE-5_PRE');
        // You will have to provide feedback within 24h after the interview
        // yep, hardcoded time
      }
    }
  }

  let recruiterFeedbackText = '';
  if (recruiterFeedbackIsCompleted) {
    recruiterFeedbackText = t('STAGE_RECRUITER_FEEDBACK_IS_DONE'); // The recruiter has given feedback
  } else {
    if (afterInterviewFeedbackTime > 48) {
      if (interviewIsDone) {
        recruiterFeedbackText = t(
          'CLIENT_STAGE_TASK_TITLE-4-SIMPLE',
        ) // The recruiter will provide you with feedback
      } else {
        recruiterFeedbackText = t(
          'CLIENT_STAGE_TASK_TITLE-4-SIMPLE_AFTER',
        ) // ...after the interview
      }
    } else if (afterInterviewFeedbackTime > 0 && afterInterviewFeedbackTime <= 48) {
      if (interviewIsDone) {
        recruiterFeedbackText = t(
          'CLIENT_STAGE_TASK_TITLE-4',
          { numberOfHours: afterInterviewFeedbackTime },
        ) // The recruiter will provide you with feedback within {{ numberOfHours }} hours
      } else {
        recruiterFeedbackText = t(
          'CLIENT_STAGE_TASK_TITLE-4_AFTER',
          { numberOfHours: afterInterviewFeedbackTime },
        ) // ...after the interview
      }
    } else {
      if (planningInterviewIsCompleted) {
        recruiterFeedbackText = t('CLIENT_STAGE_TASK_TITLE-4_OUTDATED') // The recruiter should have provided feedback
      } else {
        recruiterFeedbackText = t('RECRUITER_STAGE_TASK_TITLE-5_PRE'); // The recruiter have to provide feedback after the interview
      }
    }
  }

  return (
    <TasksList>
      {/*<ListItem
        isActive
        isActiveLine={task1isDone}
        isDone={task1isDone}
        title={task1isDone ? t('STAGE_TASK_TITLE-1_DONE') : taskText1}
        isButton={showProposeDate}
        handleClick={showProposeDate ? handleShowDateProposeModal : undefined}
      />*/}

      <ListItem
          isActive
          isActiveLine={task1isDone}
          isDone={task1isDone}
          title={task1isDone ? t('STAGE_TASK_TITLE-2_DONE') : t('INTERVIEW_TEXT_CLIENT')}
          isButton={showProposeDate}
          handleClick={showProposeDate ? handleSetInterview : undefined}
      >
        {!task1isDone && (
            <>
              <ButtonWrap>
                <InterviewButton
                    size="small"
                    className={'ant-btn-interview'}
                    icon={<RescheduleIcon />}
                    onClick={handleSetInterview}
                >
                  {t('SET_INTERVIEW_DATE')}
                </InterviewButton>
              </ButtonWrap>
            </>
        )}
      </ListItem>

      {/*<ListItem
        isActive={waitingRecruiter || planningInterviewIsCompleted}
        isActiveLine={planningInterviewIsCompleted}
        isDone={planningInterviewIsCompleted}
        title={taskText2}
      />*/}

      <ListItem
        isActive={planningInterviewIsCompleted}
        isActiveLine={planningInterviewIsCompleted}
        isDone={planningInterviewIsCompleted}
        title={planningInterviewText}
        isButton={planningInterviewIsCompleted}
        handleClick={planningInterviewIsCompleted ? handleSetInterview : undefined}
      >
        <>
          {interviewLocation && (
            <ListItemText>{`${t('LOCATION_LABEL')}: ${interviewLocation}`}</ListItemText>
          )}
          {interviewComment && (
            <ListItemText>{`${t('COMMENT')}: ${interviewComment}`}</ListItemText>
          )}

          {planningInterviewIsCompleted && interview && (
            <ButtonWrap>
              <EditDate interview={interview} />

              <ExportDate interviewId={interview.id} />
            </ButtonWrap>
          )}
        </>
      </ListItem>
      
      {recruiterFeedbackIsCompleted ? (
        <>
          <ListItem
            isActive={planningInterviewIsCompleted}
            isActiveLine={planningInterviewIsCompleted}
            isDone={recruiterFeedbackIsCompleted}
            title={recruiterFeedbackText}
          />

          <ListItem
            isActive={planningInterviewIsCompleted}
            isActiveLine={isBothSidePositive}
            isDone={clientFeedbackIsCompleted}
            title={clientFeedbackText}
            isButton={planningInterviewIsCompleted}
            handleClick={planningInterviewIsCompleted ? handleShowFeedbackModal : undefined}
          >
            {planningInterviewIsCompleted && (
              <ButtonWrap>
                <Button
                  size="small"
                  icon={<FeedbackIcon />}
                  onClick={handleShowFeedbackModal}
                >{clientFeedbackIsCompleted
                  ? t('VIEW_FEEDBACK')
                  : t('GIVE_FEEDBACK')
                }</Button>
              </ButtonWrap>
            )}
          </ListItem>
        </>
        ) : (
          <>
            <ListItem
              isActive={planningInterviewIsCompleted}
              isActiveLine={planningInterviewIsCompleted}
              isDone={clientFeedbackIsCompleted}
              title={clientFeedbackText}
              isButton={planningInterviewIsCompleted}
              handleClick={planningInterviewIsCompleted ? handleShowFeedbackModal : undefined}
            >
              {planningInterviewIsCompleted && (
                <ButtonWrap>
                  <Button
                    size="small"
                    icon={<FeedbackIcon />}
                    onClick={handleShowFeedbackModal}
                  >{clientFeedbackIsCompleted
                    ? t('VIEW_FEEDBACK')
                    : t('GIVE_FEEDBACK')
                  }</Button>
                </ButtonWrap>
              )}
            </ListItem>

            <ListItem
              isActive={planningInterviewIsCompleted}
              isActiveLine={isBothSidePositive}
              isDone={recruiterFeedbackIsCompleted}
              title={recruiterFeedbackText}
            />
          </>
        )
      }
  
      <ListItem
        isActive={isBothSidePositive}
        isDone={isBothSidePositive}
        title={t('RECRUITER_STAGE_TASK_TITLE-6')}
      />
    </TasksList>
  )
}

export default React.memo(ClientTasks);
