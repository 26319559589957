import { useCallback } from 'react';

import { getJobPdf, sendJobPdfLog } from '../../../../api/jobs';

const useGetPdf = (jobId: string) => {
  const getPdf = useCallback(() => {
    getJobPdf(jobId);
    sendJobPdfLog(jobId);
  }, [jobId]);

  return {
    actions: {
      getPdf,
    },
  };
};

export default useGetPdf;
