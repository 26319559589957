import React, {  useEffect, useState } from 'react';
import FormItem from '../FormItem';
import { CaretDownOutlined } from '@ant-design/icons';
import {
  requiredRule,
} from '../../../utils/validationRules';
import { Col, Row, Select } from 'antd';
import { getCityList } from '../../../api/regions';
import DynamicInputs from '../../../UI/inputs/DynamicInputs';
import AddressField from '../../../UI/inputs/AddressField';
import { useTranslation } from 'react-i18next';
import NumberInput from '../../../UI/buttons/NumberInput';
import SelectInput from '../../../UI/inputs/Select';
import Input from '../../../UI/inputs/Input';
import Textarea from '../../../UI/inputs/Textarea';


const { Option } = Select;

interface CompanyInfoProps {
  regions: { id: string; name: string }[];
  industries: { id: string; name: string }[];
  form?: any;
  address: string;
  setAddress: (data: any) => void;
  locations: { id: string; address: string }[];
  setLocations: (data: any) => void;
  companyId?: string;
  disabled?: boolean;
  validating?: boolean;
}

const CompanyInfo = ({
  regions,
  disabled,
  validating,
  locations,
  form,
  setAddress,
  address,
  setLocations,
  industries,
  companyId,
}: CompanyInfoProps) => {
  const [, setMale] = useState<number | null>(null);
  const [cities, setCities] = useState<{ id: string; name: string }[]>([]);
  const { t } = useTranslation();
  useEffect(() => {
    getCityList().then((res) => setCities(res.data));
  }, []);

  return (
    <>
      <FormItem
        label={t('COMPANY_NAME_TITLE')}
        name="company_name"
        rules={requiredRule(t)}
      >
        <Input size="large" disabled={!!disabled} />
      </FormItem>
      <FormItem
        label={t('LOCATION_CITY_LABEL')}
        name="city"
        //rules={requiredRule(t)}
      >
        <SelectInput
          size="large"
          filterOption={true}
          suffixIcon={<CaretDownOutlined style={{ color: '#000' }} />}
        >
          {cities.map((city) => (
            <Option key={city.id} value={city.id}>
              {city.name}
            </Option>
          ))}
        </SelectInput>
      </FormItem>
      <DynamicInputs
        validating={false}
        locations={locations}
        setLocations={setLocations}
        companyId={
          form.getFieldValue('company')
            ? form.getFieldValue('company')
            : companyId
        }
      />
      <AddressField
        validating={false}
        label={t('ADDRESS_LABEL')}
        address={address}
        setAddress={setAddress}
      />
      <FormItem
        label={t('INDUSTRIES_LABEL')}
        name="industry"
        //rules={requiredRule(t)}
      >
        <SelectInput
          size="large"
          suffixIcon={<CaretDownOutlined style={{ color: '#000' }} />}
        >
          {industries.map((industry) => (
            <Option key={industry.id} value={industry.id}>
              {industry.name}
            </Option>
          ))}
        </SelectInput>
      </FormItem>
      <FormItem
        label={t('REGIONS_LABEL')}
        name="region"
        //rules={requiredRule(t)}
      >
        <SelectInput
          size="large"
          suffixIcon={<CaretDownOutlined style={{ color: '#000' }} />}
        >
          {regions.map((region) => (
            <Option key={region.id} value={region.id}>
              {region.name}
            </Option>
          ))}
        </SelectInput>
      </FormItem>
      <FormItem
        label={t('NOTABLE_CLIENTS_LABEL')}
        name="notable_clients"
        //rules={requiredRule(t)}
      >
        <Input size="large" />
      </FormItem>
      <FormItem className="no-margin">
        <Row>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 11 }}
            md={{ span: 24 }}
            lg={{ span: 11 }}
          >
            <FormItem
              label={t('EMPLOYEES_LABEL')}
              name="number_of_employees"
              //rules={numberRule(t)}
            >
              <NumberInput size="large" type={'number'} />
            </FormItem>
          </Col>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 11, offset: 2 }}
            md={{ span: 24, offset: 0 }}
            lg={{ span: 11, offset: 2 }}
          >
            <FormItem
              label={t('AVERAGE_AGE_LABEL')}
              name="average_age"
              //rules={numberRule(t)}
            >
              <NumberInput size="large" type={'number'} />
            </FormItem>
          </Col>
        </Row>
      </FormItem>
      <FormItem className="no-margin width-gender">
        <Row>
          <Col
            xs={{ span: 10 }}
            sm={{ span: 11 }}
            md={{ span: 24 }}
            lg={{ span: 11 }}
          >
            <FormItem
              label={t('MALE_FEMALE_LABEL')}
              className="gender"
              name="male_percentage"
              //rules={numberRule(t)}
            >
              <NumberInput
                size="large"
                onChange={(e) => setMale(parseInt(e.target.value))}
                type={'number'}
              />
            </FormItem>
            <span className="female-count">
              /{' '}
              {(form.getFieldValue('male_percentage') ||
                form.getFieldValue('male_percentage') === 0) &&
                100 - form.getFieldValue('male_percentage')}
            </span>
          </Col>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 11, offset: 2 }}
            md={{ span: 24, offset: 0 }}
            lg={{ span: 11, offset: 2 }}
          >
            <FormItem
              label={t('DRESSCODE_LABEL')}
              name="dress_code"
              //rules={[...requiredRule(t), ...maxLengthRule(255, t)]}
            >
              <Input size="large" />
            </FormItem>
          </Col>
        </Row>
      </FormItem>
      <FormItem
        label={t('ABOUT_COMPANY_LABEL')}
        name="description"
        //rules={requiredRule(t)}
      >
        <Textarea autoSize={{ minRows: 4 }} />
      </FormItem>
      <FormItem
        label={t('ABOUT_COMPANY_LABEL_2')}
        name="difference_description"
        //rules={requiredRule(t)}
      >
        <Textarea autoSize={{ minRows: 4 }} />
      </FormItem>
    </>
  );
};

export default CompanyInfo;
