import React from 'react';
import styled from 'styled-components';
interface RoleProps {
  role: string;
  company: string;
  color?: 'light' | 'dark';
}
const RoleItem = styled.p`
  font-size: 0.75rem;
  line-height: 1.33;
  color: #061c2e;
  margin: 0;
  &.light {
    color: #627d95;
  }
`;
const CandidateRole = ({
  role,
  company,
  color = 'dark',
}: RoleProps) => {
  return (
    <RoleItem className={color}>
      {role} at {company}
    </RoleItem>
  );
};

export default CandidateRole;
