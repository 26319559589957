import { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, message } from 'antd';
import axios from 'axios';
import { useAppSelector } from '../../../store/hooks';
import { ADMIN_ROLE } from '../../../constants/roles';
import { getCityList, getRegions } from '../../../api/regions';
import { getIndustries } from '../../../api/industries';
import { createClientAddressList, editClientCompany } from '../../../api/client';
import { getToken } from '../../../utils/localStorage';
import { useAppDispatch } from '../../../store/hooks';
import { getUserByToken } from '../../../store/userSlice';
import { getJob } from '../../../api/jobs';
import { Company } from '../../../types';
import {
  getCompanyByName,
  getClientCompanyById,
} from '../../../api/company';

interface Iprops {
  jobId: string,
}

interface SimpleCompanyInfo {
  id: string,
  name: string,
  city: string
}

interface SimpleJobInfo {
  job_type: string,
  specialization: string,
  required_seniority: string,
  'title': string,
  'description': string
}

interface SimpleCandidateProfile {
  'key_skills': string[],
  'preferable_skills': string[],
  'languages': string[],
  'is_visa_available': boolean
}

interface SimpleOffer {
  salary_from: number,
  salary_max: number
}

interface SimpleAccount {
  email: string,
  name: string
}

interface SimpleInterviewProcess {
  stages: string[],
}

export interface SimpleJob {
  company: SimpleCompanyInfo,
  info: SimpleJobInfo,
  candidate_profile: SimpleCandidateProfile,
  offer: SimpleOffer,
  interview_process: SimpleInterviewProcess,
  account: SimpleAccount,
}

const useCompanyInfoForm = ({ jobId }: Iprops) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { user } = useAppSelector((state) => state.user);
  const [company, setCompany] = useState<Company | null>(null);
  const isAdmin = user?.role === ADMIN_ROLE;

  const [blockTransition, setBlockTransition] = useState(false);
  const [loading, setLoading] = useState(false);

  /* office and address logic start*/
  const [validating, setValidating] = useState(false);
  const [officeLocations, setOfficeLocations] = useState<{ id: string; address: string }[]>([]);
  const [address, setAddress] = useState('');

  /* get base data start */
  const [cities, setCities] = useState<{ id: string; name: string }[]>([]);
  const [regions, setRegions] = useState<{ id: string; name: string }[]>([]);
  const [industries, setIndustries] = useState<{ id: string; name: string }[]>([]);
  const [companies, setCompanies] = useState<{ id: string; name: string }[]>([]);

  useEffect(() => {
    const query = [getCityList(), getIndustries(), getRegions()];

    axios.all(query).then((res) => {
      setCities(res[0].data);
      setIndustries(res[1].data);
      setRegions(res[2].data);
    })
  }, []);
  /* get base data end*/

  const validateCustomList = useCallback(() => {
    let validated = false;
    const hasError = officeLocations.map((el: any) => {
      if (!el.address) {
        setValidating(true);
      }
      return !!el.address;
    });
    hasError.push(!!address);
    validated = !hasError.includes(false);
    setValidating(!validated);
    return validated;
  }, [address, officeLocations]);

  /* setup company for Client */
  useEffect(() => {
    if (user && !isAdmin) {
      setCompany(user.company);
    }
  }, [user, setCompany, isAdmin]);

  /* setup company for Admin */
  useEffect(() => {
    if (isAdmin && jobId && jobId !== 'new') {
      // can show company only for jobs what already exists
      getJob(jobId).then((res) => {
        setCompany(res.data.company);
      });
    }

    if (user) {
      getCompanyByName('').then((res) => setCompanies(res.data));
    }
  }, [isAdmin, jobId, user, setCompany]);

  useEffect(() => {
    if (company) {
      const fieldsData = {
        ...company,
        city: company?.city?.id,
        industry: company?.industry?.id,
        region: company?.region?.id,
      }
      form.setFieldsValue(fieldsData);
      setOfficeLocations(company.office_locations);
      setAddress(company.address);
    }
  }, [company, form, setOfficeLocations])

  const handleFieldsChange = useCallback(() => {
    setBlockTransition(true);
  }, [setBlockTransition]);

  const onFinish = useCallback((values: any) => {
    if (company && validateCustomList()) {
      setLoading(true);
      createClientAddressList(company.id, officeLocations).then((res) => {
        editClientCompany({ ...values, address }, company.id)
          .then((res) => {
            const token = getToken('access_token');
            dispatch(getUserByToken(token));

            setLoading(false);

            message.success(t('INFORMATION_SAVE_SUCCESS'), 5);
          })
          .catch(() => setLoading(false));
      });
    }
  }, [
    company,
    address,
    validateCustomList,
    t,
    officeLocations,
    dispatch,
  ]);


  const handleSelectCompany = useCallback((companyId) => {
    getClientCompanyById(companyId).then((res) => setCompany(res.data));
  }, [setCompany]);

  return {
    actions: {
      handleFieldsChange,
      setOfficeLocations,
      setAddress,
      onFinish,
      handleSelectCompany,
    },
    data: {
      blockTransition,
      form,
      company,
      isAdmin,
      cities,
      officeLocations,
      validating,
      address,
      regions,
      industries,
      loading,
      companies,
    },
  };
};

export default useCompanyInfoForm;
