import styled from 'styled-components';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

import { ReactComponent as InfoIcon } from '../../icons/info-icon-rounded.svg';
import { ReactComponent as PlusIcon } from '../../icons/plus.svg';
import { ReactComponent as DeleteIcon } from '../../icons/delete.svg';
import AddressAutocomplete from '../../components/form/shared/AddressAutocomplete';
import Button from '../buttons/Button';
import { deleteClientLocation } from '../../api/client';
import FormItem from '../../components/form/FormItem';

interface DynamicInputProps {
  locations: { id: string; address: string }[];
  setLocations: (data: any) => void;
  companyId: string;
  validating?: boolean;
  tooltip?: string;
}

const StyledInput = styled.div`
  display: flex;
  align-items: center;
`;
const StyledInputItem = styled.div`
  margin-bottom: 1.5rem;
`;

const StyledButton = styled(Button)`
  margin-bottom: 2.5rem;
`;

const InputLeft = styled.div`
  width: 100%;
`;

const DeleteButtonWrap = styled.div`
  width: 56px;

  & .delete-icon {
    color: #eb5757;
    cursor: pointer;
    margin-left: 1rem;
  }
`;

const DynamicInputs = ({
  locations,
  validating,
  companyId,
  setLocations,
  tooltip,
}: DynamicInputProps) => {
  const [t] = useTranslation();
  const remove = (idx: number, id: string) => {
    if (id) {
      deleteClientLocation(id, companyId)
        .then((res) => message.success(t('LOCATION_DELETE_SUCCESS')))
        .catch((e) => e);
    }
    setLocations(locations.filter((el, i) => idx !== i));
  };
  const add = () => {
    setLocations([...locations, { id: '', address: '' }]);
  };
  const handleSave = (index: number, search: string) => {
    let newLoc = [...locations];
    newLoc[index] = { ...newLoc[index], address: search };
    setLocations(newLoc);
  };

  return (
    <>
      <FormItem
        tooltip={tooltip ? { title: tooltip, icon: <InfoIcon /> } : false}
        label={t('OFFICE_LOCATIONS_LABEL')}
      >
        <>
          {locations.map((location, index) => {
            return (
              <StyledInputItem key={index}>
                <StyledInput>
                  <InputLeft>
                    <AddressAutocomplete
                      value={location.address}
                      locations={locations}
                      index={index}
                      handleSave={handleSave}
                    />
                  </InputLeft>
                  <DeleteButtonWrap>
                    {index !== 0 && (
                      <DeleteIcon
                        onClick={() => remove(index, location.id)}
                        className="delete-icon"
                      />
                    )}
                  </DeleteButtonWrap>
                </StyledInput>
                {validating && !location.address && (
                  <div className="ant-form-item-explain ant-form-item-explain-error">
                    <div role="alert">{t('REQUIRED_FIELD')}</div>
                  </div>
                )}
              </StyledInputItem>
            );
          })}
        </>
        <StyledButton
          onClick={() => add()}
          block
          icon={<PlusIcon />}
          style={{ maxWidth: 'max-content' }}
        >
          {t('ADD_LOCATION_TITLE')}
        </StyledButton>
      </FormItem>
    </>
  );
};

export default DynamicInputs;
