import React, { useEffect, useState } from 'react';
import { getSimilarJobs } from '../../../api/jobs';
import { Job } from '../../../types';
import styled from 'styled-components';
import { Col, Row } from 'antd';
import { Link } from 'react-router-dom';
import { JOBS } from '../../../constants/routes';
import { useTranslation } from 'react-i18next';


interface SimilarJobProps {
  jobId: string
}

const Title = styled.h3`
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.33;
  color: #061c2e;
  margin-bottom: 1.5rem;
`;
const SimilarJobWrapper = styled.div`
  margin-top: 5rem;
  width: 100%;
  height: 100%;
`;
const ListItemWrapper = styled.div`
  padding: 0 0 3.5rem;
  border-radius: .5rem;
  background-color: #ffffff;
  overflow: hidden;
  height: 100%;
`;
const ItemBanner = styled.div`
  width: 100%;
  height: 10vw;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  &:after{
    content: '';
    position:absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    background-color: rgba(6, 29, 49, 0.6);
  }
  @media (max-width: 992px){
  height: 20vw;
  }
   @media (max-width: 768px){
  height: 30vw;
  }
`;
const ItemBannerLogo = styled.div`
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  top: 100%;
  left: 2rem;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1.5rem;
  z-index: 999;
  background: #ffffff;
  & img{
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
const ItemBody = styled.div`
 padding: 3.5rem 2rem 0;
`;
const ItemJobTitle = styled(Link)`
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.2;
  color: #061c2e;
  max-height: 3rem;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  margin-bottom: .5rem;
`;
const ItemJobDescription = styled.p`
  font-size: .75rem;
  line-height: 1.33;
  color: #061c2e;
  min-height: 2rem;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  margin-bottom: 0;
`;
const SimilarJobSection = ({ jobId }: SimilarJobProps) => {
  const [jobs, setJobs] = useState<Job[]>([]);
  const [t] = useTranslation();
  useEffect(() => {
    getSimilarJobs(jobId)
      .then(res => setJobs(res.data));
  }, [jobId]);
  return (
    <SimilarJobWrapper>
      <Title>{t('SIMILAR_JOBS')}</Title>
      <Row gutter={[16, 16]}>
        {jobs.length ? jobs.map(job => <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} key={job.id}>
          <ListItemWrapper>
            <ItemBanner style={{ backgroundImage: `url(${job.bg_image ? job.bg_image : process.env.PUBLIC_URL + '/images/banner-img-placeholder.jpg'})` }}>
              <ItemBannerLogo>
                <img src={`${ job.company?.image ? job.company?.image :  process.env.PUBLIC_URL + '/images/company-logo.png'}`} alt={job.company?.name}/>
              </ItemBannerLogo>
            </ItemBanner>
            <ItemBody>
              <ItemJobTitle to={JOBS + '/' + job.id}>{job.title}</ItemJobTitle>
              <ItemJobDescription>{job.short_description}</ItemJobDescription>
            </ItemBody>
          </ListItemWrapper>
        </Col>)
          : <Col><ItemJobDescription>{t('NO_SIMILAR_JOB')}</ItemJobDescription></Col>
        }
      </Row>
    </SimilarJobWrapper>
  );
};

export default SimilarJobSection;
