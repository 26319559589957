import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import AddressAutocomplete from '../../components/form/shared/AddressAutocomplete';

interface AddressProps {
  label?: string;
  address: string;
  setAddress: (data: any) => void;
  validating?: boolean;
}


const StyledAutocomplete = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 1.5rem;
`;

const AutocompleteLabel = styled.p`
  font-size: 0.615rem;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 1.6;
  letter-spacing: 0.8px;
  color: #061c2e;
  margin-bottom: 0.5rem;
`;

const AddressField = ({
  label,
  address,
  validating,
  setAddress,
}: AddressProps) => {
  const [t] = useTranslation();

  const handleAddress = (value: string) => {
    setAddress(value);
  };

  return (
    <StyledAutocomplete>
      <label>
        <AutocompleteLabel>{label ? label : ''}</AutocompleteLabel>
      </label>
      <AddressAutocomplete handleAddress={handleAddress} value={address} />
      {validating && !address && (
        <div className="ant-form-item-explain ant-form-item-explain-error">
          <div role="alert">{t('REQUIRED_FIELD')}</div>
        </div>
      )}
      {validating && address && address.length > 255 && (
        <div className="ant-form-item-explain ant-form-item-explain-error">
          <div role="alert">{t('MAX_LENGTH_RULE', { number: 255 })}</div>
        </div>
      )}
    </StyledAutocomplete>
  );
};

export const AddressFieldInput = ({
  address,
  validating,
  setAddress,
}: AddressProps) => {
  const [t] = useTranslation();

  const handleAddress = (value: string) => {
    setAddress(value);
  };

  return (
    <>
      <AddressAutocomplete handleAddress={handleAddress} value={address} />
      {validating && !address && (
        <div className="ant-form-item-explain ant-form-item-explain-error">
          <div role="alert">{t('REQUIRED_FIELD')}</div>
        </div>
      )}
      {validating && address && address.length > 255 && (
        <div className="ant-form-item-explain ant-form-item-explain-error">
          <div role="alert">{t('MAX_LENGTH_RULE', { number: 255 })}</div>
        </div>
      )}
    </>
  );
};

export default AddressField;
