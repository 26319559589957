import axios from 'axios';

const api_url = process.env.REACT_APP_API_URL;

export const getSpecializationList = async () => {
  return await axios.get(`${api_url}specializations/`);
};

export const setSpecializationList = async (data: any) => {
  return await axios.put(`${api_url}recruiters/me/specializations/`, data);
};
