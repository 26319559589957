import React from 'react';
import { Table } from 'antd';
import { CandidateInfo } from '../../types';
import { ColumnsType } from 'antd/es/table';
import CandidateNameAvatar from '../candidates/CandidateNameAvatar';
import CandidateRole from '../candidates/CandidateRole';
import CandidateRecruiterInfo from '../candidates/CandidateRecruiterInfo';
import InfoText from '../candidates/InfoText';
import MatchTag from '../candidates/MatchTag';
import { useTranslation } from 'react-i18next';
import Button from '../../UI/buttons/Button';
import { ReactComponent as ArrowRightIcon } from '../../icons/arrow-right.svg';
import { addDecimalPoints, generateKey } from '../../utils/candidate';
import { CANDIDATES } from '../../constants/routes';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

interface CandidateList {
  candidates: CandidateInfo[];
  setCandidates: React.Dispatch<React.SetStateAction<CandidateInfo[]>>;
  setInfo: React.Dispatch<React.SetStateAction<CandidateInfo | null>>;
}
const StyledTable = styled(Table)`
  & .ant-table-tbody > tr > td {
  padding: 1rem .5rem;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
      & button {
        margin-left: auto;
      }
    }
  }
`;
const ClientCandidates = ({ candidates, setInfo }: CandidateList) => {
  const [t] = useTranslation();
  const history = useHistory();
  const handleInfo = (el: CandidateInfo) => {
    const search = new URLSearchParams(history.location.search);
    search.set('history', el.id);
    search.set('interview_id', el.interviews[0].id);
    const query = search.toString();
    history.push(CANDIDATES + '?' + query);
  };
  const columns: ColumnsType<any> = [
    {
      title: '',
      width: 150,
      key: 'name',
      responsive: ['sm', 'xxl', 'xl', 'lg', 'md'],
      dataIndex: 'name',
      render: (name, record) => (
        <CandidateNameAvatar name={name} color={record.color} />
      ),

      fixed: 'left',
    },
    {
      title: '',
      width: 170,
      dataIndex: 'name',
      key: 'name-sm',
      responsive: ['xs'],
      render: (name, record) => (
        <CandidateNameAvatar name={name} color={record.color} />
      ),
    },
    {
      title: '',
      width: 210,
      key: 'role',
      dataIndex: 'current_role',
      render: (role, record) => (
        <CandidateRole
          company={record.current_company}
          role={record.current_role}
          color="light"
        />
      ),
    },
    {
      title: '',
      width: 60,
      key: 'salary',
      dataIndex: 'salary',
      render: (salary) => (
        <InfoText>{`€${salary && addDecimalPoints(salary.toString())
          }`}</InfoText>
      ),
    },
    {
      title: '',
      width: 160,
      key: 'interviews',
      dataIndex: 'interviews',
      render: (interviews, record) =>
        interviews?.length && (
          <CandidateRecruiterInfo
            recruiter={
              interviews[0]?.recruiter || { image: '', company: '', name: '', id: '' }
            }
            color={record.color}
          />
        ),
    },
    {
      title: '',
      width: 160,
      key: 'interviews',
      dataIndex: 'interviews',
      render: (interviews) =>
        interviews?.length && <InfoText>{interviews[0].job?.title}</InfoText>,
    },
    {
      title: '',
      width: 90,
      key: 'interviews',
      dataIndex: 'interviews',
      render: (interviews) =>
        interviews[0].match_score > 89 && <MatchTag>{t('TOP_MATCH')}</MatchTag>,
    },
    {
      title: '',
      width: 100,
      key: 'action',
      responsive: ['sm', 'xxl', 'xl', 'lg', 'md'],
      dataIndex: 'id',
      fixed: 'right',
      render: (id, record) => (
        <Button size="small" type="primary" onClick={() => handleInfo(record)}>
          {t('REVIEW')}
          <ArrowRightIcon style={{ marginRight: 0, marginLeft: '.5rem' }} />
        </Button>
      ),
    },
    {
      title: '',
      width: 110,
      dataIndex: 'id',
      key: 'action-xs',
      responsive: ['xs'],
      render: (id, record) => (
        <Button size="small" type="primary" onClick={() => setInfo(record)}>
          {t('REVIEW')}
          <ArrowRightIcon style={{ marginRight: 0, marginLeft: '.5rem' }} />
        </Button>
      ),
    },
  ];
  return (
    <StyledTable
      locale={{ emptyText: ' ' }}
      columns={columns}
      dataSource={candidates}
      showHeader={false}
      scroll={{ x: 950 }}
      pagination={false}
      rowKey={() => generateKey(5)}
    />
  );
};

export default ClientCandidates;
