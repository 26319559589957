import React from 'react';
import { useTranslation } from 'react-i18next';

import { Prompt } from '../styles';
import TooltipPrompt from '../../../../../UI/tootlip';
import { CLIENT_ROLE, RECRUITER_ROLE, ADMIN_ROLE } from '../../../../../constants/roles';

interface Iprops {
  waitingFor: string,
  role: string,
  diffInHours: number,
}

const ItemFooterOutdated = ({
  role,
  waitingFor,
  diffInHours,
}: Iprops) => {
  const [t] = useTranslation();

  const isCurrentUser = waitingFor === role;
  const isAdmin = role === ADMIN_ROLE;
  const isOutdatedMoreThan48h = Math.abs(diffInHours) > 48;

  let waitingForRole;
  if (waitingFor === CLIENT_ROLE) waitingForRole = t('CLIENT_TITLE');
  if (waitingFor === RECRUITER_ROLE) waitingForRole = t('RECRUITER_TITLE');

  return (
    <Prompt
      color='error'
    >
      {isCurrentUser && isAdmin ? (
        t('BOTH_PARTIES_CALL_TO_ACTION')
      ) : (
        <>
          {isCurrentUser ? (
            <>
              {isOutdatedMoreThan48h ? (
                t('MISSED_ACTION_TEXT') // You should have taken action
              ) : (
                t('REQUIRED_ACTION_TEXT', { // Your action was required n hours ago
                  time: Math.abs(diffInHours) || 1,
                })
              )}
            </>
          ) : (
            <TooltipPrompt title={t('OUTDATED_ACTION_TOOLTIP')}>
              {isOutdatedMoreThan48h ? (
                t('SHOULD_RESPOND_TEXT_SIMPLE', { // The {{role}} should have responded
                  role: waitingForRole,
                })
              ) : (
                t('SHOULD_RESPOND_TEXT', { // The {{role}} should have responded {{time}} hrs ago
                  time: Math.abs(diffInHours) || 1,
                  role: waitingForRole,
                })
              )}
            </TooltipPrompt>
          )}
        </>
      )}
    </Prompt>
  );
};

export default React.memo(ItemFooterOutdated);