import React from 'react';
import { useTranslation } from 'react-i18next';

import { InterviewRoom } from '../../../../../types';
import {
  TasksList,
  ButtonWrap,
} from './styles';
import ListItem from './ListItem';
import Button from '../../../../../UI/buttons/Button';
import useTasks from './hooks';

interface IProps {
  interview: InterviewRoom | { [key:string]: any };
}

const RecruiterOfferTasks = ({
  interview,
}: IProps) => {
  const [t] = useTranslation();

  const {
    actions: {
      handleShowOfferModal
    },
    data: {
      feedbackTime,
      isOfferWaitingForRucruiter,
      isOfferCompleted,
    }
  } = useTasks({ interview });

  const isOfferDone = isOfferWaitingForRucruiter || isOfferCompleted;

  let offerText = '';
  if (isOfferDone) {
    offerText = t('STAGE_TASK_OFFER_WAS_MADE');
  } else {
    if (feedbackTime > 0) {
      offerText = t('RECRUITER_OFFER_STAGE_TITLE_1', { numberOfHours: feedbackTime })
    } else {
      offerText = t('RECRUITER_OFFER_STAGE_TITLE_1_OUTDATED')
    }
  }

  return (
    <TasksList>
      <ListItem
        isActive
        isActiveLine={isOfferDone}
        isDone={isOfferDone}
        title={offerText}
      />

      <ListItem
        isActive={isOfferDone}
        isActiveLine={false}
        isDone={isOfferCompleted}
        title={isOfferCompleted ?
          t('STAGE_TASK_OFFER_ACCEPTED')
          :
          t('RECRUITER_OFFER_STAGE_TITLE_2')
        }
        isButton={isOfferWaitingForRucruiter}
        handleClick={isOfferWaitingForRucruiter ? handleShowOfferModal : undefined}
      >
        {isOfferWaitingForRucruiter && (
          <ButtonWrap>
            <Button
              size="small"
              onClick={handleShowOfferModal}
            >
              {t('REVIEW_OFFER_TASK')}
            </Button>
          </ButtonWrap>
          )}
      </ListItem>
    </TasksList>
  )
}

export default React.memo(RecruiterOfferTasks);