import axios from 'axios';
import { History } from 'history';

const url = process.env.REACT_APP_API_URL;

export const getInterview = (interview_id: string, history?: History) => {
  return axios
  .get(`${url}interviews/${interview_id}/`)
  .then((res) => res)
  .catch((e) => {
    if (e?.response?.data?.error_code === 'resource_not_found' && history) history.push('/not-found');
    return e.response;
  });
};

export const getInterviewTaskList = (interview_id: string) => {
  return axios
  .get(`${url}interviews/${interview_id}/tasks/`)
  .then((res) => res)
  .catch((e) => {
    return e.response;
  });
};

export const completeTask = (interview_id: string, task_id: string) => {
  return axios.post(
    `${url}interviews/${interview_id}/tasks/${task_id}/complete/`,
  );
};

export const updateTask = (
  interview_id: string,
  task_id: string,
  data: any,
) => {
  return axios.patch(
    `${url}interviews/${interview_id}/tasks/${task_id}/`,
    data,
  );
};

export const moveToNextTask = (
  interview_id: string,
  stage_name: string,
  next_stage_id?: string,
  reason_for_change?: string,
) => {
  let data: { [key: string]: string } = {};
  if (next_stage_id) {
    data['stage_id'] = next_stage_id;
  } else {
    data['stage_name'] = stage_name;
  }
  if (reason_for_change) {
    data['reason_for_change'] = reason_for_change;
  }
  return axios.post(`${url}interviews/${interview_id}/next-stage/`, {
    ...data,
  });
};

export const getProposedDates = (interview_id: string) => {
  return axios
  .get(`${url}interviews/${interview_id}/proposed-dates/`)
  .then((res) => res)
  .catch((e) => {
    return e.response;
  });
};

export const proposeInterviewDates = (
  interview_id: string,
  proposed_dates: any,
) => {
  return axios.post(`${url}interviews/${interview_id}/proposed-dates/`, {
    proposed_dates,
  });
};

export const confirmProposedDate = (interview_id: string, date_id: string) => {
  return axios.post(
    `${url}interviews/${interview_id}/proposed-dates/${date_id}/confirm/`,
  );
};

export const updateInterviewDates = (
  interview_id: string,
  proposed_date: any,
) => {
  return axios.post(`${url}interviews/${interview_id}/planning-dates/`, {
    ...proposed_date,
  });
};

export const getClientInterviewStages = () => {
  return axios.get(`${url}interviews/stages/`);
};

export const sendFeedback = (interview_id: string, data: any) => {
  return axios
  .post(`${url}interviews/${interview_id}/feedbacks/`, data)
  .then((res) => res)
  .catch((e) => {
    return e.response;
  });
};

export const getFeedback = (interview_id: string, feedback_id: string) => {
  return axios
  .get(
    `${url}interviews/${interview_id}/feedbacks/${feedback_id}/`,
  )
  .then((res) => res)
  .catch((e) => {
    return e.response;
  });
};

export const getRejectedFeedback = (interview_id: string) => {
  return axios
  .get(`${url}interviews/${interview_id}/rejection-feedback/`)
  .then((res) => res)
  .catch((e) => {
    return e.response;
  });
};

export const sendOffer = (interview_id: string, data: any) => {
  return axios.post(`${url}interviews/${interview_id}/offer/`, data);
};

export const confirmOffer = (interview_id: string) => {
  return axios.post(`${url}interviews/${interview_id}/offer/confirm/`);
};
export const rejectOffer = (interview_id: string, data: any) => {
  return axios.post(`${url}interviews/${interview_id}/offer/reject/`, data);
};
export const disagreeOffer = (interview_id: string, data: any) => {
  return axios.post(`${url}interviews/${interview_id}/offer/disagree/`, data);
};
export const rateInterview = (interview_id: string, data: any) => {
  return axios.post(`${url}interviews/${interview_id}/ratings/`, data);
};
