import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface CheckboxGroupProps {
  children: ReactNode;
}
const GroupedCheckbox = styled.div`
  display: flex;
  flex-wrap: wrap;
  & .ant-form-item:first-child .ant-checkbox-wrapper {
    border-bottom-left-radius: 0.5rem;
    border-top-left-radius: 0.5rem;
  }
  & .ant-form-item:last-child .ant-checkbox-wrapper {
    border-bottom-right-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    border-right: none;
  }
`;
const CheckboxButtonGroup = ({ children }: CheckboxGroupProps) => {
  return <GroupedCheckbox>{children}</GroupedCheckbox>;
};

export default CheckboxButtonGroup;
