import React, { useCallback, useEffect, useState } from 'react';
import FormItem from '../FormItem';
import { maxLengthRule, requiredRule } from '../../../utils/validationRules';
import { Col, Row, Select } from 'antd';
import { getClientCompanyById, getCompanyByName } from '../../../api/company';
import { useTranslation } from 'react-i18next';
import { Client } from '../../../types';
import SelectInput from '../../../UI/inputs/Select';
import Input from '../../../UI/inputs/Input';

interface CompanyProps {
  id: string;
  name: string;
}

interface PersonalProps {
  form?: any | undefined;
  setLocations?: (data: any) => void;
  setPhone: React.Dispatch<React.SetStateAction<string>>;
  setAddress?: (data: string) => void;
  disableFields?: boolean;
  disableDepartment?: boolean;
  phone: string;
  user?: Client;
}

const { Option } = Select;

const PersonalInfo = ({
  form,
  phone,
  setLocations,
  setAddress,
  setPhone,
  disableDepartment,
  disableFields,
  user,
}: PersonalProps) => {
  const [companies, setCompanies] = useState<{ id: string; name: string }[]>(
    [],
  );
  const { t } = useTranslation();
  const handleSearch = async (value: string) => {
    await getCompanyByName(value).then((res) => setCompanies(res.data));
  };
  const handleSelectCompany = useCallback(
    (value: string) => {
      getClientCompanyById(value).then((res) => {
        const { data } = res;
        form.setFieldsValue({
          average_age: data.average_age,
          city: data.city?.id,
          description: data.description,
          difference_description: data.difference_description,
          dress_code: data.dress_code,
          industry: data.industry?.id,
          male_percentage: data.male_percentage,
          company_name: data.name,
          notable_clients: data.notable_clients,
          number_of_employees: data.number_of_employees,
          region: data.region?.id,
        });
        setLocations &&
          setLocations(
            data.office_locations.length
              ? data.office_locations
              : [{ id: '', address: '' }],
          );
        setAddress && setAddress(data.address);
      });
    },
    [form, setAddress, setLocations],
  );
  useEffect(() => {
    if (user) {
      form.setFieldsValue({
        company: user.company.name,
        company_id: user.company.id,
        client_id: user.id,
        name: user.name === 'Username' ? '' : user.name,
        title: user.title,
        department: user.department,
        email: user.email,
      });
      setPhone(user.phone)
      handleSelectCompany(user.company.id);
    }
  }, [user, form, handleSelectCompany,setPhone]);
  return (
    <>
      <FormItem
        label={t('NAME_LABEL')}
        name="name"
        rules={[...requiredRule(t), ...maxLengthRule(128, t)]}
      >
        <Input size="large" />
      </FormItem>
      <FormItem name="company_id" hidden>
        <Input size="large" />
      </FormItem>
      <FormItem name="client_id" hidden>
        <Input size="large" />
      </FormItem>
      <FormItem className="no-margin">
        <Row>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 11 }}
            md={{ span: 24 }}
            lg={{ span: 11 }}
          >
            <FormItem
              label={t('TITLE_TEXT')}
              name="title"
              rules={[...requiredRule(t), ...maxLengthRule(255, t)]}
            >
              <Input size="large" />
            </FormItem>
          </Col>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 11, offset: 2 }}
            md={{ span: 24, offset: 0 }}
            lg={{ span: 11, offset: 2 }}
          >
            <FormItem label={t('COMPANY_TITLE')} name="company">
              <SelectInput
                disabled={disableFields}
                showSearch
                allowClear
                size="large"
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={handleSearch}
                notFoundContent={null}
                onChange={(e) => {
                  handleSelectCompany(e ? e.toString() : '');
                  form.setFieldsValue({ company: e });
                }}
              >
                {companies.map((company: CompanyProps) => (
                  <Option key={company.id} value={company.id}>
                    {company.name}
                  </Option>
                ))}
              </SelectInput>
            </FormItem>
          </Col>
        </Row>
      </FormItem>
      <FormItem
        label={t('DEPARTMENT_TITLE')}
        name="department"
        rules={requiredRule(t)}
      >
        <Input disabled={disableDepartment} size="large" />
      </FormItem>
      <FormItem className="no-margin">
        <Row gutter={16}>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 24 }}
            lg={{ span: 12 }}
          >
            <FormItem
              label={t('EMAIL_LABEL')}
              name="email"
              rules={[
                {
                  type: 'email',
                  message: t('INVALID_EMAIL_ERROR'),
                  required: true,
                },
              ]}
            >
              <Input size="large" disabled={disableFields} />
            </FormItem>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 24 }}
            lg={{ span: 12 }}
          >
            <FormItem
              label={t('PHONE_TEXT')}
            >
              <Input
                size="large"
                onChange={(e) => {
                  if (e.target.value){
                    const regex = /^[+]?\d+$/;
                    if (regex.test(e.target.value)) {
                      setPhone(e.target.value);
                    }
                  }else{
                    setPhone(e.target.value);
                  }
                }}
                value={phone}
              />
            </FormItem>
          </Col>
        </Row>
      </FormItem>
    </>
  );
};

export default PersonalInfo;
