import React, { useCallback } from 'react';

import { ReactComponent as ChangeUser } from '../../../icons/change-user.svg';
import useSwitchUser from './hooks';
import { SwitchButton } from './styled';
import SwitchUserPopup from './SwitchUserPopup';

const SwitchUser = () => {
  const {
    actions: {
      setVisible,
    },
    data: {
      isVisible,
    }
  } = useSwitchUser();

  const toggleShowPopup = useCallback((e:any) => {
    setVisible(!isVisible);
  }, [isVisible, setVisible]);

  return (
    <div>
      <SwitchButton
        onClick={toggleShowPopup}>
        <ChangeUser />
      </SwitchButton>
      <SwitchUserPopup
        isVisible={isVisible}
        setVisible={setVisible}
      />
    </div>
  );
}

export default React.memo(SwitchUser);
