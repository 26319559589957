import React from 'react';
import Button from './buttons/Button';
import { JobInfo } from "../components/jobs/jobList/ClientJobListItem";
import { JobDescriptionWrapper } from '../components/jobs/jobList/JobDescription/styles';
import CheckboxInput from './inputs/CheckboxInput';
import styled from 'styled-components';


const ListItem = styled.div`
  background: #ffffff;
  border-bottom: 1px solid #dae5ec;
  padding: 1.5rem 0 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 992px) {
    flex-wrap: wrap;
  }
`;

const CandidatesInProgressWrapper = styled.div`
  width: 25%;
`
const Left = styled.div`
  width: 40%;
  text-align: right;
`
const Right = styled.div`
  width: auto;
`

function CandidatesColumn() {
  return (
    <CandidatesInProgressWrapper>
      <Left>Candidates in progess</Left>
      <Right></Right>
    </CandidatesInProgressWrapper>
  )
}

interface ClientJobListHeaderProps {
  selectedJob: string | null;
  setShowModal: React.Dispatch<React.SetStateAction<any>>;
  showModal: boolean;
}
export default function ClientJobListHeader({selectedJob, setShowModal, showModal}: ClientJobListHeaderProps) {

  return (
    <ListItem>
      <CheckboxInput style={{visibility: "hidden"}} />
      <JobDescriptionWrapper>Job name</JobDescriptionWrapper>
      <JobInfo>Details</JobInfo>
      <CandidatesColumn />
      <Button style={{minWidth: "15.5%"}} disabled={selectedJob === null} type="primary" htmlType="submit" onClick={() => {setShowModal(!showModal)}}>
        {"+ Assign talent suppliers"}
      </Button>
    </ListItem>
  )
}

