import styled from 'styled-components';
import {CLIENT} from "../../constants/routes";
import {Link} from "react-router-dom";
import React from "react";
import TooltipPrompt from "../../UI/tootlip";
import {useTranslation} from "react-i18next";

interface CandidateCompanyProps {
  company?: string;
  companyId?: string;
  role?: string;
  image?: string;
  align?: string;
  disabled?: boolean;
}

const Wrapper = styled.div`
  display: flex;
  &.center {
    align-items: center;
  }
`;

const Image = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  min-width: 2.5rem;
  min-height: 2.5rem;
  border: solid 1px #dae5ec;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 0.5rem;
  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const Role = styled.p`
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.43;
  color: #061c2e;
  margin-bottom: 0.25rem;
  position: relative;
  a {
    color: #000c17;
  }
`;

const DisabledRole = styled(Role)`
color: #627d95;
`;

const CompanyName = styled.p`
  font-size: 0.75rem;
  line-height: 1.33;
  color: #627d95;
  margin-bottom: 0;
  a {
    color: #627d95;
  }
`;

const TextSection = styled.div`
  margin-left: 0.5rem;
`;

const CandidateCompany = ({
  company,
  companyId,
  role,
  image,
  align,
  disabled,
}: CandidateCompanyProps) => {
  const [t] = useTranslation();
  return (
    <Wrapper className={`${align ? 'center' : ''}`}>
      <Image>
        <img
          src={
            image ? image : process.env.PUBLIC_URL + '/images/company-logo.png'
          }
          alt={company}
        />
      </Image>
      <TextSection>
        {disabled ? (
          <DisabledRole>{role}</DisabledRole>
        ): (
          <Role>{role}</Role>
        )}
        <CompanyName>{companyId ?
            <TooltipPrompt title={t('CLICK_TO_SEE_PROFILE')}><Link to={CLIENT + '/' + companyId}>{company}</Link></TooltipPrompt>
            :
            company}</CompanyName>
      </TextSection>
    </Wrapper>
  );
};

export default CandidateCompany;
