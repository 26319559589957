import React from 'react';
import styled from 'styled-components';

interface OfferTextProps {
  title: string;
  text?: string;
  direction?: string;
}
const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  &.vertical{
    flex-direction: column;
    margin-bottom: 2rem;
  }
`;
const Title = styled.h4`
  font-size: 0.625rem;
  font-weight: 700;
  line-height: 2.4;
  letter-spacing: 0.8px;
  text-align: left;
  color: #627d95;
  width: 40%;
  min-width: 40%;
  max-width: 40%;
  margin: 0 0 .5rem;
  text-transform: uppercase;
`;
const Text = styled.h4`
  font-size: 0.875rem;
  line-height: 1.71;
  text-align: left;
  color: #061c2e;
  width: 55%;
  min-width: 55%;
  max-width: 55%;
  margin: 0 0 .5rem;
`;
const OfferText = ({
  title,
  text,
  direction = 'horizontal',
}: OfferTextProps) => {
  return (
    <Wrapper className={direction}>
      <Title>{title}</Title>
      <Text>{text ? text : '-'}</Text>
    </Wrapper>
  );
};

export default OfferText;
