import React, { useEffect, useState } from 'react';
import { RouteProps, useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import axios from 'axios';
import { getUserByToken } from '../../store/userSlice';
import { LOGIN } from '../../constants/routes';
import { getToken } from '../../utils/localStorage';
import Error from '../../pages/Error';

interface GuestRouteProps extends RouteProps {
  component: any;
}
const GuestRoute = (props: GuestRouteProps) => {
  const { component: Component, ...rest } = props;
  const dispatch = useAppDispatch();
  const [error, setError] = useState(false);
  const { isAuth, user } = useAppSelector((state) => state.user);
  const history = useHistory();
  const token = getToken('access_token');
  useEffect(() => {
      axios.defaults.headers.common['Authorization'] = token
        ? `Token ${token}`
        : '';

      if (token && !isAuth) {
        dispatch(getUserByToken(token));
      }
      if (!token && !isAuth) {
        history.push(LOGIN);
      }
    axios.interceptors.response.use((response) => response, (error) => {
      if (error.response.status >= 500){
        setError(true)
      }else {
        return  Promise.reject(error);
      }
    });
  }, [dispatch, history, isAuth, token, user]);
  return <>{error ? <Error/> : user && <Component {...rest} />}</>;
};

export default GuestRoute;
