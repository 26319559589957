import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import TooltipPrompt from '../../../UI/tootlip';

interface FilterProps {
  onChange: (filter: string) => void;
}

const FilterWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: max-content;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  @media (max-width: 768px) {
    order: 3;
    max-width: 100%;
    margin: 1rem auto 0;
  }
`;
const FilterItem = styled.div`
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.33;
  letter-spacing: 0.96px;
  padding: 1rem 0;
  color: #061c2e;
  max-width: max-content;
  width: 100%;
  margin: 0 0.75rem;
  position: relative;
  text-transform: uppercase;
  cursor: pointer;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -1.5rem;
    height: 4px;
    width: 0;
    background: #34ce72;
    transition: width ease 0.3s;
  }
  &.active {
    &:before {
      width: 100%;
    }
  }
`;
const RecruiterHeaderFilters = ({ onChange }: FilterProps) => {
  const history = useHistory();
  const query = new URLSearchParams(history.location.search);
  const status = query.get('filter');
  const [filter, setFilter] = useState(status ? status : '');
  const [t] = useTranslation();
  const filterList = [
    {
      title: t('ALL_JOBS_FILTER_TITLE'),
      value: '',
      translation: 'ALL_JOBS_TOOLTIP',
    },
    {
      title: t('FAVORITES_TITLE'),
      value: 'favorites',
      translation: 'FAVORITE_JOBS_TOOLTIP',
    },
    {
      title: t('IN_PROCESS_TITLE'),
      value: 'in-process',
      translation: 'IN_PROCESS_JOBS_TOOLTIP',
    },
  ];
  const handleChange = (value: string) => {
    setFilter(value);
  };
  useEffect(() => {
    const query = new URLSearchParams(history.location.search);
    if (!filter) {
      query.delete('filter');
      history.push({ search: query.toString() });
    } else {
      query.set('filter', filter);
      history.push({ search: query.toString() });
    }
  }, [filter, history]);
  useEffect(() => {
    setFilter(status ? status : '');
  }, [status]);
  return (
    <FilterWrapper>
      {filterList.map((item) => (
        <FilterItem
          key={item.title}
          onClick={() => handleChange(item.value)}
          className={filter === item.value ? 'active' : ''}
        >
          <TooltipPrompt title={t(item.translation)}>{item.title}</TooltipPrompt>
        </FilterItem>
      ))}
    </FilterWrapper>
  );
};

export default RecruiterHeaderFilters;
