import axios from 'axios';

const api_url = process.env.REACT_APP_API_URL;

export const getEmploymentTypeList = async () => {
  return await axios.get(`${api_url}employment-types/`)
}

export const getEmploymentTypeById = async (id: string) => {
  return await axios.get(`${api_url}employment-types/${id}/`)
}
