import React from 'react';
import styled from 'styled-components';

interface SpotProps {
  color:string
  children:any
}

const SpotWrapper = styled.div`
  padding: .25rem .5rem;
  text-align: center;
  font-size: .625rem;
  font-weight: 700;
  line-height: 1.6;
  letter-spacing: 0.8px;
  color: #061c2e;
  text-transform: uppercase;
  margin: .5rem 0;
  position: relative;
`
const SpotItem = ({color,children,...rest}:SpotProps) => {
  return (
    <SpotWrapper style={{background: color}} {...rest}>
      {children}
    </SpotWrapper>
  );
};

export default SpotItem;
