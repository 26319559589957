import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
//import { ReactComponent as NavLogo } from '../../../icons/HirePort-Public.svg';

import { ReactComponent as MessageIcon } from '../../../icons/message-new.svg';

import {
  BannerWrapper,
  BannerOverlay,
  BannerContent,
  BannerHeader,
  BannerInfo,
  BannerLogo,
  BannerInfoText,
  JobTitle,
  JobInfoItem,
  ActionButtons,
  StyledButton,
} from './styles';
import React from "react";
import hireportLogoIcon from "../../../icons/logo-ufo.svg";
import { ReactComponent as StarIcon } from '../../../icons/start-rating.svg';
import {RecruiterProfile} from "../index";
import SvgIcon from "../../../components/helper/SvgIcon";
import BackButton from "../../../UI/buttons/BackButton";
import {HeaderTitle} from "../../../components/jobs/singleJob/Banner/styles";
import {useHistory} from "react-router-dom";
import {MESSAGES} from "../../../constants/routes";

interface BannerProps {
  loading: boolean;
  recruiter?: RecruiterProfile|null;
}

const Banner = ({
  loading,
  recruiter
}: BannerProps) => {
  const [t] = useTranslation();
  //const isClient = user?.role === CLIENT_ROLE;

  const defaultBackgroundImage = 'https://hireport-acceptance-storage-eu-central-1.s3.eu-central-1.amazonaws.com/media/assets/img-011.min.jpg';
  const bg_image = defaultBackgroundImage;
  const logo = recruiter?.image ?? hireportLogoIcon;
  const history = useHistory();
  const openChatWithRecruiter = () => {
    history.push(MESSAGES + '/' + recruiter?.id);
  }

  return (
    <BannerWrapper
      style={{
        backgroundImage: `url(${bg_image ? bg_image : defaultBackgroundImage}`,
        backgroundColor: bg_image ? '#fff' : 'inherit',
      }}
    >
      <BannerOverlay>
        <BannerContent>
          <BannerHeader>
            <BackButton onClick={() => history.goBack()} />
            <HeaderTitle>{t('BACK')}</HeaderTitle>
            {/*<NavLogo />*/}
          </BannerHeader>
          {!loading && (
            <BannerInfo>
              {logo && (
                <BannerLogo>
                  <img src={logo} alt="Logo" />
                </BannerLogo>
              )}
              <BannerInfoText>
                <JobTitle>
                  <h2>
                    {recruiter?.name}
                  </h2>
                </JobTitle>

                <JobTitle>
                  <h3>{`${recruiter?.title ? recruiter?.title : recruiter?.name} at ${recruiter?.company?.name}`}</h3>
                </JobTitle>

                <Row
                  gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}
                  justify="space-between"
                >
                  <Col xs={{ span: 24 }} md={{ span: 12 }} xl={{ span: 12 }}>
                    <Row
                        gutter={[8, { xs: 8, sm: 8, md: 8, lg: 8 }]}
                        justify="start"
                    >
                      {recruiter?.total_ratings && (
                        <JobInfoItem>
                          <StarIcon />
                          {recruiter?.total_ratings?.toFixed(1)}
                        </JobInfoItem>
                      )}
                      {recruiter?.badges && recruiter?.badges?.map((badge) => (
                          <JobInfoItem>
                            {badge.icon && (
                              <SvgIcon url={badge.icon} text={badge.name} />
                            )}
                          </JobInfoItem>
                      ))
                      }
                    </Row>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 24 }} xl={{ span: 6 }}>
                    <ActionButtons>
                          <StyledButton
                              icon={<MessageIcon />}
                              size="large"
                              type="primary"
                              onClick={openChatWithRecruiter}
                          >
                                {t('Chat')}
                          </StyledButton>
                    </ActionButtons>
                  </Col>
                </Row>
              </BannerInfoText>
            </BannerInfo>
          )}
        </BannerContent>
      </BannerOverlay>
    </BannerWrapper>
  );
};

export default Banner;
