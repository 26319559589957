import React, { useEffect, useState } from "react";
import { Col, Form, Row, Select } from 'antd';
import FormTitle from '../auth/FormTitle';
import { StyledModal } from '../jobs/UnpublishJobModal';
import FormItem from "../form/FormItem";
import Button from '../../UI/buttons/Button';
import SelectInput from "../../UI/inputs/Select";
import { ReactComponent as ArrowDownIcon } from '../../icons/arrow-down.svg';
import { Recruiter } from '../../types';
import { getSpecializationList } from "../../api/specialization";
import AddCompanyRecruiterForm from "./AddCompanyRecruiterForm";
import { getRecrutersSelectedForJob, getPreviouslySelectedRecruiters } from "../../api/recruiter";
import RecruiterManagementBoardFooter from "./RecruiterManagementBoardFooter";
import { useAppSelector } from "../../store/hooks";
import { ReactComponent as EmailIcon } from '../../icons/info-email.svg';
import styled from "styled-components";
import NewSearchInput from "../../UI/inputs/NewSearchInput";
import { RATING_FILTERS } from "../../constants/recruiters"
import { structuredDomains } from "../../utils/general";
import RecruiterSelectTable from "./RecruiterSelectTable"
import lodash from "lodash";
import { useTranslation } from 'react-i18next';

const StyledEmailIcon = styled(EmailIcon)`
  width: 20px;
  height: 20px;
  margin-right: 0;
  stroke-width: 1.5;
  & path {
    stroke: #34ce72;
    &:disabled,
    &.disabled {
      stroke: #aebeca;
    }
  }
`;

const MediumSelectInput = styled(SelectInput)`
  &.ant-select-multiple {
    & .ant-select-selection-item {
      line-height: 1.5rem;
      font-size: 0.75rem;
      color: #4b6276;
      border-radius: 0.25rem;
      background-color: #f2f6fa;
      padding: 0;
      padding-left: 5px;
    }
  }
`;

const EmailInviteBtn = styled(Button)`
  box-sizing: border-box;
  height: 40px;
  &:disabled,
  &.disabled {
    height: 40px;
    cursor: not-allowed;
    background: #fff;
    color: #aebeca;
    border-color: #aebeca;
    &:hover {
      background: #dde7ee;
      color: #ffffff;
      border-color: #dde7ee;
      & path {
        transition: all 0.3s;
        stroke: #fff;
      }
    }
    & path {
      stroke: #aebeca;
    }
  }
`;

const CustomFormTitle = styled(FormTitle)`
  font-size: 1.5rem;
`;

export const FormDescription = styled.p`
  font-size: 14px;
  line-height: 1.33;
  color: #324759;
  margin-bottom: 1.2rem;
`;

export type SortOrder = "ASC" | "DESC";
export type SortingType = "RATING" | "NAME" | "TYPE";

interface RecruiterManagementBoardProps {
    selectedJob: string | null;
    fromJobCreation: boolean;
    setIsModalVisible: React.Dispatch<React.SetStateAction<any>>;
}
export default function RecruiterManagementBoard({ selectedJob, fromJobCreation, setIsModalVisible }: RecruiterManagementBoardProps) {
    const [domains, setDomains] = useState<any[]>([]);
    const [isAddRecruiterFormVisible, setIsAddRecruiterFormVisible] = useState<boolean>(false);
    const [recruiters, setRecruiters] = useState<Recruiter[]>([]);
    const [next, setNext] = useState<string | null>(null);
    const [allSelected, setAllSelected] = useState<boolean>(false);
    const [recruitersSelectedInThisSession, setRecruitersSelectedInThisSession] = useState<string[]>([]);
    const [totalNumberOfAvailableRecruiters, setTotalNumberOfAvailableRecruiters] = useState<number>(0);
    const [previouslySelectedRecruiters, setPreviouslySelectedRecruiters] = useState<string[]>([]);
    const [isLoadingRecruiters, setIsLoadingRecruiters] = useState(false);
  const [t] = useTranslation();
    const user = useAppSelector((state) => state.user.user);
    const companyName = user?.company?.name;
    let [currentSortingType, setCurrentSortingType] = useState<SortingType>("NAME");
    let [currentSortingOrder, setCurrentSortingOrder] = useState<SortOrder>("ASC");
    let [sortQueryString, setSortQueryString] = useState("user__first_name");

    const setRecruiterIsSelectedField = (rec: Recruiter, new_is_selected_val: boolean) => {
        let updatedRecs = [...recruiters];

        const indexToUpdate = updatedRecs.findIndex(recruiter => recruiter.id === rec.id);
        // Create a copy of the recruiter object you want to update
        const updatedRecruiter = { ...updatedRecs[indexToUpdate] };

        // Update the desired field of the recruiter object
        updatedRecruiter.is_selected = new_is_selected_val;
        if (new_is_selected_val) {
            setRecruitersSelectedInThisSession((prevState) => [...prevState, updatedRecruiter.id])
        } else {
            setRecruitersSelectedInThisSession([...recruitersSelectedInThisSession.filter((rec: string) => rec !== updatedRecruiter.id)]);
        }

        // Replace the old recruiter object with the updated one
        updatedRecs[indexToUpdate] = updatedRecruiter;

        // Update the state
        setRecruiters(updatedRecs);
    }

    const handleSelectAll = (isChecked: boolean) => {
        setAllSelected(isChecked);
        setRecruiters(recruiters.map((rec) => {
            if (!isChecked && recruitersSelectedInThisSession.includes(rec.id)) {
                rec.is_selected = true;
                return rec;
            }
            rec.is_selected = isChecked;
            return rec;
        }));
        console.log(previouslySelectedRecruiters)
    }

    useEffect(() => {
        getSpecializationList().then((res) => {
            setDomains(structuredDomains(res.data))
        });
        //@ts-ignore
        getRecrutersSelectedForJob(`${companyName}-${selectedJob}&sort=${sortQueryString}`).then((res: any) => {
            setRecruiters(res.data.results)
            setNext(res.data.pagination.next);
            setTotalNumberOfAvailableRecruiters(res.data.pagination.count);
        })
        getPreviouslySelectedRecruiters(`${companyName}-${selectedJob}`).then((res: any) => {
            setPreviouslySelectedRecruiters(res.data.results);
            setRecruitersSelectedInThisSession(res.data.results);
        })
    }, [companyName, selectedJob, sortQueryString]);

    useEffect(() => {
        switch (currentSortingType) {
            case "NAME":
                if (currentSortingOrder === "ASC") setSortQueryString("user__first_name")
                if (currentSortingOrder === "DESC") setSortQueryString("-user__first_name")
                break;
            case "TYPE":
                if (currentSortingOrder === "ASC") setSortQueryString("own_recruiter")
                if (currentSortingOrder === "DESC") setSortQueryString("-own_recruiter")
                break;
            case "RATING":
                if (currentSortingOrder === "ASC") setSortQueryString("-overall_rating")
                if (currentSortingOrder === "DESC") setSortQueryString("overall_rating")
                break;
        }
        // eslint-disable-next-line
    }, [currentSortingType, currentSortingOrder])

    let onFilter = (_: any, allValues: any) => {
        let querySearch: any[] = [];

        allValues.forEach((elem: any) => {
            if (elem["name"][0] === "rating" || elem["name"][0] === "category") return;
            querySearch.push({ param: elem["name"][0], value: elem.value });
        })

        let queryString = querySearch.filter(elem => elem.value !== undefined)
            .map(elem => {
                if (Array.isArray(elem.value)) {
                    elem.value = elem.value.join(",");
                    return elem;
                }
                return elem;
            })
            .map(elem => `${elem.param}=${elem.value}`)
            .join("&");

        queryString = queryString + `&sort=${sortQueryString}`

        getRecrutersSelectedForJob(`${companyName}-${selectedJob}&${queryString}`).then((res) => {
            let newRecs = res.data.results;
            if (allSelected) {
                newRecs = newRecs.map((rec: Recruiter) => { rec.is_selected = true; return rec })
            }

            setRecruiters(newRecs.map((rec: Recruiter) => {
                if (recruitersSelectedInThisSession.includes(rec.id)) {
                    rec.is_selected = true;
                    return rec;
                }
                return  rec;
            }));
            setNext(res.data.pagination.next);
        });

    }

    onFilter = lodash.debounce(onFilter, 300, {trailing: true});


    const handleTableScroll = (e: any) => {
        if (next === null || isLoadingRecruiters) return;

        const { scrollTop, scrollHeight, clientHeight } = e.target;

        // Check if the scroll position is at the bottom
        // -1 fixed a bug not sure why, don't touch unless very neccessery
        if (scrollTop + clientHeight >= scrollHeight - 1) {
            setIsLoadingRecruiters(true);
            getRecrutersSelectedForJob(`${companyName}-${selectedJob}&cursor=${next}&sort=${sortQueryString}`).then((res) => {
                let newRecs = res.data.results;
                if (allSelected) {
                    newRecs = newRecs.map((rec: Recruiter) => { rec.is_selected = true; return rec })
                }
                setRecruiters((prevState: Recruiter[]) =>  [...prevState, ...newRecs]);
                setNext(res.data.pagination.next);
            }).finally(() => {
                setIsLoadingRecruiters(false);
            });
        }
    };

    const handleInviteRecruiterBtnClick = () => { setIsAddRecruiterFormVisible(true) }

    return (
        <>
            <Form
                scrollToFirstError={true}
                layout="horizontal"
                onFieldsChange={onFilter}
            >
                <CustomFormTitle text="Select talent suppliers" marginBottom="0.2em" />
                <FormDescription>Specify which talent supplier can introduce candidates. This is a new fresh feature, your feedback is greatly appreciated! As it’s still in beta, we offer it for free.</FormDescription>
                <Row justify="start" align="middle" gutter={10}>
                    <Col>
                        <FormItem
                            name="full_name"
                        >
                            <NewSearchInput placeholder="Full name" />
                        </FormItem>
                    </Col>
                    <Col>
                        <FormItem
                            name="company_name"
                        >
                            <NewSearchInput placeholder="Company name" />
                        </FormItem>
                    </Col>
                    <Col>
                        <FormItem>
                            <EmailInviteBtn size="middle" onClick={handleInviteRecruiterBtnClick}
                                disabled={isAddRecruiterFormVisible}>
                                <StyledEmailIcon />Invite talent supplier
                            </EmailInviteBtn>
                        </FormItem>
                    </Col>
                </Row>
                <AddCompanyRecruiterForm isVisible={isAddRecruiterFormVisible} setIsVisible={setIsAddRecruiterFormVisible} />
                <Row justify="start" gutter={10}>
                    <Col xs={{ span: 12 }} md={{ span: 8 }} xl={{ span: 8 }}>
                        <FormItem name="ratings">
                            <SelectInput
                                showSearch
                                placeholder="All Ratings"
                                optionFilterProp="children"
                                filterOption={true}
                                dropdownMatchSelectWidth={false}
                                className="custom-select"
                                suffixIcon={<ArrowDownIcon />}
                            >
                                  <Select.Option value="">{t('NONE')}</Select.Option>
                                {RATING_FILTERS.map((ratingFilter, index) => (
                                    <Select.Option value={ratingFilter.value} key={index}>
                                        {ratingFilter.name}
                                    </Select.Option>
                                ))}
                            </SelectInput>
                        </FormItem>
                    </Col>
                    <Col xs={{ span: 12 }} md={{ span: 8 }} xl={{ span: 8 }}>
                        <FormItem name="domain">
                            <MediumSelectInput
                                showSearch
                                placeholder="Specialisations"
                                optionFilterProp="children"
                                filterOption={true}
                                dropdownMatchSelectWidth={false}
                                className="custom-select"
                                suffixIcon={<ArrowDownIcon />}
                                mode="multiple"
                                allowClear
                            >
                                {domains.map((dom) => (
                                    <Select.Option value={dom.value} key={dom.value}>
                                        {dom.title}
                                    </Select.Option>
                                ))}
                            </MediumSelectInput>
                        </FormItem>
                    </Col>
                    <Col xs={{ span: 12 }} md={{ span: 8 }} xl={{ span: 8 }}>
                        <FormItem name="recommended_badge">
                            <SelectInput
                                showSearch
                                placeholder="Filter by category"
                                optionFilterProp="children"
                                filterOption={true}
                                dropdownMatchSelectWidth={false}
                                className="custom-select"
                                suffixIcon={<ArrowDownIcon />}
                                allowClear
                            >
                                <Select.Option value="Recommended">Recommended recruiters</Select.Option>
                            </SelectInput>
                        </FormItem>
                    </Col>
                </Row>
            </Form>
            <RecruiterSelectTable
                recruiters={recruiters}
                setRecruiterIsSelectedField={setRecruiterIsSelectedField}
                handleTableScroll={handleTableScroll}
                handleSelectAll={handleSelectAll}
                setSortType={setCurrentSortingType}
                setSortOrder={setCurrentSortingOrder}
                currentSortOrder={currentSortingOrder}
                currentSortType={currentSortingType}
            />
            <RecruiterManagementBoardFooter
                setIsModalVisible={setIsModalVisible}
                fromJobCreation={fromJobCreation}
                companyName={companyName}
                selectedJob={selectedJob}
                allSelected={allSelected}
                selectedRows={recruitersSelectedInThisSession}
                highThreshold={35}
                numOfSelectedRecrutiers={allSelected ? totalNumberOfAvailableRecruiters : recruitersSelectedInThisSession.length}
                recomendedNumberOfRecrutiers={35}
            />
        </>)
}

interface RecruiterManagementBoardModalProps {
    isVisible: boolean,
    setIsVisible: React.Dispatch<React.SetStateAction<any>>;
    selectedJob: string | null;
    fromJobCreation: boolean;
}
export function RecruiterManagementBoardModal({ isVisible, setIsVisible, selectedJob, fromJobCreation }: RecruiterManagementBoardModalProps) {
    return (
        <StyledModal style={{ top: "20px" }} destroyOnClose={true} footer={null} visible={isVisible} width={1000} onCancel={() => setIsVisible(false)}>
            <RecruiterManagementBoard setIsModalVisible={setIsVisible} fromJobCreation={fromJobCreation} selectedJob={selectedJob} />
        </StyledModal>
    );
}
