import { useMemo } from 'react';
import styled from 'styled-components';
import { CandidateHistory } from '../../types';
import CandidateCompany from './CandidateCompany';
import format from 'date-fns/format';
import Button from '../../UI/buttons/Button';
import { useTranslation } from 'react-i18next';
import { navigate } from '../../utils/routes';
import { useHistory } from 'react-router-dom';
import { REJECTED, REJECTED_NAME } from '../../constants/statuses';

interface HistoryListProps {
  history: CandidateHistory[];
}
const Wrapper = styled.div``;

interface Iitem {
  isActive?: boolean,
}

const Item = styled.div<Iitem>`
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dae5ec;
  color: ${props => props.isActive ? '#061c2e' : '#627d95'};

  & h5 {
    font-size: 1.25rem;
    font-weight: ${props => props.isActive ? 700 : 400};
    line-height: 1.2;
    width: 20%;
    margin: 0;
    color: ${props => props.isActive ? '#061c2e' : '#627d95'}
  }

  .time {
    font-size: 0.875rem;
    font-weight: ${props => props.isActive ? 700 : 400};
    line-height: 1.43;
    margin-bottom: 0;
    position: relative;
  }
`;

const Company = styled.div`
  width: 27%;
`;

const Plain = styled.div`
  width: 22%;
`;

const Action = styled.div`
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

const StyledButton = styled(Button)`
  margin: 0.5rem 0;
  & span {
    text-align: center;
    display: block;
    width: 100%;
  }
`;

const NoResultText = styled.p`
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.33;
  letter-spacing: 0.96px;
  text-align: center;
  padding: 1rem;
`;

const HistoryList = ({ history }: HistoryListProps) => {
  const [t] = useTranslation();
  const routerHistory = useHistory();
  
  const showFeedback = (
    feedback_id: string | undefined,
    stage: string,
    interview_id: string,
  ) => {
    feedback_id &&
      navigate(routerHistory, {
        feedback_id: feedback_id,
        stage_name: stage,
        feedback_interview_id: interview_id,
      });
  };

  const showRejectedFeedback = (stage: string, interview_id: string) => {
    interview_id &&
      navigate(routerHistory, {
        rejected_feedback: '1',
        stage_name: stage,
        feedback_interview_id: interview_id,
      });
  };
  
  const currentStatuses = useMemo(() => {
    // result {interview_id1: [all items with interview_id1], interview_id2: [all items with interview_id2], etc}
    const mapped: {[key: string]: CandidateHistory[]} = {};

    history.map((h) => {
      if (!mapped[h.interview_id]) {
        mapped[h.interview_id] = [];
      }
      mapped[h.interview_id] = [...mapped[h.interview_id], h];
      return h;
    });

    const keys: string[] = Object.keys(mapped);

    // get the latest status for the each interview
    const currentStatuses = keys.map((key: string) => {
      const arr = mapped[key];
      return arr[arr.length-1];
    })

    return currentStatuses.filter((_) => _.status !== REJECTED_NAME);
  }, [history]);

  return (
    <Wrapper>
      {history.length ? (
        <>
            {history.map((h, index) => {
              const clientFeedback = h.feedbacks
                ? h.feedbacks.find(
                    (el) =>
                      el.feedback_type === 'client_feedback' && el.is_completed,
                  )
                : null;

              const recruiterFeedback = h.feedbacks
                ? h.feedbacks.find(
                    (el) =>
                      el.feedback_type === 'recruiter_feedback' && el.is_completed,
                  )
                : null;

              const isLastStatus = currentStatuses.some(
                (_) => _.status === h.status && _.interview_id === h.interview_id && _.status_changed_at === h.status_changed_at
              );

              return (
                <Item key={h.status + index} isActive={isLastStatus}>
                  <h5>{h.status}</h5>
                  <Company>
                    <CandidateCompany
                      align={'center'}
                      company={h.job.company.name}
                      role={h.job.title}
                      image={h.job.company.image && h.job.company.image}
                      companyId={h.job.company.id}
                      disabled={!isLastStatus}
                    />
                  </Company>
                  <Plain>
                    <p className="time">
                      {format(new Date(h.status_changed_at), 'MMMM d, yyyy , H:mm')}
                    </p>
                  </Plain>
                  <Action>
                    {h.has_rejection_feedback &&
                      h.status.toLowerCase() === REJECTED && (
                        <StyledButton
                          size="small"
                          type="primary"
                          onClick={() =>
                            showRejectedFeedback(h.status, h.interview_id)
                          }
                        >
                          {t('VIEW_FEEDBACK')}
                        </StyledButton>
                      )}
                    
                      <>
                        {clientFeedback?.is_completed && (
                          <StyledButton
                            size="small"
                            type="primary"
                            onClick={() =>
                              showFeedback(
                                clientFeedback?.id,
                                h.status,
                                h.interview_id,
                              )
                            }
                          >
                            {t('VIEW_CLIENT_FEEDBACK')}
                          </StyledButton>
                        )}
                        {recruiterFeedback?.is_completed && (
                          <StyledButton
                            size="small"
                            type="primary"
                            onClick={() =>
                              showFeedback(
                                recruiterFeedback?.id,
                                h.status,
                                h.interview_id,
                              )
                            }
                          >
                            {t('VIEW_RECRUITER_FEEDBACK')}
                          </StyledButton>
                        )}
                      </>
                  </Action>
                </Item>
              );
            })
          }
        </>
      ) : (
        <NoResultText>{t('NO_HISTORY')}</NoResultText>
      )}
    </Wrapper>
  );
};

export default HistoryList;
