import { useState } from 'react';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ReactComponent as AddUserIcon } from '../../../../icons/add-user.svg';
import { ReactComponent as LiveIcon } from '../../../../icons/live.svg';
import TagButton from '../../../../UI/buttons/TagButton';
import { Job } from '../../../../types';
import {
  ADMIN_ROLE,
  CLIENT_ROLE,
  RECRUITER_ROLE,
} from '../../../../constants/roles';
import { JOBS } from '../../../../constants/routes';
import { addToFavorites, removeFromFavorites } from '../../../../api/jobs';
import {
  JobDescriptionWrapper,
  JobCompanyWrapper,
  CompanyLogo,
  JobTitle,
  NewCandidates,
  JobAbout,
  TagWrapper,
  ClientTitleRight,
  BadgeNew,
  BadgeUpdated,
  BadgeCustom,
  BadgeLive,
  BadgeWarning,
} from './styles';
import Emoji from "../../../helper/Emoji";
import { calculateDaysSinceJobOpened } from '../../../../utils/jobs';
import styled from 'styled-components';
import { capitalizeFirstLetter } from '../../../../utils/general';

type JobDescriptionProps = {
  job: Job;
  employmentTypeName?: string;
  role?: string;
  introduced?: number;
  addToFavoriteList?: (id: string) => void;
  language: any[];
}

interface CompanyJobOverTitleProps {
  job: Job;
}

const OverTitle = styled.div`
  font-size: 0.75rem;
  line-height: 1.33;
  color: #627d95;
  font-weight: 400;
`;

const CompanyJobOverTitle = ({ job }: CompanyJobOverTitleProps) => {
  const [t] = useTranslation();

  let displayText = calculateDaysSinceJobOpened(job, t);
  let capitalizedDisplayText = capitalizeFirstLetter(displayText);

  return <OverTitle>{capitalizedDisplayText}</OverTitle>;
}

const JobDescription = ({
  job,
  employmentTypeName,
  role,
  introduced,
  addToFavoriteList,
  language,
}: JobDescriptionProps) => {
  const [t] = useTranslation();

  const isAdmin = role === ADMIN_ROLE;
  const isRecruiter = role === RECRUITER_ROLE;
  const isClient = role === CLIENT_ROLE;

  const [favorite, setFavorite] = useState(job.is_favorite);
  const published = job.status === 'published';

  const handleFavorite = () => {
    if (favorite) {
      removeFromFavorites(job.id).then((res) => console.log(res));
    } else {
      addToFavorites(job.id).then((res) => {
        if (addToFavoriteList) {
          addToFavoriteList(job.id);
        }
      });
    }
    setFavorite(!favorite);
  };

  const { is_new, is_updated, urgent, tags } = job;

  return (
    <JobDescriptionWrapper
      className={
        isRecruiter || isAdmin ? 'company-img' : ''
      }
    >
      {(isRecruiter || isAdmin) && (
        <CompanyLogo>
          <img
            src={
              job.company.image
                ? job.company.image
                : process.env.PUBLIC_URL + '/images/company-logo.png'
            }
            alt=""
          />
        </CompanyLogo>
      )}
      <JobCompanyWrapper className={job.status === 'draft' ? 'draft' : ''}>
        <JobTitle
          className={
            isRecruiter && (job.status === 'unpublished' || job.status === 'on_hold' || (job.is_active_recruiter_points === false))
              ? 'grey'
              : ''
          }
        >
          {isRecruiter && !job.open_spots ? (
            <span className="job-title">{job.title} </span>
          ) : job.status !== 'draft' ? (
            <Link to={JOBS + '/' + job.id}>
              {isClient && <CompanyJobOverTitle job={job} />}
              <span className={(job.status === 'unpublished' || job.status === 'on_hold') ? 'unpublished' : ''}>{job.title} </span>{' '}
            </Link>
          ) : (
            <Link to={JOBS + '/edit/' + job.id + '/company_info'}>
              <span>{job.title}</span>
            </Link>
          )}

          {role === RECRUITER_ROLE && (
            <>
              {!!tags?.length &&
                tags?.map(tag => <BadgeCustom>{tag}</BadgeCustom>)
              }
              {is_new && (
                <BadgeNew>{t('BADGE_NEW')}</BadgeNew>
              )}
              {is_updated && !is_new && (
                <BadgeUpdated>{t('BADGE_UPDATED')}</BadgeUpdated>
              )}
              {!is_new && !is_updated && (
                <BadgeLive>
                  <LiveIcon />
                  <span>{t('BADGE_LIVE')}</span>
                </BadgeLive>
              )}
              {urgent && (
                <BadgeWarning>
                  {t('BADGE_URGENT')}
                </BadgeWarning>
              )}
              <Emoji
                symbol={favorite ? '🔔' : '🔕'}
                customClass={'job-list'}
                onClick={(e: any) => {
                  e.stopPropagation();
                  e.preventDefault();
                  handleFavorite();
                }}
              />
            </>
          )}

          {isClient || isAdmin ? (
            <ClientTitleRight>
              <span className="views">
                {published ? (
                  <>
                    <span className="views-wrap">
                      <EyeOutlined />
                    </span>
                    <span className="counter">{job.number_of_views}</span>
                  </>
                ) : (
                  <span className="views-wrap">
                    <EyeInvisibleOutlined />
                  </span>
                )}
                {published && introduced ? (
                  <NewCandidates>
                    <AddUserIcon />
                    {introduced}
                  </NewCandidates>
                ) : (
                  ''
                )}
              </span>
            </ClientTitleRight>
          ) : (
            ''
          )}
        </JobTitle>
        <JobAbout>{job.short_description}</JobAbout>
        <TagWrapper>
          {employmentTypeName && (
            <TagButton className="yellow">{employmentTypeName}</TagButton>
          )}
          {job.required_seniority?.name && (
            <TagButton>{job.required_seniority.name}</TagButton>
          )}
          {language.map((lang) => (
            <TagButton className="green">{lang}</TagButton>
          ))}
        </TagWrapper>
      </JobCompanyWrapper>
    </JobDescriptionWrapper>
  );
};

export default JobDescription;
