import React from 'react';
import { CandidateInfo, CandidateSnapshot } from '../../types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import useEmploymentTypes from '../../hooks/useEmploymentTypes';
import getEmploymentTypeSummary from '../../utils/getEmploymentTypeSummary';
import OfferText from './kanban/OfferText';

interface GeneralProps {
  candidate: CandidateInfo | CandidateSnapshot;
}
const Wrapper = styled.div`
margin-top: 2.5rem;
`
const Title = styled.h3`
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.2;
  color: #627d95;
  margin-bottom: 1rem;
`;

const SubTitle = styled.h4`
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.2;
  color: #627d95;
  margin-top: 2rem;
`;

const SalaryPackage = ({ candidate }: GeneralProps) => {
  const [t] = useTranslation();
  const employmentTypes = useEmploymentTypes();
  if (typeof (candidate as any).compensation !== 'undefined') {
    const snapshot = candidate as CandidateSnapshot;
    const employmentTypeSummary = getEmploymentTypeSummary(t, snapshot.compensation, employmentTypes);
    const candidateFields = employmentTypeSummary?.fields['candidate']
    return (
      <Wrapper>
        <Title>{t('Compensation')}</Title>
        {candidateFields?.map((field) => {
          const secondaryLabel = field.secondaryLabel ? ` (${field.secondaryLabel})` : '';
          const label = `${field.label}${secondaryLabel}`;
          return <OfferText title={t(label)} text={field.value} />
        })}
      </Wrapper>
    );
  }
  const plainCandidate = candidate as CandidateInfo;
  return (
    <Wrapper>
      <Title>{t('Compensation')}</Title>
      {plainCandidate.employment_types.map((employmentType) => {
        const employmentTypeSummary = getEmploymentTypeSummary(t, employmentType, employmentTypes);
        const candidateFields = employmentTypeSummary?.fields['candidate']
        return (
          <>
            <SubTitle>{employmentTypeSummary?.employmentType && t(employmentTypeSummary?.employmentType)} {t('employment preferences')}:</SubTitle>
            {candidateFields?.map((field) => {
              const secondaryLabel = field.secondaryLabel ? ` (${field.secondaryLabel})` : '';
              const label = `${field.label}${secondaryLabel}`;
              return <OfferText title={t(label)} text={field.value} />
            })}
          </>
        );
      })}
    </Wrapper>
  )
};

export default SalaryPackage;
