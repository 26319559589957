import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import * as d3 from 'd3';
import { getRandomColor } from '../../../constants/palette';

interface CareerProps {
  career: string[][];
  description?: string;
}

const SectionText = styled.p`
  font-size: 0.875rem;
  line-height: 1.71;
  color: #ffffff;
  margin-bottom: 2.5rem;
`;

const SvgWrapper = styled.div`
  & .link {
    fill: none;
    stroke: #ccc;
    stroke-width: 2px;
    stroke-dasharray: 2;
  }
  & .body-text {
    background: transparent;
    font-family: Lato;
    color: #ffffff;
    font-size: 0.875rem;
    text-align: center;
    line-height: 1;
  }
`;

const CareerSection = ({ career,description }: CareerProps) => {
  const [data, setData] = useState({});
  const [loaded, setLoaded] = useState(false);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const current = wrapperRef.current;
  const [bounds, setBounds] = useState({
    width: current ? current.scrollWidth : 500,
    height: 300,
  });
  useEffect(() => {
    setLoaded(true);
  }, []);
  useEffect(() => {
    const start = {
      name: '',
      color: '#e2be00',
      children: [],
    };
    career.forEach((el, index) => {
      let formatted = {};
      for (let i = el.length - 1; i >= 0; i--) {
        if (i === el.length - 1) {
          formatted = {
            name: el[i],
            color: getRandomColor().main,
            children: [],
          };
        } else {
          formatted = {
            name: el[i],
            children: [formatted],
            color: getRandomColor().main,
          };
        }
      }
      // @ts-ignore
      start.children.push(formatted);
    });
    setData(start);
  }, [career]);
  useEffect(() => {
    const updateBounds = () => {
      setBounds({
        width: current ? current.clientWidth : 500,
        height: 300,
      });
    };
    updateBounds();
    window.addEventListener('resize', updateBounds);
    return () => window.removeEventListener('resize', updateBounds);
  }, [current, loaded]);
  const createGraph = useCallback(() => {
    // set the dimensions and margins of the diagram
    let margin = { top: 20, right: 90, bottom: 30, left: 30 },
      width = bounds.width - margin.left - margin.right,
      height = bounds.height - margin.top - margin.bottom;
    // declares a tree layout and assigns the size
    let treemap = d3.tree().size([height, width]);

    //  assigns the data to a hierarchy using parent-child relationships
    let nodes = d3.hierarchy(data, function (d: any) {
      return d.children;
    });
    d3.select('.path-svg').remove();
    nodes = treemap(nodes);
    let svg = d3
        .select('#career')
        .append('svg')
        .attr('width', width + margin.left + margin.right)
        .attr('class', 'path-svg')
        .attr('height', height + margin.top + margin.bottom),
      g = svg
        .append('g')
        .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

    // adds the links between the nodes
    g.selectAll('.link')
      .data(nodes.descendants().slice(1))
      .enter()
      .append('path')
      .attr('class', 'link')
      .attr('d', function (d: any) {
        return (
          'M' +
          d.y +
          ',' +
          d.x +
          'C' +
          (d.y + d.parent.y) / 2 +
          ',' +
          d.x +
          ' ' +
          (d.y + d.parent.y) / 2 +
          ',' +
          d.parent.x +
          ' ' +
          d.parent.y +
          ',' +
          d.parent.x
        );
      });

    // adds each node as a group
    let node = g
      .selectAll('.node')
      .data(nodes.descendants())
      .enter()
      .append('g')
      .attr('class', function (d) {
        return 'node' + (d.children ? ' node--internal' : ' node--leaf');
      })
      .attr('transform', function (d: any) {
        return 'translate(' + d.y + ',' + d.x + ')';
      });

    // adds the circle to the node
    node
      .append('circle')
      .attr('r', 24)
      .attr('fill', (d: any) => d.data.color);

    node
      .append('svg')
      .attr('class', 'user-icon')
      .attr('width', 30)
      .attr('height', 30)
      .attr('fill', 'none')
      .attr('viewBox', '0 0 30 30')
      .attr('x', function (d: any) {
        return -15;
      })
      .attr('y', function (d: any) {
        return -15;
      });
    node
      .selectAll('.user-icon')
      .append('path')
      .attr('stroke', '#fff')
      .attr('stroke-linecap', 'round')
      .attr('stroke-linejoin', 'round')
      .attr(
        'd',
        'M15 25.8c5.965 0 10.8-4.835 10.8-10.8 0-5.964-4.835-10.8-10.8-10.8C9.035 4.2 4.2 9.036 4.2 15c0 5.965 4.835 10.8 10.8 10.8z',
      );
    node
      .selectAll('.user-icon')
      .append('path')
      .attr('stroke', '#fff')
      .attr('stroke-linecap', 'round')
      .attr('stroke-linejoin', 'round')
      .attr(
        'd',
        'M21 23.4c0-3.313-2.686-6-6-6s-6 2.687-6 6M15 16.2c1.988 0 3.6-1.612 3.6-3.6S16.988 9 15 9s-3.6 1.612-3.6 3.6 1.612 3.6 3.6 3.6z',
      );

    // adds the text to the node
    let positionCurrent = 0,
      positionAfter = 0;
    node
      .append('foreignObject')
      .attr('width', function (d: any) {
        if (!positionCurrent && !positionAfter) {
          positionCurrent = d.y || 0;
          positionAfter = d.children?.length ? d.children[0].y : 0;
        }
        return (positionAfter - positionCurrent) * 0.75;
      })
      .attr('height', function (d) {
        return 70;
      })
      .attr('x', (-(positionAfter - positionCurrent) * 0.75) / 2)
      .attr('y', 30)
      .attr('textAnchor', 'middle')
      .append('xhtml:body')
      .attr('class', 'body-text')
      .append('p')
      .text(function (d) {
        return d.data.name;
      });
  }, [bounds.width, bounds.height, data]);
  useEffect(() => {
    createGraph();
  }, [createGraph]);

  return (
    <>
      <SectionText>{description}</SectionText>
      <SvgWrapper id="career" ref={wrapperRef}></SvgWrapper>
    </>
  );
};

export default CareerSection;
