import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Header from '../components/layout/Header';
import PageWrapper from '../components/layout/PageWrapper';
import CandidateFilter from '../components/candidates/CandidateFilter';
import { getCandidate, getCandidateByInterview, getCandidateList } from '../api/candidates';
import {
  CandidateInfo,
  Certificate,
  Education,
  City,
  Company,
  InterviewRoom,
  Role,
  Seniority,
  Skill,
  Specialization,
} from '../types';
import CandidateListFilter from '../components/candidates/CandidateListFilter';
import { getRolesList } from '../api/roles';
import { getCityList } from '../api/regions';
import { getSkillsList } from '../api/skills';
import RecruiterCandidates from '../components/recruiter/RecruiterCandidates';
import { useAppSelector } from '../store/hooks';
import { ADMIN_ROLE, CLIENT_ROLE, RECRUITER_ROLE } from '../constants/roles';
import ClientCandidates from '../components/client/ClientCandidates';
import RoundAddButton from '../UI/buttons/RoundAddButton';
import CandidateAddForm from '../components/form/CandidateAddForm';
import { getSpecializationList } from '../api/specialization';
import { getSeniorityList } from '../api/seniority';
import { getCertificatesList } from '../api/certificates';
import { getEducationList } from '../api/education';
import { getRecruitersCompanyList } from '../api/recruiter';
import { getAllInfo } from '../utils/candidate';
import AdminCandidates from '../components/admin/AdminCandidates';
import Kanban from '../components/candidates/kanban/Kanban';
import CandidateInfoModal from '../components/candidates/CandidateInfoModal';
import AppointJobModal from '../components/candidates/AppointJobModal';
import CandidateHistoryModal from '../components/candidates/CandidateHistoryModal';
import ShowFeedback from '../components/candidates/ShowFeedback';
import { getUrlString, SHOW_COMPANY_CHAT } from '../constants/queryParams';
import { getInterview } from '../api/interviews';
import Loader from '../UI/Loader';
import RejectionFeedback from '../components/candidates/RejectionFeedback';
import ReactGa from 'react-ga4';
import CompanyChatModal from "../components/candidates/kanban/CompanyChatModal";

const CandidateList = styled.div`
  padding: 0 2rem;
  background: #ffffff;
  border-radius: 0.5rem;
  & .ant-table-tbody > tr.ant-table-row:hover > td {
    background: #fff;
  }
  @media (max-width: 576px) {
    padding: 0 1rem;
  }
`;
const NoResultText = styled.p`
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.33;
  letter-spacing: 0.96px;
  text-align: center;
  color: #061c2e;
  padding: 1rem;
`;
const KanbanWrapper = styled.div`
  overflow: auto;
  height: calc(100vh - 130px);
`;
const Candidates = () => {
  const history = useHistory();
  const { user } = useAppSelector((state) => state.user);
  const [next, setNext] = useState(null);
  const [processNext, setProcessNext] = useState(null);
  const [addCandidate, setAddCandidate] = useState<boolean>(false);
  const [candidates, setCandidates] = useState<CandidateInfo[]>([]);
  const [info, setInfo] = useState<CandidateInfo | null>(null);
  const [interview, setInterview] = useState<InterviewRoom | null>(null);
  const [selectedCandidate, setSelectedCandidate] = useState<string | null>(
    null,
  );

  const [data, setData] = useState<{
    seniority: Seniority[];
    certificates: Certificate[];
    education: Education[],
    cities: City[];
    domains: Specialization[];
    skills: Skill[];
    roles: Role[];
    company_name: [];
    visa: boolean[];
    language: [];
    relocation: string;
  }>({
    seniority: [],
    certificates: [],
    education: [],
    cities: [],
    domains: [],
    skills: [],
    roles: [],
    company_name: [],
    visa: [],
    language: [],
    relocation: '0',
  });

  const [recruiterCompanies, setRecruiterCompanies] = useState<Company[]>([]);
  const [view, setView] = useState('');
  const [loading, setLoading] = useState(true);
  const loader = useRef(null);
  const [t] = useTranslation();
  const params = new URLSearchParams(history.location.search);
  const skill = params.get('skill');
  const domain = params.get('domain');
  const seniority = params.get('seniority');
  const location = params.get('location');
  const salary_from = params.get('salary_from');
  const salary_to = params.get('salary_to');
  const search = params.get('search');
  const status = params.get('status');
  const recruiter_name = params.get('recruiter_name');
  const filter = params.get('filter');
  const historyModal = params.get('history');
  const interview_id = params.get('interview_id');
  const feedback_id = params.get('feedback_id');
  const rejected_feedback = params.get('rejected_feedback');
  const editModal = params.get('edit');
  const chat = params.get(SHOW_COMPANY_CHAT);
  const [showCompanyChatModal, setShowCompanyChatModal] = useState(false);

  const collectAllData = useCallback(
    (list: CandidateInfo[]) => getAllInfo({
      candidates: list,
      cities: data.cities,
      skills: data.skills,
      roles: data.roles,
      domains: data.domains,
      certificates: data.certificates,
      education: data.education,
    }),
    [data],
  );

  useEffect(() => {
    setLoading(true);
    const query = getUrlString({
      skill,
      domain,
      seniority,
      location,
      salary_from,
      salary_to,
      search,
      status,
      recruiter_name,
    });

    getCandidateList(query && `?${query}`, filter && filter).then((res) => {
      if (data.skills.length) {
        setCandidates(collectAllData(res.data.results));
        if (!view) {
          setNext(res.data.pagination.next);
        } else {
          setProcessNext(res.data.pagination.next);
        }
        setLoading(false);
      }
    });
    setView(filter ? filter : '');
  }, [
    filter,
    data,
    view,
    collectAllData,
    skill,
    domain,
    seniority,
    location,
    salary_from,
    salary_to,
    search,
    status,
    recruiter_name,
  ]);

  const addCandidateToList = (newCandidate: CandidateInfo) => {
    !view &&
      setCandidates([
        ...getAllInfo({
          candidates: [newCandidate],
          cities: data.cities,
          skills: data.skills,
          roles: data.roles,
          domains: data.domains,
          certificates: data.certificates,
          education: data.education,
        }),
        ...candidates,
      ]);
  };

  const editCandidate = (newCandidate: CandidateInfo) => {
    setCandidates((prevState) => {
      return prevState.map((st) => {
        if (newCandidate.id === st.id) {
          st = [
            ...getAllInfo({
              candidates: [newCandidate],
              cities: data.cities,
              skills: data.skills,
              roles: data.roles,
              domains: data.domains,
              certificates: data.certificates,
              education: data.education,
            }),
            ...candidates,
          ][0];
        }
        return st;
      });
    });
  };

  useEffect(() => {
    axios
      .all([
        getRolesList(),
        getCityList(),
        getSkillsList(),
        getSpecializationList(),
        getSeniorityList(),
        getCertificatesList(),
        getEducationList(),
      ])
      .then((res) => {
        setData({
          roles: res[0].data,
          cities: res[1].data,
          skills: res[2].data,
          domains: res[3].data,
          seniority: res[4].data,
          certificates: res[5].data,
          education: res[6].data,
          company_name: [],
          visa: [true, false],
          language: [],
          relocation: '0',
        });
      });
    user?.role === ADMIN_ROLE &&
      getRecruitersCompanyList().then((res) => setRecruiterCompanies(res.data));
  }, [user]);

  const cursor = `${getUrlString({
    skill,
    domain,
    seniority,
    location,
    salary_from,
    salary_to,
    search,
    status,
    recruiter_name,
  }) || filter
    ? history.location.search + '&cursor=' + next
    : '?cursor=' + next
    }`;

  const handleObserver = useCallback(
    (entities: any) => {
      const target = entities[0];
      if (target.isIntersecting) {
        getCandidateList(cursor, filter && filter).then((res) => {
          // @ts-ignore
          setCandidates((prev) => [
            ...prev,
            ...getAllInfo({
              candidates: res.data.results,
              cities: data.cities,
              skills: data.skills,
              roles: data.roles,
              domains: data.domains,
              certificates: data.certificates,
              education: data.education,
            }),
          ]);
          setNext(res.data.pagination.next);
          setLoading(false);
        });
      }
    },
    [cursor, filter, data],
  );

  useEffect(() => {
    const current = loader.current;
    let options = {
      root: null,
      rootMargin: '0px 0px 300px 0px',
      threshold: 1.0,
    };
    const observer = new IntersectionObserver(handleObserver, options);

    if ((!next && !view) || (view && !processNext)) {
      observer.disconnect();
    } else {
      if (loader && current && ((next && !view) || (!view && processNext))) {
        observer.observe((current as unknown) as Element);
      }
    }
    return () => observer.unobserve((current as unknown) as Element);
  }, [next, handleObserver, processNext, view]);

  useEffect(() => {
    if (data.seniority.length && data.cities.length && data.domains.length && data.skills.length && data.roles.length && data.certificates.length) {
      if (historyModal) {
        if (interview_id) {
          if (!filter && user?.role === RECRUITER_ROLE) {
            getCandidate(historyModal).then((res) => {
              const item = {
                ...res.data,
                interviews: res.data.interviews.filter(
                  (el: CandidateInfo) => el.id === interview_id,
                ),
              };
              setInfo(collectAllData([item])[0]);
            });
          } else {
            getCandidateByInterview(interview_id).then((res) => {
              const item = {
                ...res.data,
                interviews: res.data.interviews,
              };
              setInfo(collectAllData([item])[0]);
            });
          }
        } else {
          getCandidate(historyModal).then((res) => {
            const item = {
              ...res.data,
              interviews: res.data.interviews.filter(
                (el: CandidateInfo) => el.id === interview_id,
              ),
            };
            setInfo(collectAllData([item])[0]);
          });
        }


      } else {
        setInfo(null);
      }
    } else {
      setInfo(null);
    }

    if (interview_id) {
      getInterview(interview_id, history).then((res) => setInterview(res.data));
    } else {
      setInterview(null);
    }

    if (chat) {
      setShowCompanyChatModal(true);
    }
  }, [historyModal, collectAllData, interview_id, data, filter, user, history, chat]);

  useEffect(() => {
    ReactGa.pageview('/candidates');
  }, []);

  return (
    <PageWrapper className={view ? 'full-width' : ''}>
      <Header title={t('CANDIDATES')}>
        <CandidateFilter />
        <span></span>
      </Header>

      {view ? (
        <KanbanWrapper>
          <Kanban
            collect={collectAllData}
            setInfo={setInfo}
            candidates={candidates}
          />
        </KanbanWrapper>
      ) : (
        <>
          <CandidateListFilter
            domains={data.domains}
            seniorities={data.seniority}
            cities={data.cities}
            skills={data.skills}
            role={user?.role}
          />
          <Loader spinning={loading}>
            <CandidateList>
              {!loading && !candidates.length ? (
                <NoResultText>{t('NO_RESULT')}</NoResultText>
              ) : (
                <>
                  {user?.role === RECRUITER_ROLE && (
                    <RecruiterCandidates
                      candidates={candidates}
                      setCandidates={setCandidates}
                      setId={setSelectedCandidate}
                    />
                  )}
                  {user?.role === ADMIN_ROLE && (
                    <AdminCandidates
                      candidates={candidates}
                      setCandidates={setCandidates}
                    />
                  )}
                  {user?.role === CLIENT_ROLE && (
                    <ClientCandidates
                      candidates={candidates}
                      setCandidates={setCandidates}
                      setInfo={setInfo}
                    />
                  )}
                </>
              )}
            </CandidateList>
          </Loader>
        </>
      )}
      <div className="loader" ref={loader} />
      {user?.role === CLIENT_ROLE && info && (
        <CandidateInfoModal current={info} setCandidates={setCandidates} />
      )}
      {user?.role === CLIENT_ROLE && info && (
        <CompanyChatModal
          interview={interview || {}}
          visible={showCompanyChatModal && !!interview_id && !!chat}
          setShowCompanyChatModal={setShowCompanyChatModal}
        />
      )}
      {user?.role !== CLIENT_ROLE && info && (
        <CandidateHistoryModal current={info} setCurrent={setInfo} />
      )}
      {feedback_id && interview && <ShowFeedback info={interview} />}
      {rejected_feedback && interview && <RejectionFeedback info={interview} />}
      {user?.role === RECRUITER_ROLE && selectedCandidate && (
        <AppointJobModal
          id={selectedCandidate}
          setId={setSelectedCandidate}
          skills={data.skills}
          cities={data.cities}
          domain={data.domains}
          seniority={data.seniority}
          company_name={data.company_name}
          visa={data.visa}
          language={data.language}
          relocation={data.relocation}
        />
      )}

      {user?.role !== CLIENT_ROLE && (
        <>
          <RoundAddButton onClick={() => setAddCandidate(true)} />
          <CandidateAddForm
            cities={data.cities}
            certificates={data.certificates}
            education={data.education}
            onAction={editModal ? editCandidate : addCandidateToList}
            roles={data.roles}
            skills={data.skills}
            domains={data.domains}
            companies={recruiterCompanies}
            seniority={data.seniority}
            visible={addCandidate}
            changeVisibility={setAddCandidate}
            role={user?.role}
          />
        </>
      )}
    </PageWrapper>
  );
};

export default Candidates;
