import useMagicLink from './hooks';
import { Page } from './styled';
import Loader from '../../UI/Loader';

// placeholder component, will show spinner while magic_link login works
const MagicLink = () => {
  useMagicLink();

  return (
    <Page>
      <Loader spinning={true}></Loader>
    </Page>
  );
}

export default MagicLink;