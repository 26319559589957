import { useEffect, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, message } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { CaretDownOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Col, Upload, Row, RadioChangeEvent, Select } from 'antd';
import { SelectValue } from 'antd/lib/select';

import FormTitle from '../../../components/auth/FormTitle';
import Button from '../../../UI/buttons/Button';
import { getSeniorityList } from '../../../api/seniority';
import { createJob, updateJobInfo, getJobInfo } from '../../../api/jobs';
import { ReactComponent as AddIcon } from '../../../icons/plus.svg';
import FormItem from '../FormItem';
import { maxLengthRule, requiredRule } from '../../../utils/validationRules';
import {
  getCompanyByName,
  getClientCompanyDepartments,
} from '../../../api/company';
import TabRadioButton from '../../../UI/radioButton/TabRadioButton';
import { getTechnicalToolsList } from '../../../api/technical-tools';
import {
  Activity,
  Job,
  Seniority,
  TechnicalTool,
  Specialization,
} from '../../../types';
import { useAppSelector } from '../../../store/hooks';
import { JOBS } from '../../../constants/routes';
import { getSpecializationList } from '../../../api/specialization';
import { ADMIN_ROLE } from '../../../constants/roles';
import NumberInput from '../../../UI/buttons/NumberInput';
import SelectInput from '../../../UI/inputs/Select';
import Input from '../../../UI/inputs/Input';
import Textarea from '../../../UI/inputs/Textarea';
import RouterBlocker from '../../jobs/RouterBlocker';
import { ReactComponent as InfoIcon } from '../../../icons/info-icon-rounded.svg';
import {
  ColAction,
  DeleteIcon,
  DeleteImageIcon,
  FormSubtitle,
  JobBackgroundImage,
  ImageUploadButton,
  FormActions,
} from './styles';

const { Option } = Select;

interface CompanyProps {
  id: string;
  name: string;
}

const jobTypes: { title: string | number; value: string | number }[] = [
  {
    title: 'Inhouse',
    value: 'inhouse',
  },
  {
    title: 'Consultancy',
    value: 'consultancy',
  },
];

interface JobInfoFormProps {
  job: Job | undefined;
  onFinish: (id: string) => void;
}

const JobInfoForm = ({ job, onFinish }: JobInfoFormProps) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const { t } = useTranslation();
  const [saveLoading, setSaveLoading] = useState(false);
  const [blockTransition, setBlockTransition] = useState(false);

  const [jobType, setJobType] = useState('inhouse');
  const [backgroundImage, setBackgroundImage] = useState('');
  const [activities, setActivities] = useState<Activity[]>([
    { name: '', hours_per_week: 0 },
  ]);
  const [seniorities, setSeniorities] = useState<Seniority[]>([]);
  const [technicalTools, setTechnicalTools] = useState<TechnicalTool[]>([]);
  const [specializations, setSpecializations] = useState<Specialization[]>([]);
  const [companies, setCompanies] = useState<CompanyProps[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<string>();
  const [departments, setDepartments] = useState<string[]>([]);
  const [selectedDepartment, setSelectedDepartment] = useState<string>();

  const { user } = useAppSelector((state) => state.user);
  const role = user?.role;
  const isAdmin = role === ADMIN_ROLE;

  useEffect(() => {
    getSeniorityList().then((res) => setSeniorities(res.data));
    getTechnicalToolsList().then((res) => setTechnicalTools(res.data));
    getSpecializationList().then((res) => setSpecializations(res.data));

    if (isAdmin) {
      getCompanyByName('').then((res) => setCompanies(res.data));
    }

    if (job && job.id) {
      getJobInfo(job.id).then((res) => {
        let formData = { ...res.data };
        res.data.activities.forEach((activity: any, index: any) => {
          formData['activity_name_' + index] = activity.name;
          formData['activity_hours_per_week_' + index] =
            activity.hours_per_week;
        });
        form.setFieldsValue(formData);
        setBackgroundImage(res.data.bg_image);
        if (res.data.activities.length > 0) {
          setActivities(res.data.activities);
        }
        setJobType(res.data.job_type);
        setSelectedCompany(res.data.company);
        setSelectedDepartment(res.data.department);
        if (res.data.company && isAdmin) {
          getClientCompanyDepartments(res.data.company).then(
            (departmentsResult) =>
              setDepartments(departmentsResult.data.departments),
          );
        }
      });
    }
  }, [job, form, isAdmin]);

  const onJobTypeChange = (e: RadioChangeEvent) => {
    setJobType(e.target.value);
  };

  const collectFormValues = (isNewJob: boolean) => {
    const draft = form.getFieldsValue();
    let draftActivities = [] as any;
    Object.keys(draft)
      .filter((k) => k.startsWith('activity'))
      .forEach((key) => {
        if (key.startsWith('activity_name')) {
          let index = key.split('activity_name')[1];
          let newActivity = {
            name: draft[key],
            hours_per_week: draft['activity_hours_per_week' + index],
          };
          if (draft[key]) {
            draftActivities.push(newActivity);
          }
        }
      });

    if (draftActivities.length > 0) {
      draft.activities = draftActivities;
    }
    draft.job_type = jobType;

    if (backgroundImage && backgroundImage.startsWith('http')) {
      delete draft.bg_image;
    } else if (backgroundImage === '') {
      draft.bg_image = null;
    }
    return draft;
  };

  const saveJobInfo = async (isDraft: boolean) => {

    if (
      (user && user.company && user.company.id) || (isAdmin && selectedCompany)
    ) {
      let jobId = job?.id;
      let isNewJob = false;

      if (!job || !job.id) {
        isNewJob = true;
        const jobResponse = await createJob(
          isAdmin ? selectedCompany || '' : user?.company?.id || '',
          isAdmin ? selectedDepartment || '' : '',
        );
        jobId = jobResponse.data.id;
      }

      if (jobId) {
        const draft = collectFormValues(isNewJob);
        await updateJobInfo(jobId, draft);
        setBlockTransition(false);
        if (isNewJob) {
          history.push(
            JOBS + '/edit/' + jobId + '/info',
          );
          message.success(t('JOB_DRAFT_SUCCESSFULLY_CREATED'), 5);
        } else {
          message.success(t('INFORMATION_SAVE_SUCCESS'), 5);
        }
        return jobId;
      }
      return jobId;
    }
    return null;
  };

  const handleNext = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setBlockTransition(false);
    if (job?.id) {
      handleSave(e).then(() => {
        onFinish(job.id);
        setBlockTransition(false);
      }).catch(error => {
        if (error.response.data.error_code === 'access_forbidden_error') {
          return;
        }
      });
    } else {
      saveJobInfo(false).then((res) => console.log(res, 'res'));
    }
  };

  const handleSave = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setBlockTransition(false);
    await form.validateFields();
    try {
      setSaveLoading(true);
      await saveJobInfo(true);
      setBlockTransition(false);
      setSaveLoading(false);
    } catch (error: any) {
      if (error.response.data.error_code === 'access_forbidden_error') {
        message.error(t('JOB_UNDER_REVIEW'), 5);
        setBlockTransition(false);
        setSaveLoading(false);
        throw error;
      }
      message.error(error.response.data.message, 5);
      setBlockTransition(false);
      setSaveLoading(false);
    }
  };

  const handleUploadFile = useCallback(
    (file: RcFile): boolean => {
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const base64 = reader.result;
          if (base64) {
            setBackgroundImage(base64?.toString());
            form?.setFieldsValue({
              ...form.getFieldsValue(),
              bg_image: base64.toString(),
            });
          }
        };
        reader.onerror = (error) => reject(error);
      });
      return false;
    },
    [setBackgroundImage, form],
  );

  const handleAddMoreActivities = useCallback(() => {
    setActivities((state) => [
      ...state,
      {
        name: '',
        hours_per_week: 0,
      },
    ]);
  }, []);

  const handleRemoveActivity = (index: number) => {
    const draft = form.getFieldsValue();
    let draftActivities = [] as any;
    Object.keys(draft)
      .filter((k) => k.startsWith('activity'))
      .forEach((key) => {
        if (key.startsWith('activity_name')) {
          let index = key.split('activity_name')[1];
          let newActivity = {
            name: draft[key],
            hours_per_week: draft['activity_hours_per_week' + index],
          };
          if (draft[key]) {
            draftActivities.push(newActivity);
          }
        }
      });

    const newActivities = draftActivities.filter(
      (s: any, i: number) => i !== index,
    );

    setActivities((state) => [...newActivities]);
    const formData = {} as any;
    Object.keys(form.getFieldsValue()).forEach((key) => {
      if (!key.startsWith('activity')) {
        (formData as any)[key] = form.getFieldsValue()[key];
      }
    });
    newActivities.forEach((activity: any, index: any) => {
      formData['activity_name_' + index] = activity.name;
      formData['activity_hours_per_week_' + index] = activity.hours_per_week;
    });
    form.setFieldsValue(formData);
    setIsFadingOut('');
  };

  const handleCompanySearch = async (value: string) => {
    await getCompanyByName(value).then((res) => setCompanies(res.data));
  };

  const handleRemoveBackground = () => {
    setBackgroundImage('');
  };

  const handleSelectDepartment = async (value: string) => {
    setSelectedDepartment(value);
  };

  const handleSelectCompany = async (value: string) => {
    setSelectedCompany(value);
    setSelectedDepartment('');
    form.setFieldsValue({
      ...form.getFieldsValue(),
      department: '',
    });
    const departmentsResult = await getClientCompanyDepartments(value);
    setDepartments(departmentsResult.data.departments);
  };

  const [isFadingOut, setIsFadingOut] = useState('');

  const fadeOut = (index: number, cb: any) => {
    setIsFadingOut(index + '_fadeOut');
    if (typeof cb === 'function') {
      cb?.();
    }
  };

  const handleFieldsChange = (changed: any, all: any) => {
    setBlockTransition(true);
  };

  const isInfoDisabled = form.getFieldValue('has_active_interview') === true;

  return (
    <>
      <RouterBlocker isBlocked={blockTransition} />
      <Form
        scrollToFirstError={true}
        layout="vertical"
        name="job-form"
        form={form}
        onFieldsChange={handleFieldsChange}
      >
        <FormTitle text={t('JOB_INFO_TITLE')} />

        {isAdmin && (
          <>
            <FormItem
              label={t('COMPANY_TITLE')}
              name="company"
              rules={requiredRule(t)}
              tooltip={t('JOB_INFO_FORM_COMPANY_TOOLTIP') ? { title: t('JOB_INFO_FORM_COMPANY_TOOLTIP'), icon: <InfoIcon /> } : false}
            >
              <SelectInput
                size="large"
                onSearch={handleCompanySearch}
                onChange={(e: SelectValue) =>
                  handleSelectCompany(e ? e.toString() : '')
                }
                disabled={isInfoDisabled}
                suffixIcon={<CaretDownOutlined style={{ color: '#000' }} />}
              >
                {companies.map((company: CompanyProps) => (
                  <Option key={company.id} value={company.id}>
                    {company.name}
                  </Option>
                ))}
              </SelectInput>
            </FormItem>

            <FormItem
              label={t('DEPARTMENT_TITLE')}
              name="department"
              tooltip={t('JOB_INFO_FORM_DEPARTMENT_TOOLTIP') ? { title: t('JOB_INFO_FORM_DEPARTMENT_TOOLTIP'), icon: <InfoIcon /> } : false}
            >
              <SelectInput
                disabled={
                  !form.getFieldValue('company') ||
                  departments.length < 1 ||
                  isInfoDisabled
                }
                showSearch
                size="large"
                onChange={(e: SelectValue) =>
                  handleSelectDepartment(e ? e.toString() : '')
                }
              >
                {departments.map((department: string) => (
                  <Option key={department} value={department}>
                    {department}
                  </Option>
                ))}
              </SelectInput>
            </FormItem>
          </>
        )}

        <FormItem
          label={t('BACKGROUND_IMAGE')}
          name="bg_image"
          tooltip={t('JOB_INFO_FORM_BACKGROUND_IMAGE_TOOLTIP')
            ? { title: t('JOB_INFO_FORM_BACKGROUND_IMAGE_TOOLTIP'), icon: <InfoIcon /> }
            : false}
        >
          <JobBackgroundImage
            style={{ backgroundImage: 'url(' + backgroundImage + ')' }}
            className={backgroundImage ? 'bg_white' : ''}
          >
            <Upload
              accept="image/png, image/jpeg"
              beforeUpload={handleUploadFile}
              showUploadList={false}
            >
              <ImageUploadButton type="ghost" size="small">
                {t('CHOOSE_IMAGE')}
              </ImageUploadButton>
            </Upload>
            {backgroundImage && (
              <DeleteImageIcon onClick={() => handleRemoveBackground()} />
            )}
          </JobBackgroundImage>
        </FormItem>

        <TabRadioButton
          onChange={onJobTypeChange}
          value={jobType}
          data={jobTypes}
        />

        <FormItem
          label={t('DOMAIN_TITLE')}
          name="specialization"
          tooltip={t('JOB_INFO_FORM_SPECIALIZATION_TOOLTIP') ? { title: t('JOB_INFO_FORM_SPECIALIZATION_TOOLTIP'), icon: <InfoIcon /> } : false}
        >
          <SelectInput
            size="large"
            filterOption={true}
            suffixIcon={<CaretDownOutlined style={{ color: '#000' }} />}
          >
            {specializations.map((specialization) => (
              <Option key={specialization.id} value={specialization.id}>
                {specialization.name}
              </Option>
            ))}
          </SelectInput>
        </FormItem>

        <FormItem className="no-margin">
          <Row gutter={16} justify="space-between">
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 24 }}
              lg={{ span: 12 }}
            >
              <FormItem
                label={t('REQUIRED_SENIORITY')}
                name="required_seniority"
                tooltip={t('JOB_INFO_FORM_REQUIRED_SENIORITY_TOOLTIP')
                  ? { title: t('JOB_INFO_FORM_REQUIRED_SENIORITY_TOOLTIP'), icon: <InfoIcon /> }
                  : false}
              >
                <SelectInput
                  size="large"
                  filterOption={true}
                  suffixIcon={<CaretDownOutlined style={{ color: '#000' }} />}
                >
                  {seniorities.map((seniority) => (
                    <Option key={seniority.id} value={seniority.id}>
                      {seniority.name}
                    </Option>
                  ))}
                </SelectInput>
              </FormItem>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 24 }}
              lg={{ span: 12 }}
            >
              <FormItem
                label={t('SPOTS_AVAILABLE')}
                name="spots_available"
                tooltip={t('JOB_INFO_FORM_SPOTS_AVAILABLE_TOOLTIP')
                  ? { title: t('JOB_INFO_FORM_SPOTS_AVAILABLE_TOOLTIP'), icon: <InfoIcon /> }
                  : false}
              >
                <NumberInput size="large" type={'number'} />
              </FormItem>
            </Col>
          </Row>
        </FormItem>

        <FormItem
          label={t('JOB_TITLE')}
          name="title"
          rules={requiredRule(t)}
          tooltip={t('JOB_INFO_FORM_TITLE_TOOLTIP') ? { title: t('JOB_INFO_FORM_TITLE_TOOLTIP'), icon: <InfoIcon /> } : false}
        >
          <Input size="large" />
        </FormItem>

        <FormItem
          label={t('JOB_DESCRIPTION')}
          name="description"
          tooltip={t('JOB_INFO_FORM_DESCRIPTION_TOOLTIP') ? { title: t('JOB_INFO_FORM_DESCRIPTION_TOOLTIP'), icon: <InfoIcon /> } : false}
        >
          <Textarea autoSize={{ minRows: 4 }} />
        </FormItem>

        <FormItem
          label={t('JOB_DESCRIPTION_2_SENTENCES')}
          name="short_description"
          tooltip={t('JOB_INFO_FORM_SHORT_DESCRIPTION_TOOLTIP')
            ? { title: t('JOB_INFO_FORM_SHORT_DESCRIPTION_TOOLTIP'), icon: <InfoIcon /> }
            : false}
        >
          <Input size="large" />
        </FormItem>

        <FormItem
          label={t('CANDIDATE_SOLVES_PROBLEM')}
          name="problems_description"
          tooltip={t('JOB_INFO_FORM_PROBLEM_DESCRIPTION_TOOLTIP')
            ? { title: t('JOB_INFO_FORM_PROBLEM_DESCRIPTION_TOOLTIP'), icon: <InfoIcon /> }
            : false}
        >
          <Textarea autoSize={{ minRows: 4 }} />
        </FormItem>

        <FormItem
          label={t('CURRENT_PROJECTS')}
          name="current_projects"
          tooltip={t('JOB_INFO_FORM_CURRENT_PROJECTS_TOOLTIP')
            ? { title: t('JOB_INFO_FORM_CURRENT_PROJECTS_TOOLTIP'), icon: <InfoIcon /> }
            : false}
        >
          <Input size="large" />
        </FormItem>

        <FormItem
          label={t('TECH_USED_IN_COMPANY')}
          name="tools_used"
          tooltip={t('JOB_INFO_FORM_TOOLS_USED_TOOLTIP')
            ? { title: t('JOB_INFO_FORM_TOOLS_USED_TOOLTIP'), icon: <InfoIcon /> }
            : false}
        >
          <SelectInput
            size="large"
            allowClear
            mode="multiple"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            suffixIcon={<CaretDownOutlined style={{ color: '#000' }} />}
            onChange={(value) => {
              if ((value as []).length > 8) {
                (value as []).pop();
              }
            }}
          >
            {technicalTools.map((tool) => (
              <Option key={tool.id} value={tool.id}>
                {tool.name}
              </Option>
            ))}
          </SelectInput>
        </FormItem>

        <FormItem className="no-margin">
          <FormSubtitle>{t('DAY_TO_DAY_ACTIVITIES')}</FormSubtitle>
        </FormItem>

        {activities.map((activity, index) => (
          <FormItem
            key={index}
            className={
              isFadingOut === index + '_fadeOut'
                ? 'item-fadeout no-margin'
                : 'no-margin'
            }
          >
            <Row gutter={16}>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 9 }}
                md={{ span: 9 }}
                lg={{ span: 9 }}
              >
                <FormItem
                  label={t('ACTIVITY')}
                  name={'activity_name_' + index}
                  rules={maxLengthRule(255, t)}
                  tooltip={t('JOB_INFO_FORM_ACTIVITY_NAME_TOOLTIP')
                    ? { title: t('JOB_INFO_FORM_TOOLS_USED_TOOLTIP'), icon: <InfoIcon /> }
                    : false}
                >
                  <Input size="large" value={activity.name} />
                </FormItem>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 8 }}
                md={{ span: 8 }}
                lg={{ span: 8 }}
                xl={{ span: 8 }}
                xxl={{ span: 8 }}
              >
                <FormItem
                  label={t('HOURS_PER_WEEK_ALT')}
                  name={'activity_hours_per_week_' + index}
                  tooltip={t('JOB_INFO_FORM_ACTIVITY_HOURS_TOOLTIP')
                    ? { title: t('JOB_INFO_FORM_ACTIVITY_HOURS_TOOLTIP'), icon: <InfoIcon /> }
                    : false}
                >
                  <NumberInput
                    size="large"
                    type={'number'}
                    value={activity.hours_per_week}
                  />
                </FormItem>
              </Col>
              {index > 0 && (
                <Col
                  xs={{ span: 1 }}
                  sm={{ span: 1 }}
                  md={{ span: 1 }}
                  lg={{ span: 1 }}
                >
                  <ColAction>
                    <DeleteIcon
                      onClick={() =>
                        fadeOut(
                          index,
                          setTimeout(() => handleRemoveActivity(index), 300),
                        )
                      }
                    />
                  </ColAction>
                </Col>
              )}
            </Row>
          </FormItem>
        ))}
        <FormItem>
          <Button
            type="ghost"
            size="large"
            icon={<AddIcon />}
            onClick={handleAddMoreActivities}
          >
            {t('ADD')}
          </Button>
        </FormItem>

        <FormActions>
          <Button
            type="ghost"
            loading={saveLoading}
            onClick={(e) => {
              form.submit();
              form.validateFields().then((res) => {
                handleSave(e);
              });
            }}
          >
            {t('SAVE_DRAFT_TITLE')}
          </Button>
          <Button
            type="primary"
            onClick={(e) => {
              form.submit();
              form.validateFields().then((res) => {
                handleNext(e);
              });
            }}
          >
            {t('NEXT_TITLE')}
          </Button>
        </FormActions>
      </Form>
    </>
  );
};

export default JobInfoForm;
