import React from 'react';
import styled from 'styled-components';

interface StatisticProps {
  title: string;
}
const StatisticItem = styled.div`
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  border: solid 1px #dae5ec;
  font-size: 0.625rem;
  line-height: 1.6;
  letter-spacing: 0.8px;
  text-align: center;
  color: #061c2e;
  margin-bottom: 0.25rem;
  text-transform: uppercase;
  position: relative;

  @media (max-width: 992px) {
    width: 23%;
  }
  @media (max-width: 576px) {
    width: 48%;
  }
`;
const JobStatistic = ({ title }: StatisticProps) => {
  return <StatisticItem>{title}</StatisticItem>;
};

export default JobStatistic;
