import styled from 'styled-components';

export const StageList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const StageItem = styled.li`
  position: relative;
  padding: 8px 0;

  &:before {
    content: '';
    position: absolute;
    left: 20px;
    top: 24px;
    display: block;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    border: 2px solid #34ce72;
    background-color: #f2f6fa;
    z-index: 2;
  }

  &:after {
    content: '';
    position: absolute;
    left: 31px;
    top: 0;
    bottom: 0;
    display: block;
    width: 2px;
    background-color: #34ce72;
    z-index: 1;
  }

  &:first-child {
    &:after {
      top: 36px;
    }
  }

  &:last-child {
    &:after {
      top: 0;
      bottom: 36px;
    }
  }
`;

export const Content = styled.div`
  padding: 16px;
  padding-left: 56px;
  border-radius: 8px;
  background-color: #f2f6fa;
`;

export const Title = styled.h5`
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  color: #061c2e;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const TextLine = styled.div`
  display: flex;
  font-size: 12px;
  line-height: 1.33;
  margin-bottom: 8px;
  color: #4b6276;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const TextTitle = styled.span`
  display: block;
  color: #4b6276;
  font-weight: bold;
  width: 128px;
`;

export const TextInfo = styled.span`
  display: block;
  font-weight: normal;
  padding-left: 16px;
`;

export const Total = styled.div`
  display: flex;
  padding-top: 8px;
  padding-bottom: 40px;
`;

export const TotalIconWrap = styled.div`
  height: 20px;
  padding-top: 2px;
  width:24px;

  svg {
    vertical-align: top;
  }
`;

export const TotalText = styled.span`
  display: block;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  color: #061c2e;
`;