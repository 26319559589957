import React from 'react';
import { TreeSelect } from 'antd';
import styled from 'styled-components';
import {TreeSelectProps} from 'antd/lib/tree-select';
interface SelectInputProps extends TreeSelectProps<any> {}

const StyledTreeSelect = styled(TreeSelect)`
  outline: none;
  &.ant-select-arrow {
    right: 1rem;
  }
  & .ant-select-selection-overflow {
    overflow: hidden;
  }
  &.ant-select {
    &.ant-select-lg {
      &:not(.ant-select-customize-input) {
        & .ant-select-selector {
          padding: 4px 1rem;
          height: auto;
          border-radius: 0.5rem;
          border: solid 1px #aebeca;
          background-color: #ffffff;
          width: 100%;
        }
      }

      &.ant-select-multiple {
        & .ant-select-selector {
          padding: 5px 1rem;
        }
      }

      &.ant-select-focused,
      &.ant-select-auto-complete.ant-select-focused:not(.ant-select-disabled) {
        & .ant-select-selector {
          border-color: transparent;
          box-shadow: 0 0 8px 0 #34ce72;
        }
      }
    }
  }
  &.ant-select-multiple {
    & .ant-select-selection-item {
      line-height: 1.5rem;
      font-size: 0.75rem;
      color: #4b6276;
      padding: 0.25rem 0.5rem;
      border-radius: 0.25rem;
      background-color: #f2f6fa;
    }
  }
  &.ant-select-disabled {
    &.ant-select:not(.ant-select-customize-input) {
      & .ant-select-selector {
        color: #627d95;
        border: solid 1px #aebeca;
        background-color: #f2f6fa;
        cursor: not-allowed;
      }
    }
  }
  &.custom-select {
    & .ant-select-selection-placeholder {
      color: #aebeca;
    }
    & .ant-select-arrow {
      right: 0.5rem;
      width: 1.5rem;
      height: 1.5rem;
      margin-top: -0.75rem;
    }
    &.ant-select-single.ant-select-lg {
      &:not(.ant-select-customize-input):not(.ant-select-customize-input) {
        & .ant-select-selection-search-input {
          height: 3rem;
        }
      }
    }

    & .ant-select-tree-checkbox-inner {
      width: 1.125rem;
      height: 1.125rem;
      border: 1px solid #aebeca;
      border-radius: 0.2rem;
      &:after {
        left: 24%;
      }
    }

    & .ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
      background-color: #627d95;
      border-color: #627d95;
      border-radius: 0.2rem;
    }

    &:hover::after,
    &:hover .ant-select-tree-checkbox::after {
      visibility: hidden;
    }

    & .ant-select-tree-checkbox-input:focus + .ant-select-tree-checkbox-inner {
      border-color: #627d95;
      border-radius: 0.2rem;
      &::after {
        left: 24%;
      }
    }

    & .ant-select-tree-checkbox-input:hover {
      & + .ant-select-tree-checkbox-inner {
        border-color: #29ae5e;
        border-radius: 0.2rem;
      }
    }

    & .ant-select-tree-checkbox-checked {
      & .ant-select-tree-checkbox-input:hover {
        & + .ant-select-tree-checkbox-inner {
          background-color: #29ae5e;
          border-radius: 0.2rem;
        }
      }
    }
  }
  & .ant-select-selection-placeholder {
    color: #061c2e;
  }
`;

const { SHOW_PARENT } = TreeSelect;

const TreeSelectInput = ({ ...rest }: SelectInputProps) => {
    const tProps = {
        treeCheckable: true,
        showCheckedStrategy: SHOW_PARENT,
        placeholder: 'Please select',
        style: {
            width: '100%',
        },
    };
    let mergedProps = {...rest, ...tProps};
    return <StyledTreeSelect {...mergedProps}></StyledTreeSelect>;
};

export default TreeSelectInput;
