import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Form, } from 'antd';
import Button from '../../../UI/buttons/Button';
import FormTitle from '../../../components/auth/FormTitle';
import FormItem from '../../../components/form/FormItem';
import { showKomboConnect } from '@kombo-api/connect'
import { activateConnection, getConnectLink } from '../../../api/integrations';
import { FormSubtitle } from '../../form/JobInfoForm/styles';
import { useLocation } from 'react-router-dom';

const StyledForm = styled(Form)`
  margin-top: 2.5rem;
  width: 100%;
`;

type ConnectAtsFormProps = {
  onConnectionComplete: () => void,
}

const ConnectAtsForm = ({ onConnectionComplete }: ConnectAtsFormProps) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [loading] = useState(false);
  const location = useLocation();
  return (
    <StyledForm
      scrollToFirstError={true}
      layout="vertical"
      name="invitation"
      form={form}
    >
      <FormTitle text={t('ats.ATS_SETTINGS_TITLE')} />
      <FormItem>
        <FormSubtitle>{t('ats.NO_ATS_CONNECTED')}</FormSubtitle>
        <p>{t('ats.CONNECT_EXPLAINER')}</p>
        <p>Click below to start the process.</p>
        <Button type="primary" loading={loading} onClick={async () => {
          const params = (location.search.startsWith('?') ? location.search.slice(1).split('&') : []).map((param) => param.split('='));
          const options = {
            environment: params.filter(([key]) => key === 'environment').map(([_, value]) => value).find(() => true),
            tool: params.filter(([key]) => key === 'tool').map(([_, value]) => value).find(() => true),
          }
          const link = await getConnectLink(options)
          if (!link) console.error('Error obtaining connection link')
          else {
            const token = await showKomboConnect(link);
            await activateConnection(token);
            onConnectionComplete();
          }
        }}>
          {t('ats.START_CONNECTING')}
        </Button>
      </FormItem>
    </StyledForm>
  )
}

export default ConnectAtsForm
