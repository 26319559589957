import React from 'react';
import styled from 'styled-components';
import {CandidateInfo, CandidatesStagesStatuses} from '../../../types';
import Item from './Item';
import { Droppable } from 'react-beautiful-dnd';
import TooltipPrompt from '../../../UI/tootlip';
import { useTranslation } from 'react-i18next';

interface KanbanColumnProps {
  title: string;
  disabled: boolean;
  data: CandidateInfo[];
  candidateStagesStatuses: CandidatesStagesStatuses[];
  onOffSyncClicked: (info: CandidateInfo) => void;
}
const Wrapper = styled.div`
  width: 23%;
  min-width: 17rem;
  margin-right: 1rem;
  background: #ffffff;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  align-self: auto;
  flex: 1 1 auto;
  max-width: 17rem;
  &.disabled {
    opacity: 0.3;
  }
`;
const Header = styled.div`
  padding: 1rem 1.5rem;
  background-color: #eff9f3;
  display: flex;
  justify-content: space-between;
  border-radius: 0.5rem;
`;
const HeaderItem = styled.div`
  padding: 0.5rem 0.5rem 0;
  background: #ffffff;
  border-top-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
`;
const HeaderWrapper = styled.div`
  background: #f2f6fa;
  overflow: hidden;
  position: sticky;
  top: 0;
  z-index: 2;
`;
const HeaderText = styled.span`
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.33;
  letter-spacing: 0.96px;
  color: #061c2e;
  text-transform: uppercase;
`;
const Body = styled.div`
  flex-grow: 1;
`;
const BodyWrapper = styled.div`
  padding: 0 0.5rem 0.5rem;
  border-radius: 0.5rem;
  display: flex;
  flex: 1;
`;
const standardStages = ['hired','on_hold','introduced','offered','rejected']
const Column = ({
  title,
  data,
  disabled,
  candidateStagesStatuses,
  onOffSyncClicked,
}: KanbanColumnProps) => {
  const [t] = useTranslation();
  return (
    <Wrapper className={disabled ? 'disabled' : ''}>
      <HeaderWrapper>
        <HeaderItem>
          <Header>
            <HeaderText>
              <TooltipPrompt title={t(standardStages.includes(title) ?`KANBAN_${title.toUpperCase()}_TOOLTIP` : 'KANBAN_STAGE_TOOLTIP')}>
                {title === 'offered' ? 'Offer stage' : title}
              </TooltipPrompt>
            </HeaderText>
            <HeaderText style={{ fontWeight: 400, color: '#627d95' }}>
              {data.length}
            </HeaderText>
          </Header>
        </HeaderItem>
      </HeaderWrapper>
      <BodyWrapper>
        <Droppable droppableId={title} isDropDisabled={disabled}>
          {(provided) => (
            <Body ref={provided.innerRef} {...provided.droppableProps}>
              {data.map((item, index) => (
                <Item
                  info={item}
                  key={item.id + index}
                  index={index}
                  column={title.toLowerCase()}
                  candidateStagesStatuses={candidateStagesStatuses}
                  onOffSyncClicked={onOffSyncClicked}
                />
              ))}
              {provided.placeholder}
            </Body>
          )}
        </Droppable>
      </BodyWrapper>
    </Wrapper>
  );
};

export default Column;
