import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface InfoProps {
  title: string;
  result: ReactNode;
  direction?: 'horizontal' | 'vertical';
}

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 0.5rem;
  &.vertical {
    flex-direction: column;
    margin-bottom: 2.5rem;
    & p {
      width: 100%;
      min-width: 100%;
    }
  }
`;

const Title = styled.p`
  font-size: 0.625rem;
  font-weight: 700;
  line-height: 1.6;
  letter-spacing: 0.8px;
  color: #627d95;
  text-transform: uppercase;
  margin: 0.25rem 0 0.5rem;
  width: 50%;
  min-width: 50%;
  position: relative;
`;

const Result = styled.div`
  font-size: 0.875rem;
  line-height: 1.71;
  text-align: left;
  margin: 0;
  color: #061c2e;
  width: 50%;
  min-width: 50%;
  position: relative;
  white-space: pre-line;
  &.vertical{
    width: 100%;
  }
`;

const InfoItem = ({ title, result, direction = 'horizontal' }: InfoProps) => {
  return (
    <Wrapper className={direction}>
      <Title> {title}</Title>
      <Result className={direction}>{result}</Result>
    </Wrapper>
  );
};

export default InfoItem;
