import { createContext, useContext, useEffect, useState } from 'react';
import { getEmploymentTypeList } from '../api/employmentTypes';
import { EmploymentType } from '../types';

const EmploymentTypesContext = createContext<EmploymentType[]>([]);

const useEmploymentTypes = () => {
  return useContext(EmploymentTypesContext);
};

// !WB: I wrote this quickly and then realised there's actually a redux store already on the app. Maybe I should have used that..
export const EmploymentTypesProvider: React.FC = ({ children }) => {
  const [employmentTypes, setEmploymentTypes] = useState<EmploymentType[] | null>(null)

  useEffect(() => {
    if (employmentTypes) return;
    getEmploymentTypeList().then((res) => {
      setEmploymentTypes(res.data);
    })
  }, [employmentTypes]);

  return (
    <EmploymentTypesContext.Provider value={employmentTypes || []}>
      {children}
    </EmploymentTypesContext.Provider>
  );
}

export default useEmploymentTypes;