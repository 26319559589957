import React, { ReactNode, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

interface DescriptionProps {
  text?: string;
  children?: ReactNode;
}

const SectionWrapper = styled.div`
  height: 80px;
  overflow: hidden;
  position: relative;
  transition: height ease 0.5s;
  &.expand:after {
    position: absolute;
    content: '';
    bottom: 0;
    height: 1.5rem;
    width: 100%;
    background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.8),
      #ffffff
    );
    z-index: 99;
  }
`;
const SectionButton = styled.button`
  padding: 0.25rem 0.5rem;
  background: #ffffff;
  font-size: 0.75rem;
  font-weight: 800;
  line-height: 1.33;
  color: #627d95;
  border: 1px solid #627d95;
  border-radius: 0.25rem;
  margin-top: 0.5rem;
  outline: none;
  cursor: pointer;
`;
const SectionText = styled.span`
  margin-bottom: 2.5rem;
  display: block;
  white-space: pre-line;
`;
const DescriptionSection = ({ text, children }: DescriptionProps) => {
  const [expanded, setExpanded] = useState(false);
  const [textHeight, setTextHeight] = useState(80);
  const [t] = useTranslation();
  const textRef = useRef<HTMLParagraphElement | null>(null);
  const sectionRef = useRef(null);
  const handleClick = () => {
    const textCurrent = (textRef.current as unknown) as HTMLElement;
    const sectionCurrent = (sectionRef.current as unknown) as HTMLElement;
    if (!expanded) {
      const height = textCurrent.clientHeight;
      if (height > 80) {
        sectionCurrent.style.height = `${height + 20}px`;
      }
    } else {
      sectionCurrent.style.height = `80px`;
    }
    setExpanded(!expanded);
  };
  const setHeight = () => {
    if (textRef.current) {
      let textCurrent = textRef.current.offsetHeight;
      setTextHeight(textCurrent);
    }
  };
  useEffect(() => {
    setHeight();
    window.addEventListener('resize', setHeight);
    return () => window.removeEventListener('resize', setHeight);
  });
  return (
    <>
      <SectionWrapper
        ref={sectionRef}
        className={textHeight >= 80 ? 'expand' : ''}
      >
        <p ref={textRef}>
          {text && <SectionText>{text}</SectionText>}
          {children}
        </p>
      </SectionWrapper>
      {textHeight >= 80 && (
        <SectionButton onClick={handleClick}>
          {expanded ? t('SEE_LESS') : t('SEE_MORE')}
        </SectionButton>
      )}
    </>
  );
};

export default DescriptionSection;
