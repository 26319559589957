import { Modal } from 'antd';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Button from '../../../UI/buttons/Button';
import { CandidateInfo } from '../../../types';

interface MoveInAtsModalProps {
  state: CandidateInfo | null;
  onCancel: () => void;
  onConfirm: (info: CandidateInfo) => void;
};

const StyledModal = styled(Modal)`
  & .ant-modal-content {
    padding: 32px;
  }
  & .ant-modal-header {
    border-bottom: none;
    padding: 0;
    margin-bottom: 16px;
    & div {
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: left;
      color: #061c2e;
    }
  }
  & .ant-modal-body {
    padding: 0;
    & p {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
      text-align: left;
      color: #061c2e;
    }
  }
  & .ant-modal-footer {
    padding: 0;
    display: flex;
    border-top: none;
  }
`;
const MoveInAtsModal = ({ state, onCancel, onConfirm }: MoveInAtsModalProps) => {
  const [t] = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleOk = () => {
    if (!state) return;
    setLoading(true);
    onConfirm(state);
  };
  return (
    <StyledModal
      closable={false}
      visible={!!state}
      title={t('ats.MOVE_IN_ATS_TITLE')}
      onOk={handleOk}
      onCancel={onCancel}
      footer={[
        <Button danger type="primary" onClick={handleOk} loading={loading}>
          {t('ats.MOVE_IN_ATS_BUTTON')}
        </Button>,
        <Button type="ghost" onClick={onCancel}>
          {t('CANCEL_TEXT')}
        </Button>,
      ]}
    >
      <p>
        {t('ats.MOVE_IN_ATS_TEXT')}
      </p>
    </StyledModal>
  );
};

export default MoveInAtsModal;
