import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { formatDistance } from 'date-fns';

import SalaryText from '../SalaryText';
import { InterviewRoom } from '../../../types'
import {
  WAITING_RECRUITER_OFFER,
  WAITING_CLIENT_OFFER,
  REJECTED,
  HIRED,
} from '../../../constants/statuses';

interface Iprops {
  interview: InterviewRoom
}

const Wrap = styled.div`
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const CandidateInterviewColumn = ({
  interview
}: Iprops) => {
  const [t] = useTranslation();

  const inProcessAt = interview.client?.company?.name;
  const daysInProcess = interview.current_stage?.created_at;

  const stage = useMemo(() => {
    if (interview.current_offer) {
      return 'offer'; // don't translate stage name
    }
  
    if (interview.current_stage?.stage_name) {
      return interview.current_stage.stage_name;
    }

    return interview.status;
  }, [interview]);

  const status = useMemo(() => {
    if (interview.status === REJECTED || interview.status === HIRED) {
      return interview.status;
    }

    if (interview?.current_offer?.status === WAITING_CLIENT_OFFER) {
      return t('WAITING_CLIENT_OFFER_2').toLowerCase();
    }

    if (interview?.current_offer?.status === WAITING_RECRUITER_OFFER) {
      return t('WAITING_RECRUITER').toLowerCase();
    }

    const clientFeedback = interview?.current_stage?.feedbacks?.find((fb: any) => fb.feedback_type === 'client_feedback');
    const recruiterFeedback = interview?.current_stage?.feedbacks?.find((fb: any) => fb.feedback_type === 'recruiter_feedback');

    const clientFeedbackIsCompleted = clientFeedback?.is_completed;
    const recruiterFeedbackIsCompleted = recruiterFeedback?.is_completed;

    if (clientFeedbackIsCompleted && !recruiterFeedbackIsCompleted) {
      return t('RECRUITER_FEEDBACK').toLowerCase();
    }

    if (!clientFeedbackIsCompleted && recruiterFeedbackIsCompleted) {
      return t('CLIENT_FEEDBACK').toLowerCase();
    }

    if (interview.current_stage?.status) {
      // statuses: planning, faiting for recruiter, waiting for client, in progress
      return interview.current_stage?.status;
    }

    return interview.status;
  }, [interview, t]);

  let days = '';
  if (daysInProcess) {
    days = formatDistance(
      new Date(daysInProcess),
      new Date(),
    { addSuffix: true }
    );
  }

  return (
    <Wrap>
      <SalaryText>
        {`${t('IN_PROCESS_AT')}: ${inProcessAt}`}
      </SalaryText>

      {stage && (
        <SalaryText>
          {`${t('INTERVIEW_STAGE')}: ${stage}`}
        </SalaryText>
      )}

      {daysInProcess && (
        <SalaryText>
          {`${t('DAYS_IN_PROCESS')}: ${days}`}
        </SalaryText>
      )}

      <SalaryText>
        {`${t('STATUS_2')}: ${status}`}
      </SalaryText>
    </Wrap>
  );
};

export default React.memo(CandidateInterviewColumn);