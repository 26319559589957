import React from 'react';
import styled from 'styled-components';
import {Link} from "react-router-dom";
import {CLIENT} from "../../../constants/routes";
import {useTranslation} from "react-i18next";
import TooltipPrompt from "../../../UI/tootlip";

interface SectionHeadingProps {
  src?: string;
  title: string;
  className?: string;
  companyId?: string;
}
const HeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  a {
    color: #061c2e;
  }
  &.salary {
    @media (max-width: 992px) {
      margin: 0 1rem 0 0;
    }
  }
`;
const Title = styled.h5`
  font-size: 0.625rem;
  font-weight: 700;
  line-height: 1.6;
  letter-spacing: 0.8px;
  color: #061c2e;
  text-transform: uppercase;
  margin: 0;
  position: relative;
`;
const Image = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  min-width: 1.5rem;
  min-height: 1.5rem;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 0.5rem;
  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
const SectionHeading = ({ src, title, className, companyId }: SectionHeadingProps) => {
  const [t] = useTranslation();
  return (
    <HeadingWrapper className={className ? className : ''}>
        {src && (
            <Image>
              <img src={src} alt="" />
            </Image>
        )}
        <Title>
          {companyId ? (<TooltipPrompt title={t('CLICK_TO_SEE_PROFILE')}><Link to={CLIENT + '/' + companyId}>{title}</Link></TooltipPrompt>) : title}
        </Title>
    </HeadingWrapper>
  );
};

export default SectionHeading;
