import styled from 'styled-components';

import { ReactComponent as HeartIcon } from '../../../../icons/heart.svg';
import Button from '../../../../UI/buttons/Button';

export const BannerWrapper = styled.div`
  width: 100%;
  min-height: 80vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #324759;
  display: flex;
`;

export const BannerOverlay = styled.div`
  width: 100%;
  padding: 1rem 0 6.5rem ;
  background: rgba(6, 29, 49, 0.6);
  @media(max-width: 1280px){
    padding: 1rem 1rem 6.5rem ;
  }
`;

export const BannerContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 1140px;
  margin: 0 auto;
`;

export const BannerHeader = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    margin-bottom: 2rem;
  }
`;

export const HeaderTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.33;
  color: #f2f6fa;
  margin-left: 1rem;
  margin-bottom: 0;
  text-transform: capitalize;
`;

export const BannerInfo = styled.div`
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const BannerLogo = styled.div`
  min-width: 6rem;
  min-height: 6rem;
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1.5rem;
  overflow: hidden;
  background: #ffffff;
  & img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const BannerInfoText = styled.div`
  width: 100%;
`;

export const JobTitle = styled.div`
  display: flex;
  align-items: flex-start;
  color: #ffffff;
  margin-top: 0.75rem;
  & h2{
    font-size: 2rem;
  font-weight: 700;
  line-height: 1.25;
  color: #ffffff;
  margin: 0;
  }
  & .views {
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.33;
    display: flex;
    align-items: center;
    margin-top: .5rem;
    & svg {
      color: #ffffff;
      margin: 0 0.5rem 0 1rem;
      font-size: 1.5rem;
    }
  }
  a {
    color: #ffffff;
  }
`;

export const FavoriteIcon = styled(HeartIcon)`
  margin-left: 0.25rem;
  color: #aebeca;
  cursor: pointer;
  min-width: 1.5rem;
  min-height: 1.5rem;
  margin-top: .3rem;
    &.active {
    color: #34ce72;
    fill: #34ce72;
  }
`;

export const JobAbout = styled.p`
  font-size: 0.875rem;
  line-height: 1.71;
  color: #ffffff;
  //margin-bottom: 2rem;
  max-width: calc(66.6% - 50px);
  width: 100%;
`;

export const SkillItem = styled.div`
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  background-color: #f2f6fa;
  color: #4c6276;
  display: inline-block;
  font-size: 0.75rem;
  line-height: 1.33;
  max-width: max-content;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
`;

export const SkillItemAlt = styled.div`
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  background-color: rgba(52, 206, 114, .6);
  color: #4c6276;
  border: none;
  display: inline-block;
  font-size: 0.75rem;
  line-height: 1.33;
  max-width: max-content;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
`;

export const SkillItemList = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  max-height: 96px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  color:#fff;
  display: -webkit-flex;
`;

export const JobInfoItem = styled.div`
  font-size: 0.875rem;
  line-height: 1.71;
  color: #ffffff;
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  text-transform: capitalize;
  & svg {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
  }
`;

export const ActionButtons = styled.div`
  height: 100%;
  justify-content: flex-end;
  flex-direction: column;
  display: flex;
  align-items: flex-end;
  max-width: max-content;
  margin-left: auto;
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

export const StyledButton = styled(Button)`
  margin: 0.5rem 0 0 0 ;
  justify-content: center;
  width: 100%;
  & svg {
    width: 1rem;
    height: 1rem;
  }
`;
