import React, { useEffect, useState } from 'react';
import { Col, Form, Radio, Row, Select } from 'antd';
import styled from 'styled-components';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import FormItem from '../form/FormItem';
import { ReactComponent as ArrowDownIcon } from '../../icons/arrow-down.svg';
import { ReactComponent as InfoIcon } from '../../icons/info-icon-rounded.svg';
import { ReactComponent as SearchIcon } from '../../icons/search.svg';
import { City, Seniority, Skill, Specialization } from '../../types';
import { getInterviewStatuses } from '../../api/interview-stages';
import { ADMIN_ROLE } from '../../constants/roles';
import NumberInput from '../../UI/buttons/NumberInput';
import SelectInput from '../../UI/inputs/Select';
import CheckboxInput from '../../UI/inputs/CheckboxInput';
import RadioInput from '../../UI/inputs/RadioInput';
import Input from '../../UI/inputs/Input';

interface FilterProps {
  domains: Specialization[];
  seniorities: Seniority[];
  cities: City[];
  skills: Skill[];
  role?: string;
}

const FilterWrapper = styled.div`
  padding: 1rem 2rem 0;
  border-radius: 0.5rem;
  background: #ffffff;
  margin-bottom: 1rem;
  position: sticky;
  top: 0;
  z-index: 998;
  @media (max-width: 576px) {
    position: relative;
  }
`;

const StyledButton = styled.button`
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  background: #34ce72;
  color: #ffffff;
  border: none;
  outline: none;
  cursor: pointer;
  position: absolute;
  top: 2.5rem;
  left: 100%;
  transform: translateX(-50%);
  & svg {
    width: 1.2rem;
    height: 1.2rem;
  }
  &:hover {
    background: #22bf61;
  }
`;
const LogicalFilter = styled.div`
  border-top: 1px solid rgba(174, 190, 202, 0.47);
  padding: 1rem;
`;
const { Option } = Select;
const CandidateListFilter = ({
  cities,
  seniorities,
  domains,
  skills,
  role,
}: FilterProps) => {
  const history = useHistory();
  const params = new URLSearchParams(history.location.search);

  const [data, setData] = useState<{
    search: null | string;
    skill: undefined | string[];
    domain: null | string;
    seniority: null | string;
    location: null | string;
    status: null | string;
    recruiter_name: null | string;
    salary_from: null | string;
    salary_to: null | string;
    logical: null | string;
    not: boolean;
    statuses: [];
  }>({
    logical: params.get('skill')?.includes('OR') ? 'OR' : 'AND',
    not: !!params.get('skill')?.includes('NOT'),
    skill: params
      .get('skill')
      ?.replace(/OR|NOT|AND|[+]/g, '')
      .split(' ')
      .filter((el) => el),
    domain: params.get('domain'),
    seniority: params.get('seniority'),
    location: params.get('location'),
    status: params.get('status'),
    recruiter_name: params.get('recruiter_name'),
    search: params.get('search'),
    salary_from: params.get('salary_from'),
    salary_to: params.get('salary_to'),
    statuses: [],
  });
  const [t] = useTranslation();
  const [form] = Form.useForm();
  const isAdmin = role === ADMIN_ROLE;

  const onFinish = (values: any) => {
    Object.keys(values).forEach((key) => {
      if (values[key]) {
        if (key === 'skill') {
          if (
            !values[key].length ||
            (values[key].length && values[key][0] === '')
          ) {
            params.delete(key);
          } else {
            params.set(
              key,
              values.not
                ? 'NOT ' +
                    values[key].join(
                      ` ${values.logical} ${values.not ? 'NOT' : ''} `,
                    )
                : values[key].join(` ${values.logical} `),
            );
          }
        } else {
          params.set(key, values[key]);
        }
      } else {
        params.delete(key);
      }
    });
    params.delete('logical');
    params.delete('not');
    history.push({ search: params.toString() });
  };
  useEffect(() => {
    form.setFieldsValue({
      skill: skills.length ? data.skill : [],
      domain: !!domains.length && data.domain,
      seniority: !!seniorities.length && data.seniority,
      location: !!cities.length && data.location,
      salary_from: data.salary_from,
      salary_to: data.salary_to,
      search: data.search,
      status: !!role?.length && data.status,
      logical: data.logical,
      not: data.not,
      recruiter_name: data.recruiter_name,
    });
  }, [form, data, skills,cities,domains,role,seniorities]);
  useEffect(() => {
    let source = axios.CancelToken.source();
    axios.all([getInterviewStatuses()]).then((res) => {
      setData((prev) => ({
        ...prev,
        statuses: res[0].data.statuses,
      }));
    });
    return () => source.cancel();
  }, []);
  return (
    <div>
      <FilterWrapper>
        <Form
          scrollToFirstError={true}
          layout="vertical"
          name="candidate-filter"
          onFinish={onFinish}
          form={form}
          onValuesChange={(e) => {
            if (e.salary_to || e.salary_to === '') {
              setData((prev) => ({ ...prev, salary_to: e.salary_to }));
            } else if (e.salary_from || e.salary_from === '') {
              setData((prev) => ({ ...prev, salary_from: e.salary_from }));
            } else {
              setData((prev) => ({ ...prev, ...e }));
            }
          }}
        >
          <Row gutter={16}>
            <Col xs={{ span: 12 }} md={{ span: 8 }} xl={{ span: 6 }}>
              <FormItem label={t('NAME_LABEL')} name="search">
                <Input placeholder={t('CANDIDATE_NAME')} size={'large'} />
              </FormItem>
            </Col>
            <Col xs={{ span: 12 }} md={{ span: 8 }} xl={{ span: 6 }}>
              <FormItem label={t('DOMAIN_TITLE')} name="domain">
                <SelectInput
                  size="large"
                  showSearch
                  placeholder={t('DOMAIN_PLACEHOLDER')}
                  optionFilterProp="children"
                  filterOption={true}
                  className="custom-select"
                  suffixIcon={<ArrowDownIcon />}
                >
                  <Option value="">{t('NONE')}</Option>
                  {domains.map((dom) => (
                    <Option value={dom.id} key={dom.id}>
                      {dom.name}
                    </Option>
                  ))}
                </SelectInput>
              </FormItem>
            </Col>

            <Col xs={{ span: 12 }} md={{ span: 8 }} xl={{ span: 6 }}>
              <FormItem label={t('SENIORITY_LABEL')} name="seniority">
                <SelectInput
                  size="large"
                  showSearch
                  placeholder={t('SENIORITY_PLACEHOLDER')}
                  optionFilterProp="children"
                  filterOption={true}
                  className="custom-select"
                  suffixIcon={<ArrowDownIcon />}
                >
                  <Option value="">{t('NONE')}</Option>
                  {seniorities.map((sen) => (
                    <Option value={sen.id} key={sen.id}>
                      {sen.name}
                    </Option>
                  ))}
                </SelectInput>
              </FormItem>
            </Col>
            <Col xs={{ span: 12 }} md={{ span: 8 }} xl={{ span: 6 }}>
              <FormItem label={t('LOCATION_LABEL')} name="location">
                <SelectInput
                  size="large"
                  showSearch
                  placeholder={t('LOCATION_PLACEHOLDER')}
                  optionFilterProp="children"
                  filterOption={true}
                  className="custom-select"
                  suffixIcon={<ArrowDownIcon />}
                >
                  <Option value="">{t('NONE')}</Option>
                  {cities.map((city) => (
                    <Option value={city.id} key={city.id}>
                      {city.name}
                    </Option>
                  ))}
                </SelectInput>
              </FormItem>
            </Col>
            <Col
              xs={{ span: 24 }}
              md={{ span: 16 }}
              xl={{ span: isAdmin ? 6 : 12 }}
            >
              <FormItem
                label={t('SKILL_LABEL')}
                name="skill"
                tooltip={{ title: t('SKILL_INFO_TEXT'), icon: <InfoIcon /> }}
              >
                <SelectInput
                  size="large"
                  allowClear={true}
                  showSearch
                  placeholder={t('SKILL_PLACEHOLDER')}
                  optionFilterProp="children"
                  filterOption={true}
                  mode="tags"
                  className="custom-select"
                  dropdownRender={(menu) => (
                    <div>
                      {menu}
                      <LogicalFilter>
                        <Row justify="space-between">
                          <Col>
                            <FormItem name="logical" className="no-margin">
                              <Radio.Group>
                                <RadioInput value="AND">{t('AND')}</RadioInput>
                                <RadioInput value="OR">{t('OR')}</RadioInput>
                              </Radio.Group>
                            </FormItem>
                          </Col>
                          <Col>
                            <FormItem
                              name="not"
                              className="no-margin"
                              valuePropName="checked"
                            >
                              <CheckboxInput>{t('NOT')}</CheckboxInput>
                            </FormItem>
                          </Col>
                        </Row>
                      </LogicalFilter>
                    </div>
                  )}
                >
                  {skills.map((skill) => (
                    <Option value={skill.id} key={skill.id}>
                      {skill.name}
                    </Option>
                  ))}
                </SelectInput>
              </FormItem>
            </Col>
            <Col
              xs={{ span: 24 }}
              md={{ span: isAdmin ? 24 : 12 }}
              xl={{ span: 6 }}
            >
              <FormItem label={t('SALARY_RANGE_LABEL')}>
                <Row gutter={8}>
                  <Col xs={{ span: 12 }} md={{ span: 12 }} xl={{ span: 12 }}>
                    <FormItem
                      label=""
                      name="salary_from"
                      className="no-margin"
                      rules={[
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            const to = getFieldValue('salary_to');
                            if (to && parseInt(value) > parseInt(to)) {
                              return Promise.reject(t('SALARY_RANGE_ERROR'));
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <NumberInput
                        size="large"
                        type={'number'}
                        placeholder={t('SALARY_FROM_PLACEHOLDER')}
                      />
                    </FormItem>
                  </Col>
                  <Col xs={{ span: 12 }} md={{ span: 12 }} xl={{ span: 12 }}>
                    <FormItem label="" name="salary_to" className="no-margin">
                      <NumberInput
                        size="large"
                        type={'number'}
                        placeholder={t('SALARY_TO_PLACEHOLDER')}
                      />
                    </FormItem>
                  </Col>
                </Row>
              </FormItem>
            </Col>
            <Col
              xs={{ span: isAdmin ? 12 : 24 }}
              md={{ span: 12 }}
              xl={{ span: 6 }}
            >
              <FormItem label={t('STATUS')} name="status">
                <SelectInput
                  size="large"
                  showSearch
                  placeholder={t('STATUS_PLACEHOLDER')}
                  optionFilterProp="children"
                  filterOption={true}
                  className="custom-select"
                  suffixIcon={<ArrowDownIcon />}
                >
                  <Option value="">{t('NONE')}</Option>
                  {data.statuses.map((status) => (
                    <Option value={status} key={status}>
                      {status}
                    </Option>
                  ))}
                </SelectInput>
              </FormItem>
            </Col>
            {isAdmin && (
              <Col xs={{ span: 12 }} md={{ span: 12 }} xl={{ span: 6 }}>
                <FormItem label={t('RECRUITER_TITLE')} name="recruiter_name">
                  <Input placeholder={t('RECRUITER_NAME')} size={'large'} />
                </FormItem>
              </Col>
            )}
          </Row>
          <StyledButton type="submit">
            <SearchIcon />
          </StyledButton>
        </Form>
      </FilterWrapper>
    </div>
  );
};

export default CandidateListFilter;
