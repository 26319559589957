import React from 'react';
import styled from 'styled-components';
import { Activity } from '../../../types';
import { useTranslation } from 'react-i18next';

interface ActivitySectionProps {
  activities: Activity[];
}

const ActivityItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem 1rem 1.25rem;
  border-bottom: 1px solid rgba(6, 28, 46, 0.1);
  &.no-border {
    border: none;
  }
`;
const ActivityName = styled.p`
  font-size: 0.75rem;
  line-height: 1.33;
  color: #061c2e;
  margin-bottom: 0;
  &.bold {
    font-weight: 700;
  }
`;
const ActivityHours = styled.p`
  min-width: max-content;
  padding-left: .5rem;
  font-size: 0.875rem;
  line-height: 1.43;
  text-align: right;
  color: rgba(6, 28, 46, 0.5);
  margin-bottom: 0;
  &.bold {
    font-weight: 700;
    color: #061c2e;
  }
`;
const ActivitySection = ({ activities }: ActivitySectionProps) => {
  const [t] = useTranslation();
  const total = activities.reduce((a, b) => {
    const sum = a + b.hours_per_week;
    return sum;
  }, 0);
  return (
    <div>
      {activities.map((activity) => (
        <ActivityItem key={activity.name}>
          <ActivityName>{activity.name}</ActivityName>
          <ActivityHours>
            {activity.hours_per_week}
            {activity.hours_per_week > 1 ? ` ${t('HOURS_SHORTCODE')}` : ' hr'}
          </ActivityHours>
        </ActivityItem>
      ))}
      <ActivityItem className="no-border">
        <ActivityName className="bold">Total per week</ActivityName>
        <ActivityHours className="bold">
          {total}
          {total > 1 ? ` ${t('HOURS_SHORTCODE')}` : ' hr'}
        </ActivityHours>
      </ActivityItem>
    </div>
  );
};

export default ActivitySection;
