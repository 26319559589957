import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import {Link, useHistory} from 'react-router-dom';

import {
  confirmProposedDate,
  getProposedDates,
  proposeInterviewDates, updateInterviewDates,
} from '../../../../api/interviews';
import FormPopUp from '../../../form/FormPopUp';
import {  InterviewRoom } from '../../../../types';
import NameAvatar from '../../../../UI/NameAvatar';
import Tab from '../../../../UI/Tab';
import ConfirmProposeDate from '../../../form/ConfirmProposeDate';
import AddProposeDate from '../../../form/AddProposeDate';
import {
  COMPLETED,
  PLANNING,
  WAITING_CLIENT,
  WAITING_RECRUITER,
} from '../../../../constants/statuses';
import { useAppSelector } from '../../../../store/hooks';
import { CLIENT_ROLE, RECRUITER_ROLE } from '../../../../constants/roles';
import {CANDIDATES, CLIENT} from '../../../../constants/routes';
import { getRandomColor } from '../../../../constants/palette';
import {
  Header,
  HeaderLeft,
  HeaderRight,
  Name,
  NameWrapper,
  Feature
} from './styles';
import {
  RESCHEDULE_PARAM,
  SHOW_DATE_PROPOSE_PARAM,
} from '../../../../constants/queryParams';
import TooltipPrompt from "../../../../UI/tootlip";

interface InterviewStageModalProps {
  info: InterviewRoom;
  onComplete: (
    interviewId: string,
    stageName: string,
    candidateID: string,
  ) => void;
}
const { TabPane } = Tabs;

const InterviewDateProposeModal = ({
  info,
  onComplete,
}: InterviewStageModalProps) => {
  const [t] = useTranslation();
  const history = useHistory();
  const { user } = useAppSelector((state) => state.user);

  const current = info;
  const [visible, setVisible] = useState(!!current);

  const [loading, setLoading] = useState(true);
  const [dates, setDates] = useState([]);

  const isPlanning = current.current_stage?.status === PLANNING;

  const isCompleted = !!current.current_stage?.tasks.find(
    (task) => task.is_planning_task && task.status === COMPLETED,
  );

  const planningTask = current.current_stage?.tasks.find(
    (task) => task.is_planning_task ,
  );

  const waitingForRecruiter =
    current.current_stage?.status === WAITING_RECRUITER;

  const waitingForClient = current.current_stage?.status === WAITING_CLIENT;

  const isClient = user?.role === CLIENT_ROLE || planningTask?.email === user?.email;

  const isRecruiter = user?.role === RECRUITER_ROLE && planningTask?.email !== user?.email;
  
  //const urlParams = new URLSearchParams(history.location.search);
  //const reschedule = urlParams.get(RESCHEDULE_PARAM);
  const reschedule = true;

  const hide = useCallback(() => {
    const params = new URLSearchParams(history.location.search);
    params.delete(SHOW_DATE_PROPOSE_PARAM);
    params.delete(RESCHEDULE_PARAM);
    const query = params.toString();
    history.push(CANDIDATES + '?' + query);
  }, [history]);

  useEffect(() => {
    visible &&
      getProposedDates(current.id).then((res) => {
        setDates(res.data.proposed_dates);
        setLoading(false);
      });
  }, [current.id, visible]);

  useEffect(() => {
    setVisible(!!info);
  }, [info]);

  useEffect(() => {

    if ((!visible || isCompleted ) && !reschedule) {
      hide();
    }
  }, [visible, hide, isCompleted,reschedule]);

  const handleComplete = (date_id: string) => {
    confirmProposedDate(current.id, date_id).then((res) => {
      onComplete(
        current.id,
        current.current_stage?.stage_name.toLowerCase(),
        info.id,
      );
      hide();
    });
  };

  const addDates = async (dates: {}[]) => {
    if (reschedule){
      return  await updateInterviewDates(current.id, dates[0]).then((res) => {
        onComplete(
          current.id,
          current.current_stage?.stage_name.toLowerCase(),
          info.id,
        );
        hide();
      });
    }

   return  await proposeInterviewDates(current.id, dates).then((res) => {
      onComplete(
        current.id,
        current.current_stage?.stage_name.toLowerCase(),
        info.id,
      );
      hide();
    });
  };

  const interviewTask = info?.current_stage?.tasks?.find((task: any) => task.task_type === 'Plan Interview');
  // @ts-ignore
  const interviewComment = interviewTask?.comment;

  return (
    <FormPopUp
      visible={visible}
      setVisible={setVisible}
      title={t(reschedule ? 'SET_NEW_DATE_TITLE' : 'PLAN_INTERVIEW')}
      onClose={hide}
    >
      <Header>
        <HeaderLeft>
          <NameWrapper>
            <NameAvatar
              name={current.job.company.name}
              image={
                current.job.company.image
                  ? current.job.company.image
                  : process.env.PUBLIC_URL + 'images/company-logo.png'
              }
              color={getRandomColor()}
            />
            <Name><TooltipPrompt title={t('CLICK_TO_SEE_PROFILE')}><Link to={CLIENT + '/' + current.job.company.id}>{current.job.company.name}</Link></TooltipPrompt></Name>
          </NameWrapper>
          <Feature>&</Feature>
          <NameWrapper>
            <NameAvatar name={info.candidate.name} color={getRandomColor()} />
            <Name>{info.candidate.name}</Name>
          </NameWrapper>
        </HeaderLeft>
        <HeaderRight>
          <Name>{current.job.title}</Name>
        </HeaderRight>
      </Header>
      <Tab defaultActiveKey="1">
        {!isPlanning && !reschedule  && (
          <TabPane tab={t('PROPOSED_DATES')} key="1">
            <ConfirmProposeDate
              dates={dates}
              onConfirm={handleComplete}
              interview={info}
              loading={loading}
            />
          </TabPane>
        )}
        <TabPane
          tab={isPlanning || reschedule ? t('DATES') : t('OTHER_DATES')}
          key="2"
          disabled={
            (isClient && waitingForRecruiter) ||
            (isRecruiter && waitingForClient)
          }
        >
          <AddProposeDate addDates={addDates} interviewComment={interviewComment} />
        </TabPane>
      </Tab>
    </FormPopUp>
  );
};

export default InterviewDateProposeModal;
