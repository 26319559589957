import styled from 'styled-components';

interface TextProps {
  text: string;
  marginBottom?: string,
  fontSize?: string,
  lineHeight?: string,
}

interface StyledProps {
  marginBottom?: string,
  fontSize?: string,
  lineHeight?: string,
}

const StyledText = styled.p<StyledProps>`
  font-size: ${props => props.fontSize || '14px'};
  line-height: ${props => props.lineHeight || '1.71'};
  color: #061c2e;
  margin-bottom: ${props => props.marginBottom || '1.5rem'};
`;

const Text = ({
  text,
  marginBottom,
  fontSize,
  lineHeight,
}: TextProps) => {
  return (
  <StyledText
    marginBottom={marginBottom}
    fontSize={fontSize}
    lineHeight={lineHeight}
  >{text}</StyledText>
  );
};

export default Text;
