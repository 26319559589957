import moment from 'moment';

export const emailRule = (t: (key: string) => string) => {
  return [
    {
      type: 'email',
      message: t('INVALID_EMAIL_ERROR'),
    },
  ];
};
export const requiredRule = (t: (key: string) => string) => {
  return [
    {
      required: true,
      message: t('REQUIRED_FIELD'),
    },
  ];
};
export const maxLengthRule = (
  number: number,
  t: (key: string, number: { number: number }) => string,
) => {
  return [
    {
      max: number,
      message: t('MAX_LENGTH_RULE', { number: number }),
    },
  ];
};

export const passwordRule = (t: (key: string) => string) => {
  return [
    {
      required: true,
      message: t('PASSWORD_RULE'),
    },
    () => ({
      validator(rule: any, value: any) {
        let uppercaseReg = /(?=.*[A-Z])/;
        let lowercaseReg = /^(?=.*[a-z])/;
        let numberReg = /^(?=.*[0-9])/;
        let symbolReg = /^(?=.*[!@#$%^&*])/;
        let longReg = /^(?=.{8,})/;
        //let reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
        if (!longReg.test(value)) {
          return Promise.reject(t('PASSWORD_SHORT_RULE'));
        } else if (!uppercaseReg.test(value)) {
          return Promise.reject(t('UPPERCASE_RULE'));
        } else if (!lowercaseReg.test(value)) {
          return Promise.reject(t('LOWERCASE_RULE'));
        } else if (!numberReg.test(value)) {
          return Promise.reject(t('NUMBER_CONTAIN_RULE'));
        } else if (!symbolReg.test(value)) {
          return Promise.reject(t('SYMBOL_RULE'));
        } else {
          return Promise.resolve();
        }
      },
    }),
  ];
};
export const numberRuleNotRequired = (t: (key: string) => string) => {
  return [
    () => ({
      validator(rule: any, value: any) {
        let reg = /\+?\d/g;
        if (value === 0 || !value) {
          return Promise.resolve();
        }
        if (!reg.test(value)) {
          return Promise.reject(t('NOT_VALID_RULE'));
        }
        if (value < 0) {
          return Promise.reject(t('NOT_VALID_RULE'));
        }
        return Promise.resolve();
      },
    }),
  ];
};
export const correctDateRule = (t: (key: string) => string) => {
  return [
    () => ({
      validator(rule: any, value: any) {
        if (moment(new Date()).diff(value) > 0) {
          return Promise.reject(t('PAST_DATE_RULE'));
        } else {
          return Promise.resolve();
        }
      },
    }),
  ];
};

export const numberRule = (t: (key: string) => string) => {
  return [
    () => ({
      validator(rule: any, value: any) {
        let reg = /\+?\d/g;
        if (!value) {
          return Promise.reject(t('REQUIRED_FIELD'));
        }
        if (!reg.test(value)) {
          return Promise.reject(t('NOT_VALID_RULE'));
        }
        if (value < 0) {
          return Promise.reject(t('NOT_VALID_RULE'));
        }
        return Promise.resolve();
      },
    }),
  ];
};

// 0 from the backend triggers the error, and '0' from an input not, typical js bullshit
export const numberRuleAndZero = (t: (key: string) => string) => {
  return [
    () => ({
      validator(rule: any, value: any) {
        let reg = /\+?\d/g;
        if (!value && value !== 0) {
          return Promise.reject(t('REQUIRED_FIELD'));
        }
        if (!reg.test(value)) {
          return Promise.reject(t('NOT_VALID_RULE'));
        }
        if (value < 0) {
          return Promise.reject(t('NOT_VALID_RULE'));
        }
        return Promise.resolve();
      },
    }),
  ];
};

// this one will not allow zero
export const numberRuleRequired = (t: (key: string) => string) => {
  return [
    () => ({
      validator(rule: any, value: any) {
        let reg = /\+?\d/g;
        if (value === '0') {
          return Promise.reject(t('NOT_VALID_RULE'));
        }
        if (!value) {
          return Promise.reject(t('REQUIRED_FIELD'));
        }
        if (!reg.test(value)) {
          return Promise.reject(t('NOT_VALID_RULE'));
        }
        if (value < 0) {
          return Promise.reject(t('NOT_VALID_RULE'));
        }
        return Promise.resolve();
      },
    }),
  ];
};

export const numberLimitRule = (
  min: number,
  max: number,
  t: (key: string) => string,
) => {
  return [
    () => ({
      validator(rule: any, value: any) {
        if (value && (value < min || value > max)) {
          return Promise.reject(t('ENTER_A_VALUE') + ' ' + min + ' to ' + max);
        }
        return Promise.resolve();
      },
    }),
  ];
};

export const phoneNumberRule = (t: (key: string) => string) => {
  return [
    () => ({
      validator(rule: any, value: any) {
        let reg = /^(?:[+\d].*\d|\d)$/g;

        if (value.length && !reg.test(value)) {
          return Promise.reject(t('NOT_VALID_RULE'));
        }
        return Promise.resolve();
      },
    }),
  ];
};
export const phoneNumberRequiredRule = (t: (key: string) => string) => {
  return [
    () => ({
      validator(rule: any, value: any) {
        let reg = /^(?:[+\d].*\d|\d)$/g;

        if (!reg.test(value)) {
          return Promise.reject(t('NOT_VALID_RULE'));
        }
        if (!value.length) {
          return Promise.reject(t('REQUIRED_FIELD'));
        }
        return Promise.resolve();
      },
    }),
  ];
};
