import axios from 'axios';
import { History } from 'history';

export const getJobs = async (query: string = '') => {
  return await axios.get(process.env.REACT_APP_API_URL + 'jobs/' + query);
};

export const getJob = async (jobId: string, history?: History) => {
  return await axios
    .get(process.env.REACT_APP_API_URL + `jobs/${jobId}/`)
    .then((res) => res)
    .catch((e) => {
      if (e?.response?.data?.error_code === 'resource_not_found' && history) history.push('/not-found');
      return e.response;
    });
};

export const getPublicJob = async (jobId: string, history?: History) => {
  return await axios
    .get(process.env.REACT_APP_API_URL + `public/jobs/${jobId}/`)
    .then((res) => res)
    .catch((e) => {
      if (e?.response?.data?.error_code === 'resource_not_found' && history) history.push('/not-found');
      return e.response;
    });
};

export const createJob = async (company: string, department: string) => {
  return await axios.post(process.env.REACT_APP_API_URL + 'jobs/', {
    company,
    department,
  });
};

export const updateJobFlexibleFee = async (jobId:string, fee_type: string, fee_tooltip: string) => {
  return await axios.patch(process.env.REACT_APP_API_URL + `jobs/${jobId}/`, {
    recruiter_compensation_type: fee_type,
    recruiter_compensation_type_tooltip: fee_tooltip,
  });
};


export const getJobInfo = async (jobId: string) => {
  return await axios.get(
    process.env.REACT_APP_API_URL + `jobs/${jobId}/details/info/`,
  )
    .then((res) => res)
    .catch((e) => {
      return e.response;
    });
};

export const updateJobInfo = async (jobId: string, draft: any) => {
  return await axios.put(
    process.env.REACT_APP_API_URL + `jobs/${jobId}/details/info/`,
    {
      ...draft,
    },
  )
    .then((res) => res)
};

export const getJobCandidateProfile = async (jobId: string) => {
  return await axios.get(
    process.env.REACT_APP_API_URL + `jobs/${jobId}/details/candidate-profile/`,
  )
    .then((res) => res)
    .catch((e) => {
      return e.response;
    });
};

export const updateJobCandidateProfile = async (jobId: string, draft: any) => {
  return await axios.put(
    process.env.REACT_APP_API_URL + `jobs/${jobId}/details/candidate-profile/`,
    {
      ...draft,
    },
  );
};

export const getJobOffer = async (jobId: string) => {
  return await axios.get(
    process.env.REACT_APP_API_URL + `jobs/${jobId}/details/offer/`,
  )
    .then((res) => res)
    .catch((e) => {
      return e.response;
    });
};

export const updateJobOffer = async (jobId: string, draft: any) => {
  return await axios.put(
    process.env.REACT_APP_API_URL + `jobs/${jobId}/details/offer/`,
    {
      ...draft,
    },
  );
};

export const getJobTeamInfo = async (jobId: string) => {
  return await axios.get(
    process.env.REACT_APP_API_URL + `jobs/${jobId}/details/team-info/`,
  )
    .then((res) => res)
    .catch((e) => {
      return e.response;
    });
};

export const updateJobTeamInfo = async (jobId: string, draft: any) => {
  return await axios.put(
    process.env.REACT_APP_API_URL + `jobs/${jobId}/details/team-info/`,
    {
      ...draft,
    },
  );
};

export const getJobInterviewProcess = async (jobId: string) => {
  return await axios.get(
    process.env.REACT_APP_API_URL + `jobs/${jobId}/details/interview-process/`,
  )
    .then((res) => res)
    .catch((e) => {
      return e.response;
    });
};

export const updateJobInterviewProcess = async (jobId: string, draft: any) => {
  return await axios.patch(
    process.env.REACT_APP_API_URL + `jobs/${jobId}/details/interview-process/`,
    {
      ...draft,
    },
  );
};

export const createSimpleJob = async (draft: any, isDraft: boolean = false) => {
  if (isDraft) {
    draft.status = 'draft';
  }
  return await axios.post(
    process.env.REACT_APP_API_URL + `public/jobs/`,
    {
      ...draft,
    },
  );
};

export const unpublishJob = async (jobId: string, reason: string) => {
  return axios.post(`${process.env.REACT_APP_API_URL}jobs/${jobId}/unpublish/`, {
    'unpublished_reason': reason,
  });
};

export const onholdJob = async (jobId: string, reason: string) => {
  return axios.post(`${process.env.REACT_APP_API_URL}jobs/${jobId}/onhold/`, {
    'unpublished_reason': reason,
  });
};

export const publishJob = async (jobId: string) => {
  return axios.post(`${process.env.REACT_APP_API_URL}jobs/${jobId}/publish/`);
};

export const deleteJob = async (jobId: string) => {
  return axios.delete(`${process.env.REACT_APP_API_URL}jobs/${jobId}/`);
};

export const getJobsByFilter = async (filter: string, query: string = '') => {
  const url = filter
    ? `${process.env.REACT_APP_API_URL}jobs/` + filter + '/' + query
    : `${process.env.REACT_APP_API_URL}jobs/${query}`;
  return axios.get(url);
};

export const addToFavorites = async (jobId: string) => {
  return axios.post(`${process.env.REACT_APP_API_URL}jobs/${jobId}/favorites/`);
};

export const removeFromFavorites = async (jobId: string) => {
  return axios.delete(
    `${process.env.REACT_APP_API_URL}jobs/${jobId}/favorites/`,
  );
};

export const getSimilarJobs = async (jobId: string) => {
  return await axios.get(
    process.env.REACT_APP_API_URL + `jobs/${jobId}/similar/`,
  )
    .then((res) => res)
    .catch((e) => {
      return e.response;
    });
};

export const getJobPdf = async (jobId: string) => {
  return await axios.get(
    process.env.REACT_APP_API_URL + `jobs/${jobId}/export/pdf/`,
    {
      headers: { 'Content-Type': 'application/pdf' },
      responseType: 'arraybuffer'
    }
  )
    .then((res) => new Blob([res.data]))
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      // the filename you want
      a.download = 'job.pdf';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch((e) => {
      return e.response;
    });
};

//Send a log to the JobEvents in SalesForce
export const sendJobPdfLog = async (jobId: string) =>
  axios.post(
    `${process.env.REACT_APP_API_URL}tracking/event/`,
    {
      event_type: 27,
      tag_name: "Export JOB to PDF",
      job_id: jobId,
    }
  );

export const getJobActivityFeed = async (jobId: string) => {
  return await axios.get(
    process.env.REACT_APP_API_URL + `jobs/${jobId}/activity-feeds/`,
  )
    .then((res) => res)
    .catch((e) => {
      return e.response;
    });
};
