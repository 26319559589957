import { Radio, RadioGroupProps } from 'antd';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import RadioInput from '../inputs/RadioInput';

interface TabRadioGroupProps extends RadioGroupProps {
  data: { title: string | number; value: string | number }[];
}

const RadioTab = styled(Radio.Group)`
  width: 100%;
  display: flex;
  align-items: stretch;
  margin: 2.5rem 0;
  &.mb-0{
  margin-bottom: 0;
  }
  & label {
    width: 100%;
    margin-right: 1rem;
    padding: 1.25rem 1rem;
    border-radius: 8px;
    border: solid 1px #dae5ec;

    & span {
      font-size: 14px;
      font-weight: bold;
      line-height: 1.43;
      color: #061c2e;
    }

    &.ant-radio-wrapper-checked {
      border-radius: 8px;
      background-color: #f2f6fa;
    }

    &:first-child {
      border: solid 1px rgba(52, 206, 114, 0.5);
    }

    &:last-child {
      border: solid 1px rgba(235, 87, 87, 0.5);
      margin-right: 0;

      & .ant-radio-input:focus + .ant-radio-inner,
      .ant-radio-input:focus + .ant-radio-inner::after {
        border-color: rgba(235, 87, 87, 0.5);
      }
      
      & .ant-radio-checked .ant-radio-input:hover + .ant-radio,
      .ant-radio-input:focus + .ant-radio-inner::after {
        background-color: rgba(235, 87, 87, 0.5);
      }
    }
  }
`;

const TabRadioButtonFeedback = ({
  onChange,
  value,
  data,
  ...rest
}: TabRadioGroupProps) => {
  const history = useHistory();
  const params = new URLSearchParams(history.location.search);
  const recruiter =
    params.get('recruiter') || history.location.pathname.includes('recruiters');
  const client =
    params.get('client') || history.location.pathname.includes('clients');
  return (
    <RadioTab onChange={onChange} value={value} {...rest}>
      {data.map((el) => (
        <RadioInput
          value={el.value}
          key={el.value}
          disabled={
            !!(
              (recruiter && el.value === 'client') ||
              (client && el.value === 'recruiter')
            )
          }
        >
          {el.title}
        </RadioInput>
      ))}
    </RadioTab>
  );
};

export default TabRadioButtonFeedback;
