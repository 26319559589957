import axios from 'axios';

export const getEvents = async (
  query: string = '',
  date_from: string = '',
  date_to: string = '',
  interview_id: string = '',
) => {
  let requestQuery = query;

  if (date_from) {
    requestQuery += '&date_from=' + date_from;
  }

  if (date_to) {
    requestQuery += '&date_to=' + date_to;
  }

  if (interview_id) {
    requestQuery += '&interview_id=' + interview_id;
  }

  return await axios.get(
    process.env.REACT_APP_API_URL + 'events/?' + requestQuery,
  );
};

export const trackPageView = async (page:string) => {
  return await axios.post(process.env.REACT_APP_API_URL + 'tracking/event/',{
    event_type:27,
    tag_name:page,
  })
}

export const trackChatClick = async (interview:string) => {
  return await axios.post(process.env.REACT_APP_API_URL + 'tracking/event/',{
    event_type:27,
    tag_name:'chat_with',
    interview_id:interview
  })
};

export const trackJobSectionEvent = async (feedback:number,job_id?:string,tag_name?:string) => {
  return await axios.post(process.env.REACT_APP_API_URL + 'tracking/event/',{
    event_type:32,
    tag_name:tag_name,
    job_id:job_id,
    feedback:feedback.toString()
  })
};

export const exportCalendarEvent = async (event_id:string) => {
  return await axios
  .get(process.env.REACT_APP_API_URL + `events/${event_id}/export/ics/`,)
  .then((res) => res)
  .catch((e) => {
    return e.response;
  });
};
