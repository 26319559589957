import React from 'react';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CandidateInfo } from '../../types';
import { ReactComponent as PhoneIcon } from '../../icons/phone.svg';
import { ReactComponent as MessageIcon } from '../../icons/messages.svg';
import { ReactComponent as LinkedinIcon } from '../../icons/linkedin-outlined.svg';
import { ReactComponent as DownloadIcon } from '../../icons/download.svg';
import { ReactComponent as EyeIcon } from '../../icons/eye.svg';
import { ReactComponent as EditIcon } from '../../icons/edit-icon.svg';
import { ReactComponent as DeleteIcon } from '../../icons/delete.svg';
import { useAppSelector } from '../../store/hooks';
import { RECRUITER_ROLE } from '../../constants/roles';
import { CANDIDATES } from '../../constants/routes';

interface CandidateModalProps {
  candidate: CandidateInfo;
  onDelete: () => void;
}

const CandidateModalWrapper = styled.div`
  padding: 1.25rem 1rem;
  border-radius: 0.5rem;
  background-color: #ffffff;
  width: auto;
  @media (max-width: 576px) {
    padding: 1.5rem;
    transform: translateX(-50%);
  }
`;
const ModalBody = styled.div`
  border-bottom: 1px solid #dae5ec;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
`;
const ModalBodyItem = styled.p`
  font-size: 0.875rem;
  line-height: 1.71;
  color: #061c2e;
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  & svg {
    color: #627d95;
    margin-right: 1rem;
    font-size: 1.5rem;
  }
`;
const ModalLink = styled(Link)`
  display: flex;
  align-items: center;
  margin-left: 1.5rem;
`;
const ModalFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const ActionItem = styled.span`
  color: #34ce72;
  margin: 0 ;
  cursor: pointer;
  & svg {
    width: 1.5rem;
    height: 1.5rem;
  }
  &.danger {
    color: #eb5757;
  }
  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;
const FooterLeft = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
  justify-content: flex-start;
`;
const CandidateActionModal = ({ candidate, onDelete }: CandidateModalProps) => {
  const [t] = useTranslation();
  const { user } = useAppSelector((state) => state.user);
  const history = useHistory();
  const disabled =
    user?.role === RECRUITER_ROLE &&
    candidate.interviews.length &&
    candidate.interviews[0].status === 'in process';
  const handleInfo = () => {
    const search = new URLSearchParams(history.location.search);
    search.set('history', candidate.id);
    candidate.interviews[0] && search.set('interview_id', candidate.interviews[0].id);
    const query = search.toString();
    history.push(CANDIDATES + '?' + query);
  }
  return (
    <CandidateModalWrapper>
      <ModalBody>
        <ModalBodyItem>
          <PhoneIcon />
          {candidate.phone}
        </ModalBodyItem>
        <ModalBodyItem>
          <MessageIcon />
          {candidate.email}
        </ModalBodyItem>
        <a href={candidate.linkedin_link} rel="noreferrer" target="_blank">
          <ModalBodyItem>
            <LinkedinIcon />
            {t('LINKEDIN_PROFILE_TITLE')}
          </ModalBodyItem>
        </a>
        <a href={candidate.cv} rel="noreferrer" target="_blank">
          <ModalBodyItem>
            <DownloadIcon />
            {t('DOWNLOAD_CV_TITLE')}
          </ModalBodyItem>
        </a>
      </ModalBody>
      <ModalFooter>
        <FooterLeft>
            <ActionItem onClick={handleInfo}>
              <EyeIcon />
            </ActionItem>
          <ModalLink to={`?edit=${candidate.id}`}>
            <ActionItem>
              <EditIcon />
            </ActionItem>
          </ModalLink>
        </FooterLeft>
        <ActionItem
          className={`danger ${disabled && 'disabled'}`}
          onClick={disabled ? void 0 : onDelete}
        >
          <DeleteIcon />
        </ActionItem>
      </ModalFooter>
    </CandidateModalWrapper>
  );
};

export default CandidateActionModal;
