import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import format from 'date-fns/format';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import differenceInHours from 'date-fns/differenceInHours';
import addHours from 'date-fns/addHours';
import isBefore from 'date-fns/isBefore';
import { useHistory } from 'react-router-dom';
import {
  COMPLETED,
  HIRED,
  OFFERED, ON_HOLD,
} from '../../../../constants/statuses';
import { useAppSelector } from '../../../../store/hooks';
import { ADMIN_ROLE, CLIENT_ROLE, RECRUITER_ROLE } from '../../../../constants/roles';
import { CANDIDATES } from '../../../../constants/routes';
import TooltipPrompt from '../../../../UI/tootlip';
import { CandidateInfo, CandidatesStagesStatuses } from '../../../../types';
import { ReactComponent as ArrowRight } from '../../../../icons/arrow-right.svg';
import {
  Wrapper,
  CardBody,
  DateText,
  NameWrapper,
  Name,
  Title,
  Interview,
  Drag,
  Company,
  CompanyLogo,
  CompanyName,
  Prompt,
  GHWarning,
  Tags
} from './styles';
import {
  INTERVIEW_ID_PARAM,
  STAGE_PARAM,
} from '../../../../constants/queryParams';
import ItemFooter from './ItemFooter';
import ItemFooterOutdated from './ItemFooterOutdated';
import CandidateStatusDropdown from "../../CandidateStatusDropdown";

interface ItemInfo extends React.HTMLAttributes<HTMLDivElement> {
  info: CandidateInfo;
  index: number;
  column: string;
  candidateStagesStatuses: CandidatesStagesStatuses[];
  onOffSyncClicked: (info: CandidateInfo) => void;
}

const Item = ({
  info,
  column,
  index,
  candidateStagesStatuses,
  onOffSyncClicked,
  ...rest
}: ItemInfo) => {
  const [t] = useTranslation();
  const history = useHistory();
  const { user } = useAppSelector((state) => state.user);
  const tasks = info.interviews[0]?.current_stage?.tasks;

  const isClient = user?.role === CLIENT_ROLE;

  const isRecruiter = user?.role === RECRUITER_ROLE;

  const isAdmin = user?.role === ADMIN_ROLE;

  const mainInterview = tasks?.find((task) => task.is_planning_task);

  // const earn = info.interviews[0].earn_amount_2
  //   ? Math.sign(info.interviews[0].earn_amount_2) *
  //     // @ts-ignore
  //     (Math.abs(info.interviews[0].earn_amount_2) / 1000).toFixed(1)
  //   : 0;
  const earn = '';

  const isOfferStage = info.interviews[0].status === OFFERED;
  const isHired = info.interviews[0].status === HIRED;
  const isOnHold = info.interviews[0].status === ON_HOLD;
  const hasOffer =
    info.interviews[0].current_offer?.status === COMPLETED &&
    user?.role === CLIENT_ROLE;

  const isOffSync = info?.interviews[0]?.is_blocked;
  const isAtsErrored = info?.interviews[0]?.ats_error !== '';
  const showOffSyncWarning = !isRecruiter && (isOffSync || isAtsErrored);
  const offSyncTooltip = isAtsErrored
    ? t(`ats.OFF_SYNC_TOOLTIP_ERROR_${info?.interviews[0]?.ats_error}`)
    : t('ats.OFF_SYNC_TOOLTIP_CLIENT');

  const isDraggable =
    info.interviews[0].current_stage?.status === COMPLETED &&
    user?.role === CLIENT_ROLE &&
    !isOfferStage;

  let waitingFor: string;
  const respondInHours = info.interviews[0].job.feedback_time;
  let diffInHours = 0;
  const stageStatus = info.interviews[0].status;
  if (stageStatus === 'offered') {
    let offerStage = info.interviews[0].current_offer;
    let startDate = offerStage.created_at;
    if (offerStage.status === 'waiting_for_client') {
      waitingFor = CLIENT_ROLE;
    }
    if (offerStage.status === 'waiting_for_recruiter') {
      startDate = offerStage.updated_at;
      waitingFor = RECRUITER_ROLE;
    }

    diffInHours = differenceInHours(
      addHours(new Date(startDate), respondInHours),
      new Date(),
      {
        roundingMethod: 'ceil',
      },
    );
  } else if (
    stageStatus !== 'introduced' &&
    stageStatus !== 'hired' &&
    stageStatus !== 'rejected' &&
    stageStatus !== 'on hold'
  ) {
    let currentStage = info.interviews[0].current_stage;
    let startDate = currentStage.created_at;
    if (mainInterview?.time_from) {
      startDate = mainInterview?.time_from;

      // 1 hour is default duration of interview;
      startDate = addHours(new Date(startDate), 1).toString();
    }

    let taskList = currentStage.tasks.filter(
      (el) =>
        el.status !== 'completed' ||
        !!(
          el.is_feedback_task &&
          currentStage.feedbacks.find((el) => !el.is_completed)
        ),
    );
    let sortedTaskList = [];
    const isPlanning = taskList.find((el) => el.is_planning_task);
    const isFeedback = taskList.find((el) => el.is_feedback_task);
    const otherTasks = taskList.filter(
      (el) => !el.is_feedback_task && !el.is_planning_task,
    );
    if (isPlanning) {
      sortedTaskList.push(isPlanning);
    }
    if (otherTasks.length) {
      sortedTaskList = [...sortedTaskList, ...otherTasks];
    }
    if (isFeedback) {
      sortedTaskList.push(isFeedback);
    }
    if (sortedTaskList.length) {
      waitingFor = 'client';
      const first = sortedTaskList[0];
      if (first.is_planning_task) {
        if (currentStage.status.includes('waiting for ')) {
          waitingFor = currentStage.status.replace('waiting for ', '');
        }
      } else if (first.is_feedback_task) {
        const clientMissFeedback = currentStage.feedbacks.find(
          (el) => !el.is_completed && el.feedback_type === `client_feedback`,
        );
        const recruiterMissFeedback = currentStage.feedbacks.find(
          (el) => !el.is_completed && el.feedback_type === `recruiter_feedback`,
        );
        if (clientMissFeedback && recruiterMissFeedback) {
          waitingFor = user?.role || ''; // so it can be Admin, if Admin check kanban
        } else if (recruiterMissFeedback && !clientMissFeedback) {
          waitingFor = 'recruiter';
        }
      }
    }

    diffInHours = differenceInHours(
      addHours(new Date(startDate), respondInHours),
      new Date(),
      {
        roundingMethod: 'ceil',
      },
    );
  }

  const handleClick = (item: CandidateInfo) => {
    const search = new URLSearchParams(history.location.search);
    if (column === 'introduced') {
      search.set('history', info.id);
      search.set('interview_id', info.interviews[0].id);
      const query = search.toString();
      history.push(CANDIDATES + '?' + query);
    } else if (column === 'rejected') {
      search.set('history', info.id);
      search.set('interview_id', info.interviews[0].id);
      const query = search.toString();
      history.push(CANDIDATES + '?' + query);
    } else if (column === 'offered') {
      if (!isRecruiter && !isClient) return; // skip for admin
      if (!(isDraggable || (isOfferStage && hasOffer))) {
        // open interview stage modal
        search.set(INTERVIEW_ID_PARAM, info.interviews[0]?.id);
        search.set(STAGE_PARAM, column);
        const query = search.toString();
        history.push(CANDIDATES + '?' + query);
      }
    } else if (column === 'hired') {
    } else if (column === 'on hold') {
    } else {
      if (!isRecruiter && !isClient) return; // skip for admin

      // open interview stage modal
      search.set(INTERVIEW_ID_PARAM, info.interviews[0].id);
      search.set(STAGE_PARAM, info.interviews[0].current_stage.stage_name);
      const query = search.toString();
      history.push(CANDIDATES + '?' + query);
    }
  };

  const diffInDays =
    (mainInterview &&
      differenceInCalendarDays(
        new Date(mainInterview.time_from),
        new Date(),
      )) ||
    0;

  const statusIsIntroduced = info.interviews[0].status === 'introduced';
  const statusIsOffered = info.interviews[0].status === 'offered';
  const isWaitingForClient = info.interviews[0].current_offer?.status === 'waiting_for_client';
  const showHours = diffInHours > 0 && diffInHours <= 48;
  const isDragDisabled = column === 'hired' || isAdmin;

  return (
    <Draggable
      draggableId={`${info.id}&${column}&${index}`}
      index={index}
      isDragDisabled={isDragDisabled}
    >
      {(provided) => (
        <Wrapper
          {...rest}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          onClick={() => handleClick(info)}
        >
          <TooltipPrompt title={showOffSyncWarning ? "" : t('KANBAN_ITEM_TOOLTIP')}>
            <CardBody>
              <NameWrapper>
                <Name
                  onClick={(e) => {
                    const query = new URLSearchParams(history.location.search);
                    query.set('history', info.id);
                    query.set('interview_id', info.interviews[0].id);
                    e.stopPropagation();
                    e.preventDefault();
                    history.push({ search: query.toString() });
                  }}
                >
                  {info.name}
                </Name>
                {!(user?.role === RECRUITER_ROLE) && <Tags>
                  {(isDraggable || (isOfferStage && hasOffer) || isOnHold) &&
                    column !== 'hired' &&
                    column !== 'rejected' &&
                    user?.role === CLIENT_ROLE ? (
                    <Drag>{t('DRAG')}</Drag>
                  ) : user?.role === RECRUITER_ROLE ? (
                      <Drag>{earn}</Drag>
                  ) : (
                    ''
                  )}
                  {showOffSyncWarning &&
                    <TooltipPrompt title={offSyncTooltip}>
                      <GHWarning onClick={(e) => { onOffSyncClicked(info); e.stopPropagation(); }}>{t('ats.OFF_SYNC_BADGE')}</GHWarning>
                    </TooltipPrompt>
                  }
                </Tags>}
              </NameWrapper>
              <Title>{info.interviews[0].job.title}</Title>
              <CandidateStatusDropdown onClick={(e: any) => {
                e.stopPropagation();
              }} interview={info.interviews[0]} statuses={candidateStagesStatuses} isClient={isClient} customClass={'kanban-status'} />
              {user?.role === RECRUITER_ROLE && (
                <Company>
                  <CompanyLogo>
                    <img
                      src={`${info.interviews[0].job.company.image
                        ? info.interviews[0].job.company.image
                        : process.env.PUBLIC_URL + '/images/company-logo.png'
                        }`}
                      alt=""
                    />
                  </CompanyLogo>
                  <CompanyName>{info.interviews[0].job.company.name}</CompanyName>
                </Company>
              )}
              {!(isHired || isOfferStage || isOnHold || column === 'rejected') && (
                <Interview>
                  {mainInterview && mainInterview.time_from && (
                    <Title>
                      <ArrowRight />
                      {t('INTERVIEW')}:<span>&nbsp;</span>
                      <DateText
                        className={
                          isBefore(new Date(mainInterview.time_from), new Date())
                            ? 'danger'
                            : ''
                        }
                      >
                        {diffInDays === -1 ? (
                          <>
                            {t('YESTERDAY')},{' '}
                            {format(new Date(mainInterview.time_from), ' kk:mm')}
                          </>
                        ) : diffInDays === 0 ? (
                          <>
                            {t('TODAY')},{' '}
                            {format(new Date(mainInterview.time_from), ' kk:mm')}
                          </>
                        ) : diffInDays === 1 ? (
                          <>
                            {t('TOMORROW')},{' '}
                            {format(new Date(mainInterview.time_from), ' kk:mm')}
                          </>
                        ) : (
                          format(
                            new Date(mainInterview.time_from),
                            'MMM d, kk:mm',
                          )
                        )}
                      </DateText>
                    </Title>
                  )}
                </Interview>
              )}
            </CardBody>
          </TooltipPrompt>

          {!!waitingFor &&
            (!statusIsOffered || (statusIsOffered && isWaitingForClient)) && (
            <>
              {showHours && (
                <ItemFooter
                  role={user?.role || ''}
                  waitingFor={waitingFor}
                  diffInHours={diffInHours}
                />
              )}

              {diffInHours <= 0 && (
                <ItemFooterOutdated
                  role={user?.role || ''}
                  waitingFor={waitingFor}
                  diffInHours={diffInHours}
                />
              )}
            </>
          )
          }
          {/*{!!waitingFor &&*/}
          {/*(!statusIsOffered || (statusIsOffered && isWaitingForClient)) && (*/}
          {/*  <>*/}
          {/*    {showHours && (*/}
          {/*      <ItemFooter*/}
          {/*        role={user?.role || ''}*/}
          {/*        waitingFor={waitingFor}*/}
          {/*        diffInHours={diffInHours}*/}
          {/*      />*/}
          {/*    )}*/}

          {/*    {diffInHours <= 0 && (*/}
          {/*      <ItemFooterOutdated*/}
          {/*        role={user?.role || ''}*/}
          {/*        waitingFor={waitingFor}*/}
          {/*        diffInHours={diffInHours}*/}
          {/*      />*/}
          {/*    )}*/}

          {/*  </>*/}
          {/*)*/}
          {/*}*/}

          {isClient && statusIsIntroduced ?
            <Prompt color={'warn'}>{t('NEED_ACTION')}</Prompt> :
            (isAdmin || isRecruiter) && statusIsIntroduced ?
              <Prompt color={'warn'}>{t('NEED_ACTION_COMPANY')}</Prompt> : null
          }
        </Wrapper>
      )}
    </Draggable>
  );
};

export default Item;
