import React, { useRef } from 'react';
import { ReactComponent as ArrowLeftIcon } from '../../../icons/arrow-left.svg';
import { ReactComponent as ArrowRightIcon } from '../../../icons/arrow-right.svg';
import styled from 'styled-components';
import { Carousel } from 'antd';
import { CarouselRef } from 'antd/es/carousel';
import { ColleagueQuote } from '../../../types';

interface ColleagueSectionProps extends React.HTMLAttributes<HTMLDivElement>{
  colleagues: ColleagueQuote[];
}
const CarouselSection = styled.div`
 &.left {
 .arrows{
 right: 6rem;
 }
 }
`;
const ArrowSection = styled.div`
  display: flex;
  align-items: center;
  color: #ffffff;
  position: absolute;
  top: 2.25rem;
  right: 1.5rem;
  & svg {
    margin: 0 0.5rem;
    cursor: pointer;
  }
`;
const CarouselWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const Image = styled.div`
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  margin-bottom: 1rem;
  overflow: hidden;
  background: #ffffff;
  & img {
    object-fit: contain;
    object-position: center;
    width: 100%;
    height: 100%;
  }
`;
const CarouselLeft = styled.div`
  width: 9rem;
  min-width: 9rem;

  & h5 {
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.2;
    color: #ffffff;
    margin-bottom: 0.5rem;
  }
  & p {
    font-size: 0.875rem;
    line-height: 1.43;
    color: #aebeca;
  }
`;
const CarouselRight = styled.div`
  width: 100%;
  margin-left: 1rem;
  font-size: 0.875rem;
  line-height: 1.71;
  color: #ffffff;
  margin-bottom: 6rem;
`;
const ColleagueSection = ({ colleagues,...rest }: ColleagueSectionProps) => {
  const sliderRef = useRef(null);
  const prev = () => {
    const slide = (sliderRef.current as unknown) as CarouselRef;
    if (slide) {
      slide.prev();
    }
  };
  const next = () => {
    const slide = (sliderRef.current as unknown) as CarouselRef;
    if (slide) {
      slide.next();
    }
  };
  return (
    <CarouselSection {...rest}>
      {colleagues.length > 1 && (
        <ArrowSection className="arrows">
          <ArrowLeftIcon onClick={() => prev()} />
          <ArrowRightIcon onClick={() => next()} />
        </ArrowSection>
      )}
      <Carousel dots={false} autoplay={true} ref={sliderRef}>
        {colleagues.map((colleague) => {
          return (
            <div key={colleague.name}>
              <CarouselWrapper>
                <CarouselLeft>
                  <Image>
                    <img
                      src={
                        colleague.image
                          ? colleague.image
                          : process.env.PUBLIC_URL +
                            '/images/no-iamge-placeholder.jpg'
                      }
                      alt=""
                    />
                  </Image>
                  <h5>{colleague.name}</h5>
                  <p>{colleague.title}</p>
                </CarouselLeft>
                <CarouselRight>{colleague.comments}</CarouselRight>
              </CarouselWrapper>
            </div>
          );
        })}
      </Carousel>
    </CarouselSection>
  );
};

export default ColleagueSection;
