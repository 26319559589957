import { useEffect, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { CandidateInfo as ICandidateInfo, InterviewRoom } from '../../../../types';
import { getCandidate } from '../../../../api/candidates';
import { CANDIDATES } from '../../../../constants/routes';
import {
  INTERVIEW_ID_PARAM, SHOW_COMPANY_CHAT,
} from '../../../../constants/queryParams';

interface IuseCompanyChatModal {
  interview: InterviewRoom | { [key:string]: any };
  setShowCompanyChatModal: (show: boolean) => void,
}

const useCompanyChatModal = ({ interview, setShowCompanyChatModal }: IuseCompanyChatModal) => {
  const history = useHistory();

  const params = new URLSearchParams(history.location.search);
  const interview_id = params.get(INTERVIEW_ID_PARAM);

  const [candidateInfo, setCandidateInfo] = useState<ICandidateInfo | {[key: string]: string}>({
    current_role: '',
    current_company: '',
  });

  const handleCloseModal = useCallback(() => {
    setShowCompanyChatModal(false);

    const params = new URLSearchParams(history.location.search);
    params.delete(SHOW_COMPANY_CHAT);
  
    const query = params.toString();
    history.push(CANDIDATES + '?' + query);
  }, [history, setShowCompanyChatModal]);

  /* get addition candidate info */
  const { candidate, client } = interview;

  useEffect(() => {
    if (candidate?.id) {
      getCandidate(candidate.id).then((res) => setCandidateInfo(res.data));
    }
  }, [candidate?.id]);

  useEffect(() => {
    if (!interview_id) {
      handleCloseModal();
    }
  }, [interview_id, handleCloseModal]);

  return {
    actions: {
      handleCloseModal,
    },
    data: {
      candidate: candidate || {},
      candidateInfo,
      client,
    }
  }
};

export default useCompanyChatModal;
