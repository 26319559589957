import React, { useState, ReactNode, useRef } from 'react';
import styled from 'styled-components';
import TooltipPrompt from '../../UI/tootlip';
import { useTranslation } from 'react-i18next';

const Tabs = styled.div`
  display: flex;
  align-items: center;
`;

interface TabProps {
  active?: boolean;
}

const Tab = styled.nav<TabProps>`
  font-family: Lato;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.96px;
  text-align: left;
  color: #061c2e;
  text-transform: uppercase;
  border-bottom: ${(props) =>
    props.active ? '2px solid #34ce72' : '2px solid transparent'};
  margin-left: 1rem;
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
  position: relative;
`;
const ListWrapper = styled.div`
  position: relative;
  overflow: hidden;
  height: 100%;
`;
const ListContainer = styled.div`
  background: #fff;
  border-radius: 8px;
  padding: 0.75rem 2rem 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const ListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #dae5ec;
`;

const ListHeaderTitle = styled.h3`
  font-family: Lato;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.96px;
  text-align: left;
  color: #061c2e;
  text-transform: uppercase;
  padding: 1rem 0;
  @media (max-height: 740px) {
    padding: 0;
  }
`;

const List = styled.ul`
  list-style: none;
  max-height: calc(100% - 55px);
  overflow-y: auto;
  margin: 0;
  display: flex;
  flex-direction: column;
  &.scrollable {
    & table {
      & tr {
        & td:last-child {
          padding-right: 10px;
        }
      }
    }
  }
  @media (max-height: 740px) {
    max-height: calc(100% - 35px);
  }
`;

interface ListWidgetProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  tabs?: string[];
  children?: ReactNode;
  onChangeTab?: (filter: string, index: number) => void;
}

const ListWidget = ({
  title,
  tabs,
  onChangeTab,
  children,
  ...rest
}: ListWidgetProps) => {
  const [activeTab, setActiveTab] = useState(tabs ? tabs[0] : '');
  const [t] = useTranslation();
  const scrollRef = useRef<HTMLUListElement | null>(null);
  const hasScroll =
    scrollRef?.current &&
    scrollRef?.current?.scrollHeight > scrollRef?.current?.clientHeight;
  const handleClickTab = (tab: string, i: number) => {
    setActiveTab(tab);
    onChangeTab?.(tab, i);
  };
  return (
    <ListWrapper {...rest}>
      <ListContainer>
        <ListHeader>
          <ListHeaderTitle>{title}</ListHeaderTitle>
          {tabs && tabs.length > 0 && (
            <Tabs>
              {tabs.map((tab, i) => (
                <TooltipPrompt key={i} title={t(`DASHBOARD_${tab}_TOOLTIP`)}>
                  <Tab
                    active={tab === activeTab}
                    onClick={() => handleClickTab(tab, i)}
                  >
                    {t(tab)}
                  </Tab>
                </TooltipPrompt>
              ))}
            </Tabs>
          )}
        </ListHeader>
        <List ref={scrollRef} className={hasScroll ? 'scrollable' : ''}>
          {children}
        </List>
      </ListContainer>
    </ListWrapper>
  );
};

export default ListWidget;
