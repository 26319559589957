import axios from 'axios';
import { NotificationSettings } from '../types';

const api_url = process.env.REACT_APP_API_URL;

export const getUnreadNotificationsCount = async () => {
  return await axios.get(`${api_url}notifications/unread/count/`);
};

export const getNotificationList = async (next?:string) => {
  return await axios.get(`${api_url}notifications/${next ? '?' + next : ''}`);
};

export const markAllNotificationsRead = async () => {
  return await axios.post(`${api_url}notifications/mark-all-as-read/`);
};
export const markNotificationAsRead = async (id:string) => {
  return await axios.post(`${api_url}notifications/${id}/mark-as-read/`);
};

export const getNotificationSettingList = async () => {
  return await axios.get(`${api_url}notifications/settings/`)
}
export const updateNotificationSettingList = async (data:NotificationSettings) => {
  return await axios.patch(`${api_url}notifications/settings/`,data)
}
