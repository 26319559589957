import styled from 'styled-components';

export const InfoWrap = styled.div`
margin-bottom: 40px;
`;

export const SectionTitle = styled.h5`
  margin-bottom: 8px;
  font-family: Lato;
  font-size: 10px;
  font-weight: bold;
  line-height: 1.6;
  letter-spacing: 0.8px;
  text-align: left;
  color: #627d95;
  text-transform: uppercase;
`;

export const Description = styled.p`
  margin-bottom: 40px;
  font-family: Lato;
  font-size: 14px;
  line-height: 1.71;
  color: #061c2e;
`;

export const StageInfoWrap = styled.div`
  position: relative;
`;

export const Blander = styled.div`
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 20%);
`;