import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { LOGIN } from '../../constants/routes';
import { getMagicLink } from '../../api/magicLink';


const useMagicLinksInit = () => {
  const history = useHistory();

  useEffect(() => {
    if (history.location.pathname) {
      getMagicLink(history.location.pathname.substring(1))
      .then((res) => {
        if (res?.data?.link) {
          window.location.href = res.data.link;
        } else {
          history.push(LOGIN);
        }
      })
      .catch(() => {
        history.push(LOGIN);
      });
    }
  }, [history]);
}

export default useMagicLinksInit;
