import { useTranslation } from 'react-i18next';
import { Form, Select, Col, Row } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';

import { ReactComponent as InfoIcon } from '../../../icons/info-icon-rounded.svg';
import RouterBlocker from '../../jobs/RouterBlocker';
import FormTitle from '../../../components/auth/FormTitle';
import useCompanyInfoForm from './hooks';
import {
  FormSubtitleHint,
  StyledUpload,
  CompanyLogo,
  FormatHint,
  ImageUploadButton,
  StyledIcon,
  ButtonWrap,
} from './styles';
import { requiredRule, maxLengthRule } from '../../../utils/validationRules';
import FormItem from '../FormItem';
import { ReactComponent as EditIcon } from '../../../icons/edit.svg';
import SelectInput from '../../../UI/inputs/Select';
import Input from '../../../UI/inputs/Input';
import DynamicInputs from '../../../UI/inputs/DynamicInputs';
import { AddressFieldInput } from '../../../UI/inputs/AddressField';
import NumberInput from '../../../UI/buttons/NumberInput';
import Textarea from '../../../UI/inputs/Textarea';
import Button from '../../../UI/buttons/Button';

const { Option } = Select;

interface Iprops {
  jobId: string,
}

const CompanyInfoForm = ({ jobId }: Iprops) => {
  const { t } = useTranslation();

  const {
    actions: {
      handleFieldsChange,
      handleUploadFile,
      setOfficeLocations,
      setAddress,
      onFinish,
      handleSelectCompany
    },
    data: {
      blockTransition,
      form,
      logo,
      cities,
      company,
      officeLocations,
      validating,
      address,
      regions,
      industries,
      loading,
      fileError,
      companies,
      isAdmin,
    },
  } = useCompanyInfoForm({ jobId });

  const showCompanySelect = isAdmin && jobId === 'new';

  return (
    <>
      <RouterBlocker isBlocked={blockTransition} />
      <Form
        scrollToFirstError={true}
        layout="vertical"
        name="company-info-form"
        form={form}
        onFieldsChange={handleFieldsChange}
        onFinish={onFinish}
      >
        <FormTitle text={t('COMPANY_INFO_TITLE')} marginBottom="16px" />
        <FormSubtitleHint>{t('COMPANY_INFO_SUBTITLE')}</FormSubtitleHint>

        <FormItem
          label={t('COMPANY_LOGO')}
          name="image"
          //rules={requiredRule(t)}
        >
          <StyledUpload
            accept="image/png, image/jpeg, image/jpg"
            beforeUpload={handleUploadFile}
            showUploadList={false}
          >
            <CompanyLogo className={logo ? 'bg-white' : ''}>
              {logo ? <img src={logo} alt="" /> : <StyledIcon />}
              <ImageUploadButton>
                <EditIcon />
              </ImageUploadButton>
            </CompanyLogo>
            <FormatHint>PNG or JPG</FormatHint>
          </StyledUpload>
          {fileError && (
            <div className="ant-form-item-explain ant-form-item-explain-error">
              <div role="alert">{fileError}</div>
            </div>
          )}
        </FormItem>

        <FormItem
          label={t('COMPANY_TITLE')}
          name="name"
          rules={requiredRule(t)}
          tooltip={t('COMPANY_FORM_NAME_TOOLTIP') ? { title: t('COMPANY_FORM_NAME_TOOLTIP'), icon: <InfoIcon /> } : false}
        >
          {showCompanySelect ? (
            <SelectInput
              size="large"
              suffixIcon={<CaretDownOutlined style={{ color: '#000' }} />}
              onChange={(e) =>
                handleSelectCompany(e ? e.toString() : '')
              }
            >
              {companies.map((company) => (
                <Option key={company.id} value={company.id}>
                  {company.name}
                </Option>
              ))}
          </SelectInput>
          ) : (
            <Input
              size="large"
              disabled
            />
          )}
        </FormItem>

        <FormItem
          label={t('LOCATION_CITY_LABEL')}
          name="city"
          rules={requiredRule(t)}
          tooltip={
            t('COMPANY_FORM_LOCATION_CITY_TOOLTIP')
            ? { title: t('COMPANY_FORM_LOCATION_CITY_TOOLTIP'), icon: <InfoIcon /> }
            : false
          }
        >
          <SelectInput
            size="large"
            filterOption={true}
            suffixIcon={<CaretDownOutlined style={{ color: '#000' }} />}
          >
            {cities.map((city) => (
              <Option key={city.id} value={city.id}>
                {city.name}
              </Option>
            ))}
          </SelectInput>
        </FormItem>

        <DynamicInputs
          validating={validating}
          locations={officeLocations}
          setLocations={setOfficeLocations}
          companyId={company?.id || ''}
          tooltip={t('COMPANY_FORM_OFFICE_LOCATIONS_TOOLTIP')}
        />

        <FormItem
          label={t('ADDRESS_LABEL')}
          tooltip={t('COMPANY_FORM_ADDRESS_TOOLTIP') ? { title: t('COMPANY_FORM_ADDRESS_TOOLTIP'), icon: <InfoIcon /> } : false}
        >
          <AddressFieldInput
            validating={false}
            address={address}
            setAddress={setAddress}
          />
        </FormItem>

        <FormItem
          label={t('INDUSTRIES_LABEL')}
          name="industry"
          //rules={requiredRule(t)}
          tooltip={t('COMPANY_FORM_INDUSTRIES_TOOLTIP') ? { title: t('COMPANY_FORM_INDUSTRIES_TOOLTIP'), icon: <InfoIcon /> } : false}
        >
          <SelectInput
            size="large"
            suffixIcon={<CaretDownOutlined style={{ color: '#000' }} />}
          >
            {industries.map((industry) => (
              <Option key={industry.id} value={industry.id}>
                {industry.name}
              </Option>
            ))}
          </SelectInput>
        </FormItem>

        <FormItem
          label={t('REGIONS_LABEL')}
          name="region"
          //rules={requiredRule(t)}
          tooltip={t('COMPANY_FORM_REGIONS_TOOLTIP') ? { title: t('COMPANY_FORM_REGIONS_TOOLTIP'), icon: <InfoIcon /> } : false}
        >
          <SelectInput
            size="large"
            suffixIcon={<CaretDownOutlined style={{ color: '#000' }} />}
          >
            {regions.map((region) => (
              <Option key={region.id} value={region.id}>
                {region.name}
              </Option>
            ))}
          </SelectInput>
        </FormItem>

        <FormItem
          label={t('NOTABLE_CLIENTS_LABEL')}
          name="notable_clients"
          //rules={requiredRule(t)}
          tooltip={
            t('COMPANY_FORM_NOTABLE_CLIENTS_TOOLTIP')
            ? { title: t('COMPANY_FORM_NOTABLE_CLIENTS_TOOLTIP'), icon: <InfoIcon /> }
            : false
          }
        >
          <Input size="large" />
        </FormItem>

        <FormItem className="no-margin">
          <Row gutter={16} justify="space-between">
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 12 }}
            >
              <FormItem
                label={t('EMPLOYEES_LABEL')}
                name="number_of_employees"
                //rules={numberRule(t)}
                tooltip={
                  t('COMPANY_FORM_EMPLOYEES_TOOLTIP')
                  ? { title: t('COMPANY_FORM_EMPLOYEES_TOOLTIP'), icon: <InfoIcon /> }
                  : false
                }
              >
                <NumberInput size="large" type={'number'} />
              </FormItem>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 12 }}
            >
              <FormItem
                label={t('AVERAGE_AGE_LABEL')}
                name="average_age"
                //rules={numberRule(t)}
                tooltip={
                  t('COMPANY_FORM_AVERAGE_AGE_TOOLTIP')
                  ? { title: t('COMPANY_FORM_AVERAGE_AGE_TOOLTIP'), icon: <InfoIcon /> }
                  : false
                }
              >
                <NumberInput size="large" type={'number'} />
              </FormItem>
            </Col>
          </Row>
        </FormItem>

        <FormItem className="no-margin width-gender">
          <Row gutter={16} justify="space-between">
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 12 }}
            >
              <FormItem
                label={t('MALE_FEMALE_LABEL')}
                className="gender-alt"
                name="male_percentage"
                //rules={numberRule(t)}
                tooltip={
                  t('COMPANY_FORM_MALE_FEMALE_TOOLTIP')
                  ? { title: t('COMPANY_FORM_MALE_FEMALE_TOOLTIP'), icon: <InfoIcon /> }
                  : false
                }
              >
                <NumberInput
                  size="large"
                  type={'number'}
                />
              </FormItem>
              <span className="female-count female-count-alt">
                /{' '}
                {(form.getFieldValue('male_percentage') ||
                  form.getFieldValue('male_percentage') === 0) &&
                  100 - form.getFieldValue('male_percentage')}
              </span>
            </Col>

            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 24 }}
              lg={{ span: 12 }}
            >
              <FormItem
                label={t('DRESSCODE_LABEL')}
                name="dress_code"
                rules={[...maxLengthRule(255, t)]}
                tooltip={
                  t('COMPANY_FORM_DRESSCODE_TOOLTIP')
                  ? { title: t('COMPANY_FORM_DRESSCODE_TOOLTIP'), icon: <InfoIcon /> }
                  : false
                }
              >
                <Input size="large" />
              </FormItem>
            </Col>
          </Row>
        </FormItem>

        <FormItem
          label={t('ABOUT_COMPANY_LABEL')}
          name="description"
          //rules={requiredRule(t)}
          tooltip={
            t('COMPANY_FORM_ABOUT_COMPANY_TOOLTIP')
            ? { title: t('COMPANY_FORM_ABOUT_COMPANY_TOOLTIP'), icon: <InfoIcon /> }
            : false
          }
        >
          <Textarea autoSize={{ minRows: 4 }} />
        </FormItem>

        <FormItem
          label={t('COMPANY_CULTURE_LABEL')}
          name="company_culture"
          //rules={requiredRule(t)}
          tooltip={
            t('COMPANY_FORM_ABOUT_COMPANY_CULTURE_TOOLTIP')
            ? { title: t('COMPANY_FORM_ABOUT_COMPANY_CULTURE_TOOLTIP'), icon: <InfoIcon /> }
            : false
          }
        >
          <Textarea autoSize={{ minRows: 4 }} />
        </FormItem>

        <FormItem
          label={t('ABOUT_COMPANY_LABEL_2')}
          name="difference_description"
          //rules={requiredRule(t)}
          tooltip={
            t('COMPANY_FORM_ABOUT_COMPANY_2_TOOLTIP')
            ? { title: t('COMPANY_FORM_ABOUT_COMPANY_2_TOOLTIP'), icon: <InfoIcon /> }
            : false
          }
        >
          <Textarea autoSize={{ minRows: 4 }} />
        </FormItem>

        <FormItem
          label={t('EXCLUDED_COMPANIES_LABEL')}
          name="excluded_companies"
          rules={[...maxLengthRule(255, t)]}
          tooltip={
            t('COMPANY_FORM_EXCLUDED_COMPANIES_TOOLTIP')
            ? { title: t('COMPANY_FORM_EXCLUDED_COMPANIES_TOOLTIP'), icon: <InfoIcon /> }
            : false
          }
        >
          <Input size="large" />
        </FormItem>

        <ButtonWrap>
          <Button type="primary" loading={loading} htmlType="submit">
            {t('NEXT_TITLE')}
          </Button>
        </ButtonWrap>
      </Form>
    </>
  )
};

export default CompanyInfoForm;
