import React from 'react';
import { ReactComponent as NotFountIcon } from '../icons/empty.svg';
import Button from '../UI/buttons/Button';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
`;
const Text = styled.h3`
  margin: 2rem 0 4rem;
  font-size: 1.5rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #aebeca;
`;
const Error = () => {
  const [t] = useTranslation();
  return (
    <Wrapper>
      <NotFountIcon />
      <Text>{t('ERROR_MESSAGE')}</Text>
      <Button onClick={() => window.location.reload()}>
        {t('RELOAD_PAGE')}
      </Button>
    </Wrapper>
  );
};

export default Error;
