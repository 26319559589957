import styled from 'styled-components';
import Button from '../../../UI/buttons/Button';

export const ListContainer = styled.div`
  height: 100%;
  min-height: 320px;
  width: 100%;
  display: grid;
  margin-left: -2rem;
  margin-bottom: 2rem;
`;

export const EmptyText = styled.p`
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #aebeca;
  padding: 5rem 0;
`;

export const JobListItem = styled.li`
  display: grid;
  grid-template-columns: minmax(230px,40%) minmax(200px,40%) 100px;
  column-gap: 1rem;
  align-items: center;
  justify-content: space-between;
  padding: 11px 0;
  border-bottom: 1px solid #dae5ec;
  position: relative;
  cursor: pointer;
`;

export const LocalJobTitle = styled.h4`
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #061c2e;
  margin: 0;
`;

export const RemoteJobTitle = styled.h4`
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #061c2e;
  margin: 0;
`;

export const JobActions = styled.div`
  display: flex;
`;

export const JobActionButton = styled(Button)`
  width: 100%;
  justify-content: center;
`;
