import axios from "axios";
import moment from 'moment';

export type JobRef = {
  id: string,
  name: string,
}

export type StageRef = {
  id: string,
  name: string,
  order: number,
  is_required?: boolean,
  is_interview?: boolean,
}

type WithStages = {
  stages: StageRef[];
}

export type AtsStatus = {
  state: 'NOT_CONNECTED' | 'AWAITING_INITIAL_SYNC' | 'CONNECTED',
  tool_name: string,
  last_sync_date?: Date,
  ats_jobs: JobRef[],
  job_connections: {
    ats_job: JobRef,
    local_job?: JobRef,
  }[],
}

export type AtsJobStatus = {
  local_job: JobRef & WithStages,
  ats_job?: JobRef & WithStages,
  stage_mappings: {
    ats_stage: StageRef
    local_stage?: StageRef,
  }[],
}

export const getConnectLink = async (options?: { environment?: string, tool?: string }) => {
  const data = (options?.environment || options?.tool)
    ? { options: { environment: options.environment, tool: options.tool } }
    : {}
  const response = await axios.post(`${process.env.REACT_APP_API_URL}integrations/ats/connection/link/`, data)
  return response.data.link as string | undefined
}

export const activateConnection = async (token: string) => {
  // will throw if problem
  await axios.post(`${process.env.REACT_APP_API_URL}integrations/ats/connection/activate/`, { token })
}

export const forceSync = async () => {
  // will throw if problem
  await axios.post(`${process.env.REACT_APP_API_URL}integrations/ats/sync/`)
}

export const getAtsStatus = async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}integrations/ats/connection/`)
  const result: AtsStatus = {
    ...response.data,
    last_sync_date: response.data.last_sync_date ? moment(response.data.last_sync_date).toDate() : response.data.last_sync_date,
  }
  return result;
}

export const connectJob = async (localJobId: string, remoteJobId: string) => {
  await axios.post(`${process.env.REACT_APP_API_URL}integrations/ats/jobs/`, {
    local_job: { id: localJobId },
    ats_job: { id: remoteJobId },
  })
}

export const getAtsJobStatus = async (jobId: string) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}integrations/ats/jobs/${jobId}/`)
  return response.data as AtsJobStatus
}

export const createStageMapping = async (jobId: string, mappings: { localId: string, atsId: string }[]) => {
  await Promise.all([
    mappings.map(({ localId, atsId }) =>
      axios.post(`${process.env.REACT_APP_API_URL}integrations/ats/jobs/${jobId}/stages/`, {
        local_stage: { id: localId },
        ats_stage: { id: atsId },
      })
    )
  ])
}

export const createStageMappings = async (jobId: string, mappings: { localId: string, atsId: string }[]) => {
  await axios.post(`${process.env.REACT_APP_API_URL}integrations/ats/jobs/${jobId}/stages/`, mappings.map(({ localId, atsId }) => ({
    local_stage: { id: localId },
    ats_stage: { id: atsId },
  })))
}

export const disconnectJob = async (jobId: string) => {
  await axios.delete(`${process.env.REACT_APP_API_URL}integrations/ats/jobs/${jobId}/`)
}

export const disconnectAts = async () => {
  await axios.delete(`${process.env.REACT_APP_API_URL}integrations/ats/connection/`)
}
