import axios from 'axios';

const api_url = process.env.REACT_APP_API_URL;

export const getSeniorityList = async () => {
  return await axios.get(`${api_url}seniority/`)
}
export const setSeniorityList = async (seniority:any) => {
  return await axios.put(`${api_url}recruiters/me/seniority/`,seniority)
}
