import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Form, } from 'antd';
import Button from '../../../UI/buttons/Button';
import FormTitle from '../../auth/FormTitle';
import FormItem from '../../form/FormItem';
import { FormSubtitle } from '../../form/JobInfoForm/styles';

const StyledForm = styled(Form)`
  margin-top: 2.5rem;
  width: 100%;
`;

type AwaitingInitialSyncNoticeProps = {
  onRefresh: () => void;
}

const AwaitingInitialSyncNotice = ({ onRefresh }: AwaitingInitialSyncNoticeProps) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [loading] = useState(false);
  useEffect(() => {
    const handle = setInterval(() => {
      onRefresh();
    }, 10000);
    return () => {
      clearInterval(handle);
    }
  }, [onRefresh])
  return (
    <StyledForm
      scrollToFirstError={true}
      layout="vertical"
      name="invitation"
      form={form}
    >
      <FormTitle text={t('ats.ATS_SETTINGS_TITLE')} />
      <FormItem>
        <FormSubtitle>{t('ats.AWAITING_SYNC_SUBTITLE')}</FormSubtitle>
        <p>{t('ats.AWAITING_SYNC_EXPLAINER.0')}</p>
        <p>{t('ats.AWAITING_SYNC_EXPLAINER.1')}</p>
        <Button type="ghost" loading={loading} onClick={() => { onRefresh() }}>
          {t('ats.REFRESH_THIS_PAGE')}
        </Button>
      </FormItem>
    </StyledForm>
  )
}

export default AwaitingInitialSyncNotice
