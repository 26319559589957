import axios from 'axios';

const api_url = process.env.REACT_APP_API_URL;

export const getPayments = async (query?:string) => {
  return await axios.get(`${api_url}payments/${query ? query : ''}`);
};

export const getPaymentsSettings = async () => {
  return await axios.get(`${api_url}payments/settings/`);
};
export const updatePaymentsSettings = async (data:any) => {
  return await axios.patch(`${api_url}payments/settings/`,data);
};
