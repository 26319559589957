import React, { useEffect } from 'react';
import AdminDashboard from '../components/admin/Dashboard';
import ClientDashboard from '../components/client/Dashboard';
import RecruiterDashboard from '../components/recruiter/Dashboard';
import { useAppSelector } from '../store/hooks';
import ReactGa from 'react-ga4';

const Dashboard = () => {
  const { user } = useAppSelector((state) => state.user);
  useEffect(() => {
    ReactGa.pageview('/dashboard');
  }, []);
  return (
    <>
      {user?.role === 'admin' && <AdminDashboard />}
      {user?.role === 'client' && <ClientDashboard />}
      {user?.role === 'recruiter' && <RecruiterDashboard />}
    </>
  );
};

export default Dashboard;
