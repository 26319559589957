import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import PageWrapper from '../components/layout/PageWrapper';
import Header from '../components/layout/Header';
import { getClientList } from '../api/client';
import { Client } from '../types';
import { useHistory } from 'react-router-dom';
import { getUrlString } from '../constants/queryParams';
import ClientListFilter from '../components/client/ClientListFilter';
import ClientList from '../components/client/ClientList';
import RoundAddButton from '../UI/buttons/RoundAddButton';
import AdminUserAdd from '../components/admin/AdminUserAdd';
import Loader from '../UI/Loader';
import ReactGa from 'react-ga4';

const NoResultText = styled.p`
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.33;
  letter-spacing: 0.96px;
  text-align: center;
  color: #061c2e;
  padding: 1rem;
`;

const Clients = () => {
  const [loading, setLoading] = useState(true);
  const [addUser, setAddUser] = useState(false);
  const [clients, setClients] = useState<Client[]>([]);
  const [next, setNext] = useState<string | null>(null);
  const loader = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const [t] = useTranslation();
  const params = new URLSearchParams(history.location.search);
  const company_name = params.get('company_name');
  const department = params.get('department');
  const contact_name = params.get('contact_name');
  useEffect(() => {
    const query = getUrlString({
      company_name,
      department,
      contact_name,
    });
    getClientList(query && `?${query}`).then((res) => {
      setLoading(false);
      setClients(res.data.results);
      setNext(res.data.pagination.next);
    });
  }, [company_name, department, contact_name]);
  const handleObserver = useCallback(
    (entities: any) => {
      const cursor = `${
        history.location.search
          ? history.location.search + '&cursor=' + next
          : '?cursor=' + next
      }`;
      const target = entities[0];
      if (target.isIntersecting && next) {
        getClientList(cursor).then((res) => {
          setClients((prevState) => [...prevState, ...res.data.results]);
          setNext(res.data.pagination.next);
        });
      }
    },
    [next, history.location.search],
  );
  useEffect(() => {
    const current = loader.current;
    let options = {
      root: null,
      rootMargin: '0px 0px 300px 0px',
      threshold: 1.0,
    };
    const observer = new IntersectionObserver(handleObserver, options);

    if (!next) {
      observer.disconnect();
    } else {
      if (loader && current) {
        observer.observe((current as unknown) as Element);
      }
    }
    return () => observer.unobserve((current as unknown) as Element);
  }, [next, handleObserver]);
  const addClient = () => {
    setAddUser(true);
  };
  useEffect(() => {
    ReactGa.pageview('/clients');
  }, []);
  return (
    <PageWrapper>
      <Header title={t('CLIENTS')}></Header>
      <ClientListFilter />
      <Loader spinning={loading}>
        {!loading && !clients.length ? (
          <NoResultText>{t('NO_RESULT')}</NoResultText>
        ) : (
          <ClientList clients={clients} />
        )}
        <div className="loader" ref={loader} />
      </Loader>
      <RoundAddButton onClick={() => addClient()} />
      <AdminUserAdd visible={addUser} setVisible={setAddUser} update={setClients}/>
    </PageWrapper>
  );
};

export default Clients;
