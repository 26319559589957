import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Textarea from '../../UI/inputs/Textarea';
import { ReactComponent as CheckIcon } from '../../icons/check-icon.svg';
import FormItem from './FormItem';
import { Form } from 'antd';
import Button from '../../UI/buttons/Button';


interface RateFormProps {
  onFinish: (data:any) => void
  loading: boolean
}
const StyledCheckbox = styled.div`
  width: 100%;
  cursor: pointer;
  border-radius: 0.25rem;
  padding: 0.875rem 1rem;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & svg {
    width: 22px;
    height: 22px;
  }
  &.good {
    border: solid 1px #34ce72;
    color: #34ce72;
    &.active {
      border: solid 1px #34ce72;
      color: #ffffff;
      background: #34ce72;
    }
  }
  &.bad {
    border: solid 1px #627d95;
    color: #627d95;
    &.active {
      border: solid 1px #627d95;
      color: #ffffff;
      background: #627d95;
    }
  }
`;
const CommentItem = styled(FormItem)`
  margin: 2.5rem 0;
`;
const RateForm = ({onFinish,loading}:RateFormProps) => {
  const [comments, setComments] = useState('');
  const [good, setGood] = useState<{ [key: string]: boolean }>({
    nice_to_work: false,
    kept_agreements: false,
    good_attention: false,
  });
  const [bad, setBad] = useState<{ [key: string]: boolean }>({
    need_better_communication: false,
    deadlines_missed: false,
    different_role: false,
    not_enough_attention: false,
  });
  const toggleGood = (el: string) => {
    setGood((prevState) => ({ ...prevState, [el]: !prevState[el] }));
  };
  const toggleBad = (el: string) => {
    setBad((prevState) => ({ ...prevState, [el]: !prevState[el] }));
  };
  const [t] = useTranslation();
  return (
    <Form layout="vertical" scrollToFirstError={true}>
      {Object.keys(good).map((el) => {
        const isActive = good[el];
        return (
          <StyledCheckbox
            key={el}
            className={isActive ? 'active good' : 'good'}
            onClick={() => toggleGood(el)}
          >
            <div>{t(el.toUpperCase())}</div>
            {isActive && <CheckIcon />}
          </StyledCheckbox>
        );
      })}
      {Object.keys(bad).map((el) => {
        const isActive = bad[el];
        return (
          <StyledCheckbox
            key={el}
            className={isActive ? 'active bad' : 'bad'}
            onClick={() => toggleBad(el)}
          >
            <div>{t(el.toUpperCase())}</div>
            {isActive && <CheckIcon />}
          </StyledCheckbox>
        );
      })}
      <CommentItem label={t('COMMENT')}>
        <Textarea
          autoSize={{ minRows: 3 }}
          value={comments}
          onChange={(
            e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
          ) => setComments(e.target.value)}
        />
      </CommentItem>
      <Button
        style={{margin:'0 auto'}}
        type="primary"
        onClick={() => {
          onFinish({...good,...bad,comments})
        }}
        loading={loading}
      >
        {t('LEAVE_FEEDBACK_RATE')}
      </Button>
    </Form>
  );
};

export default RateForm;
