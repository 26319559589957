import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, message, Row, Select, Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';
import styled from 'styled-components';

import Subtitle from '../../UI/typography/Subtitle';
import Text from '../../UI/typography/Text';
import {
  maxLengthRule,
  requiredRule,
} from '../../utils/validationRules';
import FormItem from './FormItem';
import FormPopUp from './FormPopUp';
import { ReactComponent as DeleteIcon } from '../../icons/delete.svg';
import { ReactComponent as ArrowDownIcon } from '../../icons/arrow-down.svg';
import {
  CandidateInfo,
  Certificate,
  City,
  Company,
  Role,
  Seniority,
  Skill,
  Specialization,
  Education,
} from '../../types';
import CheckboxGroup from '../../UI/inputs/CheckboxGroup';
import CheckboxButton from '../../UI/inputs/CheckboxButton';
import {
  createCandidate,
  getCandidate,
  updateCandidate,
} from '../../api/candidates';
import { ADMIN_ROLE } from '../../constants/roles';
import CheckboxButtonGroup from '../../UI/inputs/CheckboxButtonGroup';
import { useHistory } from 'react-router-dom';
import SelectInput from '../../UI/inputs/Select';
import Button from '../../UI/buttons/Button';
import Input from '../../UI/inputs/Input';
import Textarea from '../../UI/inputs/Textarea';
import { ReactComponent as AddIcon } from '../../icons/plus.svg';
import EmploymentTypeSelectFormSection from './shared/employment-types/EmploymentTypeSelectFormSection';
import { mapEmploymentTypeApiToForm, mapEmploymentTypeFormToApi } from './shared/employment-types/helpers';
import useEmploymentTypes from '../../hooks/useEmploymentTypes';

interface CandidateFormProps {
  visible: boolean;
  changeVisibility: React.Dispatch<React.SetStateAction<boolean>>;
  onAction: (data: CandidateInfo) => void;
  cities: City[];
  domains: Specialization[];
  companies?: Company[];
  seniority: Seniority[];
  skills: Skill[];
  roles: Role[];
  certificates: Certificate[];
  education: Education[],
  role?: string;
}

const FileUploader = styled.div`
  display: flex;
  padding: 0.5rem 1rem;
  align-items: center;
  width: 100%;
  height: 100%;
`;
const FileName = styled.p`
  font-size: 0.875rem;
  line-height: 1.43;
  color: #061c2e;
  margin: 0 0.5rem 0 0;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const DeleteFileIcon = styled(DeleteIcon)`
  color: #eb5757;
  margin-left: 0.5rem;
  cursor: pointer;
`;
const UploadAction = styled.div`
  display: flex;
  align-items: center;
`;
const StyledUploader = styled(Upload)`
  display: block;
  & .ant-upload-select {
    width: 100%;
    border: 1px solid #aebeca;
    border-radius: 0.5rem;
    height: 3rem;
  }
`;

export const GroupPanel = styled.div`
  background-color: #f2f6fa;
  border-radius: 8px;
  padding: 2rem;
  margin-bottom: 1rem;
  position: relative;
`;

export const RemoveEmploymentTypeButton = styled(Button)`
  position: absolute;
  right: 2rem;
  top: 2rem;
  z-index: 100;
`;

const { Option } = Select;

const getEmploymentTypeRef = (id?: string) => id ? { type: { id } } : {}

const CandidateAddForm = ({
  visible,
  changeVisibility,
  cities,
  domains,
  seniority,
  role,
  onAction,
  companies,
}: CandidateFormProps) => {
  const [t] = useTranslation();
  const employmentTypes = useEmploymentTypes();

  const [candidate, setCandidate] = useState<CandidateInfo | null>(null);

  const [loading, setLoading] = useState(false);
  const [isFormTouched, setIsFormTouched] = useState(false);
  const [phone, setPhone] = useState<string>('');
  const history = useHistory();
  const search = new URLSearchParams(history.location.search);
  const editModal = search.get('edit');
  const [form] = Form.useForm();
  const [file, setFile] = useState('');
  const [title, setTitle] = useState('NEW_CANDIDATE');

  const employmentTypeIds = employmentTypes.map(({ id }) => id);
  const firstEmploymentTypeId = employmentTypeIds.find((_, index) => index === 0);
  const secondEmploymentTypeId = employmentTypeIds.find((_, index) => index === 1);
  const initialFormValue = useMemo(() => ({
    employment_types: [getEmploymentTypeRef(firstEmploymentTypeId)],
  }), [firstEmploymentTypeId])

  useEffect(() => {
    if (candidate) return;
    const newValue = {
      ...form.getFieldsValue(),
      ...initialFormValue,
    }
    form.setFieldsValue(newValue)
  }, [candidate, initialFormValue, form])

  useEffect(() => {
    if (editModal) {
      setTitle('EDIT_CANDIDATE');
      getCandidate(editModal).then((res) => {
        setCandidate(res.data);
        form.setFieldsValue({
          ...res.data,
          company: res.data?.company?.id,
          employment_types: (res.data?.employment_types ?? []).map(mapEmploymentTypeApiToForm)
        });
        setFile(res.data.cv);
        setPhone(res.data.phone);
      });
    }
  }, [editModal, form]);

  const handleUploadFile = (file: RcFile): boolean => {
    setFile(file.name);
    return true;
  };

  const handleFileDelete = (e: React.MouseEvent<SVGSVGElement>) => {
    e.stopPropagation();
    setFile('');
    form.setFieldsValue({ cv: undefined });
  };

  const closeEdit = useCallback(() => {
    const search = new URLSearchParams(history.location.search);
    search.delete('edit');
    const query = search.toString();
    history.push({ search: query });
    setTimeout(() => {
      setTitle('NEW_CANDIDATE');
    }, 300);
    setPhone('');
  }, [history]);

  const onFinish = async (values: any) => {
    const cv = new FormData();
    setLoading(true);
    if (!editModal) {
      cv.append('cv', values.cv.file.originFileObj);
      const formValues = {
        ...values,
        phone,
        need_laptop: values.need_laptop,
        need_phone: values.need_phone,
        cv: null,
        employment_types: values.employment_types.map((employmentType: any) => mapEmploymentTypeFormToApi(employmentType)),
      };

      createCandidate(formValues)
        .then((res) => {
          const { id } = res.data;
          updateCandidate(cv, id).then((response) => {
            onAction({ ...res.data, cv: response.data.cv, interviews: [] });
            message.success(t('CANDIDATE_CREATE_MESSAGE'), 3);
            changeVisibility(false);
            form.resetFields();
            setPhone('');
            setLoading(false);
          });
        })
        .catch((e) => {
          message.error(e.response?.data.message, 3);
          setLoading(false);
        });
    } else {
      let updatedData = {
        ...values,
        phone,
        need_laptop: values.need_laptop,
        need_phone: values.need_phone,
        employment_types: values.employment_types.map((employmentType: any) => mapEmploymentTypeFormToApi(employmentType)),
      };
      if (candidate) {
        if (file.includes('https')) {
          delete updatedData.cv;
          updateCandidate(updatedData, editModal).then((res) => {
            onAction({
              ...res.data,
              interviews: candidate?.interviews || [],
            });
            message.success(t('CANDIDATE_UPDATED_MESSAGE'), 3);
            changeVisibility(false);
            closeEdit();
            form.resetFields();
            setLoading(false);
          });
        } else {
          delete updatedData.cv;
          if (values.cv.file?.originFileObj) {
            cv.append('cv', values.cv.file.originFileObj);
            await updateCandidate(cv, editModal)
          }
          updateCandidate(updatedData, editModal).then((res) => {
            onAction({
              ...res.data,
              interviews: candidate?.interviews || [],
            });
            message.success(t('CANDIDATE_UPDATED_MESSAGE'), 3);
            changeVisibility(false);
            closeEdit();
            form.resetFields();
            setLoading(false);
          });
        }
      }
    }
  };

  useEffect(() => {
    if (!visible || editModal) {
      form.resetFields();
      setFile('');
      setIsFormTouched(false);
    }
  }, [visible, form, editModal]);

  useEffect(() => {
    if (!visible) {
      closeEdit();
    }
  }, [visible, closeEdit]);

  return (
    <FormPopUp
      visible={visible || !!editModal}
      setVisible={changeVisibility}
      title={t(title)}
      confirmClose={isFormTouched}
      onClose={() => {
        changeVisibility(false);
        setTimeout(() => {
          closeEdit();
          form.resetFields();
          setPhone('');
        }, 500);
      }}
    >
      <Form
        form={form}
        initialValues={initialFormValue}
        layout="vertical"
        name="add-client"
        onFinish={onFinish}
        scrollToFirstError={true}
        onChange={() => setIsFormTouched(true)}
      >
        <Subtitle title={t('ADMIN')} marginBottom="0px" />
        <Text text={t('CANDIDATE_FORM_COMMENT_1')} />

        {role === ADMIN_ROLE && (
          <FormItem
            label={t('RECRUITER_COMPANY')}
            name="company"
            rules={requiredRule(t)}
          >
            <SelectInput
              size="large"
              showSearch
              placeholder={t('SELECT_RECRUITER_COMPANY')}
              optionFilterProp="children"
              filterOption={true}
              className="custom-select"
              suffixIcon={<ArrowDownIcon />}
              disabled={!!editModal}
            >
              {companies?.map((company) => (
                <Option value={company.id} key={company.id}>
                  {company.name}
                </Option>
              ))}
            </SelectInput>
          </FormItem>
        )}

        <FormItem
          label={t('NAME_LABEL')}
          name="name"
          rules={[...requiredRule(t), ...maxLengthRule(128, t)]}
        >
          <Input size="large" />
        </FormItem>

        <FormItem className="no-margin">
          <Row gutter={16}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 24 }}
              lg={{ span: 12 }}
            >
              <FormItem
                label={t('EMAIL_LABEL')}
                name="email"
                rules={[
                  {
                    type: 'email',
                    message: t('INVALID_EMAIL_ERROR'),
                    required: true,
                  },
                ]}
              >
                <Input size="large" />
              </FormItem>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 24 }}
              lg={{ span: 12 }}
            >
              <FormItem label={t('PHONE_TEXT')}>
                <Input
                  size="large"
                  onChange={(e) => {
                    if (e.target.value) {
                      const regex = /^[+]?\d+$/;
                      if (regex.test(e.target.value)) {
                        setPhone(e.target.value);
                      }
                    } else {
                      setPhone(e.target.value);
                    }

                  }}
                  value={phone}
                />
              </FormItem>
            </Col>
          </Row>
        </FormItem>

        <FormItem>
          <Row gutter={16}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 24 }}
              lg={{ span: 12 }}
            >
              <FormItem
                label={t('LINKEDIN_LINK')}
                name="linkedin_link"
                rules={[
                  ...requiredRule(t),
                  {
                    type: 'url',
                    message: t('INVALID_URL_ERROR'),
                  },
                ]}
              >
                <Input size="large" />
              </FormItem>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 24 }}
              lg={{ span: 12 }}
            >
              <FormItem
                label={t('UPLOAD_CV')}
                name="cv"
                rules={requiredRule(t)}
              >
                <StyledUploader
                  name="file"
                  showUploadList={false}
                  beforeUpload={handleUploadFile}
                  customRequest={() => void 0}
                >
                  <FileUploader>
                    <FileName>{file}</FileName>
                    <UploadAction>
                      <Button type={'default'} size="small">
                        {t('CHOOSE_FILE')}
                      </Button>

                      {file && <DeleteFileIcon onClick={handleFileDelete} />}
                    </UploadAction>
                  </FileUploader>
                </StyledUploader>
              </FormItem>
            </Col>
          </Row>
          <FormItem
            label={t('LIVES_IN')}
            name="city_of_residence"
            rules={requiredRule(t)}
          >
            <SelectInput
              size="large"
              showSearch
              placeholder={t('LOCATION_PLACEHOLDER')}
              optionFilterProp="children"
              filterOption={true}
              className="custom-select"
              suffixIcon={<ArrowDownIcon />}
            >
              {cities.map((city) => (
                <Option value={city.id} key={city.id}>
                  {city.name}
                </Option>
              ))}
            </SelectInput>
          </FormItem>
        </FormItem>

        <Subtitle title={t('DOMAIN_TITLE')} />

        <FormItem
          label={t('SENIORITY_LABEL')}
          name="seniority"
          rules={requiredRule(t)}
        >
          <SelectInput
            size="large"
            showSearch
            placeholder={t('SENIORITY_PLACEHOLDER')}
            optionFilterProp="children"
            filterOption={true}
            className="custom-select"
            suffixIcon={<ArrowDownIcon />}
          >
            {seniority.map((senior) => (
              <Option value={senior.id} key={senior.id}>
                {senior.name}
              </Option>
            ))}
          </SelectInput>
        </FormItem>

        <FormItem label="" name="specializations" rules={requiredRule(t)}>
          <CheckboxGroup
            data={domains}
            fields={{ label: 'name', value: 'id' }}
          />
        </FormItem>

        <Subtitle title={t('JOB')} />

        <FormItem className="no-margin">
          <Row gutter={16}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 24 }}
              lg={{ span: 12 }}
            >
              <FormItem
                label={t('CURRENT_ROLE')}
                name="current_role"
                rules={requiredRule(t)}
              >
                <Input size="large" placeholder={t('CURRENT_ROLE')} />
              </FormItem>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 24 }}
              lg={{ span: 12 }}
            >
              <FormItem
                label={t('CURRENT_COMPANY')}
                name="current_company"
                rules={requiredRule(t)}
              >
                <Input size="large" placeholder={t('CURRENT_COMPANY')} />
              </FormItem>
            </Col>
          </Row>
        </FormItem>

        <Subtitle title={t('Compensation')} />
        <p>Create preset values that you can change later when introducing the candidate to specific roles.</p>
        <Form.List name={'employment_types'}>
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <EmploymentTypeSelectFormSection
                  name={[field.name]}
                  getSelectedEmploymentType={() => form.getFieldValue(['employment_types', field.name, 'type', 'id'])}
                  onRemove={index >= 1 ? () => { remove(field.name) } : null}
                  displayContext="candidate"
                />
              ))}
              <FormItem
                hidden={fields.length >= employmentTypes.length}>
                <Button
                  type="ghost"
                  size="small"
                  icon={<AddIcon />}
                  onClick={() => { add(getEmploymentTypeRef(secondEmploymentTypeId)) }}
                >
                  {t('Add another')}
                </Button>
              </FormItem>
            </>
          )}
        </Form.List>

        <CheckboxButtonGroup>
          <FormItem label="" name="need_phone" valuePropName="checked">
            <CheckboxButton>{t('PHONE_TEXT')}</CheckboxButton>
          </FormItem>
          <FormItem label="" name="need_laptop" valuePropName="checked">
            <CheckboxButton>{t('LAPTOP')}</CheckboxButton>
          </FormItem>
        </CheckboxButtonGroup>

        <FormItem
          label={t('CURRENT_CONTRACT')}
          name="current_contract_type"
        >
          <SelectInput
            size="large"
            showSearch
            placeholder={t('CONTRACT_TYPE')}
            optionFilterProp="children"
            filterOption={true}
            className="custom-select"
            suffixIcon={<ArrowDownIcon />}
          >
            <Option value="permanent" key="permanent">
              {t('PERMANENT')}
            </Option>
            <Option value="temporary" key="temporary">
              {t('TEMPORARY')}
            </Option>
          </SelectInput>
        </FormItem>

        <Subtitle title={t('ADDITIONAL_INFO')} marginBottom="0px" />
        <Text text={t('CANDIDATE_FORM_COMMENT_3')} />

        <FormItem label="" name="additional_info">
          <Textarea autoSize={{ minRows: 4 }} />
        </FormItem>
        <Button type="primary" htmlType="submit" size="large" loading={loading}>
          {t(editModal ? 'SAVE_TITLE' : 'CANDIDATE_ADD')}
        </Button>
      </Form>
    </FormPopUp>
  );
};

export default CandidateAddForm;
