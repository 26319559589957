import React, { useEffect, useState } from 'react';
import { Route, RouteProps, useHistory } from 'react-router-dom';
import {
  CANDIDATES,
  CANDIDATES_SPECIFICATION_SETTINGS,
  DASHBOARD,
  GUEST,
  JOBS,
  LOGIN,
  MESSAGES,
  RATE,
} from '../../constants/routes';
import styled from 'styled-components';
import { getUserByMagicLink } from '../../api/user';
import { useAppDispatch } from '../../store/hooks';
import { removeToken, storeToken } from '../../utils/localStorage';
import Error from '../../pages/Error';
import { login } from '../../store/userSlice';
import {
  ADMIN_ROLE,
  CLIENT_ROLE,
  RECRUITER_ROLE,
  IS_ADMIN_KEY,
  ADMIN_TOKEN
} from '../../constants/roles';
import axios from 'axios';
import Loader from '../../UI/Loader';
import { SHOW_OFFER_PARAM, INTERVIEW_ID_PARAM, STAGE_PARAM, SHOW_DATE_PROPOSE_PARAM } from '../../constants/queryParams';

interface NotificationRouteProps extends RouteProps {
  component: any;
}
const FullScreen = styled.div`
  width: 100vw;
  height: 100vh;
`;

// @TODO there is some big legacy, related to old magic link
const NotificationRoute = (props: NotificationRouteProps) => {
  const { component: Component, ...rest } = props;
  const [error, setError] = useState(false);
  const [loading] = useState(true);
  const dispatch = useAppDispatch();
  const history = useHistory();

  useEffect(() => {
    const params = new URLSearchParams(history.location.search);
    const magic_link = params.get('magic_link');
    const next = params.get('type');
    const user_id = params.get('user_id');
    const candidate_id = params.get('candidate_id');
    const job_id = params.get('job_id');
    const interview_id = params.get('interview_id');
    const feedback_id = params.get('feedback_id');
    const stage_name = params.get('stage_name');
    axios.interceptors.response.use((response) => response, (error) => {
      if (error.response.status >= 500){
        setError(true)
      }else {
        return  Promise.reject(error);
      }
    });
    //const task_id = params.get('task_id');
    if (magic_link) {
      getUserByMagicLink(magic_link)
        .then((res) => {
          const user = res.data.user;
          let link = '';
          const search = new URLSearchParams();
          removeToken('access_token');
          storeToken(res.data.access_token);
          axios.defaults.headers.common['Authorization'] = res.data.access_token
            ? `Token ${res.data.access_token}`
            : '';
          dispatch(login({ user: res.data.user }));

          if (res.data.user) {
            let user = res.data.user;
            // @ts-ignore
            localStorage.setItem("intercomID", process.env.REACT_APP_INTERCOM_ID);
            // @ts-ignore
            window.Intercom('boot', {
              app_id: process.env.REACT_APP_INTERCOM_ID,
              email: user.email,
              name: user.name,
              user_id: user.id,
              user_role: user.role,
              user_has_default_tier: user.role === 'recruiter' ? user.has_default_tier : false,
            });
          }

          if (user.role === CLIENT_ROLE || user.role === RECRUITER_ROLE) {
            if (next === '2') {
              interview_id && search.set(INTERVIEW_ID_PARAM, interview_id);
              stage_name && search.set(STAGE_PARAM, stage_name);
              search.set(SHOW_DATE_PROPOSE_PARAM, 'true');
              const query = search.toString();
              link += `${CANDIDATES}?filter=in-process&${query}`;
            } else if (next === '3') {
              //task_id && search.set('task_id', task_id);
              interview_id && search.set('tasks', interview_id);
              const query = search.toString();
              link += `${CANDIDATES}?filter=in-process&${query}`;
            } else if (next === '4') {
              stage_name && search.set('stage', stage_name);
              if (user.role === CLIENT_ROLE) {
                //task_id && search.set('task_id', task_id);
                interview_id && search.set('tasks', interview_id);
                const query = search.toString();
                link += `${CANDIDATES}?filter=in-process&${query}`;
              } else {
                interview_id && search.set('feedback', interview_id);

                const query = search.toString();
                link += `${CANDIDATES}?filter=in-process&${query}`;
              }
            } else if (next === '6') {
              candidate_id && search.set('history', candidate_id);
              interview_id && search.set('interview_id', interview_id);
              stage_name && search.set('stage', stage_name);
              const query = search.toString();
              link += `${CANDIDATES}?filter=in-process&${query}`;
            } else if (next === '5') {
              candidate_id && search.set('history', candidate_id);
              interview_id && search.set('interview_id', interview_id);
              interview_id && search.set('feedback_interview_id', interview_id);
              feedback_id && search.set('feedback_id', feedback_id);
              stage_name && search.set('stage_name', stage_name);
              const query = search.toString();
              link += `${CANDIDATES}?filter=in-process&${query}`;
            } else if (next === '10' || next === '13') {
              candidate_id && search.set(SHOW_OFFER_PARAM, candidate_id);
              interview_id && search.set(INTERVIEW_ID_PARAM, interview_id);
              const query = search.toString();
              link += `${CANDIDATES}?filter=in-process&${query}`;
            } else if (next === '12' || next === '15') {
              user?.role === RECRUITER_ROLE &&
                candidate_id &&
                search.set('history', candidate_id);
              interview_id && search.set('interview_id', interview_id);
              interview_id && search.set('feedback_interview_id', interview_id);
              search.set('rejected_feedback', '1');
              stage_name && search.set('stage_name', stage_name);
              const query = search.toString();
              link += `${CANDIDATES}?filter=in-process&${query}`;
            } else if (next === '11' ) {
              link += `${CANDIDATES}?filter=in-process`;
            }else if (next === '14' ) {
              user.role === CLIENT_ROLE && interview_id && search.set('tasks', interview_id);
              const query = search.toString();
              link += `${CANDIDATES}?filter=in-process&${query}`;
            } else if (next === '9') {
              link += `${CANDIDATES_SPECIFICATION_SETTINGS}`;
            } else if (next === '7') {
              if (user_id && interview_id) {
                link = `${MESSAGES}/${user_id}/${interview_id}`;
              } else {
                link = `${MESSAGES}/support`;
              }
            } else if (next === '8') {
              if (job_id) {
                link = `${JOBS}/${job_id}`;
              } else {
                link = `${JOBS}`;
              }
            } else if (next === '16') {
              if (interview_id) {
                link = `${RATE}/${interview_id}`;
              }
            }
            else if (next === '19') {
              link += `${CANDIDATES}?filter=in-process`;
            }
            history.push(link);
          } else if (user.role === ADMIN_ROLE) {
            if (next === '17') {
              job_id && history.push(`${JOBS}/${job_id}`);
            }
          } else {
            if (next === '3' || next === '19') {
              //task_id && search.set('task_id', task_id);
              interview_id && search.set('tasks', interview_id);
            } else if (next === '14' || next === '2' ) {
              interview_id && search.set(INTERVIEW_ID_PARAM, interview_id);
              stage_name && search.set(STAGE_PARAM, stage_name);
              search.set(SHOW_DATE_PROPOSE_PARAM, 'true');
            } else {
              interview_id && search.set('feedback', interview_id);
              stage_name && search.set('stage', stage_name);
              candidate_id && search.set('review', '1');
            }
            let query = search.toString();
            link += `${GUEST}?${query}`;
            history.push(link);
          }
        })
        .catch((e) => {
          removeToken('access_token');
          removeToken(IS_ADMIN_KEY);
          removeToken(ADMIN_TOKEN);
          history.push(LOGIN);
        });
    } else {
      history.push(DASHBOARD);
    }
  }, [history, dispatch]);
  return (
    <Loader spinning={loading}>
      <Route
        {...rest}
        render={(props) =>
          error? <Error />:  loading ? <FullScreen></FullScreen> : <Component {...props} />
        }
      />
    </Loader>
  );
};

export default NotificationRoute;
