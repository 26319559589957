import { TFunction } from 'react-i18next';
import { Job } from '../types';
import { addDecimalPoints } from './candidate';
import { CURRENCY_SYMBOL } from './getEmploymentTypeSummary';

const getRecruiterFeeSummary = (t: TFunction<"translation">, job: Job) => {
  // use the details in a recruiter/company agreement, if present
  // then the details in the job flex fee, if present.
  const compensation_type = job.company_agreement ? job.company_agreement?.compensation_type : job.recruiter_compensation_type;
  const compensation_details = job.company_agreement ? job.company_agreement?.compensation_type_tooltip : job.recruiter_compensation_type_tooltip;

  switch (compensation_type) {
    case 'percentage':
      return {
        recruiterFee: `${compensation_details} ${t('of yearly salary')}`
      }
    case 'fixed':
      return {
        recruiterFee: `${t('Fixed')}: ${!compensation_details.includes(CURRENCY_SYMBOL) ? CURRENCY_SYMBOL : ''}${compensation_details}`
      }
    case 'up to':
      return {
        recruiterFee: `${t('Up to')} ${!compensation_details.includes(CURRENCY_SYMBOL) ? CURRENCY_SYMBOL : ''}${compensation_details}`
      }
    case 'custom':
      return {
        recruiterFee: compensation_details
      }
  }

  // this is the fallback in case neither of the compensation types are set - use `earn_amount_2` which is based on salary brackets
  const recruiterFeeAmount = job.earn_amount_2 ? addDecimalPoints(job.earn_amount_2.toString()) : null;
  const recruiterFee = recruiterFeeAmount ? `${t('Up to')} €${recruiterFeeAmount}` : '-'

  return { recruiterFee };
};

export default getRecruiterFeeSummary;
