export const palette = [
  {
    main: '#7949cd',
    light: '#f2edfa',
  },
  {
    main: '#dd4848',
    light: '#fceded',
  },
  {
    main: '#7daf13',
    light: '#f2f7e7',
  },
  {
    main: '#15b5e8',
    light: '#e8f8fd',
  },
  {
    main: '#f58316',
    light: '#fef3e8',
  },
  {
    main: '#4e43ce',
    light: '#edecfa',
  },
  {
    main: '#15b1a8',
    light: '#e8f7f6',
  },
  {
    main: '#29ae5e',
    light: '#eaf7ef',
  },
  {
    main: '#e2be00',
    light: '#fcf9e6',
  },
  {
    main: '#e93f79',
    light: '#fdecf2',
  },
];

export const getRandomColor = () => {
  return palette[Math.floor(Math.random() * palette.length)];
};
