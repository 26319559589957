import { Avatar } from 'antd';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import useClickAwayListener from '../../../hooks/useClickAwayListener';
import CandidateModal from './CandidateModal';
import { Candidate } from '../../../types';
import { getNameFirstLetters } from '../../../utils/candidate';
import { useTranslation } from 'react-i18next';

type ColoredCandidate = Candidate & { color: { light: string; main: string } ,stage_name:string};
interface JobStageProps {
  stage: string;
  candidates?: ColoredCandidate[];
}

const JobStageWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const StageTitle = styled.p`
  font-size: 0.75rem;
  line-height: 2.4rem;
  text-align: right;
  color: #061c2e;
  margin: 0 1rem 0 0;
  width: 40%;
  position: relative;
  @media (max-width: 992px) {
    width: 48%;
    text-align: left;
  }
`;
const StageAvatarGroup = styled.div`
  position: relative;
  display: flex;
  & .ant-avatar-group {
    line-height: 1rem;
  }
`;
const StageAvatar = styled(Avatar)`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  & .ant-avatar-string {
    line-height: 1;
  }
  & .ant-avatar-string {
    font-size: 0.875rem;
  }
`;

const JobStage = ({ stage, candidates }: JobStageProps) => {
  const [activeItem, setActiveItem] = useState<ColoredCandidate | null>(null);
  const wrapperRef = useRef(null);
  const [t] = useTranslation();
  const handleClick = (candidate: ColoredCandidate) => {

    if (activeItem?.id === candidate.id) {
      setActiveItem(null);
    } else {
      setActiveItem(candidate);
    }
  };
  useClickAwayListener(wrapperRef, () => {
    if (activeItem) setActiveItem(null);
  });
  return (
    <JobStageWrapper>
      <StageTitle>{stage === 'offered' ? t('OFFER_STAGE') : stage}</StageTitle>
      {candidates?.length ? (
        <StageAvatarGroup ref={wrapperRef}>
          <Avatar.Group
            maxCount={4}
            maxStyle={{
              backgroundColor: 'rgba(98,125,149,0.1)',
              color: '#627d95',
            }}
          >
            {candidates?.map((candidate) => {
              const fullName = getNameFirstLetters(candidate.name);
              const color = candidate.color;
              return (
                <StageAvatar
                  style={{ backgroundColor: color.light, color: color.main }}
                  key={candidate.id}
                >
                  <span
                    onClick={(e) => {
                      handleClick(candidate);
                    }}
                  >
                    {fullName}
                  </span>
                </StageAvatar>
              );
            })}
          </Avatar.Group>
          {activeItem && <CandidateModal user={activeItem} stage={stage} />}
        </StageAvatarGroup>
      ) : (
        '-'
      )}
    </JobStageWrapper>
  );
};

export default JobStage;
