import React from 'react';
import FormTitle from '../auth/FormTitle';
import InterviewStageModalForm from '../form/InterviewStageModalForm';
import { InterviewRoom } from '../../types';
import { completeTask } from '../../api/interviews';

interface InterviewStageProps {
  interview: InterviewRoom;
  onComplete: (id: string) => void;
}

const InterviewStage = ({ interview, onComplete }: InterviewStageProps) => {
  const handleComplete = (id: string) => {
    completeTask(interview.id, id);
  };
  return (
    <div>
      <FormTitle text={interview.current_stage.stage_name} />
      <InterviewStageModalForm
        interview={interview}
        handleComplete={handleComplete}
      />
    </div>
  );
};

export default InterviewStage;
