import React, { ReactNode } from 'react';
import styled from 'styled-components';
interface TagProps {
  children: ReactNode;
}
const Tag = styled.div`
  padding: .25rem .5rem;
  border-radius: .25rem;
  background-color: #eff9f3;
  text-transform: uppercase;
  max-width: max-content;
  margin: 0 auto;
  font-size: .625rem;
  font-weight: 700;
  line-height: 1.6;
  letter-spacing: 0.8px;
  text-align: center;
  color: #061c2e;
`;
const MatchTag = ({ children }: TagProps) => {
  return <Tag>{children}</Tag>;
};

export default MatchTag;
