import axios from "axios";

import { City } from '../types';

export const getRegions = async () => {
    return await axios.get(process.env.REACT_APP_API_URL + 'regions/')
};
export const getCityByName = async (text?: string) => {
    return await axios.get(process.env.REACT_APP_API_URL + 'cities/?search=' + text)
};

export const getCityList = async () => {
    const response = await axios.get(process.env.REACT_APP_API_URL + 'cities/');

    const Remote = 'Remote';
    const Relocate1 = 'Relocate to NL from EU';
    const Relocate2 = 'Relocate to NL from outside EU';

    const RemoteCity = response?.data.find(({ name }: City) => name === Remote);
    const RelocateCity1 = response?.data.find(({ name }: City) => name === Relocate1);
    const RelocateCity2 = response?.data.find(({ name }: City) => name === Relocate2);

    const filtered = response?.data.filter(({ name }: City) => name !== Remote && name !== Relocate1 && name !== Relocate2);
    return { ...response, data: [RemoteCity, RelocateCity1, RelocateCity2, ...filtered]};
};

export const setCityList = async (locations:any) => {
    return await axios.put(process.env.REACT_APP_API_URL + 'recruiters/me/locations/',locations)
};
