import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0.75rem 0;
  margin-bottom: 1.5rem;
`;

export const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-right: 2rem;
`;

export const HeaderRight = styled.div`
  width: 50%;
  & p {
    text-align: right;
  }
`;

export const Name = styled.p`
  font-size: 0.875rem;
  margin-left: 0.5rem;
  font-weight: 700;
  line-height: 1.43;
  color: #061c2e;
  margin-bottom: 0;
  a {
    color: #061c2e;
  }
`;

export const NameWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Feature = styled.span`
  font-size: 0.875rem;
  line-height: 1.43;
  color: #627d95;
  margin: 0 1rem;
`;
