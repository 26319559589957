export const relocationOptions = [
    {
        id: 0,
        label: 'NO_RELOCATION',
    },
    {
        id: 1,
        label: 'FROM_EU_WITH_PACKAGE',
    },
    {
        id: 2,
        label: 'FROM_EU_NO_PACKAGE',
    },
    {
        id: 3,
        label: 'FROM_WORLD_WITH_PACKAGE',
    },
    {
        id: 4,
        label: 'FROM_WORLD_NO_PACKAGE',
    },
];

export const relocationOptionsFilters = [
    {
        id: 0,
        label: 'NO_RELOCATION_FILTERS',
    },
    {
        id: 1,
        label: 'FROM_EU_WITH_PACKAGE_FILTERS',
    },
    {
        id: 2,
        label: 'FROM_EU_NO_PACKAGE_FILTERS',
    },
    {
        id: 3,
        label: 'FROM_WORLD_WITH_PACKAGE_FILTERS',
    },
    {
        id: 4,
        label: 'FROM_WORLD_NO_PACKAGE_FILTERS',
    },
];

export const FROM_WORLD_WITH_PACKAGE = 1;
export const FROM_EU_WITH_PACKAGE = 3;
