import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import * as d3 from 'd3';
import styled from 'styled-components';

type ChartItem = {
  label?: string;
  count: number;
  textColor: string;
  bgColor: string;
};

interface ChartProps {
  info: ChartItem[];
}

const StyledSvg = styled.svg`
  width: 100%;
  height: auto;
`;
const SvgText = styled.p<ReactNode & { color: string; size: number }>`
  font-size: ${(props) => props.size}px;
  color: ${(props) => props.color};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  text-align: center;
  padding: 1rem;
`;
const BubbleChart = ({ info }: ChartProps) => {
  const [data, setData] = useState<d3.SimulationNodeDatum[]>([]);

  const svgRef = useRef<SVGSVGElement | null>(null);
  let maxValue = d3.max(info, (d) => d.count)!;
  let minValue = d3.min(info, (d) => d.count)!;
  let current = svgRef.current;
  const [bounds, setBounds] = useState({
    width: current ? current.scrollWidth : 350,
    height: current ? current.scrollHeight : 300,
  });

  useEffect(() => {
    const updateBounds = () => {
      setBounds({
        width: current ? current.scrollWidth : 350,
        height: current ? current.scrollHeight : 300,
      });
    };
    window.addEventListener('resize', updateBounds);
    return () => window.removeEventListener('resize', updateBounds);
  }, [current]);

  const radiusScale = useCallback(
    (value: number) => {
      const range = [bounds.width / 10, bounds.width / 5];
      const fx = d3.scaleSqrt().range(range).domain([minValue, maxValue]);

      return fx(value);
    },
    [minValue, maxValue, bounds],
  );

  const simulatePositions = useCallback(
    (data: any) => {
      d3.forceSimulation()
        .nodes(data)
        .velocityDecay(.9)
        .force('x', d3.forceX().strength(0.2))
        .force('y', d3.forceY().strength(0.05))
        .force(
          'collide',
          d3.forceCollide((d: any) => {
            return radiusScale(d.count);
          }),
        )
        .on('tick', () => {
          setData(data);
        });
    },
    [radiusScale],
  );

  const renderBubbles = (data: any) => {
    return data.map((item: any, index: number) => {
      const fontSize = radiusScale(item.count) / 5;
      return (
        <g
          key={index}
          transform={`translate(${bounds.width / 2 + item.x}, ${
            bounds.height / 2 + item.y
          })`}
        >
          <circle r={radiusScale(item.count)} fill={item.bgColor} />
          <foreignObject
            transform={`translate(${-radiusScale(item.count)}, ${-radiusScale(
              item.count,
            )})`}
            width={radiusScale(item.count) * 2}
            height={radiusScale(item.count) * 2}
            dy="6"
            textAnchor="middle"
            fontWeight="bold"
          >
            <SvgText color={item.textColor} size={fontSize}>
              {item.label}
            </SvgText>
          </foreignObject>
        </g>
      );
    });
  };

  useEffect(() => {
    simulatePositions(info);
    const timeoutId = setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 100);
    return () => clearTimeout(timeoutId);
  }, [info, simulatePositions]);

  return (
    <StyledSvg width={bounds.width} height={bounds.height} ref={svgRef}>
      {renderBubbles(data)}
    </StyledSvg>
  );
};

export default BubbleChart;
