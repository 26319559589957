export const RATING_FILTERS = [
  {
    name: "1 or more stars",
    value: 1
  },
  {
    name: "2 or more stars",
    value: 2
  },
  {
    name: "3 or more stars",
    value: 3
  },
  {
    name: "4 or more stars",
    value: 4
  },
  {
    name: "5 stars",
    value: 5
  },
];

/**
 * Determines the pre-filled recruiter compensation type when adding and editing a job
 */
export const HIREPORT_DEFAULT_COMPENSATION_TYPE = 'percentage';
/**
 * Determines the pre-filled recruiter compensation amount when adding and editing a job
 */
export const HIREPORT_DEFAULT_COMPENSATION_AMOUNT = '25%';

/**
 * @deprecated
 * The fee explainers were temporarily disabled until at least 2024-01-01
 * pending new commercial agreements.
 * If you are reading this comment significantly after that date, and 
 * fee explainers are still disabled, chances are it never happened
 * and you can probably safely remove this stuff.
 */
export const FEE_EXPLAINER_ENABLED = false;
