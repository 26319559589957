interface IgetMonthYearSalary {
  salary: number,
  bonusMonthly?: number,
  bonusYearly?: number,
}

export const getYearlySalary = ({
  salary,
  bonusMonthly,
  bonusYearly,
}: IgetMonthYearSalary) => {
  const bonus = bonusMonthly ? bonusMonthly : 0;
  const bonusY = bonusYearly ? bonusYearly : 0;

  return (salary * 12.96) + (bonus * 12) + bonusY;
};

export const getMonthYearSalary = ({
  salary,
  bonusMonthly,
  bonusYearly,
}: IgetMonthYearSalary) => {
  let monthSalary;
  let yearSalary;
  if (salary) {
    const bonus = bonusMonthly ? bonusMonthly : 0;
    const bonusY = bonusYearly ? bonusYearly : 0;

    monthSalary = salary + bonus;
    yearSalary = getYearlySalary({
      salary,
      bonusMonthly: bonus,
      bonusYearly: bonusY,
    })
  }

  return { monthSalary, yearSalary };
};

