import React from 'react';
import { Drawer } from 'antd';
import styled from 'styled-components';
import FormTitle from './FormTitle';
import SignUpForm from './SignUpForm';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { LOGIN } from '../../constants/routes';

interface DrawerProps {
  visible: boolean;
  setVisible: (st: boolean) => void;
}

const StyledDrawer = styled(Drawer)`
  &.ant-drawer-open {
    width: 50% !important;
    @media (max-width: 768px) {
      width: 100% !important;
    }
  }
  & .ant-drawer-body {
    padding: 4.5rem 4rem;
  }
`;

const SignUpTextLarge = styled.p`
  font-size: 1.25rem;
  line-height: 1.6;
  margin-top: 1rem;
  color: #000000;
`;
const SignUpTextSmall = styled.p`
  font-size: 0.875rem;
  line-height: 1.71;
  color: #000000;
  margin-bottom: 3.5rem;
`;
const RequestPopUp = ({ visible, setVisible }: DrawerProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const triggerDrawer = () => {
    history.push(LOGIN)
  };
  return (
    <StyledDrawer
      title=""
      placement="right"
      closable={true}
      onClose={triggerDrawer}
      visible={visible}
      getContainer={false}
      mask={false}
    >
      <FormTitle text={t('SIGN_UP_TITLE')} />
      <SignUpTextLarge>
        {t('SIGN_UP_TEXT_TOP')}
      </SignUpTextLarge>
      <SignUpTextSmall>
        {t('SIGN_UP_TEXT_BOTTOM')}
      </SignUpTextSmall>
      <SignUpForm
        onSuccess={() => {
          setVisible(!visible);
        }}
      />
    </StyledDrawer>
  );
};

export default RequestPopUp;
