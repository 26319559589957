import { Col, Form, Select, Row, message } from "antd";
import React from "react";
import FormItem from "../form/FormItem";
import Input from "../../UI/inputs/Input";
import SelectInput from "../../UI/inputs/Select";
import { ReactComponent as ArrowDownIcon } from '../../icons/arrow-down.svg';
import Button from "../../UI/buttons/Button";
import { useAppSelector } from "../../store/hooks";
import { inviteOwnSupplier } from "../../api/recruiter";

export const FEE_TYPES = [
  {
    fee_name: "Fixed",
    fee_value: "fixed"
  },
  {
    fee_name: "Percentage",
    fee_value: "percentage"
  },
  {
    fee_name: "Up to",
    fee_value: "up_to"
  },
  {
    fee_name: "Custom",
    fee_value: "custom"
  },
]

interface RecruiterManagementBoardProps {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}
export default function AddCompanyRecruiterForm({isVisible, setIsVisible}: RecruiterManagementBoardProps) {
  const [form] = Form.useForm();
  const { user } = useAppSelector((state) => state.user);


  const onSub = () => {
    let requestBody = form.getFieldsValue();
    requestBody["title"] = null;
    requestBody["phone"] = null;
    requestBody["recruiter_type"] = "freelance";
    // @ts-ignore
    requestBody["city"] = user?.company?.city.id;
    requestBody["invited_by"] = user?.company?.name;
    requestBody["is_invited"] = true;
    inviteOwnSupplier(requestBody).then((_) => {
      message.success("Invite sent");
    }).catch((e: any) => {
        let err = e.response.data;
        if (err.error_code === "data_invalid" && err.message === "You have already invited this supplier") {
          message.error("You’ve already invited this supplier");
          return;
        }
        message.error("Invitation failed, please try again later");
    });
  }

  return (
    <Form
      scrollToFirstError={true}
      style={{display: isVisible ? "block" : "none", backgroundColor: "#F2F6FA", padding: "2em", paddingBottom: "0", borderRadius: "10px", marginBottom: "1em"}}
      form={form}
      onFinish={onSub}
      labelCol={{ span: 24 }}
    >
      <Row gutter={10}>
        <Col xs={{ span: 12 }} md={{ span: 8 }} xl={{ span: 12 }}>
          <FormItem name="email" label="EMAIL" rules={[{ required: true, message: 'Please input your email!' },
          {
            type: 'email',
            message: 'Please enter a valid email address!',
          }]}>
            <Input style={{height: "40px"}} size="middle"/>
          </FormItem>
        </Col>
        <Col xs={{ span: 12 }} md={{ span: 8 }} xl={{ span: 12 }}>
          <FormItem name="name" label="FULL NAME" rules={[{ required: true, message: 'Please input your name!' }]}>
            <Input style={{height: "40px"}} size="middle" />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={10}>
        <Col xs={{ span: 12 }} md={{ span: 8 }} xl={{ span: 6 }}>
          <FormItem name="compensation_type" label="FEE STRUCTURE" rules={[{ required: true, message: 'Please select a fee type!' }]}>
            <SelectInput
              size="middle"
              showSearch
              optionFilterProp="children"
              filterOption={true}
              dropdownMatchSelectWidth={false}
              className="custom-select"
              suffixIcon={<ArrowDownIcon />}
            >
              {FEE_TYPES.map((fee, index) => (
                <Select.Option value={fee.fee_value} key={index}>
                  {fee.fee_name}
                </Select.Option>
              ))}
            </SelectInput>
          </FormItem>
        </Col>
        <Col xs={{ span: 12 }} md={{ span: 8 }} xl={{ span: 18 }}>
          <FormItem name="compensation_type_tooltip" label="FEE DETAILS" rules={[{ required: true, message: 'Please write a fee tooltip!' }]}>
            <Input maxLength={70} style={{height: "40px"}} size="middle" />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={10} justify="end">
        <Col>
          <FormItem>
            <Button style={{color: "#627d95", borderColor: "#627d95", height: "40px"}} size="middle" onClick={()=>{setIsVisible(false)}}>Cancel</Button>
          </FormItem>
        </Col>
        <Col>
          <FormItem>
            <Button type="primary" htmlType="button" onClick={() => form.submit()} style={{height: "40px"}} size="middle">Send Invite</Button>
          </FormItem>
        </Col>
      </Row>
    </Form>
  );
}
