import { Specialization } from "../types";

export function capitalizeFirstLetter(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const structuredDomains = (data: any) => {
  let structured: any = [];
  let originalDomains = data;
  let domains = [...originalDomains];
  let initialDomainIds = [...originalDomains].map(specialization => specialization.id);

  function filterDomains(domains: any, subDomain: any) {
    if (subDomain.sub_domains.length) {
      for (let sub of subDomain.sub_domains) {
        domains = filterDomains(domains, sub);
      }
    }
    return domains.filter((domain: any) => domain.id !== subDomain.id);
  }

  for (let domain of domains) {
    if (domain.sub_domains.length) {
      for (let subDomain of domain.sub_domains) {
        // @ts-ignore
        domains = filterDomains(domains, subDomain);
      }
    }
  }

  function buildDataNode(domain: Specialization) {
    const dataNode: any = {
      key: domain.id,
      title: domain.name,
      value: domain.id,
    };

    if (domain.sub_domains && domain.sub_domains.length > 0) {
      dataNode.children = domain.sub_domains.filter((subDomain: any) => initialDomainIds.includes(subDomain.id)).map((subDomain: any) => buildDataNode(subDomain));
    }

    return dataNode;
  }

  domains.forEach((domain, idx) => {
    let structuredDomains = {
      title: domain.name,
      value: domain.id,
      children: []
    };
    // @ts-ignore
    if (domain.sub_domains.length) {
      // @ts-ignore
      domain.sub_domains.filter((subDomain: any) => initialDomainIds.includes(subDomain.id)).forEach((sub_domain: any, index) => {
        let dataNode = buildDataNode(sub_domain);
        if (dataNode) {
          // @ts-ignore
          structuredDomains.children.push(dataNode);
        }
      })
    }
    structured.push(structuredDomains);
  }
  );
  return structured;
}

