import FormItem from './FormItem';
import { requiredRule } from '../../utils/validationRules';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import Button from '../../UI/buttons/Button';
import Textarea from '../../UI/inputs/Textarea';
import styled from 'styled-components';
interface RejectFormProps {
  rejectCandidate: (rejection_reason: string) => void;
  loading: boolean;
}

const StyledForm = styled(Form)`
  padding: 3px;
`;
const CandidateRejectForm = ({ rejectCandidate,loading }: RejectFormProps) => {
  const [form] = Form.useForm();
  const onFinish = (values: any) => {
    rejectCandidate(values.rejection_reason);
    form.resetFields();
  };
  const [t] = useTranslation();
  return (
    <StyledForm layout="vertical" name="add-client" onFinish={onFinish} scrollToFirstError={true}>
      <FormItem
        label={t('REASON')}
        name="rejection_reason"
        rules={[...requiredRule(t)]}
      >
        <Textarea autoSize={{ minRows: 2 }} />
      </FormItem>
      <FormItem>
        <Button size="large" type="default" danger htmlType="submit" loading={loading}>
          {t('SEND_REASON')}
        </Button>
      </FormItem>
    </StyledForm>
  );
};

export default CandidateRejectForm;
