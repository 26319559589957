import axios from 'axios';

export const getMagicLink = async (query: string) => {
  return await axios.get(
    process.env.REACT_APP_API_URL + query,
  )
  .then((res) => res)
  .catch((e) => {
    return e.response;
  });
}
