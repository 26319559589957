import React, { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';
import { Drawer, DrawerProps, Modal } from 'antd';
import FormTitle from '../auth/FormTitle';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from '../../icons/close.svg';

interface DrawerPopupProps extends DrawerProps {
  visible: boolean;
  setVisible: (st: boolean) => void;
  children: ReactNode;
  title?: string | ReactElement;
  confirmClose?: boolean;
  large?: boolean;
  description?: string;
  backButton?: ReactNode;
  customClass?: string;
}
const StyledDrawer = styled(Drawer)`
  z-index: 1000;
  & .ant-drawer-content-wrapper {
    width: 55% !important;
    box-shadow: 0 32px 80px 0 rgba(6, 28, 46, 0.2),
      0 16px 20px 0 rgba(6, 28, 46, 0.16) !important;
    @media (max-width: 768px) {
      width: 100% !important;
    }
  }
  &.large .ant-drawer-content-wrapper {
    width: 78% !important;
    @media (max-width: 768px) {
      width: 100% !important;
    }
  }
  & .ant-drawer-mask {
    background-color: rgba(6, 28, 46, 0.16);
  }
  &.company-chat-modal {
    a {
      color: rgba(0, 0, 0, 0.85)
    }
  }
  &.company-chat-modal .ant-drawer-body {
      padding: 2rem 1rem 0 1rem;
      @media (max-width: 768px) {
        padding: 1rem;
    }
  }
  & .ant-drawer-body {
    padding: 4.5rem 4rem;
    @media (max-width: 768px) {
      padding: 2rem;
    }
  }
  & .ant-drawer-close {
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 2rem;
    top: 2rem;
    margin: 0 !important;
    padding: 0 !important;
    border-radius: 8px;
    background-color: #f2f6fa;
    opacity: 1;
    color: #627d95;
    &:hover {
      color: #627d95;
    }
  }
`;
const TitleSection = styled.div`
  display: flex;
`;
const Description = styled.p`
  font-size: 0.875rem;
  line-height: 1.43;
  color: #061c2e;
  margin-bottom: 2.5rem;
`;
const { confirm } = Modal;
const FormPopUp = ({
  visible,
  setVisible,
  children,
  title,
  confirmClose = false,
  large,
  description,
  backButton,
  onClose,
  customClass,
  ...rest
}: DrawerPopupProps) => {
  const [t] = useTranslation();
  const triggerDrawer = (
    e:
      | React.KeyboardEvent<HTMLDivElement>
      | React.MouseEvent<HTMLDivElement | HTMLButtonElement>,
  ) => {
    if (confirmClose) {
      confirm({
        title: `${t('FORM_CLOSE_CONFIRMATION')}`,
        okText: t('CONFIRM_TITLE'),
        okType: 'danger',
        cancelText: t('REJECT_TITLE'),
        cancelButtonProps: {
          type: 'default',
        },
        onOk() {
          setVisible(false);
          onClose && onClose(e);
        },
      });
    } else {
      setVisible(false);
      onClose && onClose(e);
    }
  };
  let className = large ? 'large' : '';
  if (customClass) {
    className += ` ${customClass}`;
  }
  return (
    <StyledDrawer
      title=""
      placement="right"
      closable={true}
      onClose={(e) => {
        triggerDrawer(e);
      }}
      closeIcon={<CloseIcon />}
      visible={visible}
      getContainer={false}
      className={className}
      {...rest}
    >
      <TitleSection>
        {backButton}
        <div>
          {title && (
            <FormTitle
              className={description ? 'no-margin' : ''}
              text={title}
            />
          )}
          {description && <Description>{description}</Description>}
        </div>
      </TitleSection>
      {children}
    </StyledDrawer>
  );
};

export default FormPopUp;
