export const getUrlString = (obj: { [key: string]: string | null }) => {
  const str = [];
  for (let p in obj)
    if (obj.hasOwnProperty(p) && obj[p]) {
      // @ts-ignore
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
    }
  return str.join('&');
};

export const INTERVIEW_ID_PARAM = 'interview_id';

export const STAGE_PARAM = 'stage';

export const STAGE_NAME_PARAM = 'stage_name';

export const SHOW_DATE_PROPOSE_PARAM = 'show_date_propose';

export const RESCHEDULE_PARAM = 'reschedule';

export const HISTORY_PARAM = 'history';

export const SHOW_FEEDBACK_PARAM = 'feedback';
export const SHOW_COMPANY_CHAT = 'company_chat';

export const SHOW_OFFER_PARAM = 'offer';

export const FEEDBACK_ID_PARAM = 'feedback_id';

export const FEEDBACK_INTERVIEW_ID_PARAM = 'feedback_interview_id';
