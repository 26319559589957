import { useTranslation } from 'react-i18next';
import { Form, Select, Col, Row, RadioChangeEvent, message, Card } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import { ReactComponent as InfoIcon } from '../../../icons/info-icon-rounded.svg';
import { ReactComponent as UserIcon } from '../../../icons/user-bordered-green.svg';
import RouterBlocker from '../../jobs/RouterBlocker';
import FormTitle from '../../../components/auth/FormTitle';
import useCompanyInfoForm from './hooks';
import { requiredRule } from '../../../utils/validationRules';
import FormItem from '../FormItem';
import SelectInput from '../../../UI/inputs/Select';
import Input from '../../../UI/inputs/Input';
import Textarea from '../../../UI/inputs/Textarea';
import Button from '../../../UI/buttons/Button';
import TabRadioButton from "../../../UI/radioButton/TabRadioButton";
import React, { useCallback, useEffect, useState } from "react";
import { InterviewStage, Language, Seniority, Skill, Specialization } from "../../../types";
import { getSeniorityList } from "../../../api/seniority";
import { getSpecializationList } from "../../../api/specialization";
import { createNewSkill, getSkillsList } from "../../../api/skills";
import { getLanguagesList } from "../../../api/languages";
import CheckboxInput from "../../../UI/inputs/CheckboxInput";
import { FormActions, FormSubtitle } from "../JobProcessForm/styles";
import { getInterviewStages } from "../../../api/interview-stages";
import { ReactComponent as DeleteBaseIcon } from '../../../icons/delete.svg';
import { ReactComponent as AddIcon } from '../../../icons/plus.svg';
import { createSimpleJob } from "../../../api/jobs";
import { JOBS, PRICING_TERMS, PRICING_TERMS_ABSOLUTE, PRIVACY_POLICY } from "../../../constants/routes";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../store/hooks";
import { openInNewTab } from "../../../utils/routes";
import { getToken } from "../../../utils/localStorage";
import { updateUser } from "../../../store/userSlice";
import { useAppDispatch } from '../../../store/hooks';
import { getUserInfo } from "../../../api/user";
import _ from "lodash";
import EmploymentTypeSelectFormSection from '../shared/employment-types/EmploymentTypeSelectFormSection';
import { mapEmploymentTypeFormToApi } from '../shared/employment-types/helpers';
import { FEE_TYPES } from '../../recruiter/AddCompanyRecruiterForm';
import { RecruiterManagementBoardModal } from '../../recruiter/RecruiterManagementBoard';
import useEmploymentTypes from '../../../hooks/useEmploymentTypes';
import { HIREPORT_DEFAULT_COMPENSATION_AMOUNT, HIREPORT_DEFAULT_COMPENSATION_TYPE } from '../../../constants/recruiters';

const jobTypes: { title: string | number; value: string | number }[] = [
  {
    title: 'Inhouse',
    value: 'inhouse',
  },
  {
    title: 'Consultancy',
    value: 'consultancy',
  },
];

const { Option } = Select;

interface Iprops {
  jobId: string,
  showBanner?: boolean
}

interface SimpleStage {
  stage: string;
  id?: string;
}

const linkStyle = {
  color: "#34ce72",
  fontSize: "0.875rem",
  lineHeight: "1.71",
  margin: "0.5rem 0",
  cursor: "pointer",
}

const JobSimpleCreationForm = ({ showBanner }: Iprops) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [jobType, setJobType] = useState('inhouse');
  const [seniorities, setSeniorities] = useState<Seniority[]>([]);
  const [specializations, setSpecializations] = useState<Specialization[]>([]);
  const [languages, setLanguages] = useState<Language[]>([]);
  // eslint-disable-next-line
  const [skills, setSkills] = useState<Skill[]>([]);
  const [interviewStages, setInterviewStages] = useState<InterviewStage[]>([]);
  const employmentTypes = useEmploymentTypes();
  // eslint-disable-next-line
  const [createLoading, setCreateLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [blockTransition, setBlockTransition] = useState(false);
  const [jobId, setJobId] = useState('');
  const user = useAppSelector((state) => state.user.user);
  const dispatch = useAppDispatch();
  // @ts-ignore
  const hasFlexFeeModelueEnabled = user?.company.flexible_fees_module_enabled;
  // @ts-ignore
  const hasTalentSupplierModuleEnabled = user?.company.talent_supplier_module_enabled;
  const [shouldOpenRecruiterSelectionModal, setShouldOpenRecruiterSelectionModal] = useState<boolean>(false);
  const [openModal, setOpenModel] = useState<boolean>(false);
  const [jobIdForModal, setJobIdForModal] = useState<string>('');

  const [availableSkills, setAvailableSkills] = useState<any>({
    key_skills: [],
    preferable_skills: [],
  });

  const [stages, setStages] = useState<SimpleStage[]>([
    {
      stage: '',
    }
  ]);

  const employmentTypeIds = employmentTypes.map(({ id }) => id);
  const firstEmploymentTypeId = employmentTypeIds.find((_, index) => index === 0);
  const getEmploymentTypeRef = (id?: string) => id ? { employment_type: { id } } : {}
  const initialFormValue = {
    recruiter_compensation_type: HIREPORT_DEFAULT_COMPENSATION_TYPE,
    recruiter_compensation_type_tooltip: HIREPORT_DEFAULT_COMPENSATION_AMOUNT,
    employment_types: [getEmploymentTypeRef(firstEmploymentTypeId)],
  }

  const handleAddMoreStages = useCallback(() => {
    setStages((state) => [
      ...state,
      {
        stage: '',
      },
    ]);
  }, []);

  const handleRemoveStage = (stageIndex: number) => {
    setStages((state) => state.filter((stage, i) => i !== stageIndex));
  };

  const handleChangeStageField = (
    stageIndex: number,
    field: string,
    value: any,
  ) => {
    let objectValue = value;
    if (field === 'stage') {
      objectValue = interviewStages.find((s) => s.id === value)?.id || '';
    }
    setStages((state) =>
      state.map((stage, i) => {
        if (i === stageIndex) {
          return {
            ...state[i],
            [field]: objectValue,
          };
        }
        return {
          ...state[i],
        };
      }),
    );
  };

  const {
    actions: {
      handleFieldsChange,
      onFinish,
      handleSelectCompany
    },
    data: {
      form,
      cities,
      companies,
      isAdmin,
      company
    },
  } = useCompanyInfoForm({ jobId });

  useEffect(() => {
    getSeniorityList().then((res) => setSeniorities(res.data));
    getSpecializationList().then((res) => setSpecializations(res.data));
    getSkillsList().then((res) => {
      setSkills(res.data);
      setAvailableSkills({
        key_skills: res.data,
        preferable_skills: res.data,
      });
    });
    getLanguagesList().then((res) => {
      setLanguages(res.data);
    });
    getInterviewStages().then((res) => {
      const intStages = res.data;
      setInterviewStages(intStages);
      setStages([
        {
          stage: intStages[0].id,
        },
      ]);
    });
    if (employmentTypes.length <= 0) return;
    const newValue = {
      ...form.getFieldsValue(),
      employment_type: {
        type: employmentTypes[0],
      },
    }
    form.setFieldsValue(newValue);
  }, [form, employmentTypes]);

  const onJobTypeChange = (e: RadioChangeEvent) => {
    setJobType(e.target.value);
  };

  const showCompanySelect = isAdmin && jobId === 'new';

  const collectFormValues = useCallback(() => {
    const values = form.getFieldsValue();

    const currentStages = stages.map((stage: SimpleStage) => stage.stage);
    return {
      company: {
        id: company?.id,
        name: values.name,
        city: values.city,
      },
      info: {
        job_type: values.job_type,
        specialization: values.specialization,
        required_seniority: values.required_seniority,
        title: values.title,
        description: values.job_description,
      },
      candidate_profile: {
        key_skills: values.key_skills,
        preferable_skills: values.preferable_skills,
        languages: values.languages,
        is_visa_available: !!values.is_visa_available
      },
      offer: {
        salary_from: values.salary_from,
        salary_max: values.salary_max,
        employment_type: mapEmploymentTypeFormToApi(values.employment_type),
        ...(jobId ? { job_id: jobId } : {}),
        ...(!user ? { account: { email: values.email } } : {}),
      },
      interview_process: { stages: currentStages },
    } as Record<string, any>;
  }, [form, stages, user, company, jobId]);

  const saveJobInfo = useCallback(
    async (isDraft: boolean) => {
      const draft = collectFormValues();
      await form
        .validateFields()
        .then(async (_values) => {
          const res = await createSimpleJob(draft, isDraft);
          setJobIdForModal(res.data.id);
          if (isDraft) {
            setJobId(res.data.id);
          }
          setBlockTransition(false);
          message.success(t('INFORMATION_SAVE_SUCCESS'), 5);
        }).then(async () => {
          if (user && (!user?.company || !user?.company.city)) {
            const token = getToken('access_token');
            token &&
              getUserInfo(token).then((res) => {
                dispatch(updateUser(res));
              });
          }
        })
        .catch((error) => {
          message.error(error.response.data.message, 5);
          throw error;
        });
    },
    [collectFormValues, form, t, dispatch, user],
  );

  const handleKeySkillChange = (value: any, object: any) => {
    if (!user) {
      return;
    }
    let emptyKey = null;
    for (const key in object) {
      if (_.isEmpty(object[key])) {
        emptyKey = key;
      }
    }

    if (emptyKey && value[emptyKey]) {
      // @ts-ignore
      const newSkill = value.splice(emptyKey, 1)[0];
      if (newSkill) {
        // @ts-ignore
        createNewSkill(newSkill).then((res) => {
          const newSkillId = res.data.id;
          const newSkillName = res.data.name;
          // @ts-ignore
          value.push(newSkillId);
          form.setFieldsValue({
            'key_skills': value
          });
          availableSkills.key_skills.push({ 'id': newSkillId, 'name': newSkillName });
          setAvailableSkills({
            key_skills: availableSkills.key_skills,
            preferable_skills: availableSkills.preferable_skills,
          });
        })
      }
    }
  };

  const handlePreferableSkillChange = (value: any, object: any) => {
    if (!user) {
      return;
    }
    let emptyKey = null;
    for (const key in object) {
      if (_.isEmpty(object[key])) {
        emptyKey = key;
      }
    }

    if (emptyKey && value[emptyKey]) {
      // @ts-ignore
      const newSkill = value.splice(emptyKey, 1)[0];
      if (newSkill) {
        // @ts-ignore
        createNewSkill(newSkill).then((res) => {
          const newSkillId = res.data.id;
          const newSkillName = res.data.name;
          // @ts-ignore
          value.push(newSkillId);
          form.setFieldsValue({
            'preferable_skills': value
          });
          availableSkills.preferable_skills.push({ 'id': newSkillId, 'name': newSkillName });
          setAvailableSkills({
            key_skills: availableSkills.key_skills,
            preferable_skills: availableSkills.preferable_skills,
          });
        })
      }
    }
  };

  const handleSave = useCallback(
    async (e: any, isDraft = false) => {
      e.preventDefault();
      e.stopPropagation();
      try {
        setSaveLoading(true);
        await saveJobInfo(isDraft);
        setBlockTransition(false);
        setSaveLoading(false);
      } catch (error) {
        setSaveLoading(false);
        throw error;
      }
    },
    [saveJobInfo],
  );

  const handleFinish = async (e: any) => {
    try {
      await form.submit();
      await form.validateFields().then(async (_res) => {
        await handleSave(e);
      });
    } catch (e) {
      return false;
    }
    if (shouldOpenRecruiterSelectionModal) {
      setOpenModel(true);
      return;
    }
    if (!user) {
      message.success(t('INVITE_SEND_NOTIFICATION'), 5);
      history.push('/');
    }
    history.push(JOBS + '/');
  };

  const handleDraft = async (e: any) => {
    form.submit();
    form.validateFields().then((_) => {
      handleSave(e, true).then((_) => {
        history.push(JOBS + '/');
      })
    });
  }

  const { Meta } = Card;

  return (
    <>
      <RouterBlocker isBlocked={blockTransition} />
      {showBanner && (
        <Card className={'simple-job-info-card'}>
          <Meta title={t('TALENT_POOL_BASED')} />
          <p></p>
          <p><strong>{t('SALARY_TITLE')}</strong> <UserIcon /><UserIcon /><UserIcon /><UserIcon /></p>
          <p><strong>{t('LANGUAGE')}</strong> <UserIcon /><UserIcon /><UserIcon /></p>
          <p><strong>{t('LOCATION_LABEL')}</strong> <UserIcon /><UserIcon /><UserIcon /></p>
        </Card>
      )}
      <Form
        scrollToFirstError={true}
        layout="vertical"
        name="simple-job-form"
        form={form}
        onFieldsChange={handleFieldsChange}
        onFinish={onFinish}
        initialValues={initialFormValue}
      >
        <FormTitle text={t('CREATE_NEW_JOB')} marginBottom="16px" />
        {!user && (
          <FormItem
            label={t('EMAIL_LABEL')}
            name="email"
            rules={requiredRule(t)}
          >
            <Input size="large" />
          </FormItem>
        )}
        <FormItem className={!user || !user.company || !user.company.city ? "no-margin" : "no-margin hidden"}>
          <Row gutter={16} justify="space-between">
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 12 }}
            >
              <FormItem
                label={t('COMPANY_TITLE')}
                name="name"
                rules={requiredRule(t)}
                tooltip={t('COMPANY_FORM_NAME_TOOLTIP') ? { title: t('COMPANY_FORM_NAME_TOOLTIP'), icon: <InfoIcon /> } : false}
              >
                {showCompanySelect ? (
                  <SelectInput
                    size="large"
                    suffixIcon={<CaretDownOutlined style={{ color: '#000' }} />}
                    onChange={(e) =>
                      handleSelectCompany(e ? e.toString() : '')
                    }
                  >
                    {companies.map((company) => (
                      <Option key={company.id} value={company.id}>
                        {company.name}
                      </Option>
                    ))}
                  </SelectInput>
                ) : user ? (
                  <Input
                    size="large"
                    disabled
                  />
                ) : (
                  <Input
                    size="large"
                  />
                )}
              </FormItem>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 12 }}
            >
              <FormItem
                label={t('LOCATION_CITY_LABEL')}
                name="city"
                rules={requiredRule(t)}
                tooltip={
                  t('COMPANY_FORM_LOCATION_CITY_TOOLTIP')
                    ? { title: t('COMPANY_FORM_LOCATION_CITY_TOOLTIP'), icon: <InfoIcon /> }
                    : false
                }
              >
                <SelectInput
                  size="large"
                  disabled={!!company?.city}
                  filterOption={true}
                  suffixIcon={<CaretDownOutlined style={{ color: '#000' }} />}
                >
                  {cities.map((city) => (
                    <Option key={city.id} value={city.id}>
                      {city.name}
                    </Option>
                  ))}
                </SelectInput>
              </FormItem>
            </Col>
          </Row>
        </FormItem>

        <FormItem
          label={t('JOB_TYPE')}
          name="job_type"
          rules={requiredRule(t)}
        >
          <TabRadioButton
            customClass={'simple-form-radio'}
            onChange={onJobTypeChange}
            value={jobType}
            data={jobTypes}
          />
        </FormItem>

        <FormItem className="no-margin">
          <Row gutter={16} justify="space-between">
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 24 }}
              lg={{ span: 12 }}
            >
              <FormItem
                label={t('DOMAIN_TITLE')}
                rules={requiredRule(t)}
                name="specialization"
                tooltip={t('JOB_INFO_FORM_SPECIALIZATION_TOOLTIP') ? { title: t('JOB_INFO_FORM_SPECIALIZATION_TOOLTIP'), icon: <InfoIcon /> } : false}
              >
                <SelectInput
                  size="large"
                  filterOption={true}
                  suffixIcon={<CaretDownOutlined style={{ color: '#000' }} />}
                >
                  {specializations.map((specialization) => (
                    <Option key={specialization.id} value={specialization.id}>
                      {specialization.name}
                    </Option>
                  ))}
                </SelectInput>
              </FormItem>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 24 }}
              lg={{ span: 12 }}
            >
              <FormItem
                label={t('REQUIRED_SENIORITY')}
                rules={requiredRule(t)}
                name="required_seniority"
                tooltip={t('JOB_INFO_FORM_REQUIRED_SENIORITY_TOOLTIP')
                  ? { title: t('JOB_INFO_FORM_REQUIRED_SENIORITY_TOOLTIP'), icon: <InfoIcon /> }
                  : false}
              >
                <SelectInput
                  size="large"
                  filterOption={true}
                  suffixIcon={<CaretDownOutlined style={{ color: '#000' }} />}
                >
                  {seniorities.map((seniority) => (
                    <Option key={seniority.id} value={seniority.id}>
                      {seniority.name}
                    </Option>
                  ))}
                </SelectInput>
              </FormItem>
            </Col>
          </Row>
        </FormItem>

        <FormItem
          label={t('JOB_TITLE')}
          name="title"
          rules={requiredRule(t)}
          tooltip={t('JOB_INFO_FORM_TITLE_TOOLTIP') ? { title: t('JOB_INFO_FORM_TITLE_TOOLTIP'), icon: <InfoIcon /> } : false}
        >
          <Input size="large" />
        </FormItem>

        <FormItem
          label={t('JOB_DESCRIPTION')}
          name="job_description"
          tooltip={t('JOB_INFO_FORM_DESCRIPTION_TOOLTIP') ? { title: t('JOB_INFO_FORM_DESCRIPTION_TOOLTIP'), icon: <InfoIcon /> } : false}
        >
          <Textarea value={''} autoSize={{ minRows: 4 }} />
        </FormItem>


        <FormItem
          label={t('KEY_SKILLS')}
          name="key_skills"
          rules={requiredRule(t)}
          tooltip={t('JOB_PROFILE_FORM_KEY_SKILLS_TOOLTIP')
            ? { title: t('JOB_PROFILE_FORM_KEY_SKILLS_TOOLTIP'), icon: <InfoIcon /> }
            : false}
        >
          <SelectInput
            size="large"
            mode={user ? "tags" : "multiple"}
            onChange={handleKeySkillChange}
            allowClear
            optionFilterProp="children"
            filterOption={(input, option) => {
              if (!option?.children) {
                return false;
              }
              return option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            }
            suffixIcon={<CaretDownOutlined style={{ color: '#000' }} />}
          >
            {availableSkills['key_skills'].map((skill: Skill) => (
              <Option key={skill.name} value={skill.id}>
                {skill.name}
              </Option>
            ))}
          </SelectInput>
        </FormItem>

        <FormItem
          label={t('PREFERABLE_SKILLS')}
          name="preferable_skills"
          tooltip={t('JOB_PROFILE_FORM_PREFERABLE_SKILLS_TOOLTIP')
            ? { title: t('JOB_PROFILE_FORM_PREFERABLE_SKILLS_TOOLTIP'), icon: <InfoIcon /> }
            : false}
        >
          <SelectInput
            size="large"
            mode={user ? "tags" : "multiple"}
            onChange={handlePreferableSkillChange}
            allowClear
            optionFilterProp="children"
            filterOption={(input, option) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            suffixIcon={<CaretDownOutlined style={{ color: '#000' }} />}
          >
            {availableSkills['preferable_skills'].map((skill: Skill) => (
              <Option key={skill.id} value={skill.id}>
                {skill.name}
              </Option>
            ))}
          </SelectInput>
        </FormItem>

        <FormItem className="no-margin">
          <Row gutter={16} justify="space-between">
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 24 }}
              lg={{ span: 12 }}
            >
              <FormItem
                label={t('LANGUAGES')}
                name="languages"
                rules={requiredRule(t)}
                tooltip={t('JOB_PROFILE_FORM_LANGUAGES_TOOLTIP')
                  ? { title: t('JOB_PROFILE_FORM_LANGUAGES_TOOLTIP'), icon: <InfoIcon /> }
                  : false}
              >
                <SelectInput
                  size="large"
                  mode="multiple"
                  allowClear
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  suffixIcon={<CaretDownOutlined style={{ color: '#000' }} />}
                >
                  {languages.map(language => {
                    return <Option key={language.code} value={language.code}>
                      {language.name}
                    </Option>
                  })}
                </SelectInput>
              </FormItem>
            </Col>
          </Row>
        </FormItem>

        <FormItem className="no-margin">
          <FormSubtitle>{"Employment basis & compensation"}</FormSubtitle>
        </FormItem>

        <EmploymentTypeSelectFormSection
          name={['employment_type']}
          getSelectedEmploymentType={() => form.getFieldValue(['employment_type', 'type', 'id'])}
          displayContext="job"
        />

        <FormItem name="is_visa_available" valuePropName="checked">
          <CheckboxInput>
            {t('VISA_SPONSORSHIP_AVAILABLE')}
          </CheckboxInput>
        </FormItem>

        {hasTalentSupplierModuleEnabled && <FormItem name="invite_suppliers">
          <FormSubtitle>{"Select talent suppliers"}</FormSubtitle>
          <CheckboxInput onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setShouldOpenRecruiterSelectionModal(e.target.checked);
          }}>
            {t('Select this checkbox if you want to select talent suppliers after job creation')}
          </CheckboxInput>
        </FormItem>}


        {hasFlexFeeModelueEnabled && <FormItem >
          <FormSubtitle>{"Fees"}</FormSubtitle>
          <p>Set your own custom fee for this specific job. This will be visible for all recruiters.</p>
          <Row gutter={16}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              xl={{ span: 8 }}
              xxl={{ span: 4 }}
            >
              <FormItem
                label="FEE STRUCTURE"
                name="recruiter_compensation_type"
                className="no-margin"
              >
                <SelectInput
                  size="large"
                  suffixIcon={<CaretDownOutlined style={{ color: '#000' }} />}
                >
                  {FEE_TYPES.map((fee, index) => (
                    <Select.Option value={fee.fee_value} key={index}>
                      {fee.fee_name}
                    </Select.Option>
                  ))}
                </SelectInput>
              </FormItem>
            </Col>

            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              xl={{ span: 16 }}
              xxl={{ span: 8 }}
            >
              <FormItem
                label="FEE DETAILS"
                name="recruiter_compensation_type_tooltip"
                className="no-margin"
              >
                <Input maxLength={70} size="large" />
              </FormItem>
            </Col>
          </Row>
        </FormItem>}


        <FormItem className="no-margin">
          <FormSubtitle>{t('STAGES_TITLE')}</FormSubtitle>
          <p>{t('STAGES_DESCRIPTION')}</p>
        </FormItem>

        {stages.map((stage, stageIndex) => {
          return (
            <FormItem key={stageIndex} className="no-margin">
              <FormItem className="no-margin">
                <Row gutter={16} justify="space-between">
                  <Col
                    xs={{ span: 12 }}
                    sm={{ span: 12 }}
                    md={{ span: 12 }}
                    lg={{ span: 12 }}
                  >
                    <FormItem
                      //name={'stage_'+stageIndex}
                      label={t('STAGE_TITLE')}
                      rules={requiredRule(t)}
                      tooltip={t('JOB_PROCESS_FORM_STAGE_TOOLTIP')
                        ? { title: t('JOB_PROCESS_FORM_STAGE_TOOLTIP'), icon: <InfoIcon /> }
                        : false}
                    >
                      <SelectInput
                        size="large"
                        filterOption={true}
                        defaultActiveFirstOption={true}
                        suffixIcon={
                          <CaretDownOutlined style={{ color: '#000' }} />
                        }
                        onChange={(e) =>
                          handleChangeStageField(stageIndex, 'stage', e)
                        }
                        value={stage.stage}
                      /*disabled={isProcessDisabled}*/
                      >
                        {interviewStages
                          .filter((interviewStage) => {
                            if (interviewStage.id !== stage.stage) {
                              return (
                                stages
                                  .map((s) => s.stage)
                                  .indexOf(interviewStage.id) === -1
                              );
                            }
                            return true;
                          })
                          .map((interviewStage) => (
                            <Option
                              key={interviewStage.id}
                              value={interviewStage.id}
                            >
                              {interviewStage.name}
                            </Option>
                          ))}
                      </SelectInput>
                    </FormItem>
                  </Col>
                  <Col
                    xs={{ span: 12 }}
                    sm={{ span: 12 }}
                    md={{ span: 12 }}
                    lg={{ span: 12 }}
                  >
                    {stages.length > 1 && (
                      <DeleteBaseIcon className={'stage-delete-icon'} onClick={() => handleRemoveStage(stageIndex)} />
                    )}
                  </Col>
                </Row>
              </FormItem>
            </FormItem>
          );
        })}

        <FormItem>
          <Button
            type="ghost"
            size="small"
            icon={<AddIcon />}
            onClick={handleAddMoreStages}
          >
            {t('ADD_STAGE')}
          </Button>
        </FormItem>

        {!user && (
          <FormItem name="privacy_agreement" valuePropName="checked" rules={[
            {
              validator: (_, value) =>
                value ? Promise.resolve() : Promise.reject(new Error(t('ACCEPT_AGREEMENT'))),
            },
          ]}>
            <CheckboxInput>
              {`I have read `}
              <Link target='_herf' style={linkStyle} to={PRIVACY_POLICY} >{'privacy agreement'}</Link>
              {` & `}
              <Link style={linkStyle} onClick={(e) => {
                e.preventDefault();
                openInNewTab(PRICING_TERMS_ABSOLUTE)
              }} to={PRICING_TERMS}>{'pricing page'}</Link>
              {` and agree to it.`}
            </CheckboxInput>
          </FormItem>
        )}
        <FormActions>
          {user && (
            <Button type="ghost" loading={saveLoading} onClick={handleDraft}>
              {t('SAVE_DRAFT_TITLE')}
            </Button>
          )}
          <Button
            type="primary"
            loading={createLoading}
            onClick={handleFinish}
          >
            {t('CREATE_JOB')}
          </Button>
        </FormActions>
      </Form>
      <RecruiterManagementBoardModal
        fromJobCreation={true}
        isVisible={openModal}
        setIsVisible={setShouldOpenRecruiterSelectionModal}
        selectedJob={jobIdForModal}
      />
    </>
  )
};

export default JobSimpleCreationForm;
