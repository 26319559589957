import styled from 'styled-components';
import { Modal } from 'antd';

import Button from '../../../UI/buttons/Button';
import CheckboxInput from '../../../UI/inputs/CheckboxInput';

export const RuleSection = styled.div`
  width: 100%;
  padding: 1.5rem 2rem 2rem;
  background: #f2f6fa;
  border-radius: 0.5rem;
  margin: 1rem 0;
`;

export const RuleItem = styled.p`
  font-size: 0.75rem;
  line-height: 1.33;
  color: #061c2e;
  margin: 0;
`;

export const StyledCheckbox = styled(CheckboxInput)`
  margin-bottom: 1.5rem;
  & span {
    font-size: 0.875rem;
    line-height: 1.71;
    color: #061c2e;
  }
`;

export const BackButton = styled.span`
  min-width: 3rem;
  min-height: 3rem;
  max-width: 3rem;
  max-height: 3rem;
  border-radius: 8px;
  background-color: #f2f6fa;
  margin-right: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  & svg {
    color: rgba(6, 28, 46, 0.2);
  }
`;

export const Title = styled.h3`
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.2;
  // color: #627d95;
  margin-bottom: 1rem;
  margin-top: 2.5rem;
`;

export const EditButton = styled(Button)`
  margin: 1rem 0 0;
`;

export const ConfirmModal = styled(Modal)`
  & .ant-modal-content {
    padding: 32px;
  }

  & .ant-modal-header {
    border-bottom: none;
    padding: 0;
    margin-bottom: 16px;
    & div {
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: left;
      color: #061c2e;
    }
  }

  & .ant-modal-body {
    padding: 0;
  }
  
  & .ant-modal-footer {
    padding: 0;
    display: flex;
    border-top: none;
  }
`;

export const Content = styled.div`
  padding-bottom: 32px;
`;

export const TextWrap = styled.div`
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const TextBold = styled.p`
  font-size: 14px;
  font-weight: bold;
  line-height: 1.71;
  color: #061c2e;
  margin-bottom: 0;
`;

export const Text = styled(TextBold)`
  font-weight: normal;
`;
