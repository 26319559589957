import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';

import TimelineItem from './TimelineItem';
import { List } from './styles';
import { INTRODUCED, OFFERED, ON_HOLD } from '../../../../../constants/statuses';
import { InterviewStage } from '../../../../../types';

interface Iprops {
  selectedStageId: string,
  setSelectedStageid: (id: string) => void,
  currentStageId: string,
  handleSwitchStage: (
    nextStageName: string,
  ) => void,
  stages: InterviewStage[],
  blockOffSyncActions?: boolean,
}

const Timeline = ({
  selectedStageId,
  setSelectedStageid,
  currentStageId,
  handleSwitchStage,
  stages,
  blockOffSyncActions,
}: Iprops) => {
  const [t] = useTranslation();

  return (
    <Tooltip title={t('TIMELINE_TOOLTIP')}>
      <List>
        <TimelineItem
          name={ON_HOLD}
          label={t('ON_HOLD')}
          id={ON_HOLD}
          isSelected={selectedStageId === ON_HOLD}
          setSelectedStageid={setSelectedStageid}
          isCurrent={currentStageId === ON_HOLD}
          handleSwitchStage={handleSwitchStage}
          blockOffSyncActions={blockOffSyncActions}
        />

        <TimelineItem
          name={INTRODUCED}
          label={t('INTRODUCED')}
          id={INTRODUCED}
          disabled
          setSelectedStageid={setSelectedStageid}
          handleSwitchStage={handleSwitchStage}
        />

        {!!stages.length && stages.map(({ id, name, order }) => {
          const isCurrent = currentStageId === id;
          const currentOrder = stages.find((stage) => stage.id === currentStageId) || { order: 0};
          const disabled = currentOrder?.order > order || currentStageId === OFFERED;

          return (
            <TimelineItem
              key={id}
              id={id}
              name={name}
              label={name}
              isSelected={selectedStageId === id}
              setSelectedStageid={setSelectedStageid}
              isCurrent={isCurrent}
              disabled={disabled}
              handleSwitchStage={handleSwitchStage}
              blockOffSyncActions={blockOffSyncActions}
            />
          )
        })}

        <TimelineItem
          id={OFFERED}
          name={OFFERED}
          label={t('OFFER')}
          isSelected={selectedStageId === OFFERED}
          setSelectedStageid={setSelectedStageid}
          isCurrent={currentStageId === OFFERED}
          handleSwitchStage={handleSwitchStage}
          blockOffSyncActions={blockOffSyncActions}
        />
      </List>
    </Tooltip>
  );
};

export default Timeline;
