import styled from 'styled-components';

export const colors: { [key: string]: string } = {
  success: '#29ae5e',
  warn: '#f58316',
  error: '#dd4848',
  default: '#061c2e',
};

// the && below is to force a high enough specificity that the cursor takes effect
export const Wrapper = styled.div`
  && {
    margin-top: 0.5rem;
    min-height: 6.25rem;
    border-radius: 0.5rem;
    border: 1px solid #dae5ec;
    cursor: pointer;
    background: #ffffff;
  }
`;

export const CardBody = styled.div`
  padding: 1rem 1rem;
`;

export const DateText = styled.span`
  &.danger {
    color: #eb5757;
  }
`;

export const NameWrapper = styled.div`
  margin-bottom: 0.25rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const Name = styled.h5`
  font-size: 0.875rem;
  line-height: 1.71;
  text-align: left;
  color: #061c2e;
  max-width: max-content;
  margin: 0;
`;

export const Tags = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
`;

export const Title = styled.p`
  font-size: 0.75rem;
  line-height: 1.33;
  text-align: left;
  color: #627d95;
  margin-bottom: 0.25rem;
  display: flex;
  align-items: center;
`;

export const Interview = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

/* dont use it for now now, BB-1905
export const Badge = styled.div`
  width: 1rem;
  height: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #eb5757;
  font-size: 0.75rem;
  font-weight: 700;
  color: #ffffff;
  margin-left: auto;
`;
*/

export const Drag = styled.div`
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  background-color: #eff9f3;
  font-size: 0.625rem;
  font-weight: 700;
  line-height: 1.6;
  letter-spacing: 0.8px;
  color: #061c2e;
  max-width: max-content;
  text-transform: uppercase;
  height: 100%;
`;

export const GHWarning = styled.span`
  display: block;
  margin-left: 4px;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  background-color: #eb5757;
  font-size: 0.625rem;
  font-weight: 700;
  line-height: 1.6;
  letter-spacing: 0.8px;
  color: #fff;
  max-width: max-content;
  text-transform: uppercase;
  height: 100%;
  white-space: nowrap;
`;

export const Prompt = styled.p<{ color?: string }>`
  border-top: 1px solid rgb(218, 229, 236);
  padding: 7px;
  font-size: 0.625rem;
  line-height: 1.2;
  text-align: center;
  color: ${(props) => colors[props.color || 'default']};
  margin: 0;
`;

export const Company = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.25rem;
`;

export const CompanyLogo = styled.div`
  width: 1rem;
  height: 1rem;
  min-width: 1rem;
  min-height: 1rem;
  border: 1px solid #aebeca;
  border-radius: 50%;
  overflow: hidden;
  & img {
    object-fit: contain;
    object-position: center;
    display: block;
    width: 100%;
    height: 100%;
  }
`;

export const CompanyName = styled.p`
  font-size: 0.75rem;
  line-height: 1.33;
  color: #627d95;
  margin: 0 0 0 0.5rem;
`;
