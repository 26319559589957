import React, { ReactNode, useEffect, useState } from 'react';
import NameAvatar from '../../UI/NameAvatar';
import format from 'date-fns/format';
import styled from 'styled-components';
import Subtitle from '../../UI/typography/Subtitle';

import { getRandomColor } from '../../constants/palette';
import { useTranslation } from 'react-i18next';
import { getFeedback, sendFeedback } from '../../api/interviews';
import { message } from 'antd';
import FeedbackForm from '../form/FeedbackForm';
import { InterviewRoom } from '../../types';
import FormTitle from '../auth/FormTitle';
import { useHistory } from 'react-router-dom';
import { ErrorMessage } from '../auth/LoginForm';
import { COMPLETED } from '../../constants/statuses';

interface GuestFormProps {
  interview: InterviewRoom;
  review?: string | null;
}
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3rem;
`;
const HeaderItem = styled.div`
  display: flex;
  align-items: center;
  max-width: max-content;
  &:last-child {
    max-width: 50%;
  }
`;
const NameWrapper = styled.div<ReactNode & { align?: string }>`
  margin-left: 0.5rem;
  &.row {
    display: flex;
    align-items: center;
  }
`;
const Name = styled.p`
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.43;
  color: #061c2e;
  margin-bottom: 0;
`;
const Role = styled.p`
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.43;
  color: #627d95;
  margin: 0;
`;
const GuestFeedbackForm = ({ interview, review }: GuestFormProps) => {
  const [completedFeedback, setCompletedFeedback] = useState(null);
  const [expired, setExpired] = useState(false);
  const [loading, setLoading] = useState(false);
  const [t] = useTranslation();
  const history = useHistory();
  const mainInterview = interview.current_stage?.tasks?.find(
    (task) => task.is_planning_task,
  );
  const clientFeedbackCompleted = interview.current_stage.feedbacks.find(
    (feedback) =>
      review
        ? feedback.feedback_type === 'recruiter_feedback'
        : feedback.feedback_type === 'client_feedback' && feedback.is_completed,
  );
  const completed = !!clientFeedbackCompleted;
  useEffect(() => {
    const params = new URLSearchParams(history.location.search);
    const stage = params.get('stage');
    const isTaskCompleted = !interview.current_stage?.tasks
      // .filter((task) => !task.is_feedback_task)
      .find((task) => task.status === COMPLETED);

    if (
      stage &&
      interview.current_stage.stage_name.toLowerCase() !== stage.toLowerCase()
    ) {
      setExpired(true);
    } else if (isTaskCompleted) {
      setExpired(true);
    }
  }, [interview, history]);
  useEffect(() => {
    if (completed) {
      const feedback_id = clientFeedbackCompleted?.id;

      getFeedback(interview.id, feedback_id as string).then((res) =>
        setCompletedFeedback(res.data),
      );
    }
  }, [completed, clientFeedbackCompleted, interview]);
  const onFinish = (data: any) => {
    setLoading(true)
    sendFeedback(interview.id, data).then(() => {
      message.success(t('FEEDBACK_SENT'));
      setLoading(false)
    }).catch(() => setLoading(false));
  };
  return (
    <div>
      <FormTitle text={interview.current_stage.stage_name} />
      <Header>
        <HeaderItem>
          <NameAvatar
            name={interview.candidate.name}
            image={undefined}
            color={getRandomColor()}
          />
          <NameWrapper>
            <Name>{interview.candidate.name}</Name>
            <Role>{interview.candidate.current_role}</Role>
          </NameWrapper>
        </HeaderItem>
        <HeaderItem>
          {!expired && (
            <NameWrapper>
              <Name>
                {mainInterview && (
                  <>
                    {format(new Date(mainInterview.time_from), 'MMMM d, yyyy')},{' '}
                    {format(new Date(mainInterview.time_from), 'H:mm')} -{' '}
                    {format(new Date(mainInterview.time_to), 'H:mm')}
                  </>
                )}
              </Name>
              <Role>{mainInterview && mainInterview?.place}</Role>
            </NameWrapper>
          )}
        </HeaderItem>
      </Header>
      {expired ? (
        <ErrorMessage message={t('NOT_ACTUAL')} type="error" />
      ) : (
        <>
          <Subtitle title={t('FILL_IN')} />

          <FeedbackForm
            onComplete={onFinish}
            completed={completed}
            isClient={true}
            hideMessage={true}
            data={completedFeedback}
            loading={loading}
          />
        </>
      )}
    </div>
  );
};

export default GuestFeedbackForm;
