import { useRef, useState } from 'react';
import Button from '../../../../../UI/buttons/Button';
import { ReactComponent as ExportIcon } from '../../../../../icons/export-icon.svg';
import { useTranslation } from 'react-i18next';
import { getEvents, exportCalendarEvent } from '../../../../../api/events';
import styled from 'styled-components';

interface ExportProps {
  interviewId: string;
}

const StyledLink = styled.a`
  display: none;
`;

const ExportDate = ({ interviewId }: ExportProps) => {
  const [url, setUrl] = useState('');
  const [loading, setLoading] = useState(false);

  const downloadRef = useRef<HTMLAnchorElement>(null);
  const [t] = useTranslation();

  const handleExport = () => {
    setLoading(true);

    getEvents('', '', '', interviewId).then((res) => {
      const eventId = res?.data[0]?.id;

      if (eventId) {
        exportCalendarEvent(eventId).then((res) => {
          const blob = new Blob([res.data]); // Step 3
          const fileDownloadUrl = URL.createObjectURL(blob);
          setUrl(fileDownloadUrl);
          downloadRef && downloadRef?.current?.click();
          setUrl('');
        });
      }

      setLoading(false);
    });
  };

  return (
    <>
      <Button
        type="ghost"
        size="small"
        icon={<ExportIcon />}
        onClick={handleExport}
        disabled={loading}
      >
        {t('EXPORT_TEXT')}
      </Button>
      <StyledLink download="event.ics" href={url} ref={downloadRef} />
    </>
  );
};

export default ExportDate;
