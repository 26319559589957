import React from 'react';
import styled from 'styled-components';
interface TitleTextProps {
  title: string;
  text: string[];
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const Title = styled.h4`
  font-size: 0.625rem;
  font-weight: 700;
  line-height: 1.6;
  letter-spacing: 0.8px;
  color: #061c2e;
  text-transform: uppercase;
  margin: 0;
`;
const Text = styled.p`
  font-size: .75rem;
  line-height: 1.33;
  color: #061c2e;
  margin: 0.5rem 0 0;
`;
const TitleText = ({ title, text }: TitleTextProps) => {
  return (
    <Wrapper>
      <Title>{title}</Title>
      {text.map((el) => (
        <Text>{el}</Text>
      ))}
    </Wrapper>
  );
};

export default TitleText;
