import axios from 'axios';

const api_url = process.env.REACT_APP_API_URL;

export const getSupportMessageCounter = async () => {
  return await axios.get(
    `${api_url}conversations/users/admin/unread-messages/count/`,
  );
};

export const getInterviewMessageCounters = async (interviewId: string) => {
  return await axios.get(
    `${api_url}conversations/interviews/${interviewId}/unread-messages/count/`,
  )
  .then((res) => res)
  .catch((e) => {
    return e.response;
  });
};

export const getUnreadMessagesCount = async () => {
  return await axios.get(`${api_url}conversations/unread-messages/count/`);
};

export const getAdminContacts = async (query: string = '') => {
  return await axios.get(`${api_url}conversations/users/` + query);
};

export const getClientContacts = async (query: string = '') => {
  return await axios.get(`${api_url}conversations/recruiters/` + query);
};

export const getRecruiterContacts = async (query: string = '') => {
  return await axios.get(`${api_url}conversations/client-companies/` + query);
};

export const getUserRoom = async (userId: string, query: string = '') => {
  return await axios.get(
    `${api_url}conversations/users/${userId}/messages/` + query,
  )
  .then((res) => res)
  .catch((e) => {
    return e.response;
  });
};

export const getSupportRoom = async (query: string = '') => {
  return await axios.get(
    `${api_url}conversations/users/admin/messages/` + query,
  );
};

export const getInterviewRoom = async (
  interviewId: string,
  query: string = '',
) => {
  return await axios.get(
    `${api_url}conversations/interviews/${interviewId}/messages/` + query,
  )
  .then((res) => res)
  .catch((e) => {
    return e.response;
  });
};

export const getRecruiterInterviews = async (recruiterId: string, query: string = '') => {
  return await axios.get(
    `${api_url}conversations/recruiters/${recruiterId}/interviews/${query}`,
  )
  .then((res) => res)
  .catch((e) => {
    return e.response;
  });
};

export const getClientInterviews = async (companyId: string, query: string = '') => {
  return await axios.get(
    `${api_url}conversations/client-companies/${companyId}/interviews/${query}`,
  )
  .then((res) => res)
  .catch((e) => {
    return e.response;
  });
};

export const sendUserMessage = async (userId: string, text: string) => {
  return await axios.post(`${api_url}conversations/users/${userId}/messages/`, {
    text,
  });
};

export const sendSupportMessage = async (text: string) => {
  return await axios.post(`${api_url}conversations/users/admin/messages/`, {
    text,
  });
};

export const sendInterviewMessage = async (
  interviewId: string,
  text: string,
) => {
  return await axios.post(
    `${api_url}conversations/interviews/${interviewId}/messages/`,
    {
      text,
    },
  );
};
