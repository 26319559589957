import { useEffect, useState } from 'react';
import styled from 'styled-components';
import SettingsMenu from '../../components/settings/SettingsMenu';
import { AtsStatus, connectJob, disconnectAts, getAtsStatus } from '../../api/integrations';
import ConnectAtsForm from '../../components/settings/atsSettings/ConnectAtsForm';
import AtsSettingsForm from '../../components/settings/atsSettings/AtsSettingsForm';
import AwaitingInitialSyncNotice from '../../components/settings/atsSettings/AwaitingInitialSyncNotice';
import { useHistory } from 'react-router-dom';
import { ATS_SETTINGS } from '../../constants/routes';

const PageWrapper = styled.section`
  background: #f2f6fa;
  width: 100%;
  display: flex;
  min-height: 100vh;
  flex-flow: row-reverse;
`;
const FormWrapper = styled.div`
  background: #fff;
  width: 61%;
  padding: 4.5rem 4rem;
  display: flex;
  flex-direction: column;
  position: relative;

  @media (max-width: 1200px) {
    width: 70%;
  }
  @media (max-width: 768px) {
    width: 90%;
    padding: 3rem;
  }
`;

const AtsSettings = () => {
  const [atsStatus, setAtsStatus] = useState<AtsStatus | null>(null);
  const history = useHistory();
  const checkAtsStatus = () => {
    getAtsStatus().then(setAtsStatus)
  }

  useEffect(checkAtsStatus, []);
  return (
    <PageWrapper>
      <FormWrapper>
        <SettingsMenu menu="ats" />
        {atsStatus && (
          (!atsStatus || atsStatus?.state === "NOT_CONNECTED") ?
            <ConnectAtsForm onConnectionComplete={checkAtsStatus} /> : (atsStatus?.state === "AWAITING_INITIAL_SYNC") ?
              <AwaitingInitialSyncNotice onRefresh={checkAtsStatus} /> :
              <AtsSettingsForm
                atsStatus={atsStatus}
                onCheckAtsStatus={() => { checkAtsStatus() }}
                onDisconnectAts={async () => {
                  await disconnectAts();
                  checkAtsStatus();
                }}
                onConnectJob={async (localJobId, remoteJobId) => {
                await connectJob(localJobId, remoteJobId);
                history.push(`${ATS_SETTINGS}/jobs/${localJobId}`);
              }} />
        )}
      </FormWrapper>
    </PageWrapper >
  );
};

export default AtsSettings;
