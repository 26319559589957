import React, { useEffect, useState } from 'react';
import Filter from '../../UI/buttons/Filter';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const PaymentHeaderFilter = () => {
  const history = useHistory();
  const query = new URLSearchParams(history.location.search);
  const status = query.get('filter');
  const [filter, setFilter] = useState(status ? status : '');
  const [t] = useTranslation();
  const filterList = [
    {
      title: t('ALL_PAYMENTS'),
      value: '',
      translation:'ALL_PAYMENTS_TOOLTIP'
    },
    {
      title: t('UNPAID'),
      value: 'unpaid',
      translation:'UNPAID_TOOLTIP'
    },
  ];
  useEffect(() => {
    const query = new URLSearchParams(history.location.search);
    if (!filter) {
      query.delete('filter');
      history.push({ search: query.toString() });
    } else {
      query.set('filter', filter);
      history.push({ search: query.toString() });
    }
  }, [filter, history]);
  const handleChange = (value: string) => {
    setFilter(value);
  };
  return <Filter onClick={handleChange} list={filterList} active={filter} />;
};

export default PaymentHeaderFilter;
