import React, { ReactNode } from 'react';
import styled from 'styled-components';
interface TextProps {
  children: ReactNode
}
const Text = styled.p`
  font-size: .875rem;
  line-height: 1.43;
  color: #061c2e;
  margin-bottom: 0;
`;
const InfoText = ({children}:TextProps) => {
  return (
    <Text>
      {children}
    </Text>
  );
};

export default InfoText;
