import axios from 'axios';

export const getRecruiterMetrics = async () => {
  return await axios.get(
    process.env.REACT_APP_API_URL + 'dashboards/recruiter/metrics/',
  );
};

export const getRecruiterDashboardNewJobs = async (query?:string) => {

  return await axios.get(
    process.env.REACT_APP_API_URL + 'dashboards/recruiter/new-jobs/'+ (query ? query : ''),
  );
};

export const getRecruiterDashboardFavoriteJobs = async (query?:string) => {
  return await axios.get(
    process.env.REACT_APP_API_URL + 'dashboards/recruiter/favorite-jobs/' + (query ? query : ''),
  );
};

export const archiveDashboardJob = async (jobId: string) => {
  return await axios.post(
    process.env.REACT_APP_API_URL +
      `dashboards/recruiter/jobs/${jobId}/archive/`,
  )
  .then((res) => res)
  .catch((e) => {
    return e.response;
  });
};

export const getRecruiterDashboardRequiredActions = async (
  query: string = '',
) => {
  return await axios.get(
    process.env.REACT_APP_API_URL +
      'dashboards/recruiter/actions/required/' +
      query,
  );
};

export const getRecruiterDashboardUpcomingActions = async (
  query: string = '',
) => {
  return await axios.get(
    process.env.REACT_APP_API_URL +
      'dashboards/recruiter/actions/upcoming/' +
      query,
  );
};

export const getClientMetrics = async () => {
  return await axios.get(
    process.env.REACT_APP_API_URL + 'dashboards/client/metrics/',
  );
};

export const getClientDashboardCandidates = async (query: string = '') => {
  return await axios.get(
    process.env.REACT_APP_API_URL + 'dashboards/client/candidates/' + query,
  );
};

export const getClientDashboardRequiredActions = async (query: string = '') => {
  return await axios.get(
    process.env.REACT_APP_API_URL +
      'dashboards/client/actions/required/' +
      query,
  );
};

export const getClientDashboardUpcomingActions = async (query: string = '') => {
  return await axios.get(
    process.env.REACT_APP_API_URL +
      'dashboards/client/actions/upcoming/' +
      query,
  );
};
export const getAdminMetrics = async () => {
  return await axios.get(`${process.env.REACT_APP_API_URL}dashboards/admin/metrics/`)
}
