import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Col, Form, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import RadioButtonTab from '../../UI/buttons/RadioButtonTab';
import FormItem from '../form/FormItem';

interface FeedbackProps {
  data: any;
}

const labelCol = { sm: { span: 24 }, md: { span: 10 } };
const answerCol = { sm: { span: 24 }, md: { span: 14 } };

const Wrapper = styled.div`
  & .ant-radio-wrapper-disabled {
    opacity: 1;
  }
`;
const Item = styled(Row)`
  margin-bottom: 2.5rem;
`;
const Label = styled.p`
  font-size: 0.875rem;
  line-height: 1.71;
  color: #061c2e;
  width: 100%;
  margin-bottom: 0.5rem;
  &.uppercase {
    font-size: 0.625rem;
    font-weight: 700;
    line-height: 1.6;
    letter-spacing: 0.8px;
    color: #061c2e;
    text-transform: uppercase;
  }
`;
const Comment = styled.p`
  font-size: 0.875rem;
  line-height: 1.71;
  color: #061c2e;
  margin: 0;
  white-space: pre-wrap;
`;

interface IDecisionType {
  type?: string,
}

const DecisionType = styled.div<IDecisionType>`
  padding: 1.25rem 1rem;
  width: 100%;
  border-radius: 0.5rem;
  background-color: #f2f6fa;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.43;
  color: #061c2e;
  text-transform: capitalize;
  border: 1px ${props => props.type === 'positive' ? 'rgba(52, 206, 114, 0.5)' : 'rgba(235, 87, 87, 0.5)'} solid; 
`;

const FeedbackResult = ({ data }: FeedbackProps) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (!Object.keys(data).length) {
      form.setFieldsValue(data);
    } else {
      form.setFieldsValue(data);
    }
  }, [data, form]);

  const [t] = useTranslation();

  const feedbacks: { name: string; value: string }[] = [
    {
      name: t('NEGATIVE'),
      value: 'negative',
    },
    {
      name: t('NEUTRAL'),
      value: 'neutral',
    },
    {
      name: t('POSITIVE'),
      value: 'positive',
    },
  ];

  return (
    <Form form={form} scrollToFirstError={true}>
      <Wrapper>
        <Item>
          <Col {...labelCol}>
            <Label>{t('MATCH_WITH_COMPANY')}</Label>
          </Col>
          <Col {...answerCol}>
            <FormItem
              name="match_with_company"
              initialValue={data.match_with_company}
              className="mb-1"
            >
              <RadioButtonTab items={feedbacks} disabled={true} />
            </FormItem>
            <Comment>{data.match_with_company_comment}</Comment>
          </Col>
        </Item>
        <Item>
          <Col {...labelCol}>
            <Label>{t('MATCH_WITH_ROLE')}</Label>
          </Col>
          <Col {...answerCol}>
            <FormItem
              name="match_with_role"
              initialValue={data.match_with_role}
              className="mb-1"
            >
              <RadioButtonTab items={feedbacks} disabled={true} />
            </FormItem>
            <Comment>{data.match_with_role_comment}</Comment>
          </Col>
        </Item>
        <Item>
          <Col {...labelCol}>
            <Label>{t('TEAM_LABEL')}</Label>
          </Col>
          <Col {...answerCol}>
            <FormItem
              name="match_with_team"
              initialValue={data.match_with_team}
              className="mb-1"
            >
              <RadioButtonTab items={feedbacks} disabled={true} />
            </FormItem>
            {data.match_with_team_comment && (
              <Comment>{data.match_with_team_comment}</Comment>
            )}
          </Col>
        </Item>
        <Item>
          <Col {...labelCol}>
            <Label>{t('CULTURE')}</Label>
          </Col>
          <Col {...answerCol}>
            <FormItem
              name="match_with_culture"
              initialValue={data.match_with_culture}
              className="mb-1"
            >
              <RadioButtonTab items={feedbacks} disabled={true} />
            </FormItem>
            {data.match_with_culture_comment && (
              <Comment>{data.match_with_culture_comment}</Comment>
            )}
          </Col>
        </Item>
        <Item>
          <Col {...labelCol}>
            <Label>{t('CAREER-OPPORTUNITIES')}</Label>
          </Col>
          <Col {...answerCol}>
            <FormItem
              name="match_with_career"
              initialValue={data.match_with_career}
              className="mb-1"
            >
              <RadioButtonTab items={feedbacks} disabled={true} />
            </FormItem>
            {data.match_with_career_comment && (
              <Comment>{data.match_with_career_comment}</Comment>
            )}
          </Col>
        </Item>
        <Item>
          <Col {...labelCol}>
            <Label>{t('TECH_USED')}</Label>
          </Col>
          <Col {...answerCol}>
            <FormItem
              name="match_with_tech"
              initialValue={data.match_with_tech}
              className="mb-1"
            >
              <RadioButtonTab items={feedbacks} disabled={true} />
            </FormItem>
            {data.match_with_tech_comment && (
              <Comment>{data.match_with_tech_comment}</Comment>
            )}
          </Col>
        </Item>
        <Item>
          <Col {...labelCol}>
            <Label>{t('INTERVIEWERS')}</Label>
          </Col>
          <Col {...answerCol}>
            <FormItem
              name="match_with_interviewers"
              initialValue={data.match_with_interviewers}
              className="mb-1"
            >
              <RadioButtonTab items={feedbacks} disabled={true} />
            </FormItem>
            {data.match_with_interviewers_comment && (
              <Comment>{data.match_with_interviewers_comment}</Comment>
            )}
          </Col>
        </Item>

        <Item>
          <Col sm={{ span: 24 }}>
            <Label className="uppercase">{t('GENERAL_COMMENTS')}</Label>
          </Col>
          <Col sm={{ span: 24 }}>
            {data.comments && <Comment>{data.comments}</Comment>}
          </Col>
        </Item>

        <Item align="middle">
          <Col {...labelCol}>
            <Label>{t('OVERALL_DECISION')}</Label>
          </Col>
          <Col {...answerCol}>
            <DecisionType type={data?.decision}>{t(`${data?.decision?.toUpperCase()}_DECISION`)}</DecisionType>
          </Col>
        </Item>
      </Wrapper>
    </Form>
  );
};

export default FeedbackResult;
