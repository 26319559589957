import React, { useState, useEffect } from 'react';
import { Col, Row, Select } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import FormItem from '../FormItem';
import { maxLengthRule, requiredRule } from '../../../utils/validationRules';
import { useTranslation } from 'react-i18next';
import { getCityList } from '../../../api/regions';
import RadioButtonTab from '../../../UI/buttons/RadioButtonTab';
import SelectInput from '../../../UI/inputs/Select';
import Input from '../../../UI/inputs/Input';
import Textarea from "../../../UI/inputs/Textarea";
import {getRecruiterInfo} from "../../../api/recruiter";
import {Specialization} from "../../../types";
import {getSpecializationList} from "../../../api/specialization";
import { ReactComponent as InfoIcon } from '../../../icons/info-icon-rounded.svg';

interface PersonalProps {
  form?: any | undefined;
  setLocations?: (data: any) => void;
  setPhone: React.Dispatch<React.SetStateAction<string>>;
  setAddress?: (data: string) => void;
  phone: string;
  disableFields?: boolean;
  showCompany?: boolean;
  city?: string;
  isOnboarding?: boolean;
}

const { Option } = Select;

const PersonalInfoRecruiter = ({
  form,
  setLocations,
  setAddress,
  disableFields,
  setPhone,
  phone,
  showCompany,
  city,
  isOnboarding
}: PersonalProps) => {
  const [cities, setCities] = useState<{ id: string; name: string }[]>([]);
  const [specializations, setSpecializations] = useState<Specialization[]>([]);
  const { t } = useTranslation();

    useEffect(() => {
        getCityList().then((res) => setCities(res.data));
        getSpecializationList().then((res) => setSpecializations(res.data));
    }, []);
  useEffect(() => {
    cities.length &&
      form.setFieldsValue({
        city: city,
      });
  }, [cities, form, city]);
    useEffect(() => {
        getRecruiterInfo().then((res) => {
            form.setFieldsValue({
                description: res.data.description,
                about_company: res.data.about_company,
                preferable_specializations: res.data.preferable_specializations.map((specialization: any) => specialization.id),
            });
        })

    }, [form]);
  return (
    <>
      <FormItem
        label={t('NAME_LABEL')}
        name="name"
        rules={[...requiredRule(t), ...maxLengthRule(128, t)]}
      >
        <Input size="large" />
      </FormItem>

      <FormItem className="no-margin">
        <Row>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 11 }}
            md={{ span: 24 }}
            lg={{ span: 11 }}
          >
            <FormItem
              label={t('TITLE_TEXT')}
              name="title"
              rules={[...requiredRule(t), ...maxLengthRule(255, t)]}
            >
              <Input size="large" />
            </FormItem>
          </Col>
          {showCompany && (
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 11, offset: 2 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 11, offset: 2 }}
            >
              <FormItem label={t('COMPANY_TITLE')} name="company">
                <Input size="large" disabled />
              </FormItem>
            </Col>
          )}
        </Row>
      </FormItem>
      <FormItem name="recruiter_type" rules={requiredRule(t)}>
        <RadioButtonTab
          size={'large'}
          disabled={true}
          items={[
            { name: t('FREELANCE_TITLE'), value: 'freelance' },
            { name: t('INTERIM_TITLE'), value: 'interim' },
            { name: t('AGENCY_TITLE'), value: 'agency' },
          ]}
        />
      </FormItem>
      <FormItem
        label={t('LOCATION_CITY_LABEL')}
        name="city"
        rules={requiredRule(t)}
      >
        <SelectInput
          size="large"
          filterOption={true}
          suffixIcon={<CaretDownOutlined style={{ color: '#000' }} />}
        >
          {cities.map((city) => (
            <Option key={city.id} value={city.id}>
              {city.name}
            </Option>
          ))}
        </SelectInput>
      </FormItem>
      <FormItem className="no-margin">
        <Row>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 11 }}
            md={{ span: 24 }}
            lg={{ span: 11 }}
          >
            <FormItem
              label={t('EMAIL_LABEL')}
              name="email"
              rules={[
                {
                  type: 'email',
                  message: t('INVALID_EMAIL_ERROR'),
                  required: true,
                },
              ]}
            >
              <Input size="large" disabled type="email" />
            </FormItem>
          </Col>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 11, offset: 2 }}
            md={{ span: 24, offset: 0 }}
            lg={{ span: 11, offset: 2 }}
          >
            <FormItem label={t('PHONE_TEXT')}>
              <Input
                size="large"
                onChange={(e) => {
                  if (e.target.value){
                    const regex = /^[+]?\d+$/;
                    if (regex.test(e.target.value)) {
                      setPhone(e.target.value);
                    }
                  }else{
                    setPhone(e.target.value);
                  }
                }}
                value={phone}
              />
            </FormItem>
          </Col>
        </Row>
      </FormItem>
        {!isOnboarding && (
        <FormItem
            label={`${t('ABOUT_YOURSELF')}`}
            name="description"
        >
            <Textarea autoSize={{ minRows: 3 }} />
        </FormItem>
        )}
        {!isOnboarding && (
        <FormItem
            label={`${t('ABOUT_YOUR_COMPANY')}`}
            name="about_company"
        >
            <Textarea autoSize={{ minRows: 3 }} />
        </FormItem>
        )}
        {!isOnboarding && (
        <FormItem
            label={t('YOUR_SPECIALIZATIONS')}
            name="preferable_specializations"
            tooltip={t('JOB_INFO_FORM_SPECIALIZATION_TOOLTIP') ? { title: t('JOB_INFO_FORM_SPECIALIZATION_TOOLTIP'), icon: <InfoIcon /> } : false}
        >
            <SelectInput
                size="large"
                mode={"multiple"}
                filterOption={true}
                suffixIcon={<CaretDownOutlined style={{ color: '#000' }} />}
            >
                {specializations.map((specialization) => (
                    <Option key={specialization.id} value={specialization.id}>
                        {specialization.name}
                    </Option>
                ))}
            </SelectInput>
        </FormItem>
        )}
    </>
  );
};

export default PersonalInfoRecruiter;
