import React from 'react';
import {  RadioProps } from 'antd';
import styled from 'styled-components';
import RadioInput from '../inputs/RadioInput';

const StyledRadio = styled(RadioInput)`
  width: 100%;
  display: flex;
  padding: 1.25rem 1rem;
  border-radius: 8px;
  border: solid 1px #dae5ec;
  margin-bottom: 0.5rem;
  & .ant-radio {
    height: 100%;
  }
  &.ant-radio-wrapper-checked {
    background: #f2f6fa;
  }
  white-space: initial;
`;
const RadioButtonDark = ({ value, children,...rest }: RadioProps) => {
  return <StyledRadio value={value} {...rest}>{children}</StyledRadio>;
};

export default RadioButtonDark;
