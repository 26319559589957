import styled from 'styled-components';
import { Modal } from 'antd';

export const StyledModal = styled(Modal)`
  & .ant-modal-content {
    padding: 32px;
  }

  & .ant-modal-header {
    border-bottom: none;
    padding: 0;
    margin-bottom: 16px;
    & div {
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: left;
      color: #061c2e;
    }
  }

  & .ant-modal-body {
    padding: 0;

    & p {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
      text-align: left;
      color: #061c2e;
    }
  }

  & .ant-modal-footer {
    padding: 0;
    display: flex;
    border-top: none;
  }
`;
