import styled from 'styled-components';

export const SwitchButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: transparent;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  border: none;
`;
