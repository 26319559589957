import styled from 'styled-components';

import { ReactComponent as DeleteBaseIcon } from '../../../icons/delete.svg';

export const ColAction = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100%;
`;

export const DeleteIcon = styled(DeleteBaseIcon)`
  color: #eb5757;
  cursor: pointer;
  margin-left: 1rem;
`;

export const FormActions = styled.div`
  padding: 1.5rem 2rem;
  border-radius: 8px;
  background-color: #f2f6fa;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FormSubtitle = styled.h2`
  font-family: Lato;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #061c2e;
  margin: 1.5rem 0 0.5rem 0;
`;

export const CareerPathBranchLabel = styled.h5`
  font-family: Lato;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.96px;
  text-align: left;
  color: #061c2e;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
`;

export const FormSubtitleHint = styled.p`
  margin-bottom: 1.5rem;
`;