import React from 'react';
import styled from 'styled-components';
import { TabsProps, Tabs } from 'antd';

const StyledTab = styled(Tabs)`
  &.mt-5 {
    margin-top: 2.5rem;
  }
  &.ant-tabs-top > .ant-tabs-nav,
  &.ant-tabs-bottom > .ant-tabs-nav,
  &.ant-tabs-top > div > .ant-tabs-nav,
  &.ant-tabs-bottom > div > .ant-tabs-nav {
    margin: 0 0 1.5rem;
  }
  & .ant-tabs-tab {
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 1.33;
    letter-spacing: 0.96px;
    color: #061c2e;
    & + .ant-tabs-tab {
      margin-left: 1.5rem;
    }
    &.ant-tabs-tab-active {
      & .ant-tabs-tab-btn {
        font-size: 0.75rem;
        font-weight: 700;
        line-height: 1.33;
        letter-spacing: 0.96px;
        color: #061c2e;
      }
    }
    &.ant-tabs-tab-disabled {
      color: #aebeca;
    }
  }
  & .ant-tabs-nav {
    & .ant-tabs-ink-bar {
      height: 0.25rem;
      background-color: #34ce72;
    }
  }
`;

const Tab = ({ children, ...rest }: TabsProps) => {
  return (
    <StyledTab {...rest} defaultActiveKey="1">
      {children}
    </StyledTab>
  );
};

export default Tab;
