import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Progress } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Job } from '../../types';
import { JOBS } from '../../constants/routes';
import { ReactComponent as CheckedIcon } from '../../icons/checked.svg';
import {useAppSelector} from "../../store/hooks";
import { getPreviouslySelectedRecruiters } from "../../api/recruiter";


interface JobProgressProps {
  job: Job | undefined;
  step: string;
}

const JobProgressContainer = styled.div`
  position: fixed;
  top: 4.5rem;
  left: calc(39% - 15rem);
  padding: 16px 32px 32px;
  box-shadow: 0 25px 60px 0 rgba(6, 28, 46, 0.1),
    0 12px 14px 0 rgba(6, 28, 46, 0.05);
  background-color: #ffffff;
  width: 17.5rem;
  border-radius: 8px;
`;

const ProgressCircleContainer = styled.div`
  position: absolute;
  top: -20px;
  right: 28px;

  .ant-progress-circle {
    background: #fff;
    border-radius: 50%;
  }
`;

const JobProgressTitle = styled.h3`
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.96px;
  color: #061c2e;
  text-transform: uppercase;
  border-bottom: 1px solid #dae5ec;
  margin: 0;
  padding: 1rem 0;
`;

const JobProgressSteps = styled.ul`
  list-style: none;
  margin: 1rem 0 0 0;
`;

interface JobProgressStepProps {
  readonly isActive: boolean;
}

const JobProgressStep = styled.li<JobProgressStepProps>`
  padding: 1.5rem 1rem;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #061c2e;
  border-bottom: ${(props) => (props.isActive ? 'none' : '1px solid #dae5ec')};
  border-radius: ${(props) => (props.isActive ? '8px' : '0px')};
  background-color: ${(props) => (props.isActive ? '#f2f6fa' : 'transparent')};
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  &:last-child {
    border-bottom: none;
  }
`;

export const STEPS = [
  {
    title: 'JOB_INFO_TITLE',
    value: 'info',
    draft: 'info',
  },
  {
    title: 'CANDIDATE_PROFILE_TITLE',
    value: 'profile',
    draft: 'candidate_profile',
  },
  {
    title: 'WHAT_IS_OFFERED',
    value: 'offer',
    draft: 'offer',
  },
  {
    title: 'TEAM_INFO_TITLE',
    value: 'team',
    draft: 'team_info',
  },
  {
    title: 'INTERVIEW_PROCESS',
    value: 'process',
    draft: 'interview_process',
  },
];

const JobProgress = (props: JobProgressProps) => {
  const { step, job } = props;
  const { t } = useTranslation();
  const history = useHistory();
  let [wasSelectRecruiterUsed, setWasSelectRecruiterUsed] = useState(false);
  const [currentStepIndex, setCurrentStepIndex] = useState(
    STEPS.map((s) => s.value).indexOf(step),
  );
  const [percentage, setPercentage] = useState(
    (currentStepIndex / STEPS.length) * 100,
  );
  let hasFlexFeeModelueEnabled = false;
  let hasSelectRecs = false;
  // @ts-ignore
  const user = useAppSelector((state) => state.user.user);
  // @ts-ignore
  if (user.role === 'client') {
      // @ts-ignore
      hasFlexFeeModelueEnabled = user?.company.flexible_fees_module_enabled;
      // @ts-ignore
      hasSelectRecs = user?.company?.talent_supplier_module_enabled;
  }
  
    useEffect(() => {
        if (job) {
          let companyName = job.company.name;
            getPreviouslySelectedRecruiters(`${companyName}-${job.id}`).then((res: any) => {
                if (res.data.results.length > 0) setWasSelectRecruiterUsed(true);
            })
            }
    })

  useEffect(() => {
    if (job && job.sections) {

      let percentages = Object.keys(job.sections).map((key: any) => {
        return (job.sections as any)[key].progress;
      });
      let percentage = Math.round(
        percentages.reduce((a, b) => parseInt(a, 10) + parseInt(b, 10), 0) /
          percentages.length,
      );
      if (percentage > 0) {
        setPercentage(percentage);
      }
    }
    setCurrentStepIndex(STEPS.map((s) => s.value).indexOf(step));
  }, [job, step]);

  const handleClickStep = (step: any) => {
    if (job?.id) {
      history.push(JOBS + '/edit/' + job.id + '/' + step.value);
    }
  };

  return (
    <JobProgressContainer>
      <ProgressCircleContainer>
        <Progress
          type="circle"
          strokeColor="#34ce72"
          trailColor="#dae5ec"
          width={72}
          percent={percentage}
        />
      </ProgressCircleContainer>
      <JobProgressTitle>{t('CREATE_JOB')}</JobProgressTitle>
      <JobProgressSteps>
        <JobProgressStep
          isActive={step === 'company_info'}
          onClick={() => handleClickStep({ value: 'company_info'})}
          key={'COMPANY_INFO_TITLE'}
        >
          {t('COMPANY_INFO_TITLE')}
          <Progress
            type="circle"
            strokeColor="#34ce72"
            trailColor="#dae5ec"
            width={32}
            percent={100}
            format={(percent) =>
              (percent && percent < 100) || !percent ? (
                ''
              ) : (
                <CheckedIcon
                  style={{
                    color: 'rgb(52, 206, 114)',
                    width: 20,
                    height: 20,
                  }}
                />
              )
            }
          />
        </JobProgressStep>

        {STEPS.map((s, index) => {
          return (
            <JobProgressStep
              isActive={s.value === step}
              onClick={() => handleClickStep(s)}
              key={s.title}
            >
              {t(s.title)}
              <Progress
                type="circle"
                strokeColor="#34ce72"
                trailColor="#dae5ec"
                width={32}
                percent={
                  job?.sections ? (job?.sections as any)[s.draft].progress : 0
                }
                format={(percent, successPercent) =>
                  (percent && percent < 100) || !percent ? (
                    ''
                  ) : (
                    <CheckedIcon
                      style={{
                        color: 'rgb(52, 206, 114)',
                        width: 20,
                        height: 20,
                      }}
                    />
                  )
                }
              />
            </JobProgressStep>
          );
        })}
        {(hasFlexFeeModelueEnabled || hasSelectRecs) && <JobProgressStep
          isActive={step === 'psl'}
          onClick={() => handleClickStep({ value: 'psl'})}
          key={'PSL'}
        >
          {"Recruiter specification"}
          <Progress
            type="circle"
            strokeColor="#34ce72"
            trailColor="#dae5ec"
            width={32}
            percent={wasSelectRecruiterUsed ? 100 : 0}
            format={() =>
              !wasSelectRecruiterUsed ? (
                ''
              ) : (
                <CheckedIcon
                  style={{
                    color: 'rgb(52, 206, 114)',
                    width: 20,
                    height: 20,
                  }}
                />
              )
            }
          />
        </JobProgressStep> }

      </JobProgressSteps>
    </JobProgressContainer>
  );
};

export default JobProgress;
