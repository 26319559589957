import React from 'react';
import { InputNumber as AntInputNumber, InputNumberProps } from 'antd';
import styled from 'styled-components';

const StyledInputNumber = styled(AntInputNumber)`
  &.ant-input-number {
    width: 100%;
    border-radius: 0.5rem;
    border: 1px solid #aebeca;
    &:disabled {
      color: #627d95;
      border: solid 1px #aebeca;
      background-color: #f2f6fa;
    }
    &::placeholder {
      color: #aebeca;
    }
  }
  &.ant-input-number-lg {
    padding: 0.275rem 1rem;
    height: auto;
    line-height: 1.43;
    font-size: 0.875rem;
    color: #061c2e;
    &.search-input {
      padding: 0.813rem 2rem;
    }
  }
  &.ant-input-number > .ant-input-number-handler-wrap {
    display: none;
  }
&:not(.ant-input-number-affix-wrapper) .ant-input-number-lg:hover,
  &.ant-input-number:hover {
    border: solid 1px #22bf61;
  }
  &:not(.ant-input-number-affix-wrapper) .ant-input-number-lg:focus,
  &.ant-input-number:focus {
    box-shadow: 0 0 8px 0 #34ce72;
    border-color: transparent;
  }
  
`;
const InputNumber = ({ ...rest }: InputNumberProps) => {
  return <StyledInputNumber {...rest} />;
};

export default InputNumber;
