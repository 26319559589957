import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { ReactComponent as ThumbUpIcon } from '../../../icons/thumb-up.svg';
import { ReactComponent as ThumbDownIcon } from '../../../icons/thumb-down.svg';
import { JobFeedback } from '../../../types';
import { useAppSelector } from '../../../store/hooks';
import { RECRUITER_ROLE } from '../../../constants/roles';
import TooltipPrompt from '../../../UI/tootlip';
import { useTranslation } from 'react-i18next';

interface SectionItemProps {
  title?: ReactNode;
  bg_image?: ReactNode;
  children: ReactNode;
  dark?: boolean;
  jobFeedbacks?: JobFeedback[];
  trackSection?: (feedback: number, tag_name?: string) => void;
  name?: string;
}

const SectionWrapper = styled.div`
  padding: 1rem 2rem;
  background-color: #ffffff;
  border-radius: 0.5rem;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom right;
  height: 100%;
  position: relative;
  &.dark {
    background-color: #324759;
    & h4 {
      color: #ffffff;
      border-bottom: 1px solid #627d95;
    }
  }
  & .section-bg {
    position: absolute;
    bottom: 0;
    right: 0;
    color: #f2f6fa;
  }
  & .section {
    padding: 0 0;
  }
`;
const SectionTitle = styled.h4`
  padding: 1rem 0;
  border-bottom: 1px solid #dae5ec;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.5rem;
  letter-spacing: 0.96px;
  color: #061c2e;
  margin: 0;
  text-transform: uppercase;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & span {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    color: #627d95;
  }
  &.right {
    & span {
      right: 4.5rem;
    }
  }
  & svg {
    cursor: pointer;
  }
`;
const SectionBody = styled.div`
  padding: 1.5rem 0;
  & p {
    white-space: pre-line;
  }
`;
const SectionFeedback = styled.div`
  display: flex;
`;

const feedbacksList =  [
  'about_company',
  'job_description',
  'skills',
  'what_you_get',
  'career_path',
  'company_facts',
  'company_culture',
  'team',
  'will_be_doing',
  'tools_used',
  'career_path',
  'application_process'
];

const SectionItem = ({
  title,
  bg_image,
  children,
  dark = false,
  jobFeedbacks,
  trackSection,
  name,
}: SectionItemProps) => {
  const { user } = useAppSelector((state) => state.user);
  const [t] = useTranslation();
  const isRecruiter = user?.role === RECRUITER_ROLE;

  const showThumbs =
    name &&
    feedbacksList.indexOf(
      name,
    ) !== -1 &&
    !jobFeedbacks?.find((el) => el.tag_name === name);

  return (
    <SectionWrapper className={dark ? 'dark section' : 'section'}>
      {title && (
        <SectionTitle className={isRecruiter && showThumbs ? 'right' : ''}>
          {title}
          {showThumbs && isRecruiter && name && (
            <SectionFeedback>
              <TooltipPrompt title={t('THUMB_UP_TOOLTIP')}>
                <ThumbUpIcon
                  style={{ marginRight: 8 }}
                  onClick={() => trackSection && trackSection(0, name)}
                />
              </TooltipPrompt>
              <TooltipPrompt title={t('THUMB_DOWN_TOOLTIP')}>
                <ThumbDownIcon
                  onClick={() => trackSection && trackSection(1, name)}
                />
              </TooltipPrompt>
            </SectionFeedback>
          )}
        </SectionTitle>
      )}
      <SectionBody>{children}</SectionBody>
      {bg_image && bg_image}
    </SectionWrapper>
  );
};

export default SectionItem;
