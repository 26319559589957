import React from 'react';
import { Select, SelectProps } from 'antd';
import styled from 'styled-components';
export interface SelectInputProps extends SelectProps<any> { }
const StyledSelect = styled(Select)`
  outline: none;
  &.ant-select-arrow {
    right: 1rem;
  }
  & .ant-select-selection-overflow {
    overflow: hidden;
  }
  &.ant-select {
    &.ant-select {
      &:not(.ant-select-customize-input) {
        & .ant-select-selector {
          padding: 4px 1rem;
          height: auto;
          border-radius: 0.5rem;
          border: solid 1px #aebeca;
          background-color: #ffffff;
          width: 100%;
        }
      }

      &.ant-select-multiple {
        & .ant-select-selector {
          padding: 5px 1rem;
        }
      }

      &.ant-select-focused,
      &.ant-select-auto-complete.ant-select-focused:not(.ant-select-disabled) {
        & .ant-select-selector {
          border-color: transparent;
          box-shadow: 0 0 8px 0 #34ce72;
        }
      }
    }
  }
  &.ant-select-multiple {
    & .ant-select-selection-item {
      line-height: 1.5rem;
      font-size: 0.75rem;
      color: #4b6276;
      padding: 0.25rem 0.5rem;
      border-radius: 0.25rem;
      background-color: #f2f6fa;
    }
  }
  &.ant-select-disabled {
    &.ant-select:not(.ant-select-customize-input) {
      & .ant-select-selector {
        color: #627d95;
        border: solid 1px #aebeca;
        background-color: #f2f6fa;
        cursor: not-allowed;
      }
    }
  }
  &.custom-select {
    & .ant-select-selection-placeholder {
      color: #aebeca;
    }
    & .ant-select-arrow {
      right: 0.5rem;
      width: 1.5rem;
      height: 1.5rem;
      margin-top: -0.75rem;
    }
    &.ant-select-single.ant-select {
      &:not(.ant-select-customize-input):not(.ant-select-customize-input) {
        & .ant-select-selection-search-input {
          height: 3rem;
        }
      }
    }
  }
  & .ant-select-selection-placeholder {
    color: #061c2e;
  }
`;
const SelectInput = ({ ...rest }: SelectInputProps) => {
  return <StyledSelect {...rest}></StyledSelect>;
};

export default SelectInput;
