import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import FormItem from './FormItem';
import { Col, Form, Row } from 'antd';
import DateTimePicker from '../../UI/inputs/DateTimePicker';
import Button from '../../UI/buttons/Button';
import { correctDateRule, requiredRule } from '../../utils/validationRules';
import Input from '../../UI/inputs/Input';
import Textarea from '../../UI/inputs/Textarea';
//import { useHistory } from 'react-router-dom';

interface ProposedProps {
  addDates: (dates: {}[]) => Promise<any>;
  interviewComment?: string; 
}

const StyledForm = styled(Form)`
  padding: 0 3px;
  & .ant-form-item {
    &.ant-form-item-has-error {
      & .ant-input {
        border-color: #ff4d4f;
      }
    }
  }
`;

const Text = styled.p`
  font-size: 0.75rem;
  line-height: 1.33;
  color: #061c2e;
  margin-bottom: 1.5rem;
`;

const Wrapper = styled.div`
  margin-top: 0.5rem;
`;

const TextareaTitle = styled.p`
  margin-bottom: 8px;
  font-family: Lato;
  font-size: 0.625rem;
  font-weight: bold;
  line-height: 1.6;
  letter-spacing: 0.8px;
  color: #061c2e;
  text-transform: uppercase;
`;

const TextareaSubtitle = styled.p`
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 8px;
  color: #061c2e;
  font-family: Lato;
`;

const AddProposeDate = ({ addDates, interviewComment }: ProposedProps) => {
  const [t] = useTranslation();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  //const history = useHistory();

  //const urlParams = new URLSearchParams(history.location.search);

  //const reschedule = urlParams.get('reschedule');
  const reschedule = true;

  const [fields, setFields] = useState<{ [key: string]: any }[]>(
    reschedule
      ? [{ date_0: null, place_0: null }]
      : [
          {
            date_0: null,
            place_0: null,
          },
          {
            date_1: null,
            place_1: null,
          },
          {
            date_2: null,
            place_2: null,
          },
        ],
  );

  const onFinish = (values: any) => {
    setLoading(true);
    let proposed_dates = [];
    for (let i = 0; i < 3; i++) {
      if (values[`date_${i}`] && values[`place_${i}`]) {
        // for now, save the same comment for every date
        proposed_dates.push({
          time_from: values[`date_${i}`].format(),
          time_to: values[`date_${i}`].add(1, 'hours').format(),
          place: values[`place_${i}`],
          comment: values.comment,
        });
      }
    }

    addDates(proposed_dates)
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };

  const handleDate = (e: any, idx: number) => {
    setFields((prevState) =>
      prevState.map((el, index) => {
        if (idx === index) {
          el[`date_${index}`] = e ? e : null;
        }
        return el;
      }),
    );
  };

  const handlePlace = (e: any, idx: number) => {
    setFields((prevState) =>
      prevState.map((el, index) => {
        if (idx === index) {
          el[`place_${index}`] = e.target.value ? e.target.value : null;
        }
        return el;
      }),
    );
  };

  return (
    <Wrapper>
      {!reschedule && <Text>{t('PROPOSE_DATE_TEXT')}</Text>}
      <StyledForm
        layout="vertical"
        name="add-dates"
        onFinish={onFinish}
        scrollToFirstError={true}
        form={form}
        initialValues={{
          comment: interviewComment
        }}
      >
        {fields.map((field, index) => {
          let required = false;
          if (index === 0) {
            required = true;
          } else if (field[`date_${index}`] || field[`place_${index}`]) {
            required = true;
          }
          return (
            <Row gutter={16} key={`data-${index}`}>
              <Col
                xs={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 8 }}
                style={{ padding: '0 10px' }}
              >
                <FormItem
                  label={`${t('DATE')} ${reschedule ? '' : (index + 1)}`}
                  name={`date_${index}`}
                  rules={
                    required ? [...requiredRule(t), ...correctDateRule(t)] : []
                  }
                  className={!required ? 'hide-error' : ''}
                >
                  <DateTimePicker onChange={(e: any) => handleDate(e, index)} />
                </FormItem>
              </Col>
              <Col
                xs={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 16 }}
                style={{ padding: '0 10px' }}
              >
                <FormItem
                  label={t('PLACE')}
                  name={`place_${index}`}
                  rules={required ? requiredRule(t) : []}
                  className={!required ? 'hide-error' : ''}
                >
                  <Input
                    size="large"
                    onChange={(e: any) => handlePlace(e, index)}
                  />
                </FormItem>
              </Col>
            </Row>
          );
        })}

        <TextareaTitle>{t('PROPOSE_DATE_COMMENT')}</TextareaTitle>
        <TextareaSubtitle>{t('PROPOSE_DATE_COMMENT_SUBHEADER')}</TextareaSubtitle>

        <Row gutter={16}>
          <Col span={24}>
            <FormItem
              name="comment"
            >
              <Textarea autoSize={{ minRows: 2, maxRows: 2 }} />
            </FormItem>
          </Col>
        </Row>

        <FormItem>
          <Button
            style={{ marginTop: '1rem' }}
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            {t(reschedule ? 'UPDATE_DATE_TEXT' : 'PROPOSE_DATE')}
          </Button>
        </FormItem>
      </StyledForm>
    </Wrapper>
  );
};

export default AddProposeDate;
