import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import FormTitle from '../components/auth/FormTitle';
import LoginForm from '../components/auth/LoginForm';
import LoginWith from '../components/auth/LoginWith';
import RequestPopUp from '../components/auth/RequestPopUp';
import Banner from '../components/auth/Banner';
import { useHistory } from 'react-router-dom';
import { REGISTER } from '../constants/routes';
import { ReactComponent as MobileLogo } from '../icons/nav-logo.svg';

const PageWrapper = styled.section`
  background: #0c1e2d;
  width: 100%;
  display: flex;
  min-height: 100vh;
  overflow: hidden;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const FormWrapper = styled.div`
  background: #fff;
  width: 32.5%;
  padding: 4.5rem 4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  @media (max-width: 1200px) {
    width: 50%;
  }
  @media (max-width: 768px) {
    width: 100%;
    padding: 2.5rem 1.5rem;
    min-height: 100vh;
  }
`;
const FormHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  @media (max-width: 768px) {
    display: none;
  }
`;
const FormContent = styled.div``;
const TitleAction = styled.div`
  font-size: 0.875rem;
  line-height: 1.71;
  text-align: right;
  color: #627d95;
  position: relative;
  span {
    color: #34ce72;
    margin-left: 0.5rem;
    cursor: pointer;
  }
`;
const PageHeaderMobile = styled.div`
display: none;
  @media (max-width: 768px) {
display: flex;
padding: 1.25rem;
background: #ffffff;
align-items: center;
width: 100%;
justify-content: space-between;
box-shadow: 0 10px 10px -5px #00000013;
z-index: 999;
}
`;
const PageTitleMobile = styled.h1`
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.2;
  color: #061c2e;
  margin: 0;
`;
const StyledLogo = styled(MobileLogo)`
width: 2.5rem;
`;
const Login = () => {
  const [signUp, setSignUp] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();
  useEffect(() => {
    if (history.location.pathname.includes(REGISTER)) {
      setSignUp(true);
    } else {
      setSignUp(false);
    }
  }, [history.location.pathname]);
  return (
    <PageWrapper>
      <PageHeaderMobile>
        <PageTitleMobile>{t('LOGIN_TITLE')}</PageTitleMobile>
        <StyledLogo></StyledLogo>
      </PageHeaderMobile>
      <Banner size={signUp ? 'small' : ''} title={t('LOGIN_BANNER_TEXT')}/>
      <FormWrapper>
        <FormContent>
          <FormHeader>
            <FormTitle text={t('LOGIN_TITLE')} className={'no-margin'}/>
            <TitleAction>
              {t('NO_ACCOUNT_TEXT')}?
              <span onClick={() => {
                history.push(REGISTER);
              }}>{t('SIGN_UP_TITLE')}</span>
            </TitleAction>
          </FormHeader>
          <LoginForm/>
        </FormContent>
        <LoginWith/>
      </FormWrapper>
      <RequestPopUp visible={signUp} setVisible={setSignUp}/>
    </PageWrapper>
  );
};

export default Login;
