import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PRIVACY_POLICY } from '../../constants/routes';

const Footer = styled.div`
  position: absolute;
  bottom: 3.5rem;
  left: 4rem;
  width: 100%;
  z-index: 99;
`;
const FooterLink = styled(Link)`
  opacity: 0.5;
  font-size: 0.875rem;
  line-height: 1.71;
  color: #ffffff;
  &:hover {
    opacity: 1;
    color: #ffffff;
    transition:all ease .3s ;
  }
`;

const BannerFooter = () => {
  const [t] = useTranslation();
  return (
    <Footer>
      <FooterLink to={PRIVACY_POLICY}>{t('PRIVACY_POLICY')}</FooterLink>
    </Footer>
  );
};

export default BannerFooter;
