import axios from 'axios';

const api_url = process.env.REACT_APP_API_URL;

export const getInterviewStages = async () => {
  return await axios.get(`${api_url}interview-stages/`);
};

export const getInterviewStagesTaskTypes = async () => {
  return await axios.get(`${api_url}stage-task-types/`);
};

export const getJobsInterviewStages = async () => {
  return await axios.get(`${api_url}interviews/jobs/stages/`);
};

export const createJobStage = async (jobId: string, stage: any) => {
  return await axios.post(
    `${api_url}jobs/${jobId}/details/interview-process/stages/`,
    {
      ...stage,
    },
  )
  .then((res) => res)
  .catch((e) => {
    return e.response;
  });
};

export const updateJobStage = async (jobId: string, stage: any) => {
  return await axios.patch(
    `${api_url}jobs/${jobId}/details/interview-process/stages/${stage.id}/`,
    {
      ...stage,
    },
  )
  .then((res) => res)
  .catch((e) => {
    return e.response;
  });
};

export const getInterviewStatuses = async () => {
  return await axios.get(`${api_url}interviews/statuses/`);
};
