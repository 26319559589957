import axios from 'axios';
import { History } from 'history';

const api_url = process.env.REACT_APP_API_URL;

export const getClientList = async (query?: string) => {
  return await axios.get(`${api_url}clients/${query ? query : ''}`);
};

export const getClient = async (id: string, history?: History) => {
  return await axios.get(`${api_url}clients/${id}/`)
  .then((res) => res)
  .catch((e) => {
    if (e?.response?.data?.error_code === 'resource_not_found' && history) history.push('/not-found');
    return e.response;
  });
};

export const getCompanyActivityFeed = async (companyId: string) => {
  return await axios.get(
      process.env.REACT_APP_API_URL + `client-companies/${companyId}/activity-feeds/`,
  )
      .then((res) => res)
      .catch((e) => {
        return false;
      });
};
export const sendInvitationWithCreate = async (values: any) => {
  const clientData = {
    name: values.name,
    title: values.title,
    phone: values.phone,
    email: values.email,
    department: values.department,
    company: values.company,
  };
  const companyData = {
    description: values.description,
    difference_description: values.difference_description,
    address: values.address,
    notable_clients: values.notable_clients,
    number_of_employees: values.number_of_employees,
    average_age: values.average_age,
    male_percentage: values.male_percentage,
    dress_code: values.dress_code,
    city: values.city,
    industry: values.industry,
    region: values.region,
    name: values.company_name,
  };

  try {
    const company = values.company
      ? await editClientCompany(companyData, values.company)
      : await createClientCompany(companyData);
    const invitation = await sendClientInvitation({
      ...clientData,
      company: company.data.id,
    });
    if (values.office_locations) {
      await createClientAddressList(company.data.id, values.office_locations);
    }
    return invitation;
  } catch (e) {
    return e.response;
  }
};

export const editClient = async (values: any) => {
  const clientData = {
    name: values.name,
    title: values.title,
    phone: values.phone,
    department: values.department,
  };
  const companyData = {
    description: values.description,
    difference_description: values.difference_description,
    address: values.address,
    notable_clients: values.notable_clients,
    number_of_employees: values.number_of_employees,
    average_age: values.average_age,
    male_percentage: values.male_percentage,
    dress_code: values.dress_code,
    city: values.city,
    industry: values.industry,
    region: values.region,
    name: values.company_name,
  };
  try {
    const company = await editClientCompany(companyData, values.company_id);
    const invitation = await editClientInfo(clientData, values.client_id);
    if (values.office_locations) {
      await createClientAddressList(company.data.id, values.office_locations);
    }
    return {company:company.data,...invitation.data};
  } catch (e) {
    return e.response;
  }
};

export const sendClientInvitation = async (info: any) => {
  return await axios.post(`${api_url}clients/invite/`, info);
};
export const updateClient = async (info: any) => {
  return await axios.patch(`${api_url}clients/me/`, info);
};
export const editClientInfo = async (info: any, id: string) => {
  return await axios.put(`${api_url}clients/${id}/`, info);
};
export const confirmClientInvitation = async (info: any) => {
  return await axios.post(`${api_url}users/invite/confirm/`, info);
};
export const createClientCompany = async (data: any) => {
  return await axios.post(`${api_url}client-companies/`, data);
};
export const editClientCompany = async (data: any, company_id: string) => {
  return await axios.patch(`${api_url}client-companies/${company_id}/`, data);
};

/* CRUD for Client location addresses */

const createClientAddress = async (
  company_id: string,
  address: { address: string },
) => {
  return await axios.post(
    `${api_url}client-companies/${company_id}/office-locations/`,
    address,
  );
};

const editClientAddress = async (
  company_id: string,
  locId: string,
  address: { address: string },
) => {
  return await axios.patch(
    `${api_url}client-companies/${company_id}/office-locations/${locId}/`,
    address,
  );
};

export const deleteClientLocation = async (id: string, company_id: string) => {
  return await axios.delete(
    `${api_url}client-companies/${company_id}/office-locations/${id}/`,
  );
};

export const createClientAddressList = async (
  company_id: string,
  list: { id: string; address: string }[],
) => {
  const addresses = list.map((el) => {
    if (el.id) {
      return editClientAddress(company_id, el.id, { address: el.address });
    } else {
      return createClientAddress(company_id, { address: el.address });
    }
  });
  return await axios.all(addresses);
};
