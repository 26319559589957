import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Form, Row, Col, message } from 'antd';
import Button from '../UI/buttons/Button';
import FormTitle from '../components/auth/FormTitle';
import SettingsMenu from '../components/settings/SettingsMenu';
import FormItem from '../components/form/FormItem';
import Input from '../UI/inputs/Input';
import { getPaymentsSettings, updatePaymentsSettings } from '../api/payments';
import ReactGa from 'react-ga4';

const PageWrapper = styled.section`
  background: #f2f6fa;
  width: 100%;
  display: flex;
  min-height: 100vh;
  flex-flow: row-reverse;
`;
const FormWrapper = styled.div`
  background: #fff;
  width: 61%;
  padding: 4.5rem 4rem;
  display: flex;
  flex-direction: column;
  position: relative;

  @media (max-width: 1200px) {
    width: 70%;
  }
  @media (max-width: 768px) {
    width: 90%;
    padding: 3rem;
  }
`;

const StyledForm = styled(Form)`
  margin-top: 2.5rem;
  width: 100%;
`;

const PaymentInfoSettings = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getPaymentsSettings().then((res) => {
      form.setFieldsValue(res.data);
    });
  }, [form]);
  useEffect(() => {
    ReactGa.pageview('/payment-info');
  }, []);
  const onFinish = (values: any) => {
    setLoading(true);
    updatePaymentsSettings(values)
      .then((res) => {
        setLoading(false);
        message.success(t('INFORMATION_SAVE_SUCCESS'), 5);
      })
      .catch(() => setLoading(false));
  };
  return (
    <PageWrapper>
      <FormWrapper>
        <SettingsMenu menu="payment" />
        <StyledForm
          scrollToFirstError={true}
          layout="vertical"
          name="invitation"
          onFinish={onFinish}
          form={form}
        >
          <FormTitle text={t('PAYMENT_INFO')} />
          <FormItem label={t('BANK_ACCOUNT_NAME')} name="bank_account_name">
            <Input size="large" />
          </FormItem>

          <FormItem label={t('CHAMBER_OF_COMMERCE')} name="chamber">
            <Input size="large" />
          </FormItem>

          <FormItem className="no-margin">
            <Row gutter={16} justify="space-between">
              <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 24 }}
                lg={{ span: 12 }}
              >
                <FormItem label={t('IBAN_LABEL')} name="iban">
                  <Input size="large" />
                </FormItem>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 24 }}
                lg={{ span: 12 }}
              >
                <FormItem label={t('VAT_LABEL')} name="vat">
                  <Input size="large" />
                </FormItem>
              </Col>
            </Row>
          </FormItem>

          <FormItem label={t('ADDRESS_LABEL')} name="address">
            <Input size="large" />
          </FormItem>

          <FormItem
            label={t('EMAIL_LABEL')}
            name="email"
            rules={[
              {
                type: 'email',
                message: t('INVALID_EMAIL_ERROR'),
              },
            ]}
          >
            <Input size="large" type="email" />
          </FormItem>

          <FormItem>
            <Button type="primary" htmlType="submit" loading={loading}>
              {t('SAVE_TITLE')}
            </Button>
          </FormItem>
        </StyledForm>
      </FormWrapper>
    </PageWrapper>
  );
};

export default PaymentInfoSettings;
