import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Form, message } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Col, Row, Select } from 'antd';

import FormTitle from '../../auth/FormTitle';
import Button from '../../../UI/buttons/Button';
import {createNewSkill, getSkillsList} from '../../../api/skills';
import { getCertificatesList } from '../../../api/certificates';
import {
  updateJobCandidateProfile,
  getJobCandidateProfile,
} from '../../../api/jobs';
import FormItem from '../FormItem';
import { requiredRule } from '../../../utils/validationRules';

import { Certificate, Job, Language, Skill, Education } from '../../../types';
import SelectInput from '../../../UI/inputs/Select';
import Textarea from '../../../UI/inputs/Textarea';
import CheckboxInput from '../../../UI/inputs/CheckboxInput';
import { getLanguagesList } from '../../../api/languages';
import RouterBlocker from '../../jobs/RouterBlocker';
import { ReactComponent as InfoIcon } from '../../../icons/info-icon-rounded.svg';
import { getEducationList } from '../../../api/education';
import Input from '../../../UI/inputs/Input';
import NumberInput from '../../../UI/buttons/NumberInput';
import { FormSubtitle } from '../JobInfoForm/styles';
import { workingTypeOptions } from '../../../constants/common';
import {relocationOptions} from "../../../constants/relocations";
import _ from "lodash";

const { Option } = Select;

const FormActions = styled.div`
  padding: 1.5rem 2rem;
  border-radius: 8px;
  background-color: #f2f6fa;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

interface JobCandidateProfileFormProps {
  job: Job | undefined;
  onFinish: (id: string) => void;
}

const JobCandidateProfileForm = ({
  job,
  onFinish,
}: JobCandidateProfileFormProps) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [saveLoading, setSaveLoading] = useState(false);
  const [skills, setSkills] = useState<Skill[]>([]);
  // eslint-disable-next-line
  const [relocation, setRelocation] = useState(job?.relocation ? job.relocation : 0);

  const [languages, setLanguages] = useState<Language[]>([]);

  const [availableSkills, setAvailableSkills] = useState<any>({
    key_skills: [],
    preferable_skills: [],
  });

  const [certificates, setCertificates] = useState<Certificate[]>([]);
  
  const [availableCertificates, setAvailableCertificates] = useState<any>({
    min_certificates: [],
    preferable_certificates: [],
  });

  const [education, setEducation] = useState<Certificate[]>([]);

  const [availableEducation, setAvailableEducation] = useState<any>({
    min_education: [],
    preferable_education: [],
  });

  const changeRelocationOption = (e: number) => {
    setRelocation(e);
  }

  useEffect(() => {
    getSkillsList().then((res) => {
      setSkills(res.data);
      setAvailableSkills({
        key_skills: res.data,
        preferable_skills: res.data,
      });
    });

    getLanguagesList().then((res) => {
      setLanguages(res.data);
    });

    getCertificatesList().then((res) => {
      setCertificates(res.data);
      setAvailableCertificates({
        min_certificates: res.data,
        preferable_certificates: res.data,
      });
    });

    getEducationList().then((res) => {
      setEducation(res.data);
      setAvailableEducation({
        min_education: res.data,
        preferable_education: res.data,
      });
    })

    if (job && job.id) {
      getJobCandidateProfile(job.id).then((res) => {
        let formData = { ...res.data };
        form.setFieldsValue(formData);
      });
    }
  }, [job, form]);

  const collectFormValues = () => {
    const draft = form.getFieldsValue();
    return draft;
  };

  const saveJobInfo = async (isDraft: boolean) => {
    let jobId = job?.id;
    if (jobId) {
      const draft = collectFormValues();
      await updateJobCandidateProfile(jobId, draft);
      message.success(t('INFORMATION_SAVE_SUCCESS'), 5);

      return jobId;
    }
    return jobId;
  };

  const handleNext = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (job?.id) {
      handleSave(e).then(() => {
        onFinish(job.id);
      }).catch(error => {
        if (error.response.data.error_code === 'access_forbidden_error') {
          return;
        }
      });
    }
  };

  const handleSave = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      setSaveLoading(true);
      await saveJobInfo(true);
      setBlockTransition(false);
      setSaveLoading(false);
    } catch (error) {
      if (error.response.data.error_code === 'access_forbidden_error') {
        if (error.response.data.error_code === 'access_forbidden_error') {
          message.error(t('JOB_UNDER_REVIEW'), 5);
          setBlockTransition(false);
          setSaveLoading(false);
          throw error;
        }
      }
      message.error(error.response.data.message, 5);
      setBlockTransition(false);
      setSaveLoading(false);
    }
  };

  const [blockTransition, setBlockTransition] = useState(false);

  const handleFieldsChange = (changed: any, all: any) => {
    setBlockTransition(true);

    const values = form.getFieldsValue();
    setAvailableSkills({
      key_skills: skills.filter((s) => {
        return values['preferable_skills']
          ? values['preferable_skills'].indexOf(s.id) === -1
          : true;
      }),
      preferable_skills: skills.filter((s) => {
        return values['key_skills']
          ? values['key_skills'].indexOf(s.id) === -1
          : true;
      }),
    });

    setAvailableCertificates({
      min_certificates: certificates.filter((s) => {
        return values['preferable_certificates']
          ? values['preferable_certificates'].indexOf(s.id) === -1
          : true;
      }),
      preferable_certificates: certificates.filter((s) => {
        return values['min_certificates']
          ? values['min_certificates'].indexOf(s.id) === -1
          : true;
      }),
    });

    setAvailableEducation({
      min_education: education.filter((s) => {
        return values['preferable_education']
          ? values['preferable_education'].indexOf(s.id) === -1
          : true;
      }),
      preferable_education: education.filter((s) => {
        return values['min_education']
          ? values['min_education'].indexOf(s.id) === -1
          : true;
      }),
    });
  };

  const handleKeySkillChange = (value: any, object: any) => {
    let emptyKey = null;
    for (const key in object) {
      if (_.isEmpty(object[key])) {
        emptyKey = key;
      }
    }

    if (emptyKey && value[emptyKey]) {
      // @ts-ignore
      const newSkill = value.splice(emptyKey, 1)[0];
      if (newSkill) {
        // @ts-ignore
        createNewSkill(newSkill).then((res) => {
          const newSkillId = res.data.id;
          const newSkillName = res.data.name;
          // @ts-ignore
          value.push(newSkillId);
          form.setFieldsValue({
            'key_skills': value
          });
          availableSkills.key_skills.push({'id': newSkillId, 'name': newSkillName});
          setAvailableSkills({
            key_skills: availableSkills.key_skills,
            preferable_skills: availableSkills.preferable_skills,
          });
        })
      }
    }
  };

  const handlePreferableSkillChange = (value: any, object: any) => {
    let emptyKey = null;
    for (const key in object) {
      if (_.isEmpty(object[key])) {
        emptyKey = key;
      }
    }

    if (emptyKey && value[emptyKey]) {
      // @ts-ignore
      const newSkill = value.splice(emptyKey, 1)[0];
      if (newSkill) {
        // @ts-ignore
        createNewSkill(newSkill).then((res) => {
          const newSkillId = res.data.id;
          const newSkillName = res.data.name;
          // @ts-ignore
          value.push(newSkillId);
          form.setFieldsValue({
            'preferable_skills': value
          });
          availableSkills.preferable_skills.push({'id': newSkillId, 'name': newSkillName});
          setAvailableSkills({
            key_skills: availableSkills.key_skills,
            preferable_skills: availableSkills.preferable_skills,
          });
        })
      }
    }
  };

  return (
    <>
      <RouterBlocker isBlocked={blockTransition}/>
      <Form
        scrollToFirstError={true}
        layout="vertical"
        name="job-form"
        form={form}
        onFieldsChange={handleFieldsChange}
      >
        <FormTitle text={t('CANDIDATE_PROFILE_TITLE')} />
        <FormItem
          label={t('IDEAL_CANDIDATE')}
          name="ideal_candidate"
          tooltip={t('JOB_PROFILE_FORM_IDEAL_CANDIDATE_TOOLTIP')
            ? { title: t('JOB_PROFILE_FORM_IDEAL_CANDIDATE_TOOLTIP'), icon: <InfoIcon /> }
            : false}
        >
          <Textarea autoSize={{ minRows: 2 }} />
        </FormItem>

        <FormItem
          label={t('KEY_SKILLS')}
          name="key_skills"
          rules={requiredRule(t)}
          tooltip={t('JOB_PROFILE_FORM_KEY_SKILLS_TOOLTIP')
            ? { title: t('JOB_PROFILE_FORM_KEY_SKILLS_TOOLTIP'), icon: <InfoIcon /> }
            : false}
        >
          <SelectInput
            size="large"
            mode="tags"
            onChange={handleKeySkillChange}
            allowClear
            optionFilterProp="children"
            filterOption={(input, option) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            suffixIcon={<CaretDownOutlined style={{ color: '#000' }} />}
          >
            {availableSkills['key_skills'].map((skill: Skill) => (
              <Option key={skill.name} value={skill.id}>
                {skill.name}
              </Option>
            ))}
          </SelectInput>
        </FormItem>

        <FormItem
          label={t('PREFERABLE_SKILLS')}
          name="preferable_skills"
          //rules={requiredRule(t)}
          tooltip={t('JOB_PROFILE_FORM_PREFERABLE_SKILLS_TOOLTIP')
            ? { title: t('JOB_PROFILE_FORM_PREFERABLE_SKILLS_TOOLTIP'), icon: <InfoIcon /> }
            : false}
        >
          <SelectInput
            size="large"
            mode="tags"
            onChange={handlePreferableSkillChange}
            allowClear
            optionFilterProp="children"
            filterOption={(input, option) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            suffixIcon={<CaretDownOutlined style={{ color: '#000' }} />}
          >
            {availableSkills['preferable_skills'].map((skill: Skill) => (
              <Option key={skill.id} value={skill.id}>
                {skill.name}
              </Option>
            ))}
          </SelectInput>
        </FormItem>

        <FormItem
          label={t('SOFT_SKILLS')}
          name="soft_skills"
          //rules={requiredRule(t)}
          tooltip={t('JOB_PROFILE_FORM_SOFT_SKILLS_TOOLTIP')
            ? { title: t('JOB_PROFILE_FORM_SOFT_SKILLS_TOOLTIP'), icon: <InfoIcon /> }
            : false}
        >
          <Input size="large" />
        </FormItem>

        <FormItem
          label={t('INDUSTRY_EXP')}
          name="industry_experiences"
          //rules={requiredRule(t)}
          tooltip={t('JOB_PROFILE_FORM_INDUSTRY_EXP_TOOLTIP')
            ? { title: t('JOB_PROFILE_FORM_INDUSTRY_EXP_TOOLTIP'), icon: <InfoIcon /> }
            : false}
        >
          <Input size="large" />
        </FormItem>



        <FormItem className="no-margin">
          <Row gutter={16} justify="space-between">
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 24 }}
              lg={{ span: 12 }}
            >
              <FormItem
                label={t('LANGUAGES')}
                name="languages"
                rules={requiredRule(t)}
                tooltip={t('JOB_PROFILE_FORM_LANGUAGES_TOOLTIP')
                  ? { title: t('JOB_PROFILE_FORM_LANGUAGES_TOOLTIP'), icon: <InfoIcon /> }
                  : false}
              >
                <SelectInput
                  size="large"
                  mode="multiple"
                  allowClear
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  suffixIcon={<CaretDownOutlined style={{ color: '#000' }} />}
                >
                  {languages.map(language => {
                    return <Option key={language.code} value={language.code}>
                      {language.name}
                    </Option>
                  })}
                </SelectInput>
              </FormItem>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 24 }}
              lg={{ span: 12 }}
            >
              <FormItem
                label={t('PREFERABLE_LANGUAGE')}
                name="preferable_language"
                // rules={requiredRule(t)}
                tooltip={t('JOB_PROFILE_FORM_PREFERABLE_LANGUAGE_TOOLTIP')
                  ? { title: t('JOB_PROFILE_FORM_PREFERABLE_LANGUAGE_TOOLTIP'), icon: <InfoIcon /> }
                  : false}
              >
                <SelectInput
                  size="large"
                  mode="multiple"
                  allowClear
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  suffixIcon={<CaretDownOutlined style={{ color: '#000' }} />}
                >
                  {languages.map(language => {
                    return <Option key={language.code} value={language.code}>
                      {language.name}
                    </Option>
                  })}
                </SelectInput>
              </FormItem>
            </Col>
          </Row>
        </FormItem>
        
        <FormItem className="no-margin">
          <Row gutter={16} justify="space-between">
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 24 }}
              lg={{ span: 12 }}
            >
              <FormItem
                label={t('EDUCATION_MINIMUM')}
                name="min_education"
                //rules={requiredRule(t)}
                tooltip={t('JOB_PROFILE_FORM_MIN_EDUCATION_TOOLTIP')
                  ? { title: t('JOB_PROFILE_FORM_MIN_EDUCATION_TOOLTIP'), icon: <InfoIcon /> }
                  : false}
              >
                <SelectInput
                  size="large"
                  mode="multiple"
                  allowClear
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  suffixIcon={<CaretDownOutlined style={{ color: '#000' }} />}
                >
                  {availableEducation['min_education'].map(
                    (education: Education) => (
                      <Option key={education.id} value={education.id}>
                        {education.name}
                      </Option>
                    ),
                  )}
                </SelectInput>
              </FormItem>
            </Col>

            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 24 }}
              lg={{ span: 12 }}
            >
              <FormItem
                label="&nbsp;"
                name="min_education_custom"
              >
                <Input size="large" placeholder={t('PLACEHOLDER_NOTHING_FITS')} />
              </FormItem>
            </Col>
          </Row>
        </FormItem>

        <FormItem className="no-margin">
          <Row gutter={16} justify="space-between">
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 24 }}
              lg={{ span: 12 }}
            >
              <FormItem
                label={t('EDUCATION_NICE_TO_HAVE')}
                name="preferable_education"
                //rules={requiredRule(t)}
                tooltip={t('JOB_PROFILE_FORM_PREFERABLE_EDUCATION_TOOLTIP')
                  ? { title: t('JOB_PROFILE_FORM_PREFERABLE_EDUCATION_TOOLTIP'), icon: <InfoIcon /> }
                  : false}
              >
                <SelectInput
                  size="large"
                  mode="multiple"
                  allowClear
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  suffixIcon={<CaretDownOutlined style={{ color: '#000' }} />}
                >
                  {availableEducation['preferable_education'].map(
                    (education: Education) => (
                      <Option key={education.id} value={education.id}>
                        {education.name}
                      </Option>
                    ),
                  )}
                </SelectInput>
              </FormItem>
            </Col>

            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 24 }}
              lg={{ span: 12 }}
            >
              <FormItem
                label="&nbsp;"
                name="preferable_education_custom"
              >
                <Input size="large" placeholder={t('PLACEHOLDER_NOTHING_FITS')} />
              </FormItem>
            </Col>
          </Row>
        </FormItem>

        <FormItem
          label={t('CERTIFICATION_MINIMUM')}
          name="min_certificates"
          //rules={requiredRule(t)}
          tooltip={t('JOB_PROFILE_FORM_MIN_CERTIFICATES_TOOLTIP')
            ? { title: t('JOB_PROFILE_FORM_MIN_CERTIFICATES_TOOLTIP'), icon: <InfoIcon /> }
            : false}
        >
          <SelectInput
            size="large"
            mode="multiple"
            allowClear
            optionFilterProp="children"
            filterOption={(input, option) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            suffixIcon={<CaretDownOutlined style={{ color: '#000' }} />}
          >
            {availableCertificates['min_certificates'].map(
              (certificate: Certificate) => (
                <Option key={certificate.id} value={certificate.id}>
                  {certificate.name}
                </Option>
              ),
            )}
          </SelectInput>
        </FormItem>

        <FormItem
          label={t('CERTIFICATION_NICE_TO_HAVE')}
          name="preferable_certificates"
          //rules={requiredRule(t)}
          tooltip={t('JOB_PROFILE_FORM_PREFERABLE_CERTIFICATES_TOOLTIP')
            ? { title: t('JOB_PROFILE_FORM_PREFERABLE_CERTIFICATES_TOOLTIP'), icon: <InfoIcon /> }
            : false}
        >
          <SelectInput
            size="large"
            mode="multiple"
            allowClear
            optionFilterProp="children"
            filterOption={(input, option) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            suffixIcon={<CaretDownOutlined style={{ color: '#000' }} />}
          >
            {availableCertificates['preferable_certificates'].map(
              (certificate: Certificate) => (
                <Option key={certificate.id} value={certificate.id}>
                  {certificate.name}
                </Option>
              ),
            )}
          </SelectInput>
        </FormItem>

        <FormItem className="no-margin">
          <FormSubtitle>{t('JOB_PROFILE_FORM_REMOTE_TITLE')}</FormSubtitle>
        </FormItem>

        <FormItem className="no-margin">
          <Row gutter={16} justify="space-between">
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 24 }}
              lg={{ span: 12 }}
            >
              <FormItem
                label={t('DAYS_AT_THE_OFFICE')}
                name={'days_at_the_office'}
                tooltip={t('JOB_INFO_FORM_DAYS_AT_THE_OFFICE_TOOLTIP')
                  ? { title: t('JOB_INFO_FORM_DAYS_AT_THE_OFFICE_TOOLTIP'), icon: <InfoIcon /> }
                  : false}
              >
                <NumberInput
                  size="large"
                  type={'number'}
                />
              </FormItem>
            </Col>

            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 24 }}
              lg={{ span: 12 }}
            >
              <FormItem
                label={t('DAYS_WORKING_FROM_HOME')}
                name={'days_working_from_home'}
                tooltip={t('JOB_INFO_FORM_DAYS_WORKING_FROM_HOME_TOOLTIP')
                  ? { title: t('JOB_INFO_FORM_DAYS_WORKING_FROM_HOME_TOOLTIP'), icon: <InfoIcon /> }
                  : false}
              >
                <NumberInput
                  size="large"
                  type={'number'}
                />
              </FormItem>
            </Col>
          </Row>
        </FormItem>

        <FormItem
          label={t('TYPE')}
          name="working_type"
          tooltip={t('JOB_INFO_FORM_TYPE_TOOLTIP') ? { title: t('JOB_INFO_FORM_TYPE_TOOLTIP'), icon: <InfoIcon /> } : false}
        >
          <SelectInput
            size="large"
            filterOption={true}
            suffixIcon={<CaretDownOutlined style={{ color: '#000' }} />}
            defaultActiveFirstOption={false}
          >
            {workingTypeOptions.map(({ id, label}) => (
              <Option key={id} value={id}>
                {t(label)}
              </Option>
            ))}
          </SelectInput>
        </FormItem>

        <FormItem className="no-margin">
          <FormSubtitle>{t('VISA_SPONSORSHIP')}</FormSubtitle>
        </FormItem>

        <FormItem name="is_visa_available" valuePropName="checked">
          <CheckboxInput>
            {t('VISA_SPONSORSHIP_AVAILABLE')}
          </CheckboxInput>
        </FormItem>

        <FormItem className="no-margin">
          <FormSubtitle>{t('RELOCATION_REQUIREMENTS')}</FormSubtitle>
        </FormItem>
        
        <FormItem name="relocation">
          <SelectInput
              size="large"
              onChange={changeRelocationOption}
              filterOption={true}
              suffixIcon={<CaretDownOutlined style={{ color: '#000' }} />}
              defaultActiveFirstOption={true}
          >
            {relocationOptions.map(({ id, label}) => (
                <Option key={id} value={id}>
                  {t(label)}
                </Option>
            ))}
          </SelectInput>
        </FormItem>

        <FormItem
          label={t('RELOCATION_PACKAGE')}
          name="relocation_package"
          tooltip={t('JOB_PROFILE_FORM_RELOCATION_PACKAGE_TOOLTIP')
            ? { title: t('JOB_PROFILE_FORM_RELOCATION_PACKAGE_TOOLTIP'), icon: <InfoIcon /> }
            : false}
        >
          <Textarea autoSize={{ minRows: 2 }} />
        </FormItem>

        <FormActions>
          <Button type="ghost" loading={saveLoading} onClick={handleSave}>
            {t('SAVE_DRAFT_TITLE')}
          </Button>
          <Button type="primary" onClick={handleNext}>
            {t('NEXT_TITLE')}{' '}
          </Button>
        </FormActions>
      </Form>
    </>
  );
};

export default JobCandidateProfileForm;
