import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getUnreadNotificationsCount } from '../api/notifications';
import { logoutUser } from './userSlice';

interface NotificationState {
  unreadCount: number;
}

const initialState = {
  unreadCount: 0,
} as NotificationState;

export const getUnreadCount = createAsyncThunk(
  'notifications/getUnreadCount',
  async () => {
    const response = await getUnreadNotificationsCount();
    return response.data.unread_notifications_cnt;
  },
);

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    decreaseCounter: (state) => {
      if (state.unreadCount > 0){
        state.unreadCount -= 1;
      }
    },
    resetCounter: (state) => {
      state.unreadCount = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutUser.fulfilled, (state, action) => {
      state.unreadCount = 0;
    });
    builder.addCase(getUnreadCount.fulfilled, (state, action) => {
      state.unreadCount = action.payload;
    });
  },
});


export const {
  decreaseCounter,
  resetCounter,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
