import { useState } from 'react';

const useSwitchUser = () => {
  const [isVisible, setVisible] = useState(false);

  return {
    actions: {
      setVisible,
    },
    data: {
      isVisible
    }
  }
}

export default useSwitchUser;
