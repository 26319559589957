import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

import useSwitchUserPopup from './hooks';
import FormItem from '../../../form/FormItem';
import { StyledModal } from './styles';
import SelectInput from '../../../../UI/inputs/Select';
import Button from '../../../../UI/buttons/Button';

const { Option } = Select;

interface Iprops {
  isVisible: boolean,
  setVisible: (isVisible: boolean) => void,
}

const SwitchUserPopup = ({
  isVisible,
  setVisible
}:Iprops) => {
  const [t] = useTranslation();

  const {
    actions: {
      handleSelectClient,
      handleSelectRecruiter,
      handleConfirm,
      handleBackToAdminRole,
    },
    data: {
      clients,
      recruiters,
      selectedClientId,
      selectedRecruiterId,
      loading,
      isAdmin,
    }
  } = useSwitchUserPopup(isVisible);

  const handleClose = () => setVisible(false);

  return (
    <StyledModal
      visible={isVisible}
      title={t('SWITCH_ROLE')}
      onOk={handleConfirm}
      onCancel={handleClose}
      footer={isAdmin ? [
        <Button
          type="primary"
          onClick={handleConfirm}
          loading={loading}
        >
          {t('SELECT_ROLE')}
        </Button>,
        <Button
          type="ghost"
          onClick={handleClose}
        >
          {t('CANCEL_TEXT')}
        </Button>,
    ] : [
      <Button
        type="primary"
        onClick={handleBackToAdminRole}
        loading={loading}
      >
        {t('BACK_TO_ADMIN_ROLE')}
      </Button>,
      <Button
        type="ghost"
        onClick={handleClose}
      >
        {t('CANCEL_TEXT')}
      </Button>,
    ]}
    >
      {isAdmin && (
        <>
          <FormItem>
            <p>{t('CLIENTS_SELECT_LABEL')}</p>
            <SelectInput
              className="custom-select"
              size="large"
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              notFoundContent={null}
              onSelect={handleSelectClient}
              value={selectedClientId}
            >
              {clients.map((client) => (
                <Option key={client.id} value={client.id}>
                  {`${client.name} - ${client.company?.name}`}
                </Option>
              ))}
            </SelectInput>
          </FormItem>
      
          <FormItem>
            <p>{t('RECRUITERS_SELECT_LABEL')}</p>
            <SelectInput
              className="custom-select"
              size="large"
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              notFoundContent={null}
              onSelect={handleSelectRecruiter}
              value={selectedRecruiterId}
            >
              {recruiters.map((rec) => (
                <Option key={rec.id} value={rec.id}>
                  {`${rec.name} - ${rec.company?.name}`}
                </Option>
              ))}
            </SelectInput>
          </FormItem>
        </>
      )}
    </StyledModal>
  );
}

export default SwitchUserPopup;
