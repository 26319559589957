import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

interface PointsLeftProps {
    points?: number;
    customClass?: string;
    disabled?: boolean;
}

const PointsLeftWrapper = styled.div`
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.8px;
  text-align: left;
  color: #fff;
  text-transform: uppercase;
  margin-top: 8px;
  
  &.color-black {
    color: #061c2e;
  }
  
  &.left-float {
    float: left;
    display: inline-flex;
  }

  gap: 4px;
  padding: 4px 24px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  
  .points-count {
    width: 16px;
    height: 16px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    padding: 0 5px;
    border-radius: 88px;
    background-color: #34ce72;
    color: #fff;
    &.disabled-points {
      background-color: #eb5757;
    }
    &.big-label {
      width: 19px;
      height: 19px;
      padding: 0 3px;
    }
  }
`
const PointsLeft = ({points, customClass, disabled, ...rest}: PointsLeftProps) => {
    const [t] = useTranslation();
    // @ts-ignore
    let customLabelClass = '';
    if (disabled) {
        customLabelClass += ' disabled-points';
        points = 0;
    } else {
        // @ts-ignore
        customLabelClass = points > 9 ? 'big-label' : '';
    }

    return (
        <PointsLeftWrapper className={customClass}  {...rest} >
                <span className={`points-count ${customLabelClass}`}>{points ?? 0}</span>{t('POINTS_LEFT')}
        </PointsLeftWrapper>
    );
};

export default PointsLeft;
