import styled from "styled-components";
import React from "react";
import {
  calcuclateAlertType,
  StyledAlertIcon,
} from "../../recruiter/RecruiterManagementBoardFooter";
import { Job } from "../../../types";

const StatisticItem = styled.div`
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  border: solid 1px #dae5ec;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.8px;
  text-align: center;
  color: #061c2e;
  margin-bottom: 0.25rem;
  position: relative;

  @media (max-width: 992px) {
    width: 23%;
  }
  @media (max-width: 576px) {
    width: 48%;
  }
`;

interface JobSelectedRecruitersStatisticProps {
  numberOfSelectedRecruiters: number;
  highThreshold: number;
  setShowModal: React.Dispatch<React.SetStateAction<any>>;
  showModal: boolean;
  setSelectedJob: React.Dispatch<React.SetStateAction<any>>;
  job: Job;
}
export default function JobSelectedRecruitersStatistic({
  numberOfSelectedRecruiters,
  highThreshold,
  showModal,
  setShowModal,
  setSelectedJob,
  job,
}: JobSelectedRecruitersStatisticProps) {
  let color = calcuclateAlertType(numberOfSelectedRecruiters, highThreshold);

  if (numberOfSelectedRecruiters === 0) {
    return (
      <StatisticItem
        style={{
          color: "#34CE72",
          borderWidth: "1px",
          borderColor: "#34CE72",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
        }}
        onClick={() => {setShowModal(!showModal); setSelectedJob(job.id)}}
      >
        {"Assign talent suppliers"}
      </StatisticItem>
    );
  }

  return (
    <StatisticItem
      style={{
        color: color,
        borderWidth: "1px",
        borderColor: color,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
      }}
    >
      {`Talent suppliers: ${numberOfSelectedRecruiters}`}
      {color !== "#34CE72" && (
        <StyledAlertIcon
          color={color}
          style={{ width: "20px", height: "20px", display: "inline" }}
        />
      )}
    </StatisticItem>
  );
}
